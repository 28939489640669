import {useEffect, useRef, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Navigate, Route, Routes, useLocation} from 'react-router-dom'
import {MenuComponent} from '../../_metronic/assets/ts/components'
import {updateProfile} from '../store/slices/profile'
import {RoutesPath} from './RoutesPath'
import {PageDataProvider} from '../../_metronic/layout/core'
import {AsideDefault} from '../../_metronic/layout/components/aside/AsideDefault'
import {HeaderWrapper} from '../../_metronic/layout/components/header/HeaderWrapper'
import {Content} from '../../_metronic/layout/components/Content'
// import {DashboardWrapper} from '../modules/dashboard/DashboardWrapper'
// import Departments from '../modules/dashboard/Departments'
import Settings from '../modules/settings/Settings'
import {ScrollTop} from '../../_metronic/layout/components/ScrollTop'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {useGetProfileDetailsQuery} from './store/api'
import {Loader} from '../../_metronic/partials'
import ComingSoonCard from '../modules/our-forest/ComingSoonCard'
import Notifier from '../../_metronic/partials/components/notifier/Notifier'
import {DashboardWrapper} from '../modules/dashboard/DashboardWrapper'
import { DashboardWrapper2 } from '../modules/dashboard/DashboardWrapper2'
import { DashboardDetails } from '../modules/dashboard/details/DashboardDetails'
import { DashboardLeaderboard} from '../modules/dashboard/leaderboard/DashboardLeaderboard'
import {DivisionWrapper} from '../modules/dashboard/divisions/DivisionWrapper'
import {DepartmentWrapper} from '../modules/dashboard/departments/DepartmentWrapper'
import {LocationWrapper} from '../modules/dashboard/locations/LocationWrapper'
import {EditProfile} from '../modules/settings/EditProfile'
import {ChangePassword} from '../modules/settings/ChangePassword'
import {EmployeeDetails} from '../modules/employee/EmployeeDetails'
// import {EmployeeWrapper} from '../modules/employees/EmployeeWrapper'
import {EditEmployeeDetails} from '../modules/employee/EditEmployeeDetails'
import {AddNewEmployee} from '../modules/employee/AddNewEmployee'
import ComingSoonCardAfter14Days from '../modules/our-forest/ComingSoonCardAfter14Days'
import {getLocalStorageItem} from '../utils/storage'
// import AddExistingForest from '../modules/our-forest/AddExistingForest'
// import AllocateProjects from '../modules/our-forest/AllocateProjects'
// import ProjectList from '../modules/our-forest/ProjectList'
// import OurForestWrapper from '../modules/our-forest/OurForestWrapper'
import './styles.css'
import ProjectList from '../modules/our-forest/ProjectList'
import ProjectUpdates from '../modules/our-forest/ProjectUpdates'
import AllocateProjects from '../modules/our-forest/AllocateProjects'
import Configuration from '../modules/configuration/Configuration'
import AddUtilityBillPage from '../modules/configuration/AddUtilityBillPage'
import UtilitiesPage from '../modules/configuration/UtilitiesPage'
import EmptyUtilitiesPage from '../modules/configuration/EmptyUtilitiesPage'
import EmptyEmployeesPage from '../modules/employee/EmptyEmployeesPage'
import UploadEmployeeCSV from '../modules/employee/UploadEmployeeCSV'
import AuditableReportsPage from '../modules/configuration/audit-reports/audit-report-page'
import CustomTagsPage from '../modules/configuration/custom-tags'
import MasterDataPage from '../modules/configuration/master-data/MasterData'
import { RootState } from '../store/store'
import OrgReportsPage from '../modules/configuration/audit-reports'
import CustomGroupsPage from '../modules/configuration/custom-groups'
import ChallengesPage from '../modules/configuration/challenges'
import CreateChallengesPage from '../modules/configuration/challenges/screens/create-challenges'
import ChallengeDetailsPage from '../modules/configuration/challenges/screens/challenge-details'
import AttributesPage from '../modules/configuration/attributes'
import CreateAttributesPage from '../modules/configuration/attributes/screens/create-attributes-page'
import AttributeDetailsPage from '../modules/configuration/attributes/screens/attribute-details-page'
import QuizzesPage from '../modules/configuration/quizzes'
import QuizDetailsPage from '../modules/configuration/quizzes/screens/quiz-details'


const PrivateLayout = () => {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const location = useLocation()
  const dispatch = useDispatch()
  const {data: profileDetails, isLoading} = useGetProfileDetailsQuery()
  const { filterCategory, filterMonth } = useSelector((state: RootState) => state.filters)
  const [selectedMonth, setMonth] = useState<string>('All')

  const [daysSinceLiveOnDate, setDaysSinceLiveOnDate] = useState<number>(0)

  useEffect(() => {
    // Calculate the number of days since the live on date
    const liveOnDate = new Date(getLocalStorageItem('live-on'))
    const currentDate = new Date()
    const timeDiff = currentDate.getTime() - liveOnDate.getTime()
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24))
    setDaysSinceLiveOnDate(daysDiff)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [])

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = 0
    }
    setTimeout(() => {
      MenuComponent.reinitialization()
    }, 500)
  }, [location.key])

  useEffect(() => {
    if (profileDetails) dispatch(updateProfile(profileDetails))
  }, [profileDetails])

  if (isLoading) return <Loader />

  return (
    <PageDataProvider>
      <div className='page d-flex flex-row flex-column-fluid'>
        <AsideDefault />
        <div className='wrapper d-flex flex-column flex-row-fluid bkg-white' id='kt_wrapper'>
          <HeaderWrapper />
          <div
            id='kt_content'
            ref={scrollRef}
            className='content content-height d-flex flex-column custom-margin overflow-auto'
          >
            <div className='d-flex flex-column-fluid'>
              <Content>
                <Routes>
                  <Route
                    path={RoutesPath.AUTH_ALL}
                    element={<Navigate to={RoutesPath.DASHBOARD} />}
                  />
                  <Route
                    path={RoutesPath.DEFAULT}
                    element={<Navigate to={RoutesPath.DASHBOARD} />}
                  />
                  {/* Pages */}

                  {/* <Route
                    path={RoutesPath.DASHBOARD}
                    element={<DashboardWrapper month={selectedMonth} />}
                  /> */}
                  <Route
                    path={RoutesPath.DASHBOARD}
                    element={<DashboardWrapper2 month={filterMonth} />}
                  />
                  <Route
                    path={RoutesPath.DETAILS}
                    element={<DashboardDetails month={filterMonth} category={filterCategory} />}
                  />
                  <Route
                    path={RoutesPath.LEADERBOARD}
                    element={<DashboardLeaderboard />}
                  />
                  {/* <Route
                    path={RoutesPath.DIVISIONS}
                    element={<DivisionWrapper month={selectedMonth} />}
                  />
                  <Route
                    path={RoutesPath.DEPARTMENTS}
                    element={<DepartmentWrapper month={selectedMonth} />}
                  />
                  <Route
                    path={RoutesPath.LOCATIONS}
                    element={<LocationWrapper month={selectedMonth} />} 
                  /> */}
                  {/* <Route path={RoutesPath.ALL_DEPARTMENTS} element={<Departments />} /> */}
                  {/* <Route path={RoutesPath.EMPLOYEES} element={<EmployeeWrapper />} /> */}
                  <Route path={RoutesPath.EMPLOYEES.ROOT} element={<EmployeeDetails />} />
                  <Route path={RoutesPath.SETTINGS} element={<Settings />} />
                  <Route path={RoutesPath.CONFIGURATION.ROOT} element={<Configuration />} />
                  <Route path={RoutesPath.CONFIGURATION.UTILITY} element={<UtilitiesPage />} />
                  <Route path={RoutesPath.CONFIGURATION.EMPTY_UTILITY} element={<EmptyUtilitiesPage />} />
                  <Route path={RoutesPath.CONFIGURATION.ADD_UTILITY} element={<AddUtilityBillPage />} />
                  <Route path={RoutesPath.ORG_REPORTS.ROOT} element={<OrgReportsPage />} />
                  <Route path={RoutesPath.ORG_REPORTS.REPORT} element={<AuditableReportsPage />} />
                  <Route path={RoutesPath.QUIZZES.ROOT} element={<QuizzesPage />} />
                  <Route path={RoutesPath.QUIZZES.QUIZZES_DETAILS} element={<QuizDetailsPage />} />
                  <Route path={RoutesPath.CHALLENGES.ROOT} element={<ChallengesPage />} />
                  <Route path={RoutesPath.CHALLENGES.CREATE_CHALLENGES} element={<CreateChallengesPage />} />
                  <Route path={RoutesPath.CHALLENGES.CHALLENGE_DETAILS} element={<ChallengeDetailsPage />} />
                  <Route path={RoutesPath.ATTRIBUTES.ROOT} element={<AttributesPage />} />
                  <Route path={RoutesPath.ATTRIBUTES.CREATE_ATTRIBUTES} element={<CreateAttributesPage />} />
                  <Route path={RoutesPath.ATTRIBUTES.DETAILS} element={<AttributeDetailsPage />} />
                  <Route path={RoutesPath.CUSTOM_TAGS} element={<CustomTagsPage />} />
                  <Route path={RoutesPath.CUSTOM_GROUPS} element={<CustomGroupsPage />} />
                  <Route path={RoutesPath.MASTER_DATA} element={<MasterDataPage />} />
                  {/* <Route path={RoutesPath.OUR_FOREST.ROOT} element={<ComingSoonCard />} /> */}
                  <Route
                    path={RoutesPath.OUR_FOREST.ROOT}
                    // element={
                    //   daysSinceLiveOnDate <= 14 ? <ComingSoonCard /> : <ComingSoonCardAfter14Days />
                    // }
                    element={<ProjectList />}
                  />
                  <Route
                    path={RoutesPath.OUR_FOREST.ALLOCATE_PROJECT}
                    element={<AllocateProjects />}
                  />
                  <Route path={RoutesPath.PROFILE_EDIT} element={<EditProfile />} />
                  <Route path={RoutesPath.OUR_FOREST.PROJECT_UPDATE} element={<ProjectUpdates />} />
                  <Route path={RoutesPath.CHANGE_PASSWORD} element={<ChangePassword />} />
                  <Route path={RoutesPath.EMPLOYEES.EMPLOYEES_DETAILS} element={<EmployeeDetails />} />
                  <Route path={RoutesPath.EMPLOYEES.EDIT_EMPLOYEE} element={<EditEmployeeDetails />} />
                  <Route path={RoutesPath.EMPLOYEES.ADD_EMPLOYEE} element={<AddNewEmployee />} />
                  <Route path={RoutesPath.EMPLOYEES.EMPTY_EMPLOYEE} element={<EmptyEmployeesPage />} />
                  <Route path={RoutesPath.EMPLOYEES.UPLOAD_CSV} element={<UploadEmployeeCSV />} />
                  {/* <Route path={RoutesPath.ADD_EXISTING_FOREST} element={<AddExistingForest />} />
                  
                  <Route path={RoutesPath.OUR_FOREST.ROOT} element={<OurForestWrapper />} /> */}
                  {/** TODO: Our Forest pages to be implemented in future release */}
                  {/* * <Route path={RoutesPath.OUR_FOREST.ROOT} element={<OurForestWrapper />} />
                 
                  <Route path={RoutesPath.OUR_FOREST.ADD_PROJECT} element={<AddProject />} />
                  <Route path={RoutesPath.OUR_FOREST.EDIT_PROJECT} element={<AddProject />} /> */}
                  <Route
                    path={RoutesPath.LOGIN}
                    element={<Navigate to={RoutesPath.DASHBOARD} />}
                  />
                  {/* <Route index element={<DashboardWrapper />} /> */}
                  <Route path={RoutesPath.ALL} element={<Navigate to={RoutesPath.ERROR_404} />} />
                  <Route path={RoutesPath.ERROR_ALL} element={<ErrorsPage />} />
                </Routes>
                <Notifier />
              </Content>
            </div>
          </div>
        </div>
      </div>

      <ScrollTop />
    </PageDataProvider>
  )
}

export {PrivateLayout}
