import React, {useState, useEffect} from 'react'
import mixpanel from 'mixpanel-browser';
import { useLazyGetUtilityBillListQuery } from './store/api';
import '../../routing/styles.css'
import EmptyUtilitiesPage from './EmptyUtilitiesPage'
import UtilitiesPage from './UtilitiesPage'
import { EVENT_TYPE, PAGES } from '../../analytics/tracker-types';

const Configuration: React.FC = () => {
  const [
    getUtilityBillList,
    {data: utilityBillListData, isLoading: isLoadingUB, isError: isErrorUB, isSuccess: isSuccessUB},
  ] = useLazyGetUtilityBillListQuery()

  // API CALLS
  const listData:any = utilityBillListData
  useEffect(() => {
    getUtilityBillList({})
    // tracking
    mixpanel.track(EVENT_TYPE.PageView, {'name': PAGES.ConfigurationPage})
  }, [])

  if (isLoadingUB) {
    return (
      <div className='wrap-loader-utility-bill'>
        <h2 style={{color: 'grey'}}>Fetching utility bills..</h2>
      </div>
    )
  } else {
    return (isSuccessUB && listData.length>0) ? 
      <UtilitiesPage listData={listData} /> : 
      <EmptyUtilitiesPage /> 
  }
}

export default Configuration
