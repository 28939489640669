/* eslint no-nested-ternary: "error" */

import {CSSProperties, useCallback, useEffect, useRef} from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import { useLazyGetNetZeroPercentageQuery } from '../../../../app/modules/dashboard/store/api'
import { getLocalStorageItem } from '../../../../app/utils/storage'
import { getYYYYDDMMFormat } from '../../../../app/utils/dateFormatter'

const chartOptions = (chartColor, percentageOnboarded) => {
    const baseColor = getCSSVariableValue(`--bs-${chartColor}`)
    const lightColor = getCSSVariableValue(`--bs-${chartColor}-light`)
    const labelColor = getCSSVariableValue('--bs-gray-700')
    return {
      chart: {
          height: 140,
          width: 180,
          type: "radialBar",
        },
        series: [percentageOnboarded],
        colors: ["#232B47"],
        plotOptions: {
          radialBar: {
            
            startAngle: -90,
            endAngle: 90,
            
            dataLabels: {
              name: {
                show: false,
              },
              
              value: {
                fontSize: "18px",
                show: true,
                formatter: (val ) => `${val}%`,
              }
            }
          }
        },
        fill: {
          type: "solid",
        },
        stroke: {
          lineCap: "butt",
        },
        labels: ["Progress"]
    };
}

const NetZeroStatusChart = () => {
  const chartRef = useRef(null)
  const {mode} = useThemeMode()
  const {profile} = useSelector((state) => state.profile)
  const {filterYear} = useSelector((state) => state.filters)
  
  const [getNetZeroPercentage, {
    data: getNetZeroPercentageData
  }] = useLazyGetNetZeroPercentageQuery()

  const liveOnDate = new Date(getLocalStorageItem('live-on'))
  const netZeroToDateLocal = getLocalStorageItem('ws-net-zero-to-date') || null;

  const timePeriodFilter = liveOnDate.getUTCMonth().toString()

  const getDateFilters = useCallback(() => {
    const currentDate = new Date()
    if (timePeriodFilter) {
      const selectedMonth = parseInt(timePeriodFilter, 10)
      const selectedYear =
        selectedMonth > currentDate.getUTCMonth()
          ? currentDate.getUTCFullYear() - 1
          : currentDate.getUTCFullYear()

      const fromDate = getYYYYDDMMFormat(
        new Date(selectedYear, parseInt(timePeriodFilter, 10), 1)
      )

      const toDate = (profile?.organization?.isWorkshopOrg) ? 
        // (netZeroToDateLocal || moment(profile?.organization?.workshopDate).add(89, 'days').format('YYYY-MM-DD'))
        '2030-12-31'
        : getYYYYDDMMFormat(currentDate)
      return {
        fromDate,
        toDate,
      }
    }
    return undefined
  }, [timePeriodFilter])
    
  useEffect(() => {
    if (timePeriodFilter) {
      getNetZeroPercentage({
        ...(timePeriodFilter && {...getDateFilters()}),
      })
    }
  }, [timePeriodFilter]) 

  const percentageNetZeroRecapture = Math.round(getNetZeroPercentageData?.data?.percentage || 0);

  const refreshChart = useCallback(() => {
    if (!chartRef.current) {
      return undefined
    }

    const chartColor = 'danger'
    const chart = new ApexCharts(chartRef.current, chartOptions(chartColor, percentageNetZeroRecapture))
    if (chart) {
      chart.render()
    }
    return chart
  }, [percentageNetZeroRecapture])
  
    useEffect(() => {
      const chart = refreshChart()
  
      return () => {
        if (chart) {
          chart.destroy()
        }
      }
    }, [chartRef, mode, refreshChart])

    
   return ( 
      <div className='d-flex align-items-center flex-column m-0 p-0 position-relative'>
        {/* <div ref={chartRef} className='semi m-0 p-0'/>
        <p style={{color: '#666666', fontSize: '13px', position: 'absolute', bottom: '2px',}}>Recaptured</p> */}
        <div className='d-flex flex-column w-100 mb-5 align-items-center'>
          <div className='semi-donut-model mt-2' style={{'--percentage': `${percentageNetZeroRecapture > 100 ? 100 : percentageNetZeroRecapture  }`}}>
            <div className='d-flex flex-column mt-3 align-items-center'>
            <span className='percent-val' style={{fontWeight: 800, fontSize: '15px'}}>
              {
                (profile?.organization?.isWorkshopOrg && filterYear !== '2030') ? 
                  '--' : 
                  percentageNetZeroRecapture
              }%
            </span>
            <span className='percent-val'>
              Recapture
            </span>
            </div>


          </div>

          {/* <div className='progress mb-2' style={{width: '90%', height: '12px', backgroundColor: '#ebebeb'}}>
            <div
              className='progress-bar'
              role='progressbar'
              style={{width: `${percentageNetZeroRecapture}%`, backgroundColor: '#232B47'}}
            />
          </div> */}
        </div>
      </div>
    )
  }
  export {NetZeroStatusChart}
  
/* eslint no-nested-ternary: "error" */