const Loader = () => (
  <div className='text-center'>
    {/* <div
      className='spinner-border text-primary'
      style={{width: '3rem', height: '3rem'}}
      role='status'
    > */}
    <span>Loading ...</span>
    {/* </div> */}
  </div>
)

export default Loader
