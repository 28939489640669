import React, { useState, useEffect } from 'react'
import { commaSeparatedFormat } from "../../../../utils/helpers";

const RenderTitleCellTravel = ({idx, title, carbon=0}:any) => {
  let emission:number|string = Number(carbon);
  if (emission<10 && emission!==0) {
    emission = commaSeparatedFormat(emission?.toFixed(1))
  } else {
    emission = commaSeparatedFormat(Math.round(emission))
  }
  return (
    <h2 className="accordion-header table-title-wrapper" id={`travelLocationSection${idx}`}>
      <button className="accordion-button fs-3 bold bg-white" type="button" data-bs-toggle="collapse" data-bs-target={`#panel-travelLocationSection${idx}`} aria-expanded="true" aria-controls={`panel-travelLocationSection${idx}`}>
        <h4 className='report-sec-header table-title-text my-0'>{title}</h4>
      </button>
      <div className='accordion-right-header table-cell-values-wrap'>
        <div/>
        <p className='table-cell-value mb-0 fw-bolder' style={{fontSize: '15px'}}>{emission}</p>
      </div>
    </h2>
  )
}

const RenderTitleCellUtility = ({idx, title, carbon=0}:any) => {
  let emission:number|string = Number(carbon);
  if (emission<10 && emission!==0) {
    emission = commaSeparatedFormat(emission?.toFixed(1))
  } else {
    emission = commaSeparatedFormat(Math.round(emission))
  }
  return (
    <h2 className="accordion-header table-title-wrapper" id={`utilitiesLocationSection${idx}`}>
      <button className="accordion-button fs-3 bold bg-white" type="button" data-bs-toggle="collapse" data-bs-target={`#panel-utilitiesLocationSection${idx}`} aria-expanded="true" aria-controls={`panel-utilitiesLocationSection${idx}`}>
        <h4 className='report-sec-header table-title-text my-0'>{title}</h4>
      </button>
      <div className='accordion-right-header table-cell-values-wrap'>
        <div/>
        <p className='table-cell-value mb-0 fw-bolder' style={{fontSize: '15px'}}>{emission}</p>
      </div>
    </h2>
  )
}

const RenderCellTravel = ({idx, title, distance=0, carbon=0}:any) => {
  let dis:number|string = Number(distance);
  let emission:number|string = Number(carbon );
  if (dis<10 && dis!==0) {
    dis = commaSeparatedFormat(dis?.toFixed(1))
  } else {
    dis = commaSeparatedFormat(Math.round(dis))
  }
  if (emission<10 && emission!==0) {
    emission = commaSeparatedFormat(emission?.toFixed(1))
  } else {
    emission = commaSeparatedFormat(Math.round(emission))
  }
  return (
    <h2 className="accordion-header table-cell-wrapper" style={{marginLeft: '20px'}} id={`travelActivitiesSection${idx}`}>
      <button className="accordion-button collapsed fs-3 bold bg-white" type="button" data-bs-toggle="collapse" data-bs-target={`#panel-travelActivitiesSection${idx}`} aria-expanded="false" aria-controls={`panel-travelActivitiesSection${idx}`}>
        <h4 className='report-sec-header table-title-text my-0' style={{fontSize: '15px', color: "#707070"}}>{title}</h4>
      </button>
      <div className='accordion-right-header table-cell-values-wrap' style={{width: '35.5%'}}>
        <p className='table-cell-value mb-0 fw-bolder' style={{fontSize: '15px'}}>{dis}</p>
        <p className='table-cell-value mb-0 fw-bolder' style={{fontSize: '15px'}}>{emission}</p>
      </div>
    </h2>
  )
}

const RenderElectricCellUtility = ({idx, title, units=0, carbon=0}:any) => {
  const unit:string = commaSeparatedFormat(Math.round(Number(units)));
  let emission:number|string = Number(carbon);
  if (emission<10 && emission!==0) {
    emission = commaSeparatedFormat(emission?.toFixed(1))
  } else {
    emission = commaSeparatedFormat(Math.round(emission))
  }

  if (emission === '0' && unit === '0') return null;

  return (
    <h2 className="accordion-header table-cell-wrapper" style={{marginLeft: '20px'}} id={`utilitiesElectricSection${idx}`}>
      <button className="accordion-button collapsed fs-3 bold bg-white" type="button" data-bs-toggle="collapse" data-bs-target={`#panel-utilitiesElectricSection${idx}`} aria-expanded="false" aria-controls={`panel-utilitiesElectricSection${idx}`}>
        <h4 className='report-sec-header table-title-text my-0' style={{fontSize: '15px', color: "#707070"}}>{title}</h4>
      </button>
      <div className='accordion-right-header table-cell-values-wrap' style={{width: '35.5%'}}>
        <p className='table-cell-value mb-0 fw-bolder' style={{fontSize: '15px'}}>{unit}</p>
        <p className='table-cell-value mb-0 fw-bolder' style={{fontSize: '15px'}}>{emission}</p>
      </div>
    </h2>
  )
}

const RenderGasCellUtility = ({idx, title, units=0, carbon=0}:any) => {
  const unit:string = commaSeparatedFormat(Math.round(Number(units)));
  let emission:number|string = Number(carbon);
  if (emission<10 && emission!==0) {
    emission = commaSeparatedFormat(emission?.toFixed(1))
  } else {
    emission = commaSeparatedFormat(Math.round(emission))
  }

  if (emission === '0' && unit === '0') return null;

  return (
    <h2 className="accordion-header table-cell-wrapper" style={{marginLeft: '20px'}} id={`utilitiesGasSection${idx}`}>
      <button className="accordion-button collapsed fs-3 bold bg-white" type="button" data-bs-toggle="collapse" data-bs-target={`#panel-utilitiesGasSection${idx}`} aria-expanded="false" aria-controls={`panel-utilitiesGasSection${idx}`}>
        <h4 className='report-sec-header table-title-text my-0' style={{fontSize: '15px', color: "#707070"}}>{title}</h4>
      </button>
      <div className='accordion-right-header table-cell-values-wrap' style={{width: '35.5%'}}>
        <p className='table-cell-value mb-0 fw-bolder' style={{fontSize: '15px'}}>{unit}</p>
        <p className='table-cell-value mb-0 fw-bolder' style={{fontSize: '15px'}}>{emission}</p>
      </div>
    </h2>
  )
}

const RenderSubCellTravel = ({title, distance=0, carbon=0}:any) => {
  let dis:number|string = Number(distance);
  let emission:number|string = Number(carbon);
  if (dis<10 && dis!==0) {
    dis = commaSeparatedFormat(dis?.toFixed(1))
  } else {
    dis = commaSeparatedFormat(Math.round(dis))
  }
  if (emission<10 && emission!==0) {
    emission = commaSeparatedFormat(emission?.toFixed(1))
  } else {
    emission = commaSeparatedFormat(Math.round(emission))
  }
  return (
    <div className='table-cell-wrapper'>
      <p className='table-cell-title mb-0' style={{marginLeft: '60px'}}>{title}</p>
      <div className='table-cell-values-wrap'>
        <p className='table-cell-value mb-0'>{dis}</p>
        <p className='table-cell-value mb-0'>{emission}</p>
      </div>
    </div>
  )
}

const RenderSubCellUtility = ({title, units=0, carbon=0}:any) => {
  const unit:string = commaSeparatedFormat(Math.round(Number(units)));
  let emission:number|string = Number(carbon);
  if (emission<10 && emission!==0) {
    emission = commaSeparatedFormat(emission?.toFixed(1))
  } else {
    emission = commaSeparatedFormat(Math.round(emission))
  }

  if (emission === '0' && unit === '0') return null;

  return (
    <div className='table-cell-wrapper'>
      <p className='table-cell-title mb-0' style={{marginLeft: '60px'}}>{title}</p>
      <div className='table-cell-values-wrap'>
        <p className='table-cell-value mb-0'>{unit}</p>
        <p className='table-cell-value mb-0'>{emission}</p>
      </div>
    </div>
  )
}

const AccordionOrgReport: React.FC<any> = (props) => {
  const {reportData} = props; 

  const [travelData, setTravelData] = useState<any[]>([])
  const [utilitiesData, setUtilitiesData] = useState<any[]>([])

  useEffect(() => {
    if (reportData.travel !== null) {
      // console.log(reportData)
      setTravelData(Object.values(reportData.travel))
    } 
    if (reportData.utilities !== null) {
      setUtilitiesData(Object.values(reportData.utilities))
    }
  }, [reportData])

  return (
    <div className="accordion" id="accordion">

      <div className="accordion-item">
        <h2 className="accordion-header" id="travelSection">
          <button className="accordion-button fs-3 bold bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#panel-travelSection" aria-expanded="true" aria-controls="panel-travelSection">
            <p className='report-sec-header'>Employees Travel</p>
          </button>
          <div className='accordion-right-header'>
            <h4>KMs</h4>
            <h4>KgCO2</h4>
          </div>
        </h2>
        <div id="panel-travelSection" className="accordion-collapse collapse show" aria-labelledby="travelSection">
          <div className="accordion-body">
            <div className="accordion" id="accordion-travelLocations">
              {
                travelData.length > 0 && 
                travelData.map((item:any, index:number) => {

                  const activitiesData = Object.values(item.activities);
                  const activitiesCells = activitiesData.map((act:any, idx:number) => (
                    <div className="accordion" key={act?.activityModel} id="accordion-travelActivities">
                      <div className="accordion-item">
                        <RenderCellTravel idx={`${idx}${item.locationId}`} title={act?.activityModel} distance={act?.distance} carbon={act?.emission} />
                        <div id={`panel-travelActivitiesSection${idx}${item.locationId}`} className="accordion-collapse collapse" aria-labelledby={`travelActivitiesSection${idx}${item.locationId}`}>
                          {
                            act?.fuelTypes.map((fuelType:any) => (
                              <RenderSubCellTravel key={fuelType?.fuelType} title={fuelType?.fuelType} distance={fuelType?.distance} carbon={fuelType?.emission} />
                            ))
                          }
                        </div>
                      </div>
                    </div>
                  ))

                  return (
                    <div className="accordion-item" key={item?.location}>
                      <RenderTitleCellTravel idx={index} title={item?.location} distance={item?.distance} carbon={item?.emission} />
                      <div id={`panel-travelLocationSection${index}`} className="accordion-collapse collapse show" aria-labelledby={`travelLocationSection${index}`}>
                        {activitiesCells}
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>

      <div className="accordion-item">
        <h2 className="accordion-header" id="utilitiesSection">
          <button className="accordion-button fs-3 bold bg-white" type="button" data-bs-toggle="collapse" data-bs-target="#panel-utilitiesSection" aria-expanded="true" aria-controls="panel-utilitiesSection">
            <p className='report-sec-header'>Power Consumption</p>
          </button>
          <div className='accordion-right-header'>
            <h4>KWh or Ltrs</h4>
            <h4>KgCO2</h4>
          </div>
        </h2>
        <div id="panel-utilitiesSection" className="accordion-collapse collapse show" aria-labelledby="utilitiesSection">
          <div className="accordion-body">
            <div className="accordion" id="accordion-utilitiesLocations">
              {
                utilitiesData.length > 0 && 
                utilitiesData.map((item:any, index:number) => {
                  const activities = Object.values(item?.activities);
                  return (
                    <div className="accordion-item" key={item?.location}>
                      <RenderTitleCellUtility idx={index} title={item?.location} carbon={item?.emission} />
                      <div id={`panel-utilitiesLocationSection${index}`} className="accordion-collapse collapse show" aria-labelledby={`utilitiesLocationSection${index}`}>
                        <div className="accordion" id="according-utilitiesEnergy">
                          <div className="accordion-item">
                            <RenderElectricCellUtility idx={`${index}electric`} title='Electricity' units={item?.electricUnits} carbon={item?.electricEmission} />
                            <div id={`panel-utilitiesElectricSection${index}electric`} className="accordion-collapse collapse" aria-labelledby={`utilitiesElectricSection${index}electric`}>
                              {
                                activities.map((act: any) => (
                                  <RenderSubCellUtility key={act?.activityModel} title={act?.activityModel} units={act?.electricUnits} carbon={act?.electricEmission} />
                                ))
                              }
                            </div>
                          </div>
                          <div className="accordion-item">
                            <RenderGasCellUtility idx={`${index}gas`} title='Natural Gas' units={item?.gasUnits} carbon={item?.gasEmission} />
                            <div id={`panel-utilitiesGasSection${index}gas`} className="accordion-collapse collapse" aria-labelledby={`utilitiesGasSection${index}gas`}>
                              {
                                activities.map((act: any) => (
                                  <RenderSubCellUtility key={act?.activityModel} title={act?.activityModel} units={act?.gasUnits} carbon={act?.gasEmission} />
                                ))
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default AccordionOrgReport;