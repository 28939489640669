import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux'

import { TrashIcon2 } from '../../../../../_metronic/assets/icons'
import { RootState } from '../../../../store/store'

type ModalDeleteCustomGroupTypes = {
  show: boolean
  onHide: () => void
  onDelete: () => void
}

export default function ModalDeleteCustomGroup(props: ModalDeleteCustomGroupTypes) {
  const { onHide, onDelete } = props

  const {profile} = useSelector((state:RootState) => state.profile)

  const handleDelete = () => {
    // workshop handling
    if (profile?.organization?.isWorkshopOrg)
      return;
    
    onDelete()
    onHide()
  }

  return (
    <Modal {...props} aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Body>
        <button type='button' className='remove-btn-styles modal-header-cross-btn' onClick={onHide}>
          <i className="bi bi-x-lg" style={{fontSize: '16px', color: 'black'}} />
        </button>
        <div className='d-flex align-items-center justify-content-center flex-column'>
          <div className='custom-tag-delete-modal-trash-wrap'>
            <TrashIcon2 style={{color: 'black', height: '60px', width: '60px'}} />
          </div>
          <h4 className='m-0 fs-2 mt-8'>Are you sure?</h4>
          <h6 className='m-0 mt-4'>Do you want to delete this group?</h6>
        </div>
        <div className='d-flex align-items-center justify-content-between mb-5 mt-15 px-15'>
          <button
            type="button"
            className='remove-btn-styles btn-outline-round-style'
            style={{fontWeight: '600'}}
            onClick={onHide}
          >
            CANCEL
          </button>
          <button
            type="button"
            className='remove-btn-styles btn-outline-round-style'
            style={{color: '#C03E35', fontWeight: '600'}}
            onClick={handleDelete}
          >
            DELETE
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}