import {FC} from 'react'
import {EmissionContributionChartForAllMonths} from '../../../_metronic/partials/components/chart/EmissionContributionChartForAllMonths'
import { ActivityEmission } from './type'

type EmissionContributionForAllMonth = {
  locationType: string;
}

const EmissionContributionForAllMonth: FC<EmissionContributionForAllMonth> = ({locationType}) => (
  <EmissionContributionChartForAllMonths
    locationType={locationType}
  />
)

export {EmissionContributionForAllMonth}
