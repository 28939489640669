import React from 'react'
import {WithChildren} from '../react18MigrationHelpers'

type Props = {
  className?: string
}

const ModifySVG: React.FC<Props & WithChildren> = ({className = '', children}) => (
  <span className={`svg-icon ${className}`}>{children}</span>
)

export {ModifySVG}
