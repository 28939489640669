/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback, useEffect, useRef, useState} from 'react'
import { useSelector } from 'react-redux'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {
  useLazyGetDivisionsListQuery,
  useLazyGetDepartmentsListQuery,
  useLazyGetLocationsListQuery,
  useLazyGetEmissionTrendsQuery,
} from '../../../../app/modules/dashboard/store/api'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import {getLocalStorageItem} from '../../../../app/utils/storage'
import {convertDailyToMonthly, convertDailyToMonthlyWithActivities, getMonthFormat, getYYYYDDMMFormat} from '../../../../app/utils/dateFormatter'
import {DateRangeType} from '../types'
import {ChartValueTypes} from '../../../../app/modules/dashboard/type'
import '../styles.css'
import { RootState } from '../../../../app/store/store'
import { commaSeparatedFormat, convertMonthYearToDate } from '../../../../app/utils/helpers'

type EmissionContributionChartForAllMonthsProps = {
  disableAnimation?: boolean
  locationType: string
}

function getChartOptions(
  _height: number, 
  categoryVal: string, 
  locationType: string, 
  series2Data: number[], 
  totalActivitiesSeriesData: any[],
  subActivitiesSeriesData: any[],
  categories: string[], 
  avgEployeesFlag: boolean
  ): ApexOptions {

  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')

  return {
    chart: {
      fontFamily: 'inherit',
      type: 'bar',
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: true,
      onItemClick: {
        toggleDataSeries: false,
      },
      onItemHover: {
        highlightDataSeries: false,
      },
      markers: {
        width: 15,
        height: 15,
        strokeColor: borderColor,
        strokeWidth: 0,
        fillColors: ['#FD5C5C', '#F2888180'],
        offsetX: 0,
        offsetY: 0,
        radius: 0,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      colors: ['#FD5C5C', '#F2888180'],
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: [0, 0],
      colors: ['#FD5C5C', '#F2888180'],
      dashArray: [0, 0],
    },
    xaxis: {
      axisBorder: {
        show: false,
        // color: '#d8d8d8',
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        show: false,
      },
      tooltip: {
        enabled: false,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      tickAmount: 4,
      title: {
        text: 'kgCO2',
        style: {
          color: labelColor,
          fontSize: '12px',
        },
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      shared: true, 
      intersect: false,
      custom: (itm) => {
        let data1 = (itm.series[0][itm.dataPointIndex])
        if (data1<10 && data1!==0) {
          data1 = data1?.toFixed(1)
        } else {
          data1 = Math.round(data1)
        }
        // const data2 = categoryVal !== 'All' && Math.ceil(itm.series[1][itm.dataPointIndex] + data1)
        const series2DataAtIndex = series2Data[itm.dataPointIndex]
        let data2:any = (categoryVal !== 'All') ? (series2DataAtIndex) : 0
        if (data2<10 && data2!==0) {
          data2 = data2?.toFixed(1)
        } else {
          data2 = Math.round(data2)
        }

        let titleLabel = 'All Divisions'
        if (locationType === 'department') {
          titleLabel = 'All Departments'
        } else if (locationType === 'location') {
          titleLabel = 'All Locations'
        }

        // Activities Data
        const totActivities = totalActivitiesSeriesData[itm.dataPointIndex] || []
        const subActivities = subActivitiesSeriesData[itm.dataPointIndex] || []

        let strTotActivities = ''
        totActivities?.map((act: any, index: number) => {
          const name = act?.name || ''
          let emm = act?.emission || 0
          if (emm !== 0 && emm < 10) {
            emm = emm?.toFixed(1)
          } else {
            emm = Math.round(emm)
          }
          if (name.length !== 0) {
            strTotActivities = strTotActivities.concat(
              `<p 
                  style="
                    font-size: 13px; 
                    margin: 0px; 
                    margin-bottom: 2px;
                    margin-left: 20px;"
                >
                  ${name}: ${commaSeparatedFormat(emm)}
                </p>
                `
              )
          }
          return null
        })
        if (strTotActivities.length !== 0 && categoryVal !== 'All') {
          strTotActivities = strTotActivities.concat('<hr>')
        }
        let strSubActivities = ''
        subActivities?.map((act: any, index: number) => {
          const name = act?.name || ''
          let emm = act?.emission || 0
          if (emm !== 0 && emm < 10) {
            emm = emm?.toFixed(1)
          } else {
            emm = Math.round(emm)
          }
          if (name.length !== 0) {
            strSubActivities = strSubActivities.concat(
              `<p 
                  style="
                    font-size: 13px; 
                    margin: 0px; 
                    margin-bottom: 2px;
                    margin-left: 20px;"
                >
                  ${name}: ${commaSeparatedFormat(emm)}
                </p>
                `
              )
          }
          return null
        })

        if (avgEployeesFlag && categoryVal==='All') {
          return `<div>
                    <div style="padding:10px 15px 5px 10px; background-color:  #d8d8d8">
                      <h5>Emissions on ${categories[itm.dataPointIndex]} (kgCO2)</h5>
                    </div>
                    <div style="padding:10px 15px 5px 10px">
                      <h6 className="no-bold"> 
                        Avg. Emission : <span>${commaSeparatedFormat(data1)}</span>
                      </h6>
                    </div>
                  </div>`
        }
        return `<div>
                  <div style="padding:10px 15px 5px 10px; background-color:  #d8d8d8">
                    <h5>Emissions on ${categories[itm.dataPointIndex]} (kgCO2)</h5>
                  </div>
                  <div style="padding:10px 15px 5px 10px">
                    ${categoryVal === 'All' ? 
                      `
                        <h6 className="no-bold"> 
                          Total Emission : <span>${commaSeparatedFormat(data1)}</span>
                        </h6>
                        ${strTotActivities}
                      ` : 
                      `
                        <h6 className="no-bold"> 
                          ${titleLabel} : <span>${commaSeparatedFormat(data2)}</span>
                        </h6>
                        ${strTotActivities}
                        <h6> ${categoryVal} Emission : <span>${commaSeparatedFormat(data1)}</span></h6>
                        ${strSubActivities}
                      `
                    }
                  </div>
                </div>`
      },
      // y: {
      //   formatter: (val) => `${commaSeparatedFormat(Math.round(val))} kgCO2`,
      //   title: {
      //     formatter: (seriesName) => avgEployeesFlag ? 'Avg. Emission' : 'Total Emission',
      //   },
      // },
      style: {
        fontSize: '12px',
      },
      marker: {
        show: false,
      },
      followCursor: true,
    },
    colors: ['#FD5C5C', 'rgba(242, 136, 129,0.3)'],
    grid: {
      borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}

const EmissionContributionChartForAllMonths: FC<EmissionContributionChartForAllMonthsProps> = (
  props
) => {
  const {disableAnimation, locationType} = props
  // apis
  const [getDivisionsList, {data: divisionsList}] = useLazyGetDivisionsListQuery()
  const [getDepartmentsList, {data: departmentsList}] = useLazyGetDepartmentsListQuery()
  const [getLocationsList, {data: locationsList}] = useLazyGetLocationsListQuery()
  const [getTotalEmission, {data: totalEmissionData}] = useLazyGetEmissionTrendsQuery()
  const [getEmissionTrend, {data: emissionTrend}] = useLazyGetEmissionTrendsQuery()
  // state
  const avgEployeesFlag = useSelector((state: RootState) => state.flags.avgEployeesFlag)
  const {
    filterDate, 
    filterYear, 
    filterDivSubCategory, 
    filterDeptSubCategory, 
    filterLocSubCategory, 
    filterExtrapolation
  } = useSelector((state: RootState) => state.filters)
  const {profile} = useSelector((state: RootState) => state.profile)
  const [listData, setListData] = useState(['All'])
  const [categoryVal, setCategoryVal] = useState<string>('All')
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeType>()
  // const [chartValues, setChartValues] = useState<ChartValueTypes>()

  useEffect(() => {
    if (locationType === 'division') {
      setCategoryVal(filterDivSubCategory)
    }
    else if (locationType === 'department') {
      setCategoryVal(filterDeptSubCategory)
    }
    else if (locationType === 'location'){
      setCategoryVal(filterLocSubCategory)
    }
  }, [filterDivSubCategory, filterDeptSubCategory, filterLocSubCategory])

  const setInitialSelectedDate = () => {
    const currentDate = new Date()
    const liveOnDate = new Date(getLocalStorageItem('live-on'))
    // currentDate.setMonth(currentDate.getUTCMonth() + 4)

    setSelectedDateRange({
      startDate: new Date(currentDate.getUTCFullYear(), liveOnDate.getUTCMonth(), 1),
      endDate: new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 0),
    })
  }

  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const labelColor = getCSSVariableValue('--bs-gray-500')

  const populateDataList = (getApi: any, setData: any) => {
    getApi({})
      .then((res: any) => {
        const tempData = ['All']
        res.data?.forEach((itm: any) => {
          tempData.push(itm.name)
        })
        setData(tempData)
      })
      .catch((err: any) => console.log(err))
  }

  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
    setInitialSelectedDate()
    if (locationType === 'department') {
      populateDataList(getDepartmentsList, setListData)
    } else if (locationType === 'location') {
      populateDataList(getLocationsList, setListData)
    } else if (locationType === 'division') {
      populateDataList(getDivisionsList, setListData)
    }
  }, [])

  useEffect(() => {
    const liveOnDate = new Date(getLocalStorageItem('live-on'))
    const currentDate = new Date()
    // const reqObject1 = {
    //   fromDate: getYYYYDDMMFormat(liveOnDate),
    //   toDate: getYYYYDDMMFormat(currentDate),
    //   category: locationType,
    //   by_average: avgEployeesFlag,
    //   is_all: 'true',
    //   monthly: 'true',
    //   is_scaled: filterExtrapolation,
    // }
    // getTotalEmission(reqObject1)
    // if (categoryVal === 'All') {
    //   const reqObject = {
    //     fromDate: getYYYYDDMMFormat(liveOnDate),
    //     toDate: getYYYYDDMMFormat(currentDate),
    //     category: locationType,
    //     by_average: avgEployeesFlag,
    //     is_all: 'true',
    //     monthly: 'true',
    //     is_scaled: filterExtrapolation,
    //   }
    //   getEmissionTrend(reqObject)
    // } else {
    //   const reqObject = {
    //     fromDate: getYYYYDDMMFormat(liveOnDate),
    //     toDate: getYYYYDDMMFormat(currentDate),
    //     category: locationType,
    //     subCategory: categoryVal,
    //     by_average: avgEployeesFlag,
    //     is_all: 'true',
    //     monthly: 'true',
    //     is_scaled: filterExtrapolation,
    //   }
    //   getEmissionTrend(reqObject)
    // }

    if (filterDate.startDate !== '') {
      const reqObject1 = {
        fromDate: filterDate.startDate,
        toDate: filterDate.endDate,
        category: locationType,
        by_average: avgEployeesFlag,
        is_all: 'true',
        monthly: 'true',
        is_scaled: filterExtrapolation,
      }
      getTotalEmission(reqObject1)
      if (categoryVal === 'All') {
        const reqObject = {
          fromDate: filterDate.startDate,
          toDate: filterDate.endDate,
          category: locationType,
          by_average: avgEployeesFlag,
          is_all: 'true',
          monthly: 'true',
          is_scaled: filterExtrapolation,
        }
        getEmissionTrend(reqObject)
      } else {
        const reqObject = {
          fromDate: filterDate.startDate,
          toDate: filterDate.endDate,
          category: locationType,
          subCategory: categoryVal,
          by_average: avgEployeesFlag,
          is_all: 'true',
          monthly: 'true',
          is_scaled: filterExtrapolation,
        }
        getEmissionTrend(reqObject)
      }
    }
  }, [avgEployeesFlag, categoryVal, filterExtrapolation, filterDate])

  const liveOnDate = new Date(getLocalStorageItem('live-on'))
  const totalMonthlyData = convertDailyToMonthlyWithActivities(totalEmissionData || [], liveOnDate, filterYear, profile?.organization?.isWorkshopOrg)
  const monthlyData = convertDailyToMonthlyWithActivities(emissionTrend || [], liveOnDate, filterYear, profile?.organization?.isWorkshopOrg)
  const seriesData: number[] = []

  const categories: string[] = []
    
  monthlyData?.forEach((itm) => {
    // eslint-disable-next-line no-sequences, no-unused-expressions
    const newDate = convertMonthYearToDate(itm.monthYear) // for safari error - invalid date
    // const currentYear = new Date(itm.monthYear).getUTCFullYear()-2000;
    // const monthYearString = `${new Date(itm.monthYear).toLocaleString('default', { month: 'short' })} ${currentYear}`;
    const monthYearString = getMonthFormat(itm.monthYear);
    seriesData.push(itm?.emission);
    categories.push(monthYearString)
  })

  const totalSeriesData: number[] = new Array(12).fill(0)
  const totalSeriesDataCopy: number[] = new Array(12).fill(0)
  const totalActivitiesSeriesData: any[] = new Array(12).fill(null)
  const subActivitiesSeriesData: any[] = new Array(12).fill(null)

  totalMonthlyData?.forEach((itm, index) => {
    // eslint-disable-next-line no-sequences, no-unused-expressions
    const totHeight = itm.emission - (seriesData[index] || 0) 
    totalSeriesData[index] = (totHeight < 0) ? 0 : totHeight
    totalSeriesDataCopy[index] = itm.emission

    totalActivitiesSeriesData[index] = itm.activities
    
  })
  monthlyData?.forEach((itm, index) => {
    subActivitiesSeriesData[index] = itm?.activities
  })

  const series = [
    {
      name: categoryVal === 'All' ?  'Total Emissions' : `${categoryVal} Emission`,
      data: seriesData,
      type: 'bar',
    },
  ]
  if (categoryVal !== 'All') {
    series.push({
      name: `Total Emission`, 
      data: totalSeriesData, 
      type: 'bar',
    })
  }

  const refreshMode = useCallback(() => {
    if (!chartRef.current) {
      return undefined
    }
    const height = parseInt(getCSS(chartRef.current, 'height'), 10)
    const values = getChartOptions(height, categoryVal, locationType, totalSeriesDataCopy, totalActivitiesSeriesData, subActivitiesSeriesData, categories, avgEployeesFlag)
    const options = {
      ...values,
      series,
      xaxis: {...values.xaxis, categories, tickAmount: 5},
      ...(disableAnimation && {
        chart: {
          ...values.chart,
          animations: {
            enabled: false,
          },
        },
      }),
    }

    options.yaxis = {
      ...options.yaxis,
      labels: {
        formatter: (value) => `${commaSeparatedFormat(parseFloat(value?.toFixed(1)))}`,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    }

    const chart = new ApexCharts(chartRef.current, options)
    if (chart) {
      chart.render()
    }
    return chart
  }, [series, categories, disableAnimation])

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, refreshMode])

  const handleCategorySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCategoryVal(e.target.value)
  }

  return (
    <div className='card mb-5 mb-xl-8 pt-2 bordered-grey'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-6'>
        {/* begin::Wrapper */}
        <div style={{marginLeft: 'auto'}} className='d-flex align-items-center'>
          <select
            className='form-select form-select-sm w-100 form-select-solid'
            data-control='select2'
            data-placeholder='Latest'
            data-hide-search='true'
            value={categoryVal}
            onChange={handleCategorySelect}
          >
            {listData.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
        </div>
      </div>
      {/* end::Header */}

      <div className='card-body pt-4'>
        <div ref={chartRef} id='kt_charts_widget_3_chart' />
      </div>
    </div>
  )
}

export {EmissionContributionChartForAllMonths}
