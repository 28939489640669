/* eslint-disable global-require */
/* eslint no-restricted-syntax: ["error", "FunctionExpression", "WithStatement", "BinaryExpression[operator='in']"] */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import React, {useEffect, useMemo, useState} from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import { 
  useLazyGetQuizzesListQuery,
  useLazyGetQuizzesSummaryQuery
} from '../store/api';
import { RoutesPath } from '../../../routing/RoutesPath';
import { RootState } from '../../../store/store';
import { commaSeparatedFormat } from '../../../utils/helpers';

import ReactTable from '../../../../_metronic/partials/components/tables/ReactTable';
import { FilterExistingQuizzes } from './components/filter-existing-quizzes';
import { HeaderInfoCard } from './components/header-info-card';

import './styles.css';


type Props = {}

const transformQuizData = (data: any) => ((data?.quizzes?.length) ? data?.quizzes : [])

const RenderName = ({name, imgUrl, onClickHandler}: {name:string, imgUrl:string, onClickHandler:()=>void}) => (
  <div className='d-flex'>
    <img alt="name" src={imgUrl} style={{height: '24px', width: '24px', borderRadius: '12px', marginRight: '10px', objectFit: 'cover'}} /> 
    <button
      type="button"
      className="remove-btn-styles"
      style={{color: '#1F7F9D', fontWeight: '800'}}
      onClick={onClickHandler}
      >
      {name}
    </button>
  </div>
)

const RenderStatus = ({value}: {value:string}) => {
  switch (value) {
    case "upcoming":
      return (
        <span className='d-flex justify-content-center badge fw-semibold me-1 fs-6' style={{  color: '#C8AD2F' }}>
          Upcoming
        </span>
      )
    case "live":
      return (
        <span className='d-flex justify-content-center badge fw-semibold me-1 fs-6' style={{  color: '#82C44B' }}>
          Live
        </span>
      )
    default:
      return (
        <span className='d-flex justify-content-center badge fw-semibold me-1 fs-6' style={{  color: '#e64343' }}>
          Ended
        </span>
      )
  }
}

const RenderPeriod = ({startDate, endDate}: {startDate:string, endDate: string}) => (
  <div className='d-flex justify-content-center' style={{color: '#757575'}}>{moment(startDate?.slice(0,10)).format('DD/MM/YYYY')} - {moment(endDate?.slice(0,10)).format('DD/MM/YYYY')}</div>
)

const RenderValue = ({value}: {value:string}) => (
  <div className='d-flex justify-content-center' style={{color: '#757575'}}>{commaSeparatedFormat(value)}</div>
)

const QuizzesPage: React.FC<Props> = () => {

   /* ------------------------- REDUX DISPATCHER, SELECTOR --------------------------- */

   const intl = useIntl();
   const navigate = useNavigate();
   const { filterQuizzesType } = useSelector((state: RootState) => state.filters);

  /* ----------------------------- REACT STATES ------------------------------ */

  const [tableData, setTableData] = useState<any[]>([]);
  const [quizSummary, setQuizSummary] = useState<any>();

  const headers = useMemo(
    () => [
      {
        Header: <div style={{paddingLeft: '35px'}}>NAME</div>, 
        accessor: 'quizName',  
        Cell: ({cell}: {cell: any}) => (
          <RenderName 
            name={cell.row.original.title} 
            imgUrl={cell.row.original.imgUrl}
            onClickHandler={() => {navigate(RoutesPath.QUIZZES.QUIZZES_DETAILS, {state: cell.row.original})}} 
            />
        )
      },
      {
        Header: <div className='d-flex justify-content-center'>PERIOD</div>,
        accessor: 'period',
        Cell: ({cell}: {cell: any}) => <RenderPeriod startDate={cell.row.original.startDate} endDate={cell.row.original.endDate} />
      },
      {
        Header: <div className='d-flex justify-content-center'>STATUS</div>, 
        accessor: 'status',  
        Cell: ({cell}: {cell: any}) => <RenderStatus value={cell.row.original.status} />
      },
      {
        Header: <div className='d-flex justify-content-center'>NO. OF PARTICIPANTS</div>,
        accessor: 'participantCount',
        Cell: ({cell}: {cell: any}) => <RenderValue value={cell.row.original.participantCount} />
      },
    ],
    []
  )

  /* --------------------------------- RTK QUERY ----------------------------------- */

  const [getQuizzesData, {
    data: quizzesData,
    isLoading: isLoadingGetQuizzesData,
    isSuccess: isSuccessGetQuizzesData,
    isError: isErrorGetQuizzesData
  }] = useLazyGetQuizzesListQuery();

  const [getQuizzesSummary, {
    data: quizzesSummaryData,
    isLoading: isLoadingGetQuizzesSummaryData,
    isSuccess: isSuccessGetQuizzesSummaryData,
    isError: isErrorGetQuizzeSummarysData
  }] = useLazyGetQuizzesSummaryQuery();

  /* ---------------------------------- HANDLERS ----------------------------------- */

  useEffect(() => {
    if (quizzesData?.data) {
      setTableData(transformQuizData(quizzesData?.data));
    }
  }, [quizzesData])

  useEffect(() => {
    if (quizzesSummaryData?.data) {
      setQuizSummary(quizzesSummaryData?.data);
    }
  }, [quizzesSummaryData])

  /* --------------------------- COMPONENT MOUNT - API CALLS ----------------------------- */

  useEffect(() => {
    getQuizzesData({
      skip: 0,
      limit: 100,
    });
    getQuizzesSummary({});
  }, []);


  /* ----------------------------- TOAST MESSAGES ------------------------------ */

  return (
    <div className='blank-page-view pb-8 px-12 py-8'>
      {/* HEADER CARDS */}
      <div className='d-flex justify-content-between'>
        <HeaderInfoCard   
          label="TOTAL QUIZZES LAUNCHED"
          value={`${quizSummary?.totalQuizCount ? quizSummary?.totalQuizCount : '--'}`}
          containerStyle={{width: '25%'}}
          />
        <HeaderInfoCard 
          label="AVERAGE PARTICIPATION %"
          value={`${quizSummary?.avgParticipationPercentage ? Math.round(Number(quizSummary?.avgParticipationPercentage || 0)) : '--'}%`}
          containerStyle={{width: '25%'}}
          />
        <HeaderInfoCard 
          type="white"
          label="TOP EMPLOYEE"
          value={quizSummary?.topEmployee?.length>0 ? quizSummary?.topEmployee[0]?.name?.toUpperCase() : '--'}
          containerStyle={{width: '25%'}}
          />
        <HeaderInfoCard 
          type="white"
          label="TOP LOCATION"
          value={quizSummary?.topLocation?.length ? quizSummary?.topLocation[0]?.name?.toUpperCase() : '--'}
          containerStyle={{width: '25%'}}
          />
      </div>
      <div className='mt-6'>
        <h1 className='page-title-text fs-2 mb-6 mt-6'>EXISTING QUIZZES</h1>
        <FilterExistingQuizzes />
        {/* TABLE  */}
        <div className=''>
          <ReactTable
            headerData={headers}
            tableData={tableData?.filter((quiz:any) => {
              if (filterQuizzesType === 'all') return true;
              else return quiz.status === filterQuizzesType
            })}
            className='mt-6 custom-border'
            currentPageIndex={0}
            disableSort
            showPagination={false}
            message={intl.formatMessage({id: 'CONFIGURATION.QUIZZES.NO_RESULTS_FOUND'})}
            />
        </div>
      </div>
    </div>
  )
}

export default QuizzesPage;
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
/* eslint no-restricted-syntax: ["error", "FunctionExpression", "WithStatement", "BinaryExpression[operator='in']"] */
/* eslint-disable global-require */