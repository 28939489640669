/* eslint-disable global-require */
import React from 'react'
import {useNavigate} from 'react-router-dom'

import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { RoutesPath } from '../../routing/RoutesPath';
import './styles.css';

type Props = {

}

const EmptyUtilitiesPage: React.FC<Props> = () => {
  const navigate = useNavigate()
  return (
    <div className='blank-page-view py-8 px-12'>
      <h1 className='page-title-text'>UTILITIES</h1>
      <div className='centered-div-wrap'>
        <img src={toAbsoluteUrl('/media/illustrations/empty/EmployeesEmpty.png')} alt="no_utility_bill" />
        <h1 className='fs-1 text-center mt-10 bold'>Add Your Utility Bills!</h1>
        <p className='fs-5 my-5' style={{color: '#666666'}}>You can add your utility bills and get more accurate tracking of your emissions</p>
        <button
            type='button'
            // disabled={!loading}
            className='custom-primary-delete-button'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={()=>{navigate(RoutesPath.CONFIGURATION.ADD_UTILITY)}}
          >
          Add Utility Bill
        </button>
      </div>
      <div className='cloud-upload-bg bg-image-bottom-right' />
    </div>
  )
}

export default EmptyUtilitiesPage
/* eslint-disable global-require */