import {createApi} from '@reduxjs/toolkit/dist/query/react'
import {axiosBaseQueryWithReauth} from './axios-wrapper'
import {API_ENDPOINTS, API_TAGS} from './constants'

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: axiosBaseQueryWithReauth({
    baseUrl: `${process.env.REACT_APP_API_URL}${API_ENDPOINTS.ORGANIZATIONS}/`,
  }),
  endpoints: () => ({}),
  tagTypes: API_TAGS,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
})

export default baseApi

export const baseApiv2 = createApi({
  reducerPath: 'baseApiv2',
  baseQuery: axiosBaseQueryWithReauth({
    baseUrl: `${process.env.REACT_APP_API_URL_V2}${API_ENDPOINTS.ORGANIZATIONS}/`,
  }),
  endpoints: () => ({}),
  tagTypes: API_TAGS,
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true,
})
