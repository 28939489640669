import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {useLocation, useNavigate} from 'react-router-dom'
import mixpanel from 'mixpanel-browser'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {NotificationType} from '../../../_metronic/partials/components/types'
import {useAppDispatch, RootState} from '../../store/store'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {RoutesPath} from '../../routing/RoutesPath'
import {employeeDetailsInitValues as initialValues} from '../../../_metronic/partials/components/types'
import {Employee} from '../employee/types'
import {updateNotification} from '../../store/slices/notifier'
import ImageUploadModal from './ImageUploadModal'
import {updateProfile} from '../../store/slices/profile'
import { PageTitle } from '../../routing/RoutesPageTitle'
import {
  useGetDepartmentsQuery,
  useGetDivisionsQuery,
  useGetLocationsQuery,
} from '../employee/store/api'
import {useUpdateAdminMutation, useGetProfileDetailsQuery} from '../../routing/store/api'
import { EVENT_TYPE, PAGES } from '../../analytics/tracker-types'
import { EditOrgIcon } from '../../../_metronic/assets/icons'

const profileDetailsSchema = Yup.object().shape({
  departmentId: Yup.string(),
  divisionId: Yup.string(),
  locationId: Yup.string(),
})

interface employeeInterface {
  employee: Employee
}

const EditProfile = () => {
  const navigate = useNavigate()

  const {profile} = useSelector((state: RootState) => state.profile)

  // update page title
  useEffect(() => {
    document.title = PageTitle.PROFILE
    // tracking
    mixpanel.track(EVENT_TYPE.PageView, {'name': PAGES.EditProfilePage})
  }, [])

  const {data: departments} = useGetDepartmentsQuery()
  const {data: locations} = useGetLocationsQuery()
  const {data: divisions} = useGetDivisionsQuery()

  const {
    data: profileDetails,
    isLoading: isProfileDataLoading,
    refetch: refetchUserProfileData,
  } = useGetProfileDetailsQuery()

  const intl = useIntl()
  const [updateAdmin, {isLoading, isSuccess, isError}] = useUpdateAdminMutation()

  const [imageUploadModalVisible, setImageUploadModalVisible] = useState(false)

  const dispatch = useAppDispatch()

  const formik = useFormik<Employee>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      // workshop handling
      if (profile?.organization?.isWorkshopOrg) {
        return;
      }
      const payload: {[key: string]: string} = {}
      if (values.locationId !== '') {
        payload.work_location_guid = values.locationId
      }
      if (values.departmentId !== '') {
        payload.department_guid = values.departmentId
      }
      if (values.divisionId !== '') {
        payload.division_guid = values.divisionId
      }
      await updateAdmin(payload)
      await refetchUserProfileData()
    },
  })

  // useEffect(() => {
  //   if (profileDetails) {
  //     dispatch(updateProfile(profileDetails))
  //   }
  // }, [profileDetails])

  useEffect(() => {
    if (isSuccess && !isProfileDataLoading) {
      dispatch(updateProfile(profileDetails))
      dispatch(
        updateNotification({
          message: 'Profile updated successfully',
          type: NotificationType.SUCCESS,
        })
      )
      navigate(RoutesPath.SETTINGS)
    }
  }, [isSuccess, profileDetails])

  useEffect(() => {
    if (isError) {
      dispatch(
        updateNotification({
          message: intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}),
          type: NotificationType.ERROR,
        })
      )
    }
  }, [isError])

  useEffect(() => {
    if (profile) {
      formik.setFieldValue('divisionId', profile?.division.guid || '')
      formik.setFieldValue('departmentId', profile?.department.guid || '')
      formik.setFieldValue('locationId', profile?.workLocation.guid || '')
    }
    // else {
    //   // Redirect to another page or show appropriate JSX
    //   navigate(RoutesPath.SETTINGS);
    // }
  }, [profile, formik.setFieldValue, navigate])

  const isDisable = true

  return (
    <div className='position-relative' style={{backgroundColor: 'white', flex: '1', minHeight: '100%'}}>
      <div >
        <div className='card mb-5 mb-xl-8 pt-10'>
          <div className='back-header-btn' style={{marginLeft: '40px'}}>
            <button 
              type='button' 
              className='p-2 back-left-arrow-btn-wrap'
              onClick={()=>{navigate(RoutesPath.SETTINGS)}}
              >
              <div className='back-left-arrow-btn' /> 
            </button>
            <h6 className='m-0 mx-4 fs-4 font-weight-normal'><u>Edit Profile</u></h6>
          </div>
          <form onSubmit={formik.handleSubmit} noValidate className='form mb-10'>
            <div className='me-7 row-1 p-10'>
              {/* <img
                src={userData?.organization?.logo}
                alt=''
                className='w-60 rounded'
                style={{width: '200px', height: '200px'}}
              /> */}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
              <button type='button' className='card card-custom overlay overflow-hidden remove-btn-styles' onClick={() => setImageUploadModalVisible(true)}>
                <EditOrgIcon className='org-pic-edit-icon' />
                <div className='card-body p-0'>
                  <div className='overlay-wrapper'>
                    <img 
                      src={profile?.organization?.logo} 
                      alt='' 
                      className='w-60 rounded' 
                      style={{width: '200px', height: '200px'}} 
                      />
                  </div>
                  <div className='overlay-layer bg-dark bg-opacity-15'>
                    <button
                      type='button'
                      // disabled={!loading}
                      className='btn btn-light'
                      data-kt-menu-trigger='click'
                      data-kt-menu-placement='bottom-end'
                      data-kt-menu-flip='top-end'
                      style={{color: '#000'}}
                      onClick={() => setImageUploadModalVisible(true)}
                    >
                      Edit Company Logo
                    </button>
                  </div>
                </div>
              </button>
              <ImageUploadModal
                show={imageUploadModalVisible}
                onHide={() => setImageUploadModalVisible(false)}
                onSave={() => formik.handleSubmit()}
              />
              {/* begin::Separartor */}
              <div className='vertical-divider-middle me-10' />
              {/* end::Separartor */}

              {/* {/* <div className='px-6 symbol symbol-50px symbol-lg-80px symbol-fixed position-relative align-bottom'> */}
                {/* <img src={toAbsoluteUrl('/media/avatars/companyLogo.jpg')} alt='' /> */}
                {/* <div className='position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-white h-20px w-20px'/> */}
              {/* </div> */}
              <div className='align-bottom col-lg-5'>
                <label htmlFor='companyName' className='col-lg-4 col-form-label fw-bold fs-6'>
                  Company Name
                </label>
                <div className='fv-row'>
                  <input
                    type='text'
                    disabled
                    className='form-control form-control-lg form-control-solid'
                    placeholder='Company Name'
                    id='companyName'
                    value={profile?.organization?.name}
                  />
                </div>
              </div>
            </div>

            <div className='card-body p-12'>
              <div className='row'>
                <div className='col mb-10'>
                  <label htmlFor='fullName' className='col-lg-4 col-form-label fw-bold fs-6'>
                    Employee Name
                  </label>

                  <div className=''>
                    <div className='row'>
                      <div className='col-lg-10 fv-row'>
                        <input
                          type='text'
                          id='fullName'
                          disabled
                          value={profile?.name}
                          className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                          placeholder='Full name'
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className='col mb-10'>
                  <label htmlFor='position' className='col-lg-4 col-form-label fw-bold fs-6'>
                    Position
                  </label>

                  <div className=''>
                    <div className='row'>
                      <div className='col-lg-10 fv-row'>
                        <input
                          type='text'
                          disabled
                          className='form-control form-control-lg form-control-solid'
                          placeholder='Position'
                          value='Admin'
                          id='position'
                        />
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className='row'>
                <div className='col mb-10'>
                  <label htmlFor='divisionId' className='col-lg-4 col-form-label fw-bold fs-6'>
                    Division
                  </label>

                  <div className=''>
                    <div className='row'>
                      <div className='col-lg-10 fv-row'>
                        <select
                          className='form-select form-select-solid form-select-lg'
                          {...formik.getFieldProps('divisionId')}
                        >
                          <option value='' key='empty'>
                            Select a division
                          </option>
                          {divisions &&
                            divisions.map((division) => (
                              <option value={division.guid} key={division.guid}>
                                {division.name}
                              </option>
                            ))}
                        </select>

                        {formik.touched.divisionId && formik.errors.divisionId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.divisionId}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/** Department */}
                <div className='col mb-10'>
                  <label htmlFor='departmentId' className='col-lg-4 col-form-label fw-bold fs-6'>
                    Department
                  </label>

                  <div className=''>
                    <div className='row'>
                      <div className='col-lg-10 fv-row'>
                        <select
                          className='form-select form-select-solid form-select-lg'
                          {...formik.getFieldProps('departmentId')}
                        >
                          <option value='' key='empty'>
                            Select a department
                          </option>
                          {departments &&
                            departments.map((department) => (
                              <option value={department.guid} key={department.guid}>
                                {department.name}
                              </option>
                            ))}
                        </select>

                        {formik.touched.departmentId && formik.errors.departmentId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.departmentId}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col mb-18'>
                  <label htmlFor='location' className='col-lg-4 col-form-label fw-bold fs-6'>
                    Location
                  </label>

                  <div className=''>
                    <div className='row'>
                      <div className='col-lg-10 fv-row'>
                        <select
                          className='form-select form-select-solid form-select-lg'
                          {...formik.getFieldProps('locationId')}
                        >
                          <option value='' key='empty'>
                            Select a location
                          </option>
                          {locations &&
                            locations.map((location) => (
                              <option value={location.guid} key={location.guid}>
                                {location.name}
                              </option>
                            ))}
                        </select>

                        {formik.touched.locationId && formik.errors.locationId && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.locationId}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col mb-18'>
                  <label htmlFor='emailId' className='col-lg-4 col-form-label fw-bold fs-6'>
                    Email ID
                  </label>

                  <div className=''>
                    <div className='row'>
                      <div className='col-lg-10 fv-row'>
                        <input
                          type='text'
                          disabled
                          className='form-control form-control-lg form-control-solid'
                          placeholder='emailId'
                          id='emailId'
                          value={profile?.email}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className='background-image pencil-bg' />
        </div>
      </div>

      <div className='card-footer footer shadow mt-auto'>
        <div className='settings-btn-bottom px-8'>
          <div className='py-6 px-3'>
            <button
              type='button'
              // disabled={!loading}
              className='custom-outline-button'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              onClick={() =>
                navigate({
                  pathname: RoutesPath.SETTINGS,
                })
              }
            >
              Cancel
            </button>
          </div>

          <div className='py-6 px-3'>
            <button
              type='submit'
              // disabled={isLoading || JSON.stringify(employee) === JSON.stringify(formik.values)}
              className='custom-primary-delete-button'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              onClick={() => formik.handleSubmit()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export {EditProfile}
