import {createSlice} from '@reduxjs/toolkit'

export type FlagsType = {
  avgEployeesFlag: boolean
  isForestAllocated: boolean
}

const initialState: FlagsType = {
  avgEployeesFlag: false, // set on dashboard home page
  isForestAllocated: false, // set on our-forest page
}

export const flagsSlice = createSlice({
  name: 'flagsFilter',
  initialState,
  reducers: {
    setAvgEmployeesFlag: (state, action) => {
      state.avgEployeesFlag = action.payload
    },
    setIsForestAllocated: (state, action) => {
      state.isForestAllocated = action.payload
    }
  },
})

export const {setAvgEmployeesFlag, setIsForestAllocated} = flagsSlice.actions

export default flagsSlice.reducer
