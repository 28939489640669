/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import {useLayout} from '../../core'
import {AsideMenu} from './AsideMenu'
import {AsideToolbar} from './AsideToolbar'
import {logout} from '../../../../app/modules/auth/core/logout'
import {RoutesPath} from '../../../../app/routing/RoutesPath'

const AsideDefault: FC = () => {
  const {classes} = useLayout()
  const intl = useIntl()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLogout = () => {
    logout(dispatch)
    navigate(RoutesPath.LOGIN)
  }

  return (
    <div
      id='kt_aside'
      className='aside aside-bar-wrapper'
      style={{backgroundColor: '#181c32'}}
      data-kt-drawer='true'
      data-kt-drawer-name='aside'
      data-kt-drawer-activate='{default: true, lg: false}'
      data-kt-drawer-overlay='true'
      data-kt-drawer-width="{default:'200px', '300px': '250px'}"
      data-kt-drawer-direction='start'
      data-kt-drawer-toggle='#kt_aside_mobile_toggle'
    >

      <div className='aside-menu flex-column-fluid'>
        <AsideMenu asideMenuCSSClasses={classes.asideMenu} />
      </div>
      <div className='aside-footer flex-column-auto py-5' id='kt_aside_footer'>
        <button className='btn btn-custom btn-primary w-100' type='button' onClick={handleLogout}>
          <span className='btn-label'>{intl.formatMessage({id: 'MENU.LOGOUT'})}</span>
        </button>
      </div>
    </div>
  )
}

export {AsideDefault}
