import {useSelector} from 'react-redux'
import {PrivateLayout} from './PrivateLayout'
import PublicLayout from './PublicLayout'
import {RootState} from '../store/store'

function RouteLayout() {
  const token = useSelector((state: RootState) => state.token.token)
  return token ? <PrivateLayout /> : <PublicLayout />
}

export default RouteLayout
