import api, {baseApiv2} from '../../../services/api'
import {APITags, MethodType} from '../../../services/constants'
import { queryStringBuilder } from '../../../utils/querStringBuilder'
import {
  AddProjectResponse,
  ProjectsListResponse,
  Project,
  SubscriptionPlanListResponse,
  SubscriptionPlan,
  SubscriptionResponseType,
  SubscriptionRequestType,
  SubscriptionResponse,
  ProjectDetailResponse,
  AllocateProjectListResponse,
  ProjectAllocation,
  AllocateProjectType,
  AlocateProjectRespnse,
  AddProjectRequestType,
  AddProjectType,
  SuggestedPlanQueryType,
  PlanLeadRequestType,
  ForestDetailResponse,
  ForestUpdateResponse,
  ForestUpdateType,
  ProjectAllocationResponse,
  ProjectAllocations,
  ForestDetailFilteredResponse,
} from '../types'
import API_ENDPOINTS from './apiPaths'

const projectApisv2 = baseApiv2.injectEndpoints({
  endpoints: (builder) => ({
    getSuggestedPlansV2: builder.query<SubscriptionPlan[], SuggestedPlanQueryType>({
      query: (params) => ({
        url: params.net_zero_date
          ? `${API_ENDPOINTS.SUGGESTED_PLANS}?net_zero_date=${params.net_zero_date}${params?.assigned_date?`&assigned_date=${params.assigned_date}`:''}`
          : `${API_ENDPOINTS.SUGGESTED_PLANS}?sip_amount_per_month=${params.sip_amount_per_month}${params?.assigned_date?`&assigned_date=${params.assigned_date}`:''}`,
        method: MethodType.get,
        data: params,
      }),
      transformResponse: (response: SubscriptionPlanListResponse) => response?.data,
    }),
  }),
})

const projectApis = api.injectEndpoints({
  endpoints: (builder) => ({
    getProjects: builder.query<Project[], void>({
      query: () => ({
        url: `${API_ENDPOINTS.PROJECTS}`,
        method: MethodType.get,
      }),
      providesTags: [APITags.GET_PROJECTS],
      transformResponse: (response: ProjectsListResponse) => response?.data,
    }),
    getAllocateProjectList: builder.query<ProjectAllocation, AllocateProjectType>({
      query: (params) => ({
        url: `${API_ENDPOINTS.PROJECTS}/${params.guid}${API_ENDPOINTS.ALLOCATIONS}`,
        method: MethodType.get,
      }),
      transformResponse: (response: AllocateProjectListResponse) => response?.data,
    }),
    generatePlanLead: builder.mutation<unknown, PlanLeadRequestType>({
      query: (params) => ({
        url: `${API_ENDPOINTS.LEADS}`,
        data: {
          amount: params.amount,
          net_zero_area: params.net_zero_area,
          avg_emission: params.avg_emission,
          net_zero_date: params.net_zero_date,
          net_zero_date_percentage: params.net_zero_date_percentage,
          // assigned_date: params?.assigned_date || null,
        },
        method: MethodType.post,
      }),
      transformResponse: (response: AddProjectResponse) => response?.data,
    }),
    addProject: builder.mutation<AddProjectType, AddProjectRequestType>({
      query: (params) => ({
        url: `${API_ENDPOINTS.PROJECTS}`,
        data: {
          name: params.name,
          cover_image: params.image,
          start_date: params.startDate,
          partner_name: params.partnerName,
          partner_website: params.website,
          location: params.location,
          project_area: params.area,
          acres_planned: params.acresPlanned,
          acres_planted: params.acresPlanted,
          estimated_offset: params.offset,
        },
        method: MethodType.post,
      }),

      invalidatesTags: (data, error) => {
        if (!error) return [APITags.GET_PROJECTS]
        return []
      },
      transformResponse: (response: AddProjectResponse) => response?.data,
    }),
    getSuggestedPlans: builder.query<SubscriptionPlan[], SuggestedPlanQueryType>({
      query: (params) => ({
        url: `${API_ENDPOINTS.SUGGESTED_PLANS}`,
        method: MethodType.get,
        data: params,
      }),
      transformResponse: (response: SubscriptionPlanListResponse) => response?.data,
    }),
    subscribePlan: builder.query<SubscriptionResponseType, SubscriptionRequestType>({
      query: (params) => ({
        url: `${API_ENDPOINTS.SUBSCRIBE}`,
        method: MethodType.post,
        data: params,
      }),
      transformResponse: (response: SubscriptionResponse) => response?.data,
    }),
    getProjectDetail: builder.query({
      query: (params) => ({
        url: `${API_ENDPOINTS.PROJECTS}/${params.guid}`,
        method: MethodType.get,
      }),
      transformResponse: (response: ProjectDetailResponse) => response?.data,
    }),
    getTotalForest: builder.query({
      query:(params) => {
        const queryParams = queryStringBuilder([
          {name: API_ENDPOINTS.FROM_DATE, value: params.fromDate},
          {name: API_ENDPOINTS.TO_DATE, value: params.toDate},
        ])
        return {
          url: `${API_ENDPOINTS.TOTAL_FOREST}${queryParams ? `?${queryParams}` : ''}`,
          method: MethodType.get,
        }
      },
      transformResponse: (response: ForestDetailResponse) => response?.data,
    }),
    getForestUpdates: builder.query<ForestUpdateType[], {
      id: string
    }>({
      query:(params) => ({
          url: `${API_ENDPOINTS.FOREST_UDPATES}?forest_ids=${params.id}`,
          method: MethodType.get,
        }),
      transformResponse: (response: ForestUpdateResponse) => response?.data,
    }),
    getAllocations: builder.query<ProjectAllocations, void>({
      query:() => ({
          url: `${API_ENDPOINTS.ALLOCATION}`,
          method: MethodType.get,
        }),
      transformResponse: (response: ProjectAllocationResponse) => response?.data,
    }),
    updateProject: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.PROJECTS}/${params.guid}`,
        data: {
          name: params.name,
          cover_image: params.image,
          start_date: params.startDate,
          partner_name: params.partnerName,
          partner_website: params.website,
          location: params.location,
          project_area: params.area,
          acres_planned: params.acresPlanned,
          acres_planted: params.acresPlanted,
          estimated_offset: params.offset,
        },
        method: MethodType.put,
      }),

      invalidatesTags: (data, error) => {
        if (!error) return [APITags.GET_PROJECTS]
        return []
      },
      transformResponse: (response: AddProjectResponse) => response?.data,
    }),
    allocateProject: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.PROJECTS}/${params.guid}${API_ENDPOINTS.ALLOCATIONS}`,
        data: {
          data: params.allocations,
        },
        method: MethodType.put,
      }),

      invalidatesTags: (data, error) => {
        if (!error) return [APITags.GET_PROJECTS]
        return []
      },
      transformResponse: (response: AlocateProjectRespnse) => response?.data,
    }),
    razorpayCallback: builder.query({
      query: (requestBody) => ({
        url: `${API_ENDPOINTS.PAYMENTS}${API_ENDPOINTS.RAZORPAY}${API_ENDPOINTS.CALLBACKS}`,
        data: requestBody,
        method: MethodType.post,
        customBaseUrl: `${process.env.REACT_APP_API_URL}`,
      }),
    }),
  }),
})

export const {
  useGetProjectsQuery,
  useGetAllocateProjectListQuery,
  useAddProjectMutation,
  useGetSuggestedPlansQuery,
  useLazyGetSuggestedPlansQuery,
  useLazySubscribePlanQuery,
  useLazyGetProjectDetailQuery,
  useUpdateProjectMutation,
  useAllocateProjectMutation,
  useLazyRazorpayCallbackQuery,
  useGeneratePlanLeadMutation,
  useGetTotalForestQuery,
  useLazyGetTotalForestQuery,
  useLazyGetForestUpdatesQuery,
  useGetForestUpdatesQuery,
  useGetAllocationsQuery
} = projectApis

export const {useLazyGetSuggestedPlansV2Query} = projectApisv2
