import {FC} from 'react'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const Error500: FC = () => (
  <div className='d-flex flex-column flex-root'>
    {/* begin::Authentication - Error 500 */}
    <div className='d-flex flex-column flex-column-fluid'>
      {/* begin::Content */}
      <div className='d-flex flex-column flex-column-fluid text-center p-10 py-lg-15'>
        {/* begin::Wrapper */}
        <div className='pt-lg-10 mb-10'>
          {/* begin::Logo */}
          <h1 className='fw-bolder fs-2qx text-gray-800 mb-10'>System Error</h1>
          {/* end::Logo */}
          {/* begin::Message */}
          <div className='fw-bold fs-3 text-muted mb-15'>
            Something went wrong!
            <br />
            Please try again later.
          </div>
          {/* end::Message */}
        </div>
        {/* end::Wrapper */}
        {/* begin::Illustration */}
        <div
          className='d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px'
          style={{
            backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')}')`,
          }}
        />
        {/* end::Illustration */}
      </div>
    </div>
    {/* end::Authentication - Error 500 */}
  </div>
)

export {Error500}
