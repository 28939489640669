import React, { useState, useEffect, useRef } from 'react';

import './styles.css';

type OptionType = {
  id: number,
  name: string
}

type PropsType = {
  placeholder?: string
  setSelectedOptions: any
  selectedOptions: any[]
  options: OptionType[]
}

const MultiSelectDropdown = (props: PropsType) => {
  const {
    placeholder = "Select Options",
    setSelectedOptions,
    selectedOptions,
    options = []
  } = props;

  const dropdownRef = useRef<any>(null);

  const [showDropdown, setShowDropdown] = useState(false);
  const [dropdownLabelText, setDropdownLabelText] = useState<string>(placeholder);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleCheckboxChange = (option: any) => {
    setSelectedOptions((prevSelected: any) => (prevSelected.findIndex((item: any) => item.id === option.id) !== -1)
        ? prevSelected.filter((item: any) => item.id !== option.id)
        : [...prevSelected, option]
    );
  };

  const handleClickOutside = (event: any) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    if (selectedOptions.length === 0) {
      setDropdownLabelText(placeholder);
    } else if (selectedOptions.length < 3) {
      setDropdownLabelText(selectedOptions.map((item) => item.name).join(', '));
    } else {
      setDropdownLabelText(`Selected (${selectedOptions.length})`);
    }
  }, [selectedOptions])

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="w-100 multi-select-check-dropdown position-relative" ref={dropdownRef}>
      <button className="multi-select-check-dropdown-button" type="button" onClick={toggleDropdown}>
        {dropdownLabelText}
      </button>
      {
        showDropdown && (
          <div className="multi-select-check-dropdown-content">
            {options.map((option: any) => (
              <label key={option.id} className='d-flex align-items-center'>
                <input
                  type="checkbox"
                  value={option.id}
                  checked={selectedOptions.findIndex((value: any) => value.id === option.id) !== -1}
                  onChange={() => handleCheckboxChange(option)}
                />
                {option.name}
              </label>
            ))}
          </div>
        )
      }
      <div 
        style={{
          position: 'absolute',
          height: '8px',
          width: '8px',
          bottom: '18px',
          right: '16px',
          border: '1px solid #202020',
          borderWidth: '0px 1px 1px 0px',
          rotate: '45deg',
        }} 
        />
    </div>
  );
};

export default MultiSelectDropdown;
