/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import OpenIcon from '../../../../assets/OpenIcon.svg'
import {ActivityEmission} from '../types'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import { commaSeparatedFormat } from '../../../../app/utils/helpers'

type Props = {
  className: string
  chartColor: string
  chartHeight: string
  title: string
  desctiption: string
  data?: Array<ActivityEmission> | undefined
}

const Activities: React.FC<Props> = ({
  className,
  chartColor,
  chartHeight,
  title,
  desctiption,
  data,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const refreshMode = () => {
    if (!chartRef.current || !data) {
      return undefined
    }

    const sortedData = [...data].sort((a, b) => b.emission - a.emission)
    const topThreeItems = sortedData.slice(0, 3)

    const chartData = {
      series: topThreeItems.map((item) => Math.round(item.emission)),
      labels: topThreeItems.map((item) => item.name ?? ''),
    }

    const chartOptions: ApexOptions = {
      chart: {
        type: 'donut',
        height: chartHeight,
        toolbar: {
          show: false,
        },
      },
      dataLabels: {
        enabled: false,
        formatter: (value: number) => `${Math.round(value)}%`,

        style: {
          fontSize: '14px',
          fontFamily: 'Arial, sans-serif',
          fontWeight: 500,
          colors: ['#fff'], // Customize the label color
        },
      },
      // colors: ['#FB3636', '#F88181', '#FF9F9F'],
      colors: ['#3699FF', '#86C1FF', '#BBDCFF'],
      plotOptions: {
        pie: {
          expandOnClick: false,
          donut: {
            size: '55%',
            labels: {
              show: false,
              name: {
                show: true,
                fontSize: '22px',
                fontFamily: 'Nunito ,sans-serif',
                color: '#888',
                offsetY: -10,
              },
              value: {
                show: true,
                fontSize: '16px',
                fontFamily: 'Nunito ,sans-serif',
                color: '#888',
                offsetY: 16,
                formatter: (value) => `${value}kg`,
              },
            },
          },
        },
      },
      states: {
        hover: {
          filter: {
            type: 'darken',
            value: 1,
          },
        },
      },

      series: chartData.series,
      labels: chartData.labels,
      legend: {
        position: 'bottom',
        horizontalAlign: 'center',
        offsetY: 0,
        markers: {
          width: 12,
          height: 12,
          radius: 4,
        },
        onItemClick: {
          toggleDataSeries: false,
        },
        onItemHover: {
          highlightDataSeries: false,
        },
        itemMargin: {
          horizontal: 5,
        },
      },

      tooltip: {
        enabled: true, 
        y: {
          formatter: (val) => `${commaSeparatedFormat(parseFloat(val.toFixed(1)))} kgCO2`,
          title: {
              formatter: (seriesName) => `${seriesName} :`,
          },
        },
      }
    }
    const chart = new ApexCharts(chartRef.current, chartOptions)

    chart.render()
    return chart
  }

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart !== undefined) {
        ;(chart as ApexCharts).destroy()
      }
    }
  }, [chartRef, mode, refreshMode])

  return (
    <div className='card mb-xl-8 mt-2 bordered-grey'>
      {/* begin::Beader */}
      <div className='card-header border-0 pt-2'>
        <h3 className='card-title align-items-center flex-row'>
          <span className='card-label fw-bold fs-3 mb-1'>{title.toUpperCase()}</span>
          <span className='fw-semibold fs-7'>(TOP 3)</span>
        </h3>

        {/* <div className='card-toolbar'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
          >
            <img src={OpenIcon} alt='' />
          </button>
        </div> */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body-chart d-flex flex-column mb-3'>
        <div className='flex-grow-1'>
          <div ref={chartRef} className='mixed-widget-4-chart pt-6 pb-8' />
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {Activities}
