import {useCallback, useEffect, useState} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import mixpanel from 'mixpanel-browser'
// import {CardsWidgetWrapper} from '../../../../_metronic/partials/components/chart/CardsWidgetWrapper'
import { HeaderWidgetsWrapper } from '../../../../_metronic/partials/components/chart/HeaderWidgetsWrapper'
import {EmissionContribution} from '../EmissionContribution'
import {
  useLazyGetContributedEmployeesDataQuery,
  useLazyGetTopActivitiesDataQuery,
  useLazyGetTopEmissionsListQuery,
  useLazyGetTotalEmissionsDataQuery,
  useLazyGetOrgEmployeesDataQuery, 
  useLazyGetPlansQuery
} from '../store/api'
import { EVENTS, EVENT_TYPE, PAGES } from '../../../analytics/tracker-types' 
import { PageTitle } from '../../../routing/RoutesPageTitle'
import {getMMFormat, getYYYYDDMMFormat} from '../../../utils/dateFormatter'
import {ActivityEmission, MenuItem} from '../../../../_metronic/partials/components/types'
import {getLocalStorageItem} from '../../../utils/storage'
import {EmissionContributionForAllMonth} from '../EmissionContributionForAllMonth'
import {SpiderChart} from '../../../../_metronic/partials/components/chart/SpiderChart'
import {AllDivisionsTable} from './AllDivisionsTable'
import { setAvgEmployeesFlag } from '../../../store/slices/flags'

import '../Dashboard.css'
import { RootState } from '../../../store/store'
import { EmissionTrendLineForAllMonths } from '../EmissionTrendLineForAllMonths'
import { TopCategoryEmission } from '../../../../_metronic/partials/components/chart/TopCategoryEmissions'
import { EmptyMixedWidget } from '../../emptyStates/emptyStates'
import { TopActivites } from '../../../../_metronic/partials/components/chart/TopActivities'
import { EmissionTrendLine } from '../EmissionTrendLine'

interface DivisionWrapperProps {
  month: string
}

const ToggleButton = () => {
  const dispatch = useDispatch()
  const avgEployeesFlag = useSelector((state: RootState) => state.flags.avgEployeesFlag)
  const [isAvgPerEmployee, setIsAvgPerEmployee] = useState(avgEployeesFlag)

  const handleToggle = () => {
    if (isAvgPerEmployee) {
      dispatch(setAvgEmployeesFlag(false))
      setIsAvgPerEmployee(false)
    } else {
      dispatch(setAvgEmployeesFlag(true))
      setIsAvgPerEmployee(true)
    }
  };

  return (
    <div className="tg-toggle-container d-flex align-items-center justify-content-center">
      <span className={`tg-label ${isAvgPerEmployee ? 'inactive' : 'active'}`}>
        All Employees
      </span>
      <label className="tg-switch">
        <input type="checkbox" checked={isAvgPerEmployee} onChange={handleToggle} />
        <span className="tg-slider tg-round" />
      </label>
      <span className={`tg-label ${isAvgPerEmployee ? 'active' : 'inactive'}`}>
        Avg. Per Employee
      </span>
    </div>
  );
};

export function DivisionWrapper({month}: DivisionWrapperProps) {
  const {filterDate, filterDivSubCategory, filterExtrapolation} = useSelector((state: RootState) => state.filters)
  // states
  const [monthsFilterOptions, setMonthsFilterOptions] = useState<Array<MenuItem>>([])
  const [timePeriodFilter, setTimePeriodFilter] = useState<MenuItem | undefined>()
  const liveOnDate = new Date(getLocalStorageItem('live-on'))
  const {profile} = useSelector((state: RootState) => state.profile)
  const avgEployeesFlag = useSelector((state: RootState) => state.flags.avgEployeesFlag)

  // update page title
  useEffect(() => {
    document.title = PageTitle.DIVISION
    // tracking
    mixpanel.track(EVENT_TYPE.PageView, {'name': PAGES.DivisionsPage})
  }, [])

  const getDateFilters = useCallback(() => ({
    fromDate: filterDate.startDate, 
    toDate: filterDate.endDate,
  }), [timePeriodFilter, filterDate])

  // const getDateFilters = useCallback(() => {
  //   if (month === 'All') {
  //     const timePeriodFilterValue = liveOnDate.getUTCMonth().toString()
  //     const currentDate = new Date()
  //     if (timePeriodFilterValue) {
  //       const selectedMonth = parseInt(timePeriodFilterValue, 10)
  //       const selectedYear =
  //         selectedMonth > currentDate.getUTCMonth()
  //           ? currentDate.getUTCFullYear() - 1
  //           : currentDate.getUTCFullYear()

  //       const fromDate = getYYYYDDMMFormat(
  //         new Date(selectedYear, parseInt(timePeriodFilterValue, 10), 1)
  //       )

  //       const toDate = getYYYYDDMMFormat(currentDate)
  //       return {
  //         fromDate,
  //         toDate,
  //       }
  //     }
  //     return undefined
  //   } 
  //     const currentDate = new Date()
  //     if (timePeriodFilter) {
  //       const selectedMonth = parseInt(timePeriodFilter.value, 10)
  //       const selectedYear =
  //         selectedMonth > currentDate.getUTCMonth()
  //           ? currentDate.getUTCFullYear() - 1
  //           : currentDate.getUTCFullYear()
  //       const isCurrentMonth = selectedMonth === currentDate.getUTCMonth()

  //       const fromDate = getYYYYDDMMFormat(
  //         new Date(selectedYear, parseInt(timePeriodFilter.value, 10), 1)
  //       )
  //       const toDate = isCurrentMonth
  //         ? getYYYYDDMMFormat(currentDate)
  //         : getYYYYDDMMFormat(new Date(selectedYear, parseInt(timePeriodFilter.value, 10) + 1, 0))
  //       return {
  //         fromDate,
  //         toDate,
  //       }
  //     }
  //     return undefined
    
  // }, [timePeriodFilter])

  const [getTotalEmissions, {data: totalEmissionData}] = useLazyGetTotalEmissionsDataQuery()

  const [getTopActivitiesList, {data: topActivitiesList, isLoading: isLoadingTopActivities}] =
  useLazyGetTopActivitiesDataQuery()

  const [
    getTopEmissionsListDivisions,
    {data: topEmissionsListDivisions, isLoading: isLoadingTopDivisions},
  ] = useLazyGetTopEmissionsListQuery()

  const [
    getTopEmissionsListLocations,
    {data: topEmissionsListLocations, isLoading: isLoadingTopLocations},
  ] = useLazyGetTopEmissionsListQuery()

  const [
    getTotalEmployeeOnboardedData,
    {data: totalEmployeeOnboardedData, isLoading: isLoadingTotalEmployeeOnboarded},
  ] = useLazyGetOrgEmployeesDataQuery()

  const [
    getEmployeeContributedData,
    {data: employeeContributedData, isLoading: isLoadingEmployeeContributedData},
  ] = useLazyGetContributedEmployeesDataQuery()

  const [getPlans, {data: plansData}] = useLazyGetPlansQuery()

  useEffect(() => {
    getPlans({})
      .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    let timePeriod: MenuItem | undefined = monthsFilterOptions[0]

    if (month === 'All') {
      timePeriod = {
        label: 'All',
        value: liveOnDate.getUTCMonth().toString(),
      }
    } else {
      timePeriod = monthsFilterOptions.find((option) => option.label === month)
    }

    if (filterDate.startDate !== '') {
      setTimePeriodFilter(timePeriod)
    }
  }, [monthsFilterOptions, month, filterDate])

  useEffect(() => {
    if (timePeriodFilter) {
      getTopActivitiesList({
        ...(timePeriodFilter && {...getDateFilters()}),
        is_scaled: (month==='All' && filterExtrapolation),
      })
    }
  }, [timePeriodFilter, month, filterExtrapolation])

  const [topActivities, setTopActivities] = useState<Array<ActivityEmission>>([])

  useEffect(() => {
    if (topActivitiesList?.top_activities) {
      const sortedData = [...topActivitiesList.top_activities].sort(
        (a, b) => b.emission - a.emission
      )
      const topThree = sortedData.slice(0, 3)
      setTopActivities(topThree)
    }
  }, [topActivitiesList, month])

  useEffect(() => {
    if (timePeriodFilter) {
      if (filterDivSubCategory !== 'All') {
        getTotalEmissions({
          ...(timePeriodFilter && {...getDateFilters()}),
          category: 'division', 
          sub_category: filterDivSubCategory,
          is_scaled: (month==='All') && filterExtrapolation,
        })
      } else {
        getTotalEmissions({
          ...(timePeriodFilter && {...getDateFilters()}),
          category: 'division', 
          is_scaled: (month==='All') && filterExtrapolation,
        })
      }
    }
  }, [timePeriodFilter, month, filterDivSubCategory, filterExtrapolation])

  useEffect(() => {
    if (timePeriodFilter) {
      getTopEmissionsListDivisions({
        ...(timePeriodFilter && {...getDateFilters()}),
        category: 'division',
        is_scaled: (month==='All') && filterExtrapolation,
        by_average: avgEployeesFlag
      })
    }
  }, [timePeriodFilter, month,avgEployeesFlag, filterExtrapolation])

  useEffect(() => {
    if (timePeriodFilter) {
      if (filterDivSubCategory !== 'All') {
        getTopEmissionsListLocations({
          ...(timePeriodFilter && {...getDateFilters()}),
          category: 'location',
          in_category: 'division',
          in_sub_category: filterDivSubCategory,
          is_scaled: (month==='All') && filterExtrapolation,
        })
      } else {
        getTopEmissionsListLocations({
          ...(timePeriodFilter && {...getDateFilters()}),
          category: 'location',
          in_category: 'division',
          is_scaled: (month==='All') && filterExtrapolation,
        })
      }
    }
  }, [timePeriodFilter, month, filterDivSubCategory, filterExtrapolation])



  // const getDateFiltersForMonth = (selectedMonth: number) => {
  //   const currentDate = new Date()
  //   const currentYear = currentDate.getUTCFullYear()

  //   const selectedYear = selectedMonth > currentDate.getUTCMonth() ? currentYear - 1 : currentYear
  //   const isCurrentMonth = selectedMonth === currentDate.getUTCMonth()

  //   const fromDate = getYYYYDDMMFormat(new Date(selectedYear, selectedMonth - 1, 1))

  //   const toDate = isCurrentMonth
  //     ? getYYYYDDMMFormat(currentDate)
  //     : getYYYYDDMMFormat(new Date(selectedYear, selectedMonth + 1, 0))

  //   return {
  //     fromDate,
  //     toDate,
  //   }
  // }

  useEffect(() => {
    if (timePeriodFilter) {
      getTotalEmployeeOnboardedData({
        ...(timePeriodFilter && {...getDateFilters()}),
        category: 'division',
      })
      if (filterDivSubCategory !== 'All') {
        getEmployeeContributedData({
          ...(timePeriodFilter && {...getDateFilters()}),
          category: 'division',
          sub_category: filterDivSubCategory,
        })
      } else {
        getEmployeeContributedData({
          ...(timePeriodFilter && {...getDateFilters()}),
          category: 'division',
        })
      }
    }
  }, [timePeriodFilter, month, filterDivSubCategory])

  const getTimePeriodFilterOptions = () => {
    const currentDate = new Date()
    const currentMonth = currentDate.getUTCMonth()
    const months = Array.from(
      {length: 12},
      (item, i) => new Date(currentDate.getUTCFullYear(), currentMonth - i)
    )
    const monthsOptions: Array<MenuItem> = []
    months.forEach((item) =>
      monthsOptions.push({
        label: getMMFormat(item),
        value: item.getUTCMonth().toString(),
      })
    )
    setMonthsFilterOptions(monthsOptions)
  }

  useEffect(() => {
    getTimePeriodFilterOptions()
  }, [])

  const employeesOnboarded = totalEmployeeOnboardedData
    ? {total: totalEmployeeOnboardedData.total, onboard: totalEmployeeOnboardedData.onboard}
    : {total: 0, onboard: 0}

  const totalEmissionForSelectedMonth = totalEmissionData?.total_emission
    ? totalEmissionData?.total_emission
    : 0
  const totalRecaptureForSelectedMonth = totalEmissionData?.total_offset
    ? totalEmissionData?.total_offset
    : 0
  const totalEmployeeContributed = employeeContributedData?.total
    ? employeeContributedData?.total
    : 0

  const totalEmployeeDashboard = employeeContributedData?.total_employees
  ? employeeContributedData?.total_employees
  : 0

  const RenderChart = useCallback(() => {
    if(month === 'All' && topEmissionsListDivisions) {
      return <EmissionTrendLineForAllMonths topValue={topEmissionsListDivisions[0]?.location || ''} locationType='division' />;
    }
    else if (!!timePeriodFilter && !!topEmissionsListDivisions) {
      return <EmissionTrendLine topValue={topEmissionsListDivisions[0]?.location || ''} locationType='division' />
    }
    return null;
  }, [month, topEmissionsListDivisions, timePeriodFilter])

  return (
    <div className='p-4'>
      <HeaderWidgetsWrapper
        totalEmissionValue={totalEmissionForSelectedMonth}
        totalRecaptureValue={totalRecaptureForSelectedMonth}
        totalEmployeesOnboarded={employeesOnboarded?.onboard}
        totalEmployeesDashboard={totalEmployeeDashboard}
        totalEmployeesContributed={totalEmployeeContributed}
        totalEmployees={profile?.organization?.totalEmployeeCount}
        month={month}
        isPlansAvailable={plansData?.data?.plans?.length>0 || false}
      />
      <div className='custom-content mt-6 px-0'>
        <div className='d-inline d-flex justify-content-between'>
          <h3 className='fw-bolder my-2 title d-inline'>EMISSION TRENDS BY DIVISON</h3>
          <ToggleButton />
        </div>

        {/* begin::Row2 */}
        <div className='row  max-height lkg-5 g-xl-10 mb-5'>
          {/* begin::Col */}
          <div className='col-md-7 col-lg-7 col-xl-7 col-xxl-7'>
           <RenderChart />
          </div>
          {/* end::Col */}

          <div className='col-md-5 col-lg-5 col-xl-5 col-xxl-5'>
            {(timePeriodFilter && topEmissionsListDivisions) ? (
              <TopCategoryEmission
                className='h-md-35'
                title='Divisions'
                desctiption=''
                data={topEmissionsListDivisions}
              />
            ) : <EmptyMixedWidget title='DIVISIONS' />
            }
          </div>
        </div>
        {/* end::Row2 */}

        {/* begin::Row3 */}
        <div className='d-inline d-flex justify-content-between'>
          <h3 className='fw-bolder my-2 title d-inline'>EMISSION CONTRIBUTION </h3>
        </div>
        <div className='row  max-height lkg-5 g-xl-10 mb-5'>
          {/* begin::Col */}
          <div className='col-md-7 col-lg-7 col-xl-7 col-xxl-7'>
            {(month === 'All' && topActivities)? (
              <EmissionContributionForAllMonth locationType='division' />
            ) : (
              timePeriodFilter && <EmissionContribution locationType='division' />
            )}
          </div>
          {/* end::Col */}

          <div className='col-md-5 col-lg-5 col-xl-5 col-xxl-5'>
            {topActivities && (
              <TopActivites
                type='division'
                _data={topActivities}
              />
            )}
          </div>
        </div>
        {/* end::Row3 */}

        <div>
          <h3 className='fw-bolder my-2 title'>ALL DIVISIONS</h3>
        </div>

        {/* begin::Row4 */}
        <div className='row g-5 g-xl-10 mb-5 '>
          <AllDivisionsTable selectedMonth={timePeriodFilter} />
        </div>
        {/* end::Row4 */}
      </div>
    </div>
  )
}
