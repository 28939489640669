import { FC, useEffect, useMemo, useState } from "react";
import moment from "moment";
import {useSelector} from 'react-redux'; 
import { ForestsPlantedChart } from "../../../_metronic/partials/components/chart/ForestsPlantedChart"
import { getYYYYDDMMFormat } from "../../utils/dateFormatter";
import { DateRangeType } from "../../../_metronic/partials/components/types";
import { useLazyGetForestTrendsQuery } from "./store/api";
import { useLazyGetTotalForestQuery } from "../our-forest/store/api";
import { ChartValueTypes } from "./type";
import { EmptyForestTrends } from '../emptyStates/emptyStates'
import { getLocalStorageItem } from "../../utils/storage";
import { RootState } from "../../store/store";


type ForestTrendsProps = {
  selectedMonth: {
    label: string
    value: string
  }
}

const removeLeadingZero = (curr:any, comp:any, dates:any) => {
  while (curr.indexOf(0) === 0) {
     curr.shift();
     comp.shift();
     dates.shift();
  };
};

const ForestTrends: FC<ForestTrendsProps> = ({
  selectedMonth,
  }) => {

    const {profile} = useSelector((state:RootState) => state.profile)

    const [getForestTrend, {data: forestTrend, isLoading}] =
    useLazyGetForestTrendsQuery()

    const [getTotalForestPlanted, {data: totalForestPlanted}] =
    useLazyGetTotalForestQuery()

    const totalArea = totalForestPlanted?.total_area;
  
    const [selectedDateRange, setSelectedDateRange] = useState<DateRangeType>()
  
    const [chartValues, setChartValues] = useState<ChartValueTypes>()
  
    const setInitialSelectedDate = (month: number) => {
      const currentDate = new Date()
      const selectedYear =
        month > currentDate.getUTCMonth() ? currentDate.getUTCFullYear() - 1 : currentDate.getUTCFullYear()
      let endDay
      if (month === currentDate.getUTCMonth())
        endDay = new Date(selectedYear, month, currentDate.getUTCDate())
      else endDay = new Date(selectedYear, month + 1, 0)
      setSelectedDateRange({
        startDate: new Date(selectedYear, month, 1),
        endDate: endDay,
      })
    }

    const getDaysArray = useMemo(() => {
      const datesArray = []
      if (selectedDateRange?.startDate) {
        for (
          // let dt = new Date(selectedDateRange.startDate);
          let dt = new Date('2023-10-01'); // because actual planted date of forest is less than the liveOnDate of enterprise
          dt <= new Date(
            profile?.organization?.isWorkshopOrg ? 
              moment(profile?.organization?.workshopDate).add(90, 'days').format('YYYY-MM-DD') : 
              selectedDateRange.endDate
            );
          dt = new Date(dt.getTime() + 86400000) // Add milliseconds equivalent to one day
        ) {
          datesArray.push(new Date(dt))
        }
      }
      return datesArray
    }, [selectedDateRange])

    useEffect(() => {
      if (selectedDateRange && selectedDateRange.startDate && selectedDateRange.endDate) {
        getTotalForestPlanted({
            fromDate: getYYYYDDMMFormat(selectedDateRange.startDate),
            toDate: profile?.organization?.isWorkshopOrg ? 
              moment(profile?.organization?.workshopDate).add(90, 'days').format('YYYY-MM-DD') : 
              moment(selectedDateRange.endDate).format('YYYY-MM-DD'),
            // category: 'division',
          }) 
      }
    }, [selectedDateRange])
  
    const liveOnDate = new Date(getLocalStorageItem('live-on')) // Convert to Date object
    const currentDate = new Date()

    useEffect(() => {
      // if (selectedMonth.label !==  "All"){
      //   const month = parseInt(selectedMonth.value, 10)
      //   setInitialSelectedDate(month)
      // } else {
        setSelectedDateRange({
          startDate: liveOnDate,
          endDate: currentDate,
        })
      // }
    }, [selectedMonth])
  
    useEffect(() => {
      if (selectedDateRange && selectedDateRange.startDate && selectedDateRange.endDate) {
        getForestTrend({
            // fromDate: getYYYYDDMMFormat(selectedDateRange.startDate),
            fromDate: '2023-01-01', // because actual planted date of forest is less than the liveOnDate of enterprise
            toDate: profile?.organization?.isWorkshopOrg ? 
              moment(profile?.organization?.workshopDate).add(90, 'days').format('YYYY-MM-DD') : 
              moment(selectedDateRange.endDate).format('YYYY-MM-DD'),
          })
      }
    }, [selectedDateRange])
  
    useEffect(() => {
      if (selectedDateRange && selectedDateRange.startDate && selectedDateRange.endDate && !isLoading) {
        const dates = getDaysArray
        const plantedValues = new Array(dates.length).fill(0)
        if (forestTrend?.length && forestTrend?.length>0) {
          forestTrend.forEach((trend) => {
            const index = dates.findIndex((d) => {
              const date = new Date(trend.date)
              return d.toDateString() === date.toDateString()
            })
            
            if (index >= 0) plantedValues[index] = trend.acre_planted
          })
        }
        const plannedForest = new Array(dates.length).fill(totalArea)
        const categories = dates.map((date) =>
          date.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'short',
            year: '2-digit',
          })
        )

        // if(selectedMonth.label === "All"){
        // removeLeadingZero(plantedValues, plannedForest, categories);
        // }
        for (let i=1; i<plantedValues.length; i+=1) {
          plantedValues[i] += plantedValues[i-1]
        }
        // round of values
        for (let i=0; i<plantedValues.length; i+=1) {
          plantedValues[i] = plantedValues[i]?.toFixed(2)
        }

        const series = [
          {
            name: 'Total Purchased Acres',
            type: 'line',
            data: plannedForest,
          },
          {
            name: 'Planted Acres',
            type: 'area',
            data: plantedValues,
          },
        ]

        setChartValues({series, categories})
      }

    }, [forestTrend, totalForestPlanted, selectedDateRange, isLoading])
          
    let chartComponent = null
    if (chartValues && forestTrend) {
        chartComponent = <ForestsPlantedChart
          totalPlanted={totalForestPlanted?.total_planted_area || 0}
          series={chartValues.series}
          categories={chartValues.categories}
          disableAnimation={chartValues.disableAnimation} 
        />
    } else {
      chartComponent = <EmptyForestTrends />
    }
      
    return(
      <div className='col-xxl-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12'>
        {chartComponent}
      </div>
)}

export {ForestTrends}