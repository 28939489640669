import {FC} from 'react'
import {EmissionContributionChart} from '../../../_metronic/partials/components/chart/EmissionContributionChart'
import { EmissionTrendLineChart } from '../../../_metronic/partials/components/chart/EmissionTrendLineChart'
import { ActivityEmission } from './type'

type EmissionTrendLineProps = {
  locationType: string
  topValue: string
}

const EmissionTrendLine: FC<EmissionTrendLineProps> = ({locationType, topValue}) => (
  <EmissionTrendLineChart locationType={locationType} topValue={topValue}/>
)
export {EmissionTrendLine}
