type CardProps = {
  label?: String | undefined
  value?: String | undefined
  type?: "blue" | "white"
  containerStyle?: any | undefined
  titleTextStyle?: any | undefined
  contentTextStyle?: any | undefined
}

export const HeaderInfoCard: React.FC<CardProps> = ({label, type = "blue", value, containerStyle, titleTextStyle, contentTextStyle}) => (
  type === "blue" ? (
    <div className='pt-6 custom-header-card' style={containerStyle}>
      <div className='chc-title'>
        <span 
          className='fs-6'
          style={{...titleTextStyle, color: 'white', fontWeight: 400}}
          >
          {label}
        </span>
      </div>
      <div className='chc-content mb-2'>
        <span
          className='value'
          style={{...contentTextStyle, display: 'inline-block', verticalAlign: 'baseline', color: 'white', fontSize: '2.2rem'}}
          >
          {value}
        </span>
      </div>
    </div>
  ) : (
    <div 
      className='pt-6 custom-header-card' 
      style={{
        ...containerStyle, 
        backgroundColor: 'white', 
        border: '1px solid #d8d8d8'
      }}>
      <div className='chc-title'>
        <span 
          className='fs-6'
          style={{...titleTextStyle, color: '#5D5D5D', fontWeight: 400}}
          >
          {label}
        </span>
      </div>
      <div className='chc-content2 mb-2 position-relative'>
        <span className='value hover-truncate-ellipsis' data-text={(value!=='undefined') ? value?.toString() : '-'} style={{display:'block', fontSize: '1.8rem', color: '#5D5D5D', fontWeight: 600}}>
          {(value!=='undefined') ? value : '--'}
        </span>
      </div>
    </div>
  )
)