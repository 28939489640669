import Modal from 'react-bootstrap/Modal'

type ModalViewImageTypes = {
  show: boolean
  onHide: () => void
  imageUrl: string
}

const height = '400px';
const width = '300px';

export default function ModalViewImage(props: ModalViewImageTypes) {
  const { onHide, imageUrl } = props

  return (
    <Modal {...props} aria-labelledby='contained-modal-title-vcenter' centered dialogClassName="view-image-challenges-modal-width">
      <Modal.Body style={{height, width, padding: '0px'}}>
        <button 
          type='button' 
          className='m-0 p-0 remove-btn-styles modal-header-cross-btn' 
          onClick={onHide}
          style={{
            height: '20px', 
            width: '20px', 
            borderRadius: '20px', 
            backgroundColor: '#ededed',
            boxShadow: '1px 1px 7px 2px rgba(28,28,28,0.2)'
          }}
          >
          <i className="bi bi-x-lg m-0 p-0" style={{fontSize: '12px', color: 'black'}} />
        </button>
        <div className='p-0 d-flex justify-content-center'>
          <img 
            src={imageUrl}
            alt={imageUrl}
            style={{
              height,
              width,
              objectFit: 'cover',
              borderRadius: '5px'
            }}  
            />
        </div>
      </Modal.Body>
    </Modal>
  )
}