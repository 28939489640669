const API_ENDPOINTS = {
  ORGANIZATIONS: '/organizations',
  GET_UTILITY_BILL_LIST: '/get-utility-bill-list',
  ADD_UTILITY_BILL: '/add-utility-bill',
  LOCATION_WISE_EMPLOYEE_COUNT: '/location-wise-employee-count', 
  GENERATE_AUDIT_REPORT: '/generate-audit-report',
  REPORT: '/report',
  TAG: '/tag',
  CUSTOM_GROUP: '/custom-group',
  UPDATE_ORG_EMPLOYEE_COUNT: '/update-org-employee-count',
  UPDATE_DIVISION: '/update-division', 
  UPDATE_DEPARTMENT: '/update-department', 
  UPDATE_WORK_LOCATION: '/update-work-location', 
  CREATE_WORK_LOCATION: '/create-work-location',
  CHALLENGE: '/challenge',
  CHALLENGES_SUMMARY: '/challenges-summary',
  QUIZZES: '/quizzes',
  QUIZZES_SUMMARY: '/quizzes-summary',
  WASTE: '/waste',
  UPLOAD_IMAGE: '/upload-image'
}

export default API_ENDPOINTS
