import {createSlice} from '@reduxjs/toolkit'

type ReportOrgTag = {
  id: number,
  name: string,
  short_name: string,
  color: string,
}

export type CustomGroupCategoryType = "live" | "upcoming" | "completed";

export type ChallengesFilterType = "live" | "upcoming" | "draft" | "completed";

export type QuizzesFilterType = "all" | "live" | "upcoming" | "completed";

export type FilterType = {
  filterYear: string
  filterMonth: string
  filterDate: {
    startDate: string, 
    endDate: string
  }
  filterCategory: string
  filterLeaderboardTimePeriod: string
  filterLeaderboardCategory: string
  filterLeaderboardActivities: string[]
  filterDivSubCategory: string
  filterDeptSubCategory: string
  filterLocSubCategory: string
  filterExtrapolation: boolean
  filterCustomGroupCategory: CustomGroupCategoryType
  filterChallengesType: ChallengesFilterType
  filterQuizzesType: QuizzesFilterType
  filterReportsOrgTag: ReportOrgTag
}

const initialState: FilterType = {
  filterYear: 'All',
  filterMonth: 'All',
  filterDate: {
    startDate: '', 
    endDate: ''
  },
  filterCategory: 'Department',
  filterLeaderboardTimePeriod: 'weekly',
  filterLeaderboardCategory: 'department',
  filterLeaderboardActivities: [],
  filterDivSubCategory: 'All', 
  filterDeptSubCategory: 'All',
  filterLocSubCategory: 'All', 
  filterExtrapolation: false, 
  filterCustomGroupCategory: 'live',
  filterChallengesType: 'live',
  filterQuizzesType: 'all',
  filterReportsOrgTag: {
    id: 0,
    name: 'Enterprise Reports',
    short_name: 'Enterprise Reports',
    color: '#FFFFFF',
  },
}

export const filterSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    updateDateFilter: (state, action) => {
      state.filterDate = action.payload
    },
    updateYearFilter: (state, action) => {
      if (state.filterYear !== action.payload) {
        state.filterMonth = 'All'
      }
      state.filterYear = action.payload
    },
    updateMonthFilter: (state, action) => {
      state.filterMonth = action.payload
      if (action.payload !== 'All') {
        state.filterExtrapolation = false
      }
    },
    updateCategoryFilter: (state, action) => {
      state.filterCategory = action.payload
    },
    udpateLeaderboardTimePeriodFilter: (state, action) => {
      state.filterLeaderboardTimePeriod = action.payload
    },
    updateLeaderboardCategoryFilter: (state, action) => {
      state.filterLeaderboardCategory = action.payload
    },
    addLeaderboardActivitiesFilter: (state, action) => {
      state.filterLeaderboardActivities = [...state.filterLeaderboardActivities, action.payload]
    },
    removeLeaderboardActivitiesFilter: (state, action) => {
      state.filterLeaderboardActivities = state.filterLeaderboardActivities.filter(activity => activity !== action.payload)
    },
    resetLeaderboardActivitiesFilter: (state) => {
      state.filterLeaderboardActivities = []
    },
    selectAllLeaderboardActivitiesFilter: (state, action) => {
      state.filterLeaderboardActivities = action.payload;
    },
    updateCustomGroupCategoryCategoryFilter: (state, action: {payload: CustomGroupCategoryType}) => {
      state.filterCustomGroupCategory = action.payload;
    },
    updateChallengesFilterType: (state, action: {payload: ChallengesFilterType}) => {
      state.filterChallengesType = action.payload;
    },
    updateQuizzesFilterType: (state, action: {payload: QuizzesFilterType}) => {
      state.filterQuizzesType = action.payload;
    },
    updateReportsOrgTag: (state, action: {payload: ReportOrgTag, type: string}) => {
      state.filterReportsOrgTag = action.payload
    },
    resetReportsOrgTag: (state) => {
      state.filterReportsOrgTag = {
        id: 0,
        name: 'Enterprise Reports',
        short_name: 'Enterprise Reports',
        color: '#FFFFFF',
      }
    },
    updateDivSubCateogryFilter: (state, action) => {
      state.filterDivSubCategory = action.payload
    },
    updateDeptSubCateogryFilter: (state, action) => {
      state.filterDeptSubCategory = action.payload
    },
    updateLocSubCateogryFilter: (state, action) => {
      state.filterLocSubCategory = action.payload
    }, 
    updateExtrapolationFilter: (state, action) => {
      state.filterExtrapolation = action.payload
    },
  },
})

export const {
  updateDateFilter, 
  updateYearFilter,
  updateMonthFilter, 
  updateCategoryFilter,
  udpateLeaderboardTimePeriodFilter,
  updateLeaderboardCategoryFilter,
  addLeaderboardActivitiesFilter,
  removeLeaderboardActivitiesFilter,
  resetLeaderboardActivitiesFilter,
  selectAllLeaderboardActivitiesFilter,
  updateDivSubCateogryFilter, 
  updateDeptSubCateogryFilter, 
  updateLocSubCateogryFilter, 
  updateExtrapolationFilter, 
  updateReportsOrgTag,
  resetReportsOrgTag,
  updateCustomGroupCategoryCategoryFilter,
  updateChallengesFilterType, 
  updateQuizzesFilterType
} = filterSlice.actions

export default filterSlice.reducer
