/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useMemo } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { useSelector } from 'react-redux'

import { ModifySVG } from '../../../../_metronic/helpers/components/ModifySVG'
import { EditIcon } from '../../../../_metronic/assets/icons'
import DivisionMasterData from './DivisionMasterData'
import DepartmentMasterData from './DepartmentMasterData'
import LocationMasterData from './LocationMasterData'
import ModalEditTotalEmployeeCount from './modals/ModalEditTotalEmployeeCount'

import { commaSeparatedFormat } from '../../../utils/helpers'
import './styles.css'
import { RootState } from '../../../store/store'

type Props = {

}

const MasterDataPage: React.FC<Props> = () => {
  const [showModalEditTotalEmp, setShowModalEditTotalEmp] = useState<boolean>(false)
  const { profile } = useSelector((state: RootState) => state.profile)

  return (
    <div className='blank-page-view py-8 px-12'>
      <h1 className='page-title-text'>MASTER DATA</h1>

      <div className='d-flex align-items-center my-2'>
        <h2 className='page-subtitle-text-grey'>Total number of employees in the company</h2>
        <div className='d-flex mx-5'>
          <div className='employee-count-container-div mx-5'>
            <h2 className='page-subtitle-text-grey'>{commaSeparatedFormat(profile?.organization?.totalEmployeeCount || '--')}</h2>
          </div>
          <button
            type='button'
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-md'
            onClick={() => setShowModalEditTotalEmp(true)}
          >
            <ModifySVG className='svg-icon-3'>
              <EditIcon className='svg-icon svg-icon-3' />
            </ModifySVG>
          </button>
        </div>
      </div>

      <div className='action-sec-container-div'>
        <Tabs
          defaultActiveKey="division"
          id="uncontrolled-tab-example"
          className="mb-3 tabs-list-container"
        >
          <Tab eventKey="division" title="Division">
            <DivisionMasterData />
          </Tab>
          <Tab eventKey="department" title="Department">
            <DepartmentMasterData />
          </Tab>
          <Tab eventKey="location" title="Location">
            <LocationMasterData />
          </Tab>
        </Tabs>
      </div>
      <ModalEditTotalEmployeeCount 
        show={showModalEditTotalEmp}
        numberOfEmployee={profile?.organization?.totalEmployeeCount}
        onHide={() => setShowModalEditTotalEmp(false)}
        />
    </div>
  )
}

export default MasterDataPage
/* eslint-disable react/no-unstable-nested-components */