/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useGetForestUpdatesQuery, useLazyGetForestUpdatesQuery } from './store/api';
import { commaSeparatedFormat } from '../../utils/helpers';
import { RootState } from '../../store/store';
import { ForestUpdateType } from './types';

interface TimelineItemProps {
date: string;
data?: [{
    species_name:string
    tree_planted:number
    images?:[string]
}];
acrePlanted: number;
index: number;
}

interface TimelineProps {
    ids: number[];
}

// const TimelineItem: React.FC<TimelineItemProps> = ({ date, data,index }) => {

//     const renderTimelineElement:React.ReactElement[] = []
//     data.forEach((itm)=>(
//         renderTimelineElement.push(
//             <p>{itm.tree_planted} trees of {itm.species_name} planted.</p>
//         )
//     ))

//     const dispDate = new Date(date).toLocaleDateString('en-GB');
//     return (
//         <div className="vertical-timeline-item vertical-timeline-element mt-10">
//             <div>
//             <span className="vertical-timeline-element-icon bounce-in">
//                 <i className="badge badge-dot badge-dot-xl badge-dark"> </i>
//             </span>
//             <div className="vertical-timeline-element-content bounce-in">
//                 {renderTimelineElement}
//             <span className="vertical-timeline-element-date">{dispDate}</span>
//             </div>
//             </div>
//         </div>
//     )
// };

const TimelineItem: React.FC<TimelineItemProps> = ({ date, data,index,acrePlanted }) => {

    // const renderTimelineElement:React.ReactElement[] = []
    // data.forEach((itm)=>(
    //     renderTimelineElement.push(
    //         <p className='m-0'>{commaSeparatedFormat(itm.tree_planted)} trees of {itm.species_name} planted.</p>
    //     )
    // ))

    const dispDate = new Date(date).toLocaleDateString('en-US');
    return (
        <li className='mx-5 position-relative'>
            <div className='timeline-line-grey' />
			<h6>{Number(acrePlanted).toFixed(2)} acres planted</h6>
            <h6 className='sub-title mb-0'>{dispDate}</h6>
            {/* {renderTimelineElement} */}
        </li>
    )
};

const Timeline: React.FC<TimelineProps>= ({ids}) => {

    const id = ids.join(',');

    const {profile} = useSelector((state:RootState) => state.profile)

    const {data:forestUpdatesData} = useGetForestUpdatesQuery({ id });

    if (!forestUpdatesData){
        return null;
    }

    // let dummyData = null


    // if (profile?.name === 'M') {
    //     dummyData = [
    //         {
    //             "visit_date": "2023-10-30",
    //             "acre_planted": 5.80,
    //             "data": [
    //                 {
    //                     "species_name": "Placeholder",
    //                     "tree_planted": 1,
    //                     "images": ['asdf']
    //                 }
    //             ]
    //         }, 
    //         {
    //             "visit_date": "2023-11-15",
    //             "acre_planted": 5.00,
    //             "data": [
    //                 {
    //                     "species_name": "Placeholder",
    //                     "tree_planted": 1, 
    //                     "images": ['asdf']
    //                 }
    //             ]
    //         }
    //     ]
            
    // }

    return (
//     <div className="vertical-timeline vertical-timeline--animate vertical-timeline--one-column mt-4 mb-2">
   
//     </div>
        <ul className="timeline forest-timeline-logs">
        {/* {(forestUpdatesData && !dummyData) && ( */}
        {(forestUpdatesData) && (
            forestUpdatesData?.map((item,index)=>(
                <TimelineItem 
                    key={`${index}-${item.visit_date}`}
                    date={item.visit_date} 
                    acrePlanted={item.acre_planted} 
                    index={index} data={item.data} 
                />
            ))
        )} 
        {/* {
            dummyData && (
                dummyData?.map((item,index)=>(
                    <TimelineItem 
                        key={`${index}-${item.visit_date}`}
                        date={item.visit_date} 
                        acrePlanted={item.acre_planted} 
                        index={index}
                    />
                ))
            )
        } */}
        </ul>
);
};

export default Timeline;
