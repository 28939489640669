const API_ENDPOINTS = {
  TOTAL_EMISSION: '/total-emission',
  TOTAL_RECAPTURE: '/recapture',
  ORG_EMPLOYEE: '/employees-onboard',
  EMPLOYEE_CONTRIBUTED: '/employees-contributed',
  TOP_ACTIVITIES: '/top-activities',
  NET_ZERO_PERCENTAGE: '/net-zero-percentage',
  HIGHLIGHTS: '/highlights',
  TOTAL_FOREST: '/total-forest',
  PLANS: '/plans',
  FOREST_TREND: '/forest-trend',
  EMISSIONS: '/emissions',
  DEPARTMENTS: '/departments',
  DIVISIONS: '/divisions',
  LOCATIONS: '/work-locations',
  LIST: '/list',
  FROM_DATE: 'from_date',
  TO_DATE: 'to_date',
  CATEGORY: 'category',
  SUB_CATEGORY: 'sub_category',
  IS_ACTIVE: 'isActive',
  IS_SCALED: 'is_scaled',
  IN_CATEGORY: 'in_category',
  IN_SUB_CATEGORY: 'in_sub_category',
  BY_AVERAGE: 'by_average',
  IS_ALL: 'is_all',
  MONTHLY: 'monthly',
  GROUP_ACTIVITIES: 'group_activities',
  EMISSION_TREND: '/emission-trend',
  DETAILED_LIST: '/detailed-list',
  ADMIN_DATA: '/admins/me',
  LEADERBOARD: '/leaderboard',
  START_DATE: 'start_date', 
  END_DATE: 'end_date', 
  RANGE: 'range', 
  ACTIVITIES: 'activities',
}

export default API_ENDPOINTS
