/* eslint-disable react/no-unstable-nested-components */
import {SetStateAction, useEffect, useState, useMemo} from 'react'
import {Link, useNavigate, useSearchParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import mixpanel from 'mixpanel-browser'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {RoutesPath} from '../../routing/RoutesPath'
import { PageTitle } from '../../routing/RoutesPageTitle'
import {ModifySVG} from '../../../_metronic/helpers/components/ModifySVG'
import {EditIcon, TrashIcon} from '../../../_metronic/assets/icons'
import {useLazyGetAllEmployeesQuery} from './store/api'
import {Employee, EmployeeFilters} from './types'
import ReactTable from '../../../_metronic/partials/components/tables/ReactTable'
import DeleteModal from './DeleteModal'
import { commaSeparatedFormat } from '../../utils/helpers'
import EmptyEmployeesPage from './EmptyEmployeesPage'
import { EVENT_TYPE, PAGES } from '../../analytics/tracker-types'

const RenderOnboardingStatus = ({item}: any) => {
  if (item.is_onboarding_completed === 1) {
    return (
      <span
        className='badge fw-semibold me-1 fs-6'
        style={{
          backgroundColor: '#F5FCF0',
          color: '#82C44B',
        }}
      >
        Completed
      </span>
    )
  }
  return (
    <span
      className='badge fw-semibold me-1 fs-6'
      style={{
        backgroundColor: '#FFFAE2',
        color: '#C8AD2F',
      }}
    >
      Pending
    </span>
  )
}

const RenderEmployeeName = ({item}: any) => (
  <div className='d-flex align-items-center'>
    <div className='symbol symbol-45px me-5 '>
      <img src={item?.image} alt='' style={{height: '46px', width: '46px', borderRadius: '23px'}} />
    </div>
    <div className='d-flex justify-content-start flex-column'>
      <div className='text-dark fw-bold fs-6'>{item.name}</div>
    </div>
  </div>
)

const RenderMenuOptions = ({item, handleSelectedEmployee, handleShowDeleteModal}: any) => (
  <div className='d-flex justify-content-start flex-shrink-0'>
    <Link
      to={RoutesPath.EMPLOYEES.EDIT_EMPLOYEE}
      state={{employee: item}}
      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
    >
      <ModifySVG className='svg-icon-3'>
        <EditIcon className='svg-icon svg-icon-3' />
      </ModifySVG>
    </Link>
    <button
      type='button'
      onClick={() => {
        handleSelectedEmployee()
        handleShowDeleteModal()
      }}
      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
    >
      <ModifySVG className='svg-icon-3'>
        <TrashIcon className='svg-icon svg-icon-3' style={{color: "#5CADC9"}} />
      </ModifySVG>
    </button>
  </div>
)

const EmployeeDetails = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  // update page title
  useEffect(() => {
    document.title = PageTitle.EMPLOYEE
    // tracking
    mixpanel.track(EVENT_TYPE.PageView, {'name': PAGES.EmployeesPage})
  }, [])
  const [searchParams, setSearchParams] = useSearchParams()
  const searchTerm = searchParams.get('searchTerm') ?? ''
  const appliedDepartment = searchParams.get('departmentId') ?? ''
  const appliedLocation = searchParams.get('locationId') ?? ''
  const appliedDivision = searchParams.get('divisionId') ?? ''

  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [sortBy, setSortBy] = useState('')
  const [sortOrder, setSortOrder] = useState('')
  const [filters, setFilters] = useState<EmployeeFilters>({
    department: appliedDepartment,
    location: appliedLocation,
    division: appliedDivision,
  })
  const [lastDebounceSearch, setLastDebounceSearch] = useState('')

  // const handleEditEmployee = (employee: Employee) => {
  //   navigate(RoutesPath.EDIT_EMPLOYEE, { state: { selectedEmployee: employee } });
  // };

  const limit = 10
  const [currentPage, setCurrentPage] = useState(0)
  const [getAllEmployees, {data: employeeData, isLoading, isFetching, isError}] =
    useLazyGetAllEmployeesQuery()
  const getEmployeeData = () => {
    getAllEmployees({
      offset: (currentPage === 0 || searchTerm !== lastDebounceSearch) ? 0 : currentPage * limit,
      limit,
      searchTerm: searchTerm.trim(),
      sortBy,
      sortOrder: sortOrder.toUpperCase(),
      ...(filters && filters.department !== '' && {departmentId: [filters.department]}),
      ...(filters && filters.location !== '' && {locationId: [filters.location]}),
      ...(filters && filters.division !== '' && {divisionId: [filters.division]}),
    })
    .then(() => {
      // preventing empty state while debouncing
      setLastDebounceSearch(searchTerm)
    })
  }
  
  
  // debouncing while searching for employees
  useEffect(() => {
    // resetting current page to first page when searched for employee
    setCurrentPage(0)
    const getData = setTimeout(() => {
      if (searchTerm.length!==0 && currentPage===0) { // if-check for bug -- fetching api again otherwise
        getEmployeeData()
      }
    }, 1000)
    if (searchTerm.length === 0) { // if-check for bug -- fetching api again otherwise
      getEmployeeData()
    }
    return () => clearTimeout(getData)
  }, [searchTerm])

  useEffect(() => {
    getEmployeeData()
  }, [sortBy, sortOrder, filters, currentPage])

  const handlePagechange = (pageIndex: number) => {
    setCurrentPage(pageIndex)
  }

  const onSortChange = (columnId: string, order: string) => {
    if (columnId !== sortBy) {
      setSortBy(columnId)
    }
    if (order !== sortOrder) {
      setSortOrder(order)
    }
  }

  // useEffect(() => {
  //   getEmployeeData()
  // }, [currentPage])

  const headers = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'name',
        Cell: ({cell}: {cell: any}) => <RenderEmployeeName item={cell.row.original} />,
      },
      {Header: 'Email Id', accessor: 'email'},
      {Header: 'Division', accessor: 'division'},
      {Header: 'Department', accessor: 'department'},
      {
        Header: 'Location',
        accessor: 'location',
      },
      {
        Header: 'Onboarding',
        accessor: 'is_onboarding_completed',
        Cell: ({cell}: {cell: any}) => <RenderOnboardingStatus item={cell.row.original} />,
      },
      {
        Header: 'Action',
        accessor: 'edit',
        disableSortBy: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({cell}: {cell: any}) => (
          <RenderMenuOptions
            item={cell.row.original}
            handleSelectedEmployee={() => setSelectedEmployee(cell.row.original)}
            handleShowDeleteModal={() => setShowDeleteModal(true)}
          />
        ),
      },
      // {
      //   Header: '',
      //   accessor: 'delete',
      //   // eslint-disable-next-line react/no-unstable-nested-components
      //   Cell: ({cell}: {cell: any}) => (
      //     <button
      //       type='button'
      //       onClick={() => {
      //         setSelectedEmployee(cell.row.original)
      //         setShowDeleteModal(true)
      //       }}
      //       className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
      //     >
      //       <ModifySVG className='svg-icon-3'>
      //         <TrashIcon className='svg-icon svg-icon-3' />
      //       </ModifySVG>
      //     </button>
      //   ),
      // },
    ],
    []
  )

  const employeesDetails = employeeData?.edges

  if ((
    !isLoading && 
    employeesDetails?.length===0 && 
    searchTerm.length===0 && 
    appliedDepartment.length===0 && 
    appliedDivision.length===0 && 
    appliedLocation.length===0 && 
    searchTerm === lastDebounceSearch
    )) {
    return <EmptyEmployeesPage />
  }

  return (
    <div className='card card-xxl-stretch pt-2 pb-6 px-8'>
      {/* begin::Header */}
      <DeleteModal
        selectedEmployee={selectedEmployee}
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
      />

      <div className='card-header border-0'>
        <h1 className='align-self-center page-title-text'>
          EMPLOYEES 
          <span className='fs-4 mx-10 text-muted'>Total Employees - {employeeData ? commaSeparatedFormat(employeeData?.totalCount): 0}</span>
          <span className='fs-4 text-muted'>Onboarding Pending - <span className='text-warning'>{employeeData ? commaSeparatedFormat(employeeData?.onboarding_pending): 0}</span></span>
        </h1>
        <div
          className='card-toolbar'
        >
          <div className='wrap-employee-search-input'>
            {/* <SearchIcon color='grey' /> */}
            <i className="bi bi-search" style={{fontSize: '15px', color: 'grey', marginRight: '5px'}} />
            <input
              placeholder='Search employee name'
              className='employee-search-input'
              value={searchParams.get('searchTerm') || ''}
              onChange={(v) => {
                setSearchParams({searchTerm: v.currentTarget.value})
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter')  {
                  getEmployeeData()
                }
              }}
            />
            {
              searchTerm.length > 0 && 
              <button type='button' className='remove-btn-styles' onClick={() => {setSearchParams({searchTerm: ''})}}>
                <i className="bi bi-x-lg" style={{fontSize: '15px', color: 'grey'}} />
              </button>
            }
          </div>
          {/* <button
            type='button'
            // disabled={!loading}
            className='custom-primary-delete-button'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            // onClick={() =>
            //   navigate({
            //     pathname: RoutesPath.EMPLOYEES.EMPTY_EMPLOYEE,
            //   })
            // }
          >
            Download
          </button> */}
          <button
            type='button'
            // disabled={!loading}
            className='custom-primary-delete-button'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={() =>{
              navigate(RoutesPath.EMPLOYEES.UPLOAD_CSV)
              // navigate({
              //   pathname: RoutesPath.EMPLOYEES.ADD_EMPLOYEE,
              // })
            }}
          >
            Add Employee
          </button>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className=''>
        <ReactTable
          headerData={headers}
          tableData={employeeData?.edges ?? []}
          currentPageIndex={currentPage}
          className='min-w-100px'
          manualSort
          onSort={onSortChange}
          showPagination={employeeData?.edges.length !== 0}
          manualPagination
          pagesCount={
            employeeData ? Math.ceil(employeeData.totalCount / employeeData.pagination.limit) : 1
          }
          onPageChange={handlePagechange}
          isLoading={isFetching}
          message={intl.formatMessage({id: 'EMPLOYEE.NO_RESULTS_FOUND'})}
        />
      </div>
    </div>
  )
}

export {EmployeeDetails}
