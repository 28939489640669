import api from '../../../services/api'
import baseApiv2 from '../../../services/api'
import {MethodType} from '../../../services/constants'
import {queryStringBuilder} from '../../../utils/querStringBuilder'
import {
  EmissionTrendResponse,
  TopEmissionsListResponse,
  TotalEmissionsResponse,
  EmissionDetailsResponse,
  TopActivitiesResponse,
  OrgEmployeesDetail,
  ForestTrendResponse,
  TotalForestResponse,
  DeptListResponse,
} from '../type'
import API_ENDPOINTS from './apiPaths'

const dashboardApisV2 = baseApiv2.injectEndpoints({
  endpoints: (builder) => ({
    getDepartmentsList: builder.query({
      query: (params) => {
        const queryParams = queryStringBuilder([
          ...(params?.isActive ? [{name: API_ENDPOINTS.IS_ACTIVE, value: params?.isActive}] : [])
        ])
        return {
          url: `${API_ENDPOINTS.DEPARTMENTS}${queryParams ? `?${queryParams}` : ''}`,
          method: MethodType.get,
        }
      },
      transformResponse: (response: DeptListResponse) => response?.data,
    }),
    getLocationsList: builder.query({
      query: (params) => {
        const queryParams = queryStringBuilder([
          ...(params?.isActive ? [{name: API_ENDPOINTS.IS_ACTIVE, value: params?.isActive}] : [])
        ])
        return {
          url: `${API_ENDPOINTS.LOCATIONS}${queryParams ? `?${queryParams}` : ''}`,
          method: MethodType.get,
        }
      },
      transformResponse: (response: DeptListResponse) => response?.data,
    }),
    getDivisionsList: builder.query({
      query: (params) => {
        const queryParams = queryStringBuilder([
          ...(params?.isActive ? [{name: API_ENDPOINTS.IS_ACTIVE, value: params?.isActive}] : [])
        ])
        return {
          url: `${API_ENDPOINTS.DIVISIONS}${queryParams ? `?${queryParams}` : ''}`,
          method: MethodType.get, 
        }
      },
      transformResponse: (response: DeptListResponse) => response?.data,
    }),
  }),
})

const dashboardApis = api.injectEndpoints({
  endpoints: (builder) => ({
    getTotalEmissionsData: builder.query({
      query: (params) => {
        const queryParams = queryStringBuilder([
          {name: API_ENDPOINTS.FROM_DATE, value: params.fromDate},
          {name: API_ENDPOINTS.TO_DATE, value: params.toDate},
          {name: API_ENDPOINTS.CATEGORY, value: params.category},
          {name: API_ENDPOINTS.SUB_CATEGORY, value: params.sub_category},
          {name: API_ENDPOINTS.IS_SCALED, value: params.is_scaled},
          {name: API_ENDPOINTS.BY_AVERAGE, value: params.by_average},
        ])
        return {
          url: `${API_ENDPOINTS.TOTAL_EMISSION}${queryParams ? `?${queryParams}` : ''}`,
          method: MethodType.get,
        }
      },
      transformResponse: (response: TotalEmissionsResponse) => response?.data,
    }),
    getTopEmissionsList: builder.query({
      query: (params) => {
        const queryParams = queryStringBuilder([
          {name: API_ENDPOINTS.FROM_DATE, value: params.fromDate},
          {name: API_ENDPOINTS.TO_DATE, value: params.toDate},
          {name: API_ENDPOINTS.CATEGORY, value: params.category},
          {name: API_ENDPOINTS.IN_CATEGORY, value: params.in_category},
          {name: API_ENDPOINTS.IN_SUB_CATEGORY, value: params.in_sub_category},
          {name: API_ENDPOINTS.BY_AVERAGE, value: params.by_average},
          {name: API_ENDPOINTS.IS_SCALED, value: params.is_scaled},
        ])
        return {
          url: `${API_ENDPOINTS.EMISSIONS}${API_ENDPOINTS.LIST}${
            queryParams ? `?${queryParams}` : ''
          }`,
          method: MethodType.get,
        }
      },
      transformResponse: (response: TopEmissionsListResponse) => response?.data,
    }),
    getEmissionTrends: builder.query({
      query: (params) => {
        const queryParams = queryStringBuilder([
          {name: API_ENDPOINTS.FROM_DATE, value: params.fromDate},
          {name: API_ENDPOINTS.TO_DATE, value: params.toDate},
          {name: API_ENDPOINTS.CATEGORY, value: params.category},
          {name: API_ENDPOINTS.SUB_CATEGORY, value: params.subCategory},
          {name: API_ENDPOINTS.BY_AVERAGE, value: params.by_average},
          {name: API_ENDPOINTS.IS_ALL, value: params.is_all},
          {name: API_ENDPOINTS.MONTHLY, value: params.monthly}, 
          {name: API_ENDPOINTS.IS_SCALED, value: params.is_scaled},
          {name: API_ENDPOINTS.GROUP_ACTIVITIES, value: params.group_activities}
        ])
        return {
          url: `${API_ENDPOINTS.EMISSION_TREND}${queryParams ? `?${queryParams}` : ''}`,
          method: MethodType.get,
        }
      },
      transformResponse: (response: EmissionTrendResponse) => response?.data,
    }),
    getEmissionDetailedList: builder.query({
      query: (params) => {
        const queryParams = queryStringBuilder([
          {name: API_ENDPOINTS.FROM_DATE, value: params.fromDate},
          {name: API_ENDPOINTS.TO_DATE, value: params.toDate},
          {name: API_ENDPOINTS.CATEGORY, value: params.category},
          {name: API_ENDPOINTS.IS_SCALED, value: params.is_scaled},
          {name: API_ENDPOINTS.BY_AVERAGE, value: params.by_average},
        ])
        return {
          url: `${API_ENDPOINTS.EMISSIONS}${API_ENDPOINTS.DETAILED_LIST}${
            queryParams ? `?${queryParams}` : ''
          }`,
          method: MethodType.get,
        }
      },
      transformResponse: (response: EmissionDetailsResponse) => response?.data,
    }),
    getTopActivitiesData: builder.query({
      query: (params) => {
        const queryParams = queryStringBuilder([
          {name: API_ENDPOINTS.FROM_DATE, value: params.fromDate},
          {name: API_ENDPOINTS.TO_DATE, value: params.toDate},
          {name: API_ENDPOINTS.CATEGORY, value: params.category}, 
          {name: API_ENDPOINTS.SUB_CATEGORY, value: params.sub_category},
          {name: API_ENDPOINTS.IS_SCALED, value: params.is_scaled},
          {name: API_ENDPOINTS.BY_AVERAGE, value: params.by_average},
        ])
        return {
          url: `${API_ENDPOINTS.TOP_ACTIVITIES}${queryParams ? `?${queryParams}` : ''}`,
          method: MethodType.get,
        }
      },
      transformResponse: (response: TopActivitiesResponse) => response?.data,
    }),
    getNetZeroPercentage: builder.query({
      query: (params) => {
        const queryParams = queryStringBuilder([
          {name: API_ENDPOINTS.FROM_DATE, value: params.fromDate},
          {name: API_ENDPOINTS.TO_DATE, value: params.toDate},
        ])
        return {
          url: `${API_ENDPOINTS.NET_ZERO_PERCENTAGE}${queryParams ? `?${queryParams}` : ''}`,
          method: MethodType.get,
        }
      },
    }),
    getHighlights: builder.query({
      query: (params) => {
        const queryParams = queryStringBuilder([
          {name: API_ENDPOINTS.FROM_DATE, value: params.fromDate},
          {name: API_ENDPOINTS.TO_DATE, value: params.toDate},
        ])
        return {
          url: `${API_ENDPOINTS.HIGHLIGHTS}${queryParams ? `?${queryParams}` : ''}`,
          method: MethodType.get,
        }
      },
    }),
    getOrgEmployeesData: builder.query({
      query: (params) => {
        const queryParams = queryStringBuilder([
          {name: API_ENDPOINTS.FROM_DATE, value: params.fromDate},
          {name: API_ENDPOINTS.TO_DATE, value: params.toDate},
          {name: API_ENDPOINTS.CATEGORY, value: params.category},
        ])
        return {
          url: `${API_ENDPOINTS.ORG_EMPLOYEE}${queryParams ? `?${queryParams}` : ''}`,
          method: MethodType.get,
        }
      },
      transformResponse: (response: OrgEmployeesDetail) => response?.data,
    }),
    getContributedEmployeesData: builder.query({
      query: (params) => {
        const queryParams = queryStringBuilder([
          {name: API_ENDPOINTS.FROM_DATE, value: params.fromDate},
          {name: API_ENDPOINTS.TO_DATE, value: params.toDate},
          {name: API_ENDPOINTS.CATEGORY, value: params.category},
          {name: API_ENDPOINTS.SUB_CATEGORY, value: params.sub_category}
        ])
        return {
          url: `${API_ENDPOINTS.EMPLOYEE_CONTRIBUTED}${queryParams ? `?${queryParams}` : ''}`,
          method: MethodType.get,
        }
      },
      transformResponse: (response: OrgEmployeesDetail) => response?.data,
    }),
    getForestTrends: builder.query({
      query: (params) => {
        const queryParams = queryStringBuilder([
          {name: API_ENDPOINTS.FROM_DATE, value: params.fromDate},
          {name: API_ENDPOINTS.TO_DATE, value: params.toDate},
        ])
        return {
          url: `${API_ENDPOINTS.FOREST_TREND}${queryParams ? `?${queryParams}` : ''}`,
          method: MethodType.get,
        }
      },
      transformResponse: (response: ForestTrendResponse) => response?.data,
    }),
    getPlans: builder.query({
      query: (params) => ({
        url: `${API_ENDPOINTS.PLANS}`,
        method: MethodType.get,
      }),
    }),
    getOrganizationsLeaderboard: builder.mutation({
      query: (params) => ({
          url: `${API_ENDPOINTS.LEADERBOARD}`, 
          data: {
            "start_date": params.startDate,
            "end_date": params.endDate,
            "range": params.range,
            "category": params.category,
            "activities": params.activities,
          },
          method: MethodType.post
      }),
    })
  //   getTotalForest: builder.query({
  //     query: (params) => {
  //       const queryParams = queryStringBuilder([
  //         {name: API_ENDPOINTS.FROM_DATE, value: params.fromDate},
  //         {name: API_ENDPOINTS.TO_DATE, value: params.toDate},
  //       ])
  //       return {
  //         url: `${API_ENDPOINTS.TOTAL_FOREST}${queryParams ? `?${queryParams}` : ''}`,
  //         method: MethodType.get,
  //       }
  //     },
  //     transformResponse: (response: TotalForestResponse) => response?.data,
  //   }),
  }),
})

export const {
  useLazyGetPlansQuery, 
  useLazyGetTotalEmissionsDataQuery,
  useLazyGetTopActivitiesDataQuery,
  useLazyGetOrgEmployeesDataQuery,
  useLazyGetForestTrendsQuery,
  // useLazyGetTotalForestQuery,
  useLazyGetTopEmissionsListQuery,
  useLazyGetEmissionTrendsQuery,
  useLazyGetEmissionDetailedListQuery,
  useLazyGetContributedEmployeesDataQuery,
  useGetEmissionTrendsQuery,
  useLazyGetNetZeroPercentageQuery,
  useLazyGetHighlightsQuery, 
  useGetOrganizationsLeaderboardMutation,
} = dashboardApis

export const {
  useLazyGetDivisionsListQuery,
  useLazyGetDepartmentsListQuery,
  useLazyGetLocationsListQuery,
} = dashboardApisV2
