const API_ENDPOINTS = {
  PROJECTS: '/projects',
  PROJECT: '/project',
  SUGGESTED_PLANS: '/suggested-plans',
  SUBSCRIBE: '/plans/subscribe',
  UPDATE: '/update',
  ALLOCATIONS: '/allocations',
  PAYMENTS: '/payments',
  RAZORPAY: '/razorpay',
  CALLBACKS: '/callbacks',
  LEADS: '/plan-lead',
  TOTAL_FOREST:'/total-forest',
  FROM_DATE: 'from_date',
  TO_DATE: 'to_date',
  FOREST_UDPATES:'/forest-updates',
  FOREST_GUID:'forest_guid',
  ALLOCATION:'/allocation'
}

export default API_ENDPOINTS
