/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useMemo} from 'react'

import { useLazyGetLocationsListQuery } from '../../dashboard/store/api'

import ReactTable from '../../../../_metronic/partials/components/tables/ReactTable'
import { ModifySVG } from '../../../../_metronic/helpers/components/ModifySVG'
import { EditIcon, TrashIcon } from '../../../../_metronic/assets/icons'
import ModalDeleteCurrentCell from './modals/ModalDeleteCurrentCell'
import ModalAddLocationData from './modals/ModalAddLocationData'

import { commaSeparatedFormat, getAddressByLatLng } from '../../../utils/helpers'

const defaultLatLng = {
  lat: 28.6186, 
  lng: 77.2037
}

const RenderSectionName = ({value}: {value:string}) => (
  <div>{value}</div>
)

const RenderNoOfEmployee = ({value}: {value:number}) => (
  <div>{commaSeparatedFormat(value)}</div>
)

const RenderAddress = ({lat, lng}: {lat:any, lng:any}) => {
  const latitude = lat ? Number(lat) : 0;
  const longitude = lng ? Number(lng) : 0;
  const [address, setAddress] = useState<string>('')

  useEffect(()=>{
    async function getAddress() {
     const val = await getAddressByLatLng(latitude, longitude) ;
     setAddress(val)
    };
    if (latitude!==0 || longitude!==0) {
      getAddress();
    }
  }, [latitude, longitude])
  
  return (
    <div className='location-table-address-wrap'>
      <p className='location-table-address-text'>
        {address}
      </p>
    </div>
  )
 }

const RenderMenuOptions = ({item, handleShowAddLocData, handleShowDeleteCurrent}: any) => (
  <div className='d-flex justify-content-start flex-shrink-0'>
    <button
      type='button'
      onClick={handleShowAddLocData}
      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
    >
      <ModifySVG className='svg-icon-3'>
        <EditIcon className='svg-icon svg-icon-3' />
      </ModifySVG>
    </button>
    {/* <button
      type='button'
      onClick={() => {
        handleShowDeleteCurrent()
      }}
      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
    >
      <ModifySVG className='svg-icon-3'>
        <TrashIcon className='svg-icon svg-icon-3' />
      </ModifySVG>
    </button> */}
  </div>
)

const LocationMasterData: any = () => {
  const [onChange, setOnChange] = useState<boolean>()
  const [showDeleteCurrentModal, setShowDeleteCurrentModal] = useState<boolean>(false)
  const [showModalAddLocationData, setShowModalAddLocationData] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [numberOfLocation, setNumberOfLocation] = useState<number>(0)
  const [currectLocationSelected, setCurrectLocationSelected] = useState<any>()

  const [getLocationsList, {data: locationsListData}] = useLazyGetLocationsListQuery()

  useEffect(() => {
    getLocationsList({})
  }, [onChange])

  useEffect(() => {
    if (locationsListData) {
      setNumberOfLocation(locationsListData?.length)
    }
  }, [locationsListData])

  const headers = useMemo(
    () => [
      {
        Header: `LOCATIONS (${numberOfLocation})`,
        accessor: 'name',
        Cell: ({cell}: {cell: any}) => (
          <RenderSectionName value={cell.row.original.name} />
        )
      },
      {
        Header: 'NO. OF EMPLOYEES (TOTAL)', 
        accessor: 'total_employee_count', 
        Cell: ({cell}: {cell: any}) => (
          <RenderNoOfEmployee value={cell.row.original.total_employee_count || '-'} />
        )
      },
      // {
      //   Header: 'ADDRESS',
      //   accessor: 'latitude',
      //   Cell: ({cell}: {cell: any}) => (
      //     <RenderAddress lat={cell.row.original.latitude} lng={cell.row.original.longitude} />
      //   )
      // },
      {
        Header: 'ACTION',
        accessor: 'edit',
        disableSortBy: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({cell}: {cell: any}) => (
          <RenderMenuOptions
            item={cell.row.original}
            handleShowAddLocData={() => {
              setIsEdit(true)
              setCurrectLocationSelected(cell.row.original)
              setTimeout(() => {
                setShowModalAddLocationData(true)
              }, 500)
            }}
            handleShowDeleteCurrent={() => setShowDeleteCurrentModal(true)}
          />
        ),
      },
    ],
    [numberOfLocation]
  )

  return (
    <div className='my-5 position-relative'>
      <ReactTable
        headerData={headers}
        tableData={locationsListData || []}
        currentPageIndex={0}
        className='custom-border'
        manualSort
        disableSort
        showPagination={false}
        manualPagination
        message='No data found!'
      />
      <button
        type='button'
        // disabled={!loading}
        className='custom-primary-delete-button m-0 master-data-add-new-btn'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='top-end'
        onClick={() => {
          setIsEdit(false)
          setShowModalAddLocationData(true)
        }}
      >
        Add New Location
      </button>
      {
        showModalAddLocationData &&
        <ModalAddLocationData
          show={showModalAddLocationData}
          onHide={() => setShowModalAddLocationData(false)}
          onChange={() => setOnChange(state => !state)}
          isEdit={isEdit}
          name={currectLocationSelected?.name || ''}
          numberOfEmployee={currectLocationSelected?.total_employee_count || 0}
          latitude={currectLocationSelected?.latitude || defaultLatLng.lat}
          longitude={currectLocationSelected?.longitude || defaultLatLng.lng}
          id={currectLocationSelected?.id || null}
        />
      }  
      <ModalDeleteCurrentCell
        show={showDeleteCurrentModal}
        onHide={() => setShowDeleteCurrentModal(false)}
        onChange={() => setOnChange(state => !state)}
        />
    </div>
  )
}

export default LocationMasterData
/* eslint-disable react/no-unstable-nested-components */