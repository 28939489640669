import api from '../../../services/api'
import {APITags, MethodType} from '../../../services/constants'
import {UpdatePasswordRequest, UpdateProfileRequest, UpdateResponse} from '../types'
import API_ENDPOINTS from './apiPaths'

const customBaseUrl = `${process.env.REACT_APP_API_URL}`

const profileApis = api.injectEndpoints({
  endpoints: (builder) => ({
    updateProfile: builder.mutation<string[], UpdateProfileRequest>({
      query: (params) => ({
        url: `${API_ENDPOINTS.ADMINS}${API_ENDPOINTS.UPDATE}`,
        data: {
          name: params.name,
          department_guid: params.departmentId,
        },
        method: MethodType.put,
        customBaseUrl,
      }),
      invalidatesTags: (data, error) => {
        if (!error) return [APITags.GET_PROFILE]
        return []
      },
      transformResponse: (response: UpdateResponse) => response?.data,
    }),
    updatePassword: builder.mutation<string[], UpdatePasswordRequest>({
      query: (params) => ({
        url: `${API_ENDPOINTS.ADMINS}${API_ENDPOINTS.RESET_PASSWORD}`,
        data: {
          current_password: params.currentPassword,
          new_password: params.newPassword,
        },
        method: MethodType.put,
        customBaseUrl,
      }),
      transformResponse: (response: UpdateResponse) => response?.data,
    }),
    uploadOrganizationLogo: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.ADMINS}${API_ENDPOINTS.UPLOAD_ORGANIZATION_LOGO}`,
        data: params, 
        method: MethodType.post, 
        customBaseUrl,
      })
    }), 
    syncWorkshopData: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.ORG_EMISSION_SYNC}?start_date=${params?.start_date}&end_date=${params?.end_date}`,
        method: MethodType.post,
      })
    })
  }),
})

export const {
  useUpdateProfileMutation, 
  useUpdatePasswordMutation, 
  useUploadOrganizationLogoMutation,
  useSyncWorkshopDataMutation, 
} = profileApis
