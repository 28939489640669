import { useDispatch, useSelector } from 'react-redux'

import { RootState } from '../../../../store/store'
import { CustomGroupCategoryType, updateCustomGroupCategoryCategoryFilter } from '../../../../store/slices/filters'

export const FilterCustomGroups = () => {
  const categoriesFilter: CustomGroupCategoryType[] = ["live", "upcoming", "completed"];
  const dispatch = useDispatch();
  const {filterCustomGroupCategory} = useSelector((state: RootState) => state.filters);

  const handleFilterLeaderboardCategory = (value: CustomGroupCategoryType) => {
    dispatch(updateCustomGroupCategoryCategoryFilter(value));
  }

  return (
    <div className='d-flex mx-0 px-0 mb-6'>
    {
      categoriesFilter.map((value: CustomGroupCategoryType) => (
      <div key={value} className='justify-content-end' style={{marginRight: '10px'}}>
        <button
          type='button'
          // disabled={!loading}
          className={`${filterCustomGroupCategory === value ? 'custom-group-category-filter-button' : 'custom-group-category-filter-outline-button'} m-0 px-10`}
          style={{
            borderRadius: '8px',
            fontWeight: '700'
          }}
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
          onClick={() => {
            handleFilterLeaderboardCategory(value);
          }}
        >
          {value.toUpperCase()}
        </button>
      </div>
      ))
    }
    </div>
  )
}
