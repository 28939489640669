import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useLocation, useNavigate} from 'react-router-dom'
import mixpanel from 'mixpanel-browser'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { useSelector } from 'react-redux'
import {
  useGetDepartmentsQuery,
  useGetDivisionsQuery,
  useGetLocationsQuery,
  useGetPositionsQuery,
  useUpdateEmployeeMutation,
} from './store/api'
import {useAppDispatch} from '../../store/store'
import {NotificationType} from '../../../_metronic/partials/components/types'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {RoutesPath} from '../../routing/RoutesPath'
import {Employee} from './types'
import {updateNotification} from '../../store/slices/notifier'
import { EVENT_TYPE, PAGES } from '../../analytics/tracker-types'
import { RootState } from '../../store/store'

interface employeeInterface {
  employee: Employee
}

const profileDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  departmentId: Yup.string().required('Department is required'),
  divisionId: Yup.string().required('Division is required'),
  locationId: Yup.string().required('Location is required'),
})

const EditEmployeeDetails = () => {
  const navigate = useNavigate()

  const loc = useLocation()
  const employee = (loc.state as employeeInterface)?.employee

  // window.alert(employee.name)
  const intl = useIntl()
  const [updateEmployee, {isLoading, isSuccess, isError}] = useUpdateEmployeeMutation()
  const {data: departments} = useGetDepartmentsQuery()
  const {data: locations} = useGetLocationsQuery()
  const {data: divisions} = useGetDivisionsQuery()

  const {profile} = useSelector((state:RootState) => state.profile)

  useEffect(() => {
    // tracking
    mixpanel.track(EVENT_TYPE.PageView, {'name': PAGES.EditEmployeeDetailPage})
  }, [])

  const formik = useFormik<Employee>({
    initialValues: employee,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      // workshop handling
      if (profile?.organization?.isWorkshopOrg) {
        return;
      }
      await updateEmployee({
        employeeId: employee.guid,
        departmentId: values.departmentId,
        locationId: values.locationId,
        divisionId: values.divisionId,
      })
    },
  })

  const dispatch = useAppDispatch()

  const updateData = () => {
    formik.handleSubmit()
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        updateNotification({
          message: 'Employee updated successfully',
          type: NotificationType.SUCCESS,
        })
      )
      navigate(RoutesPath.EMPLOYEES.EMPLOYEES_DETAILS)
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      dispatch(
        updateNotification({
          message: intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}),
          type: NotificationType.ERROR,
        })
      )
    }
  }, [isError])

  useEffect(() => {
    if (employee) {
      formik.setFieldValue('name', employee.name)
      formik.setFieldValue('divisionId', employee.divisionId)
      formik.setFieldValue('departmentId', employee.departmentId)
      formik.setFieldValue('locationId', employee.locationId)
    } else {
      navigate(RoutesPath.EMPLOYEES.EMPLOYEES_DETAILS)
    }
  }, [employee, formik.setFieldValue, navigate])

  if (!employee) {
    // Employee data not available, handle the condition (e.g., show loading state or redirect)
    // Example: Redirect to the EmployeeDetails page
    navigate(RoutesPath.EMPLOYEES.EMPLOYEES_DETAILS)
    return null
  }
  const isDisable = true

  return (
    <div style={{backgroundColor: 'white', flex: '1', minHeight: '100%', position: 'relative'}}>
      <div className='back-header-btn mt-8' style={{marginLeft: '40px'}}>
        <button 
          type='button' 
          className='p-2 back-left-arrow-btn-wrap'
          onClick={()=>{navigate(RoutesPath.EMPLOYEES.ROOT)}}
          >
          <div className='back-left-arrow-btn' /> 
        </button>
        <h6 className='m-0 mx-4 fs-4 font-weight-normal'><u>Edit Employee</u></h6>
      </div>

      {/* <div className='background-image pencil-bg mb-20' /> */}
      <div className='card-wrapper-edit-employee'>
        <form onSubmit={formik.handleSubmit} noValidate className='form row m-0 p-0'>

          <div className='col-2 edit-employee-image-wrapper'>
            <img className='edit-employee-image' src={employee?.image} alt='' />
          </div>

          <div className='col-8 px-5 edit-employee-header-wrap'>
            <div>
              <h2 className='edit-employee-name-header'>{employee?.name}</h2>
              <h4 className='edit-employee-email-header'>{employee?.email}</h4>
            </div>
          </div>

          <div className='mt-10 px-8'>
            {/** Division */}
            <div className='row m-0 p-0 mb-10 justify-content-between'>
              <div className='col-6 m-0 p-0 px-5'>
                <label htmlFor='divisionId' className='col-lg-4 col-form-label fw-bold fs-6'>
                  Division
                </label>
                
                <select
                  className='form-select form-select-solid form-select-lg'
                  // className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                  {...formik.getFieldProps('divisionId')}
                >
                  <option value='' key='empty'>
                    Select a division
                  </option>
                  {divisions &&
                    divisions.map((division) => (
                      <option value={division.guid} key={division.guid}>
                        {division.name}
                      </option>
                    ))}
                </select>

                {formik.touched.divisionId && formik.errors.divisionId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.divisionId}</div>
                  </div>
                )}
              </div>

              {/** Department */}
              <div className='col-6 mb-0 p-0 px-5'>
                <label htmlFor='departmentId' className='col-lg-4 col-form-label fw-bold fs-6'>
                  Department
                </label>

                <select
                  className='form-select form-select-solid form-select-lg'
                  // className='form-control form-control-lg form-control-solid'
                  {...formik.getFieldProps('departmentId')}
                >
                  <option value='' key='empty'>
                    Select a department
                  </option>
                  {departments &&
                    departments.map((department) => (
                      <option value={department.guid} key={department.guid}>
                        {department.name}
                      </option>
                    ))}
                </select>

                {formik.touched.departmentId && formik.errors.departmentId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.departmentId}</div>
                  </div>
                )}
              </div>
            </div>

            {/** Location */}
            <div className='row m-0 p-0 justify-content-between'>
              <div className='col-6 m-0 p-0 px-5'>
                <label htmlFor='locationId' className='col-lg-4 col-form-label fw-bold fs-6'>
                  Location
                </label>

                <select
                  className='form-select form-select-solid form-select-lg'
                  // className='form-control form-control-lg form-control-solid'
                  {...formik.getFieldProps('locationId')}
                >
                  <option value='' key='empty'>
                    Select a location
                  </option>
                  {locations &&
                    locations.map((location) => (
                      <option value={location.guid} key={location.guid}>
                        {location.name}
                      </option>
                    ))}
                </select>

                {formik.touched.locationId && formik.errors.locationId && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.locationId}</div>
                  </div>
                )}
              </div>

            </div>
          </div>
        </form>

      </div>
      {/* <div className='card pt-20 '> */}
      <div className='card-footer footer shadow mt-auto'>
        <div className='settings-btn-bottom px-8'>
          <div className='py-6 px-3'>
            <button
              type='button'
              // disabled={!loading}
              className='custom-outline-button'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              onClick={() =>
                navigate({
                  pathname: RoutesPath.EMPLOYEES.EMPLOYEES_DETAILS,
                })
              }
            >
              Cancel
            </button>
          </div>

          <div className='py-6 px-3'>
            <button
              type='submit'
              disabled={isLoading || JSON.stringify(employee) === JSON.stringify(formik.values)}
              className='custom-primary-delete-button'
              onClick={() => updateData()}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  )
}

export {EditEmployeeDetails}
