// ChatButton.js
import React, { useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useSyncWorkshopDataMutation } from '../../settings/store/api'
import { setLocalStorageItem } from '../../../utils/storage';

import { RootState } from '../../../store/store';

import './SyncFloatingBtn.css';

const SyncFloatingBtn:any = () => {

  const {profile} = useSelector((state: RootState) => state.profile)
  const [isOpen, setIsOpen] = useState(false);

  const toggleSyncBtn = () => {
    setIsOpen(!isOpen);
  };

  const [syncWorkshopData, {
    data: workshopSyncData, 
    isLoading: isSyncingWorkshopData
  }] = useSyncWorkshopDataMutation()

  const handleSyncWorkshopData = (range:string) => {
    const waitTime = 60
    const currentTimeStamp = new Date().getTime();
    const previousTimeStamp = Number(localStorage.getItem('lastSyncActionTimestamp') || 0)
    let diff = -1
    if (previousTimeStamp) {
      diff = (currentTimeStamp-previousTimeStamp)/1000
    }
    if (profile?.organization?.isWorkshopOrg && (diff>waitTime || diff===-1)) {
      localStorage.setItem('lastSyncActionTimestamp', String(new Date().getTime()));
      if (range === '180_days') {
        syncWorkshopData({
          start_date: moment(profile?.organization?.workshopDate).subtract(92, 'days').format('YYYY-MM-DD'), 
          end_date: moment(profile?.organization?.workshopDate).add(92, 'days').format('YYYY-MM-DD'), 
        })
      } else if (range === '2030') {
        syncWorkshopData({
          start_date: '2030-01-01', 
          end_date: '2031-01-01',
        })
        setLocalStorageItem('ws-net-zero-to-date', '2030-12-31')
      }
      setTimeout(() => {
        toggleSyncBtn()
      }, 500)
    }
  }

  return (
    <>
      <button type='button' className="sync-floating-button" onClick={toggleSyncBtn}>
        <i className="bi bi-arrow-repeat" style={{fontSize: '28px', color: '#fff'}} />
      </button>
      {isOpen && (
        <div className="sync-floating-widget">
          <div className="sync-floating-header">
            <h3 className='sync-floating-header-text m-0' style={{color: '#FFF'}}>
              Sync Workshop Data
            </h3>
            <button type='button' className='remove-btn-styles' onClick={toggleSyncBtn}>
              <span className="sync-floating-close">&times;</span>
            </button>
          </div>
          <div className="sync-floating-body">
            {
              isSyncingWorkshopData ? (
                <h2 className='m-0 mb-4' style={{textAlign: 'center', color: ''}}>
                  Syncing...
                </h2>
              ) : (
                <div className='d-flex justify-content-between'>
                  <button
                    type='button'
                    disabled={isSyncingWorkshopData}
                    className='custom-primary-delete-button m-0'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    onClick={() => handleSyncWorkshopData('180_days')}
                  >
                    Sync 180 Days
                  </button>
                  <button
                    type='button'
                    disabled={isSyncingWorkshopData}
                    className='custom-primary-delete-button m-0'
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                    onClick={() => handleSyncWorkshopData('2030')}
                  >
                    Sync 2030
                  </button>
                </div> 
              )
            }
            <p className='mt-2'>
              <i className="bi bi-info-circle me-2" style={{color: 'black'}} />
              Syncing takes few minutes to reflect.<br /> Try <span style={{fontWeight: '700'}}>refreshing</span>..
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default SyncFloatingBtn;
