/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback, useEffect, useRef, useState} from 'react'
import { useSelector } from 'react-redux'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {
  useLazyGetDivisionsListQuery,
  useLazyGetDepartmentsListQuery,
  useLazyGetLocationsListQuery,
  useLazyGetEmissionTrendsQuery,
} from '../../../../app/modules/dashboard/store/api'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import {getLocalStorageItem} from '../../../../app/utils/storage'
import {convertDailyToMonthly, convertDailyToMonthlyWithActivities, getMonthFormat, getYYYYDDMMFormat} from '../../../../app/utils/dateFormatter'
import {ActivityEmission, DateRangeType} from '../types'
import {ChartValueTypes} from '../../../../app/modules/dashboard/type'
import '../styles.css'
import { RootState } from '../../../../app/store/store'
import { commaSeparatedFormat, convertMonthYearToDate } from '../../../../app/utils/helpers'

type EmissionTrendLineChartForAllMonthsProps = {
  disableAnimation?: boolean
  locationType: string
  topValue: string
}

function getChartOptions(_height: number, color: string, categories: string[]): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')

  return {
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 300,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {},
    legend: {
      show: true,
      markers: {
        width: 20,
        height: 2,
        strokeColor: borderColor,
        strokeWidth: 0,
        fillColors: ["#fff",color],
        offsetX: 0,
        offsetY: 0,
        radius: 0,
        customHTML: () =>
          `<div style="width: 20px;height: 2px;border-top: 2px dashed ${color};"></div>`,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: ["none", "gradient"],
      gradient: {
        opacityFrom: 1,
        opacityTo: 0,
      }
    },
    xaxis: {
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        formatter: (val) => `${val}`
      },
      crosshairs: {
        show: true,
      },
      tooltip: {
        enabled: false,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      tickAmount: 4,
      title: {
        text: 'kgCO2',
        style: {
          color: labelColor,
          fontSize: '12px',
        },
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        formatter: (val) => `${val}`,
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: (val) => `${commaSeparatedFormat(parseFloat(val?.toFixed(1)))}`,
      },
      x: {
        formatter: (val) => `${categories[val-1]} (kgCO2)`,
      },
      marker: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: [2, 2],
      colors: [color, color],
      dashArray: [8, 0],
    },
    colors: [color],
    grid: {
      borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}

const EmissionTrendLineChartForAllMonths: FC<EmissionTrendLineChartForAllMonthsProps> = (
  props
) => {
  const {disableAnimation, locationType, topValue} = props
  // apis
  const [getDivisionsList, {data: divisionsList}] = useLazyGetDivisionsListQuery()
  const [getDepartmentsList, {data: departmentsList}] = useLazyGetDepartmentsListQuery()
  const [getLocationsList, {data: locationsList}] = useLazyGetLocationsListQuery()
  const [getTotalEmission, {data: totalEmissionData}] = useLazyGetEmissionTrendsQuery()
  const [getEmissionTrend, {data: emissionTrend}] = useLazyGetEmissionTrendsQuery()

  // state
  const avgEployeesFlag = useSelector((state: RootState) => state.flags.avgEployeesFlag)
  const {
    filterDate, 
    filterYear, 
    filterDivSubCategory, 
    filterDeptSubCategory, 
    filterLocSubCategory, 
    filterExtrapolation 
  } = useSelector((state: RootState) => state.filters)
  const {profile} = useSelector((state: RootState) => state.profile)
  const [listData, setListData] = useState(['All'])
  const [firstCategoryVal, setFirstCategoryVal] = useState<string>('All')
  const [secondCategoryVal, setSecondCategoryVal] = useState<string>('All')
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeType>()

  useEffect(()=>{
    setSecondCategoryVal(topValue);
  },[topValue])

  // const [chartValues, setChartValues] = useState<ChartValueTypes>()

  // useEffect(() => {
  //   if (locationType === 'division') {
  //     setFirstCategoryVal(filterDivSubCategory)
  //   }
  //   else if (locationType === 'department') {
  //     setCategoryVal(filterDeptSubCategory)
  //   }
  //   else if (locationType === 'location'){
  //     setCategoryVal(filterLocSubCategory)
  //   }
  // }, [filterDivSubCategory, filterDeptSubCategory, filterLocSubCategory])

  const handleFirstCategorySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFirstCategoryVal(e.target.value)
  }
  
  const handleSecondCategorySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSecondCategoryVal(e.target.value)
  }

  // const setInitialSelectedDate = () => {
  //   const currentDate = new Date()
  //   const liveOnDate = new Date(getLocalStorageItem('live-on'))
  //   // currentDate.setMonth(currentDate.getUTCMonth() + 4)

  //   setSelectedDateRange({
  //     startDate: new Date(currentDate.getUTCFullYear(), liveOnDate.getUTCMonth(), 1),
  //     endDate: new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth(), 0),
  //   })
  // }

  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const labelColor = getCSSVariableValue('--bs-gray-500')

  const populateDataList = async (getApi: any, setData: any) => {
    await getApi({})
      .then((res: any) => {
        const tempData = ['All']
        res.data?.forEach((itm: any) => {
          tempData.push(itm.name)
        })
        setData(tempData)
      })
      .catch((err: any) => console.log(err))
  }

  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
    // setInitialSelectedDate()
    if (locationType === 'department') {
      populateDataList(getDepartmentsList, setListData)
    } else if (locationType === 'location') {
      populateDataList(getLocationsList, setListData)
    } else if (locationType === 'division') {
      populateDataList(getDivisionsList, setListData)
    }
  }, [])

  useEffect(() => {
    const liveOnDate = new Date(getLocalStorageItem('live-on'))
    const currentDate = new Date()
    // const reqObject1 = {
    //   fromDate: getYYYYDDMMFormat(liveOnDate),
    //   toDate: getYYYYDDMMFormat(currentDate),
    //   category: locationType,
    //   by_average: avgEployeesFlag,
    //   is_all: 'true',
    //   monthly: 'true',
    //   subCategory: firstCategoryVal,
    //   is_scaled: filterExtrapolation,
    // }
    // getTotalEmission(reqObject1)
    // const reqObject = {
    //   fromDate: getYYYYDDMMFormat(liveOnDate),
    //   toDate: getYYYYDDMMFormat(currentDate),
    //   category: locationType,
    //   subCategory: secondCategoryVal,
    //   by_average: avgEployeesFlag,
    //   is_all: 'true',
    //   monthly: 'true',
    //   is_scaled: filterExtrapolation,
    // }
    // getEmissionTrend(reqObject)

    if (filterDate.startDate !== '') {
      const reqObject1 = {
        fromDate: filterDate.startDate,
        toDate: filterDate.endDate,
        category: locationType,
        by_average: avgEployeesFlag,
        is_all: 'true',
        monthly: 'true',
        subCategory: firstCategoryVal,
        is_scaled: filterExtrapolation,
      }
      getTotalEmission(reqObject1)
      const reqObject = {
        fromDate: filterDate.startDate,
        toDate: filterDate.endDate,
        category: locationType,
        subCategory: secondCategoryVal,
        by_average: avgEployeesFlag,
        is_all: 'true',
        monthly: 'true',
        is_scaled: filterExtrapolation,
      }
      getEmissionTrend(reqObject)
    }

  }, [avgEployeesFlag, firstCategoryVal, secondCategoryVal, filterExtrapolation, filterDate])

  const liveOnDate = new Date(getLocalStorageItem('live-on'))
  const totalMonthlyData = convertDailyToMonthlyWithActivities(totalEmissionData || [], liveOnDate, filterYear, profile?.organization?.isWorkshopOrg)
  const monthlyData = convertDailyToMonthlyWithActivities(emissionTrend || [], liveOnDate, filterYear, profile?.organization?.isWorkshopOrg)
  const seriesData: number[] = []

  const categories: string[] = []
    
  monthlyData?.forEach((itm) => {
    // eslint-disable-next-line no-sequences, no-unused-expressions
    const newDate = convertMonthYearToDate(itm.monthYear) // for safari error - invalid date
    // const currentYear = new Date('2023-01-01').getUTCFullYear()-2000;
    // const monthYearString = `${new Date('2023-01-01').toLocaleString('default', { month: 'short' })} ${currentYear}`;
    const monthYearString = getMonthFormat(itm.monthYear);
    seriesData.push(itm?.emission);
    categories.push(monthYearString)
  })

  const totalSeriesData: number[] = new Array(12).fill(0)
  const totalSeriesDataCopy: number[] = new Array(12).fill(0)
  const totalActivitiesSeriesData: any[] = new Array(12).fill(null)
  const subActivitiesSeriesData: any[] = new Array(12).fill(null)

  totalMonthlyData?.forEach((itm, index) => {
    // eslint-disable-next-line no-sequences, no-unused-expressions
    const totHeight = itm.emission
    totalSeriesData[index] = (totHeight < 0) ? 0 : totHeight
    totalSeriesDataCopy[index] = itm.emission

    totalActivitiesSeriesData[index] = itm.activities
    
  })
  monthlyData?.forEach((itm, index) => {
    subActivitiesSeriesData[index] = itm?.activities
  })

  const series = [
    {
      name: firstCategoryVal === 'All' ?  'Total Emissions' : `${firstCategoryVal} Emission`,
      data: totalSeriesData,
      type: 'line',
    },
    {
        name: secondCategoryVal === 'All' ?  'Total Emissions' : `${secondCategoryVal} Emission`, 
        data: seriesData, 
        type: 'area',
    }
  ]

  const getConfig = () => {
    if(locationType === 'department'){
      return {
        color: 'rgba(255, 168, 0, 1)',
        bgColor: 'rgba(255, 236, 199, 1)'
      }
    }
    if(locationType === 'division'){
      return {
        color: 'rgba(27, 197, 189, 1)',
        bgColor: 'rgba(208, 255, 253, 1)'
      }
    }if(locationType === 'location'){
      return {
        color: 'rgba(54, 153, 255, 1)',
        bgColor: 'rgba(204, 229, 255, 1)'
      }
    }
    return {
      color: '',
      bgColor: 'rgba(255, 168, 0, 1)'
    };
  }


  const refreshMode = useCallback(() => {
    if (!chartRef.current) {
      return undefined
    }
    const height = parseInt(getCSS(chartRef.current, 'height'), 10)
    const {color} = getConfig();
    const values = getChartOptions(height, color, categories)
    const options = {
      ...values,
      series,
      xaxis: {...values.xaxis, categories, tickAmount: 5},
      ...(disableAnimation && {
        chart: {
          ...values.chart,
          animations: {
            enabled: false,
          },
        },
      }),
    }

    options.yaxis = {
      ...options.yaxis,
      labels: {
        formatter: (value) => `${commaSeparatedFormat(parseFloat(value?.toFixed(1)))}`,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    }

    const chart = new ApexCharts(chartRef.current, options)
    if (chart) {
      chart.render()
    }
    return chart
  }, [series, categories, disableAnimation])

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, refreshMode])

  return (
    <div className='card mb-0 mb-xl-8 pt-2 bordered-grey'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-4'>
        {/* <h3 className='card-title align-items-center flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Emission Contribution</span>
        </h3> */}
        {/* begin::Wrapper */}
        <h2 style={{marginLeft:'auto'}} className='card-title align-items-center flex-column'>
            Compare
          </h2>
        <div className='d-flex align-items-center gap-4'>
        <select
            className='form-select form-select-sm w-100 form-select-solid'
            data-control='select2'
            data-placeholder='Latest'
            data-hide-search='true'
            value={secondCategoryVal}
            onChange={handleSecondCategorySelect}
          >
            {listData.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
          vs
        <select
            className='form-select form-select-sm w-100 form-select-solid'
            data-control='select2'
            data-placeholder='Latest'
            data-hide-search='true'
            value={firstCategoryVal}
            onChange={handleFirstCategorySelect}
          >
            {listData.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>

          
        </div>
      </div>
      {/* end::Header */}

      <div className='card-body pt-4'>
        <div ref={chartRef} id='kt_charts_widget_3_chart' />
      </div>
    </div>
  )
}

export {EmissionTrendLineChartForAllMonths}
