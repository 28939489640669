import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux';

import { useLazyGetPlansQuery } from './store/api';

import { RoutesPath } from '../../routing/RoutesPath';
import OpenIcon from '../../../assets/OpenIcon.svg'
import { commaSeparatedFormat } from '../../utils/helpers';
import { RootState } from '../../store/store';

const EmptyState = () => {
  const navigate = useNavigate();
  return (
  <div className='bordered-grey m-0 fs-3'>
    <h2 className='m-4'>Current Forest Plans</h2>
    <div className='d-flex justify-content-center align-items-center py-12 mt-10'> 
      <h3 className='' style={{color: 'grey'}}>No Active Plans</h3>
    </div>
    <div className='d-flex justify-content-center'>
      <button
        type='button'
        className='custom-primary-delete-button m-0 py-2 px-4 mt-4 mb-10'
        onClick={()=> navigate(RoutesPath.OUR_FOREST.ROOT)}
        >
        Explore Plans
      </button>
    </div>
  </div>
  )
}

const CardPlans = (props:any) => {
  const {acresSip, amountSip, years} = props
  const {profile} = useSelector((state: RootState) => state.profile)
  return (
    <div className='my-2'>
      <div className='d-flex mb-1 justify-content-between'>
        <h2 className='m-0'>{acresSip} acres{profile?.organization?.isWorkshopOrg ? '' : '/mo'}</h2>
        <h2 className='m-0'>{years} YEARS</h2>
      </div>
      {
        (!profile?.organization?.isWorkshopOrg) ? ( 
          <p className='fs-6'>₹{amountSip}/mo</p>
        ) : null
      }
    </div>
  )
}

const CurrentPlansWidget = (props:any) => {
  const navigate = useNavigate();

  const {plansData} = props

  // const [getPlans, {
  //   data: plansData, 
  //   isSuccess, isError
  // }] = useLazyGetPlansQuery();

  const {profile} = useSelector((state: RootState) => state.profile)
  const [currentPlansData, setCurrentPlansData] = useState<any>([])
  const [netZeroYear, setNetZeroYear] = useState<number>(2030)

  const handleCardNavigation = () => {
    navigate(RoutesPath.OUR_FOREST.ROOT)
  }

  // useEffect(() => {
  //   getPlans({})
  //     .catch(err => console.log(err))
  // }, [])

  useEffect(() => {
    if (plansData?.data?.plans) {
      setCurrentPlansData(plansData?.data?.plans)
      setNetZeroYear(plansData?.data?.earliestNetZeroYear)
    }
  }, [plansData])

  return (
    currentPlansData.length>0 ? 
    <div className='bordered-grey m-0'>
      <div className='mb-4 d-flex justify-content-between align-items-center'>
        <h2 className='m-4 fs-3'>Current Forest Plans</h2>
        <div className='card-toolbar mx-2'>
          <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={() => handleCardNavigation()}
          >
            <img src={OpenIcon} alt='' />
          </button>
        </div> 
      </div>
      <div className='mx-4 pt-5 pb-6'>
        {
          currentPlansData && (
            // profile?.organization?.isWorkshopOrg ? (
              <CardPlans 
                key={currentPlansData[0]?.start_date}
                acresSip={(currentPlansData[0]?.sip_area || 0).toFixed(2)}
                amountSip={commaSeparatedFormat(Math.round(currentPlansData[0]?.sip_amount || 0))}
                years={currentPlansData[0]?.year}
                />
            // ) : (
            //   currentPlansData?.map((item:any) => (
            //     <CardPlans 
            //       key={item?.start_date}
            //       acresSip={(item?.sip_area || 0).toFixed(2)}
            //       amountSip={commaSeparatedFormat(Math.round(item?.sip_amount || 0))}
            //       years={item?.year}
            //       />
            //   ))
            // )
          )
        }
      </div>

      <div className='mx-4 mt-6 mb-6 net-zero-year-blue-wrap'>
        <h3 className='m-0 fs-4' style={{fontWeight: '400'}}>☁️ Target Net Zero By <span className='bold fs-4'>{netZeroYear}</span></h3>
      </div>
      
    </div> :
    <EmptyState />
  )
}

export default CurrentPlansWidget