import api from '../../../services/api'
import {MethodType} from '../../../services/constants'
import {LoginResponse} from '../type'
import API_ENDPOINTS from './apiPaths'

const loginApis = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.query({
      query: (params) => ({
        url: `${API_ENDPOINTS.AUTH}${API_ENDPOINTS.ADMIN_LOGIN}`,
        method: MethodType.post,
        data: params,
        customBaseUrl: `${process.env.REACT_APP_API_URL}`,
      }),
      transformResponse: (response: LoginResponse) => response?.data,
    }),
  }),
})

export const {useLazyLoginQuery} = loginApis
