import {configureStore} from '@reduxjs/toolkit'
import {setupListeners} from '@reduxjs/toolkit/query'
import {useDispatch} from 'react-redux'

import {baseApi, baseApiv2} from '../services/api'
import {notifierSlice} from './slices/notifier'
import {profileSlice} from './slices/profile'
import {tokenSlice} from './slices/token'
import {filterSlice} from './slices/filters'
import { masterListSlice } from './slices/masterList'
import { flagsSlice } from './slices/flags'
import { challengesFormSlice } from './slices/forms/challengesForm'

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    [baseApiv2.reducerPath]: baseApiv2.reducer,
    notifier: notifierSlice.reducer,
    profile: profileSlice.reducer,
    token: tokenSlice.reducer,
    filters: filterSlice.reducer,
    masterList: masterListSlice.reducer, 
    flags: flagsSlice.reducer,
    challengesForm: challengesFormSlice.reducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(baseApi.middleware, baseApiv2.middleware),
})

setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>()
export type RootState = ReturnType<typeof store.getState>

export default store
