import {Employee} from '../../../app/modules/employee/types'
import {UtilityBillForm} from '../../../app/modules/configuration/types'
import { AttributeWasteTypes, AttributeWasteUnitTypes, AttributesFormType } from '../../../app/modules/configuration/attributes/types'
import { CHALLENGE_CATEGORY_TYPE } from '../../../app/modules/configuration/challenges/types'

export interface TableHeader {
  key: string
  value: string
}

export type EmployeesOnboard = {
  total: number
}

export interface ReactTableProps {
  className?: string
  headerData: Array<any>
  tableData: Array<any>
  hasFilters?: boolean
  manualSort?: boolean
  disableSort?: boolean
  onPageChange?: (arg: number) => void
  pagesCount?: number
  currentPageIndex: number
  pageSize?: number
  showPagination?: boolean
  onSort?: (columnId: string, sortOrder: string) => void
  manualPagination?: boolean
  isLoading?: boolean
  message?: string
}

export interface MenuItem {
  label: string
  value: string
}
export interface DropdownProps {
  options: Array<MenuItem> | undefined
  onSelect: (arg: MenuItem) => void
  selectedOption?: MenuItem | null
  className?: string
}

export interface TotalEmissionData {
  total_emission: number
  total_offset: number
}

export type ActivityEmission = {
  name: string
  emission: number
  icon: string
}

export type TotalForestPlanted = {
  total: number
  planted: number
}

export interface EmissionDataType {
  avg_no_of_active_employees: number
  location?: string
  department?: string
  division?: string
  emission: number
  percentage_contribution: number
}

export interface DateRangeType {
  startDate: Date
  endDate: Date
}

export interface DatePickerProps {
  minDate: Date
  maxDate: Date
  preSelectedDateRange: DateRangeType | undefined
  onApply: (arg: DateRangeType) => void
}

export enum NotificationType {
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface IProfileDetails {
  avatar: string
  fName: string
  lName: string
  company: string
  contactPhone: string
  companySite: string
  country: string
  language: string
  timeZone: string
  currency: string
  communications: {
    email: boolean
    phone: boolean
  }
  allowMarketing: boolean
}

export const profileDetailsInitValues: IProfileDetails = {
  avatar: '',
  fName: '',
  lName: '',
  company: '',
  contactPhone: '',
  companySite: '',
  country: '',
  language: '',
  timeZone: '',
  currency: '',
  communications: {
    email: false,
    phone: false,
  },
  allowMarketing: false,
}

export const employeeDetailsInitValues: Employee = {
  name: '',
  email: '',
  division: '',
  department: '',
  departmentId: '',
  location: '',
  locationId: '',
  divisionId: '',
  id: '',
  guid: '',
  image:''
}

export const utilityBillFormInitValues: UtilityBillForm = {
  id: '', 
  work_location_id: '',
  total_employee_count: 0,
  energy_type: 'electricity',
  bill_start_date: '',
  bill_end_date: '',
  units: 0,
  unit_type: 'KWh'
}

export const createAttributesFormInitValues: AttributesFormType = {
  name: '',
  unit_type: AttributeWasteUnitTypes.KG,
  value: 0,
  waste_type: AttributeWasteTypes.PAPER,
  group_type: CHALLENGE_CATEGORY_TYPE.LOCATION,
  group_ids: [],
  start_date: undefined,
  end_date: undefined
}