import {createSlice} from '@reduxjs/toolkit'
import {getLocalStorageItem} from '../../utils/storage'

export type TokenType = {
  token: string | null
}

const initialState: TokenType = {
  token: getLocalStorageItem('token'),
}

export const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    updateToken: (state, action) => {
      state.token = action.payload
    },
  },
})

export const {updateToken} = tokenSlice.actions

export default tokenSlice.reducer
