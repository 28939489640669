import {FC} from 'react'
import './emptyStates.css'

type EmptyMixedWidgetProps = {
    title: string
}

export const EmptyForestTrends = () => (
    <div className='col-xxl-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 forest-empty-div'>
        <h3>FORESTS</h3>
        <h6>🌱 You will be able to see the recaptured data after you buy your forest!</h6>
    </div>
)

export const EmptyTopContribution = () => (
    <div className='col-xxl-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 forest-empty-div'>
        <h3>DEPARTMENTS | DIVISIONS | LOCATIONS | ACTIVITIES</h3>
        <h6>Check back in some time, while we build your enterprise data for you!</h6>
        <h6>Meanwhile, let’s grow a forest, shall we?</h6>
    </div>
)

export const EmptyMixedWidget: FC<EmptyMixedWidgetProps> = ({title}) => (
    <div className='mixed-empty-div'>
        <h3>{title}</h3>
        <h6>Check back in some time!</h6>
    </div>
)

export const EmptyEmployees  = () => (
    <div className='col-xxl-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 forest-empty-div'>
        <h3>EMPLOYEE DATA</h3>
        <h6>Check back in some time, while we build your enterprise data for you!</h6>
    </div>
)