
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';

import { RootState } from '../../../../store/store'
import { QuizzesFilterType, updateQuizzesFilterType } from '../../../../store/slices/filters'
import { RoutesPath } from '../../../../routing/RoutesPath';

export const FilterExistingQuizzes = () => {
  const categoriesFilter: QuizzesFilterType[] = ["live", "upcoming", "completed"];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {filterQuizzesType} = useSelector((state: RootState) => state.filters);

  const handleFilterLeaderboardCategory = (value: QuizzesFilterType) => {
    if (value === filterQuizzesType) dispatch(updateQuizzesFilterType("all"))
    else dispatch(updateQuizzesFilterType(value));
  }

  return (
    <div className='d-flex mx-0 px-0 mb-6'>
    {
      categoriesFilter.map((value: QuizzesFilterType) => (
      <div key={value} className='justify-content-end' style={{marginRight: '10px'}}>
        <button
          type='button'
          // disabled={!loading}
          className={`${filterQuizzesType === value ? 'custom-group-category-filter-button' : 'custom-group-category-filter-outline-button'} m-0 px-10`}
          style={{
            borderRadius: '8px',
            fontWeight: '700'
          }}
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
          onClick={() => {
            handleFilterLeaderboardCategory(value);
          }}
        >
          {value.toUpperCase()}
        </button>
      </div>
      ))
    }
    </div>
  )
}
