import React, {useState, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'
import { useDispatch, useSelector } from 'react-redux'

import { useUpdateOrgEmployeeCountMutation } from '../../store/api'
import { updateProfile } from '../../../../store/slices/profile'
import { RootState } from '../../../../store/store'

export default function ModalEditTotalEmployeeCount(props:any) {
  const {onHide, numberOfEmployee} = props
  const dispatch = useDispatch()
  const {profile} = useSelector((state:RootState) => state?.profile)

  const [employeeCountValue, setEmployeeCountValue] = useState<any>(numberOfEmployee)
  const [isValidEmpCount, setIsValidEmpCount] = useState<boolean>(true)

  const [updateOrgEmployeeCount, {
    isSuccess: isSuccessUpdateEmpCount, 
    isError: isErrorUpdateEmpCount, 
    isLoading: isLoadingUpdateEmpCount
  }] = useUpdateOrgEmployeeCountMutation()

  const handleUpdateEmployeeCount = () => {
    // workshop handling
    if (profile?.organization?.isWorkshopOrg) {
      return;
    }
    if (employeeCountValue !== undefined && employeeCountValue>0) {
      setIsValidEmpCount(true)
      updateOrgEmployeeCount({
        "total_employee_count": employeeCountValue
      })
        .then(res => {
          onHide()
          dispatch(updateProfile({...profile, organization: {...profile?.organization, totalEmployeeCount: Number(employeeCountValue)}}))
        })
        .catch(err => console.log(err))
    } else {
      setIsValidEmpCount(false)
    }
  }

  return (
    <Modal {...props} size='md' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header className='px-6' closeButton>
        <Modal.Title id='contained-modal-title-vcenter' style={{width: '80%'}}>
          <h4 className='m-0'>Total employee count</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body> 
        <input
          placeholder='Enter total employee count'
          className='modal-input-field'
          value={employeeCountValue}
          type='number'
          min="1"
          required
          onChange={(v) => {
            setEmployeeCountValue(v.currentTarget.value)
          }}
        />
        {
          !isValidEmpCount &&
          <p style={{color: 'red', fontSize: '14px', marginTop: '8px', marginBottom: '0px'}}>Employee count is required</p>
        }
        <div className='mt-6 d-flex flex-end'>
          <button
            type='button'
            // disabled={!loading}
            className='custom-primary-delete-button m-0 py-2 px-3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={handleUpdateEmployeeCount}
            >
            Save
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}