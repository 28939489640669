import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  notification: {
    message: null,
    type: null,
  },
}

export const notifierSlice = createSlice({
  name: 'notifier',
  initialState,
  reducers: {
    updateNotification: (state, action) => {
      state.notification = action.payload
    },
  },
})

export const {updateNotification} = notifierSlice.actions

export default notifierSlice.reducer
