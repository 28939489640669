import React, {useState, useEffect, useRef} from 'react'
import { useIntl } from 'react-intl'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux';

import { useLazyGetAllEmployeesQuery } from '../../../employee/store/api';
import { usePostCustomTagMutation, useUpdateCustomTagMutation } from '../../store/api';
import { tagsColorPalette, tagsDarkColorShade } from '../../../../utils/helpers';
import { updateNotification } from '../../../../store/slices/notifier';
import { RootState, useAppDispatch } from '../../../../store/store';
import { NotificationType } from '../../../../../_metronic/partials/components/types';

const RenderMemberCard: React.FC<any> = (props) => {
  const {name, email, removeMember} = props;
  return (
    <div className='custom-tag-member-card' title={email}>
      <h6 className='m-0 mx-4'>{name}</h6>
      <button type='button' className='remove-btn-styles' onClick={removeMember}>
        <i className="bi bi-x-circle-fill" style={{fontSize: '18px', color: '#666666'}} />
      </button>
    </div>
  )
}

const RenderSearchCard: React.FC<any> = (props) => {
  const {name, email, isLastCell, addMember} = props;
  return (
    <button 
      type='button'
      className='custom-tag-emp-search-cell remove-btn-styles'
      style={{borderWidth: isLastCell ? '0px': '0px 0px 1px 0px'}}
      onClick={addMember}
      >
      {name}
      <p className='custom-tag-emp-search-email-text'>• {email}</p>
    </button>
  )
}

const RenderTagColorBtn: React.FC<any> = (props) => {
  const {onClick, color} = props;
  return (
    <button
      type='button'
      onClick={onClick}
      className='remove-btn-styles tag-color-picker-btn m-1'
      style={{backgroundColor: color, border: '1.5px solid', borderColor: tagsDarkColorShade[color]}}
    />
  )
}

export default function ModalCreateCustomModal(props: any) {
  const {groupTitle, onHide, isEdit, tagData, onSubmitSuccess} = props

  const {profile} = useSelector((state: RootState) => state.profile)
  const [displayNameVal, setDisplayNameVal] = useState('')
  const [tagTitleVal, setTagTitleVal] = useState('')
  const [groupMembers, setGroupMembers] = useState([])
  const [searchTerm, setSearchTerm] = useState('')
  const [isValidTagName, setIsValidTagName] = useState(true)
  const [isValidDisplayName, setIsValidDisplayName] = useState(true)
  const [isValidMemberList, setIsValidMemberList] = useState(true)
  const [lastDebounceSearch, setLastDebounceSearch] = useState('')
  const [isSearchTermActive, setIsSearchTermActive] = useState(false)
  const [selectedEmployees, setSelectedEmployees] = useState<any[]>([])
  const [isColorPickerActive, setIsColorPickerActive] = useState(false)
  const [tagColor, setTagColor] = useState(tagsColorPalette[0])

  const intl = useIntl()
  const dispatch = useAppDispatch()

  const [getAllEmployees, {data: employeesData}] = useLazyGetAllEmployeesQuery();
  const [postCustomTag, {isSuccess, isError, isLoading}] = usePostCustomTagMutation();
  const [updateCustomTag, {
    isSuccess: isSuccessUpdateTag, 
    isError: isErrorUpdateTag, 
    isLoading: isLoadingUpdateTag
  }] = useUpdateCustomTagMutation();

  const getEmployeeData = () => {
    getAllEmployees({
      offset: 0,
      limit: 5,
      searchTerm: searchTerm.trim(),
      sortBy: 'name',
      sortOrder: 'ASC',
    })
    .then((res) => {
      // preventing empty state while debouncing
      setLastDebounceSearch(searchTerm)
    })
  }

  // debouncing while searching for employees
  useEffect(() => {
    // resetting current page to first page when searched for employee
    const getData = setTimeout(() => {
      if (searchTerm.trim().length>0) {
        getEmployeeData()
      } 
    }, 500)
    return () => clearTimeout(getData)
  }, [searchTerm])

  const checkValidTagName = (nameVal:string) => {
    const str = nameVal.trim()
    if (str.length<3) {
      setIsValidTagName(false)
      return false;
    }
    setIsValidTagName(true)
    return true;
  }

  const checkValidDisplayName = (nameVal:string) => {
    const str = nameVal.trim()
    if (str.length===0 || str.length > 4) {
      setIsValidDisplayName(false)
      return false;
    }
    setIsValidDisplayName(true)
    return true;
  }

  const checkValidSelectedMembers = (numOfSelectedMembers:number) => {
    if (numOfSelectedMembers === 0) {
      setIsValidMemberList(false)
      return false;
    }
    setIsValidMemberList(true)
    return true;
  }

  const checkValidForm = () => {
    const checkA = checkValidTagName(tagTitleVal)
    const checkB = checkValidDisplayName(displayNameVal)
    const checkC = checkValidSelectedMembers(selectedEmployees.length)
    return checkA && checkB && checkC
  }

  const handleAddMember = (emp:any) => {
    let isPresent = false;
    selectedEmployees.forEach((item) => {
      if (item.id === emp.id) {
        isPresent = true;
      }
    })
    setSelectedEmployees((state:any) => {
      if (!isPresent) {
        return [...state, {id: emp.id, name: emp.name, email: emp.email}]
      }
      return state
    })
  }

  const handleRemoveMember = (empId:any) => {
    setSelectedEmployees((state:any) => state.filter((itm:any) => itm.id!==empId))
  }

  const handleClearState = () => {
    setDisplayNameVal('')
    setGroupMembers([])
    setSearchTerm('')
    setIsValidDisplayName(true)
    setIsValidTagName(true)
    setIsValidMemberList(true)
    setSelectedEmployees([])
  }

  const handlePostCustomTag = () => {
    const employeesIds = selectedEmployees.map((emp:any) => emp.id)
    postCustomTag({
      "name": tagTitleVal, 
      "abbreviation_name": displayNameVal, 
      "color_hex_code": tagColor, 
      "employee_ids": employeesIds
    })
      .then(res => {
        handleClearState()
        onSubmitSuccess()
        onHide()
      })
      .catch(err => {
        console.log('err', err)
      })
  }

  const handleEditCustomTag = () => {
    const employeesIds = selectedEmployees.map((emp:any) => emp.id)
    updateCustomTag({
      "id": tagData?.id,
      "name": tagTitleVal, 
      "abbreviation_name": displayNameVal, 
      "color_hex_code": tagColor, 
      "employee_ids": employeesIds
    })
      .then(res => {
        handleClearState()
        onSubmitSuccess()
        onHide()
      })
      .catch(err => {
        console.log('err', err)
      })
  }

  useEffect(() => {
    if (isEdit && tagData) {
      setDisplayNameVal(tagData?.abbreviation_name)
      setSelectedEmployees(tagData?.users)
      setTagColor(tagData?.color_code)
      setTagTitleVal(tagData?.name)
    } else {
      setDisplayNameVal('')
      setTagTitleVal(groupTitle)
      setSelectedEmployees([])
      setTagColor(tagsColorPalette[0])
    }
  }, [isEdit, tagData, onHide])

  useEffect(() => {
    setIsValidDisplayName(true)
    setIsValidTagName(true)
    setIsValidMemberList(true)
  }, [onHide])

  useEffect(() => {
    if (isSuccess && !isLoading) {
      dispatch(
        updateNotification({
          message: 'Custom tag created successfully', 
          type: NotificationType.SUCCESS
        })
      )
    }
  }, [isSuccess])

  useEffect(() => {
    if (isSuccessUpdateTag && !isLoadingUpdateTag) {
      dispatch(
        updateNotification({
          message: 'Custom tag updated successfully', 
          type: NotificationType.SUCCESS
        })
      )
    }
  }, [isSuccessUpdateTag])

  useEffect(() => {
    if (isError || isErrorUpdateTag) {
      dispatch(
        updateNotification({
          message: intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}),
          type: NotificationType.ERROR,
        })
      )
    }
  }, [isError, isErrorUpdateTag])

  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header className='px-20' closeButton>
        <Modal.Title id='contained-modal-title-vcenter' style={{width: '80%'}}>
          <input
            placeholder='Enter tag name'
            className='create-tag-model-title'
            value={tagTitleVal}
            onChange={(v) => {
              setTagTitleVal(v.currentTarget.value)
            }}
          />
          {
            !isValidTagName &&
            <p 
              style={{
              color: 'red', marginTop: '5px', marginLeft: '10px', fontSize: '13px', fontWeight: '400'
              }}>
                *Minimum 3 characters required
            </p>
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-20'>
        <h6 className='fs-5'>Display name <span style={{fontSize: '12px', color: 'grey'}}>(max 4 characters)</span></h6>
        <div className='wrap-create-custom-group-modal-input'>
          <input
            placeholder='Enter display name'
            className='employee-search-input'
            value={displayNameVal}
            onChange={(v) => {
              setDisplayNameVal(v.currentTarget.value.trim().slice(0,4).toUpperCase())
            }}
          />
          <button
            type='button'
            className='remove-btn-styles tag-color-picker-btn' 
            style={{backgroundColor: tagColor, border: '1.5px solid', borderColor: tagsDarkColorShade[tagColor]}}
            onFocus={() => setIsColorPickerActive(true)}
            onBlur={() => setTimeout(() => setIsColorPickerActive(false), 300)}
            />
          {
            isColorPickerActive && 
            <div className='tag-color-picker-container'>
              {
                tagsColorPalette.map((item, index) => (
                  <RenderTagColorBtn
                    key={item}
                    color={item}
                    onClick={()=>setTagColor(item)}
                   />
                ))
              }
            </div>
          }
        </div>
        {
          !isValidDisplayName &&
          <p style={{color: 'red', marginTop: '5px'}}>Display name is required*</p>
        }
        <div className='position-relative'>
          <h6 className='fs-5 mt-8'>Add members</h6>
          <div className='wrap-create-custom-group-modal-input'>
            <input
              placeholder='Enter member name'
              onFocus={() => setIsSearchTermActive(true)}
              onBlur={() => {
                setTimeout(() => {
                  setIsSearchTermActive(false)
                }, 300)
              }}
              className='employee-search-input'
              value={searchTerm}
              onChange={(v) => {
                setSearchTerm(v.currentTarget.value)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter')  {
                  getEmployeeData()
                }
              }}
              />
          </div>
          { (employeesData && isSearchTermActive && searchTerm.trim().length>0) &&
            <div className='custom-tag-search-results-wrap'>
            {
              employeesData?.edges.map((item, index) => (
                <RenderSearchCard 
                  key={item.name} 
                  name={item.name} 
                  email={item.email}
                  addMember={() => handleAddMember(item)} 
                  isLastCell={employeesData?.edges.length === index+1} 
                  />
              ))
            }
            </div>
          }
        </div>
        <div className='d-flex mt-8'>
        <h6 className='fs-5'>Selected members ({selectedEmployees.length})</h6>
        {
          !isValidMemberList &&
          <p style={{color: 'red', marginLeft: '20px'}}>*Atleast 1 member is required</p>
        }
        </div>
        <div className='wrap-create-custom-group-modal-input-emp'>
          {
            selectedEmployees.map((item:any) => (
              <RenderMemberCard 
                key={item?.id}
                name={item?.name} 
                email={item?.email}
                removeMember={() => handleRemoveMember(item.id)} 
                />
            ))
          }
        </div>
        <div className='d-flex flex-end'>
          <button
            type='button'
            // disabled={!loading}
            className='custom-primary-delete-button m-0 mt-5'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={() => {
              if (checkValidForm() && !profile?.organization?.isWorkshopOrg) { // workshop handling
                if (isEdit) {
                  handleEditCustomTag()
                } else {
                  handlePostCustomTag()
                }
              }
            }}
            >
            {isEdit ? 'Update' : 'Submit'}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}