import {deleteLocalStorageItem} from '../../../utils/storage'
import {updateProfile} from '../../../store/slices/profile'
import {updateToken} from '../../../store/slices/token'
import {AppDispatch} from '../../../store/store'
import { setAvgEmployeesFlag, setIsForestAllocated } from '../../../store/slices/flags'
import { updateMonthFilter, updateDivSubCateogryFilter, updateDeptSubCateogryFilter, updateLocSubCateogryFilter } from '../../../store/slices/filters'
import { setDepartmentsList, setDivisionsList, setLocationsList } from '../../../store/slices/masterList'

export const logout = (dispatch: AppDispatch) => {
  deleteLocalStorageItem('token')
  deleteLocalStorageItem('refresh-token')
  deleteLocalStorageItem('live-on')
  dispatch(updateProfile(null))
  dispatch(updateToken(null))
  dispatch(setAvgEmployeesFlag(false))
  dispatch(setIsForestAllocated(false))
  dispatch(updateMonthFilter('All'))
  dispatch(setDivisionsList([]))
  dispatch(setDepartmentsList([]))
  dispatch(setLocationsList([]))
  dispatch(updateDivSubCateogryFilter('All'))
  dispatch(updateDeptSubCateogryFilter('All'))
  dispatch(updateLocSubCateogryFilter('All'))
}
