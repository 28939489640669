/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback, useEffect, useRef, useState} from 'react'
import { useSelector } from 'react-redux'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {
  useLazyGetDivisionsListQuery,
  useLazyGetDepartmentsListQuery,
  useLazyGetLocationsListQuery,
  useLazyGetEmissionTrendsQuery,
} from '../../../../app/modules/dashboard/store/api'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import {getLocalStorageItem} from '../../../../app/utils/storage'
import {convertDailyToMonthly, convertDailyToMonthlyWithActivities, getAllDatesOfCurrentMonth, getYYYYDDMMFormat} from '../../../../app/utils/dateFormatter'
import {DateRangeType} from '../types'
import {ChartValueTypes} from '../../../../app/modules/dashboard/type'
import '../styles.css'
import { RootState } from '../../../../app/store/store'
import { commaSeparatedFormat } from '../../../../app/utils/helpers'

type EmissionTrendLineChartProps = {
  disableAnimation?: boolean
  locationType: string
  topValue: string
}

function getChartOptions(_height: number, color: string, categories: string[]): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')

  return {
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 300,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {},
    legend: {
      show: true,
      markers: {
        width: 20,
        height: 2,
        strokeColor: borderColor,
        strokeWidth: 0,
        fillColors: ["#fff",color],
        offsetX: 0,
        offsetY: 0,
        radius: 0,
        customHTML: () =>
          `<div style="width: 20px;height: 2px;border-top: 2px dashed ${color};"></div>`,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: ["none", "gradient"],
      gradient: {
        opacityFrom: 1,
        opacityTo: 0
      }
    },
    xaxis: {
      axisBorder: {
        show: true,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        formatter: (val) => `${val || ''}`
      },
      crosshairs: {
        show: true,
      },
      tooltip: {
        enabled: false,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      tickAmount: 4,
      title: {
        text: 'kgCO2',
        style: {
          color: labelColor,
          fontSize: '12px',
        },
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        formatter: (val) => `${val}`,
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: (val) => `${commaSeparatedFormat(parseFloat(val?.toFixed(1)))}`,
      },
      x: {
        formatter: (val) => `${categories[val-1]} (kgCO2)`
      },
      marker: {
        show: false,
      },
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: [2, 2],
      colors: [color, color],
      dashArray: [8, 0],
    },
    colors: [color],
    grid: {
      borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const EmissionTrendLineChart: FC<EmissionTrendLineChartProps> = (props) => {
  const {disableAnimation, locationType, topValue} = props
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const selectedMonth = useSelector((state: RootState) => state.filters.filterMonth)

  const [getTotalEmission, {data: totalEmissionData}] = useLazyGetEmissionTrendsQuery()
  const [getEmissionTrend, {data: emissionTrend}] = useLazyGetEmissionTrendsQuery()

  const [getDivisionsList] = useLazyGetDivisionsListQuery()
  const [getDepartmentsList] = useLazyGetDepartmentsListQuery()
  const [getLocationsList] = useLazyGetLocationsListQuery()
  // state
  const avgEployeesFlag = useSelector((state: RootState) => state.flags.avgEployeesFlag)
  const {
    filterDate, 
    filterDivSubCategory, 
    filterDeptSubCategory, 
    filterLocSubCategory
  } = useSelector((state: RootState) => state.filters)
  const [listData, setListData] = useState(['All'])
  const [firstCategoryVal, setFirstCategoryVal] = useState<string>('All')
  const [secondCategoryVal, setSecondCategoryVal] = useState<string>(topValue)
  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeType>()
  const [categories, setCategories] = useState<string[]>([])

  const handleFirstCategorySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setFirstCategoryVal(e.target.value)
  }
  
  const handleSecondCategorySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSecondCategoryVal(e.target.value)
  }

  // useEffect(() => {
  //   if (locationType === 'division') {
  //     setCategoryVal(filterDivSubCategory)
  //   }
  //   else if (locationType === 'department') {
  //     setCategoryVal(filterDeptSubCategory)
  //   }
  //   else if (locationType === 'location'){
  //     setCategoryVal(filterLocSubCategory)
  //   }
  // }, [filterDivSubCategory, filterDeptSubCategory, filterLocSubCategory])

  const setInitialSelectedDate = (month: number) => {
    const currentDate = new Date()
    const selectedYear =
      month > currentDate.getUTCMonth() ? currentDate.getUTCFullYear() - 1 : currentDate.getUTCFullYear()
    let endDay
    if (month === currentDate.getUTCMonth()) {
      endDay = new Date(selectedYear, month, currentDate.getUTCDate())

      // let newComparedMonth = new Date(selectedYear,month+1,currentDate.getUTCDate())
      // setSelectedCompareToMonth(newComparedMonth)
    } else {
      endDay = new Date(selectedYear, month + 1, 0)
    }
    setSelectedDateRange({
      startDate: new Date(selectedYear, month, 1),
      endDate: endDay,
    })
  }
  const populateDataList = (getApi: any, setData: any) => {
    getApi({})
      .then((res: any) => {
        const tempData = ['All']
        res.data?.forEach((itm: any) => {
          tempData.push(itm.name)
        })
        setData(tempData)
      })
      .catch((err: any) => console.log(err))
  }

  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
    if (locationType === 'department') {
      populateDataList(getDepartmentsList, setListData)
    } else if (locationType === 'location') {
      populateDataList(getLocationsList, setListData)
    } else if (locationType === 'division') {
      populateDataList(getDivisionsList, setListData)
    }
  }, [])

  useEffect(() => {
    const month = selectedMonth

    const monthIndex = months.findIndex((itm) => itm.toLowerCase() === month.toLowerCase())

    setInitialSelectedDate(monthIndex)
  }, [selectedMonth])

  useEffect(() => {
    // if (selectedDateRange) {
    //   const reqObject1 = {
    //     fromDate: getYYYYDDMMFormat(selectedDateRange.startDate),
    //     toDate: getYYYYDDMMFormat(selectedDateRange.endDate),
    //     category: locationType,
    //     subCategory: firstCategoryVal,
    //     by_average: avgEployeesFlag,
    //   }
    //   getTotalEmission(reqObject1)
    //   const reqObject = {
    //     fromDate: getYYYYDDMMFormat(selectedDateRange.startDate),
    //     toDate: getYYYYDDMMFormat(selectedDateRange.endDate),
    //     category: locationType,
    //     subCategory: secondCategoryVal,
    //     by_average: avgEployeesFlag,
    //   }
    //   getEmissionTrend(reqObject)
    // }
    if (filterDate.startDate !== '') {
      const reqObject1 = {
        fromDate: filterDate.startDate,
        toDate: filterDate.endDate,
        category: locationType,
        subCategory: firstCategoryVal,
        by_average: avgEployeesFlag,
      }
      getTotalEmission(reqObject1)
      const reqObject = {
        fromDate: filterDate.startDate,
        toDate: filterDate.endDate,
        category: locationType,
        subCategory: secondCategoryVal,
        by_average: avgEployeesFlag,
      }
      getEmissionTrend(reqObject)
    }
  }, [avgEployeesFlag, firstCategoryVal, secondCategoryVal, selectedDateRange, filterDate])

  useEffect(() => {
    if (filterDate.startDate !== '') {
      setCategories(getAllDatesOfCurrentMonth(filterDate.startDate))
    }
  }, [filterDate])

  const resizeArray = (array: any, newSize: any) => {
    const changeSize = newSize - array.length
    if (changeSize > 0) {
      return array.concat(Array(changeSize).fill(0))
    }
    return array.slice(0, newSize)
  }

  const totalEmissionSeriesData: number[] = new Array(31).fill(0)
  const totalEmissionSeriesCopyData: number[] = new Array(31).fill(0)
  const subCategoryEmissionSeriesData: number[] = new Array(31).fill(0)
  const totalActivitiesSeriesData: any[] = new Array(31).fill(null)
  const subActivitiesSeriesData: any[] = new Array(31).fill(null)

  emissionTrend?.forEach((data: any) => {
    const index = new Date(data.dates).getUTCDate() - 1
    subCategoryEmissionSeriesData[index] = data.emission
    // subActivitiesSeriesData[index] = data.activities
  })
  totalEmissionData?.forEach((data: any) => {
    const index = new Date(data.dates).getUTCDate() - 1
    const totHeight = data.emission;
    totalEmissionSeriesData[index] = (totHeight < 0) ? 0 : totHeight
    // totalEmissionSeriesCopyData[index] = data.emission
    // totalActivitiesSeriesData[index] = data.activities
  })
  const series = [
    {
      name: firstCategoryVal === 'All' ? `Total Emission` : `${firstCategoryVal} Emission`,
      data: totalEmissionSeriesData,
      type: 'line',
      
    },
    {
      name: secondCategoryVal === 'All' ? `Total Emission` : `${secondCategoryVal} Emission`,
      data: subCategoryEmissionSeriesData,
      type: 'area',
    },
  ]
  // if (secondCategoryVal !== 'All') {
    
  // }

  const getConfig = () => {
    if(locationType === 'department'){
      return {
        color: 'rgba(255, 168, 0, 1)',
        bgColor: 'rgba(255, 236, 199, 1)'
      }
    }
    if(locationType === 'division'){
      return {
        color: 'rgba(27, 197, 189, 1)',
        bgColor: 'rgba(208, 255, 253, 1)'
      }
    }if(locationType === 'location'){
      return {
        color: 'rgba(54, 153, 255, 1)',
        bgColor: 'rgba(204, 229, 255, 1)'
      }
    }
    return {
      color: '',
      bgColor: 'rgba(255, 168, 0, 1)'
    };
  }

  const refreshMode = useCallback(() => {
    if (!chartRef.current) {
      return undefined
    }
    const config = getConfig();
    const height = parseInt(getCSS(chartRef.current, 'height'), 10)
    const values = getChartOptions(height, config.color, categories)

    const options = {
      ...values,
      series,
      xaxis: {...values.xaxis, categories, tickAmount: 5},
      ...(disableAnimation && {
        chart: {
          ...values.chart,
          animations: {
            enabled: false,
          },
        },
      }),
    }

    options.yaxis = {
      ...options.yaxis,
      labels: {
        formatter: (value) => `${commaSeparatedFormat(parseFloat(value?.toFixed(1)))}`,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    }

    const chart = new ApexCharts(chartRef.current, options)
    if (chart) {
      chart.render()
    }
    return chart
  }, [series, categories, disableAnimation])

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, refreshMode])

  // const handleSubCategorySelect = useCallback((e: React.ChangeEvent<HTMLSelectElement>) => {
  //   const selectedMonth = e.target.value
  //   const selectedCompareToMonth = monthOptions.findIndex((month) => month === selectedMonth)
  //   setProgress(selectedMonth)
  //   onSubCategorySelect(selectedCompareToMonth.toString())
  // }, [])

  return (
    <div className='card mb-5 mb-xl-8 pt-2 pb-2 bordered-grey'>
      {/* begin::Header */}
      <div className='card-header border-0 pt-6'>
        {/* <h3 className='card-title align-items-center flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Emission Contribution</span>
        </h3> */}

        {/* begin::Wrapper */}
        <h2 style={{marginLeft:'auto'}} className='card-title align-items-center flex-column'>
            Compare
          </h2>
        <div className='d-flex align-items-center gap-4'>
        <select
            className='form-select form-select-sm w-100 form-select-solid'
            data-control='select2'
            data-placeholder='Latest'
            data-hide-search='true'
            value={secondCategoryVal}
            onChange={handleSecondCategorySelect}
          >
            {listData.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
          vs
        <select
          className='form-select form-select-sm w-100 form-select-solid'
          data-control='select2'
          data-placeholder='Latest'
          data-hide-search='true'
          value={firstCategoryVal}
          onChange={handleFirstCategorySelect}
        >
          {listData.map((item) => (
            <option key={item} value={item}>
              {item}
            </option>
          ))}
        </select>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-4'>
        {/* begin::Chart */}
        <div ref={chartRef} id='kt_charts_widget_3_chart' />
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {EmissionTrendLineChart}
