import {FC} from 'react'
import {EmissionContributionChart} from '../../../_metronic/partials/components/chart/EmissionContributionChart'

type EmissionContributionProps = {
  locationType: string
}

const EmissionContribution: FC<EmissionContributionProps> = ({locationType}) => (
  <EmissionContributionChart locationType={locationType} />
)
export {EmissionContribution}
