/* eslint-disable no-useless-return */
/* eslint-disable prefer-destructuring */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';

import { useLazyGetChallengeByIdQuery } from '../../store/api';
import { RoutesPath } from '../../../../routing/RoutesPath';
import { CHALLENGE_PARTICIPANT_TYPE, ChallengeActionType } from '../types';
import { CalendarMonthIcon, IconCircularBlueTick, CustomGroupTabIcon, NoImageIcon, IconGroupsChallenges, IconTickCompleteBlue, IconTargetBoard } from '../../../../../_metronic/assets/icons';
import ModalViewImage from '../components/modal-view-image';

type PropsType = {

}

const IconCircleContainer = (props: any) => {
  const {children, content} = props;
  return (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
      <div style={{
        backgroundColor: '#E2F3FB', 
        height: '32px',
        width: '32px', 
        borderRadius: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
        }}>
        {children}
      </div>
      <p className='m-0 px-2' style={{color: '#3A3A3A'}}>
        {content}
      </p>
    </div>
  )
}

const RenderParticipantHeaderRow = (props: any) => {
  const {name, isAchieve, actionType} = props;
  return (
    <div 
      className='px-10 py-3 fs-6 d-flex justify-content-between' 
      style={{
        border: '1px solid #d8d8d8', 
        borderWidth: '1px 0px 0px',
        fontWeight: '700',
      }}
      >
      <p className='m-0' style={{color: '#5C5C5C', width: '200px', paddingLeft: '20px'}}>{name}</p>
      {
        actionType === "UPLOAD_SINGLE_IMAGE" &&
        <p className='m-0' style={{color: '#5C5C5C', width: '200px', textAlign: 'center'}}>Uploaded Image</p>
      }
      <p className='m-0' style={{color: '#5C5C5C', width: '200px', textAlign: 'center'}}>{isAchieve}</p>
    </div>
  )
}

const RenderGroupHeaderRow = (props: any) => {
  const {name} = props;
  return (
    <div 
      className='px-10 py-3 fs-6 d-flex justify-content-between' 
      style={{
        border: '1px solid #d8d8d8', 
        borderWidth: '1px 0px 0px',
        fontWeight: '700',
      }}
      >
      <p className='m-0' style={{color: '#5C5C5C', paddingLeft: '10px'}}>{name}</p>
      <div className='d-flex justify-content-between' style={{width: '55%'}}>
        <p className='m-0' style={{color: '#5C5C5C', }}>Member Count</p>
        <p className='m-0' style={{color: '#5C5C5C'}}>% Completed</p>
      </div>
    </div>
  )
}

const RenderParticipantRow = (props: any) => {
  const {name, rank, isAchieved, imageUrl, actionType, handleShowViewImageModal, setSelectedViewImage} = props;

  let imgUrl = imageUrl
  if (typeof imageUrl !== 'string' && imageUrl?.length)
    imgUrl = imageUrl[0];
    
  return (
    <div 
      className='px-10 py-3 fs-6 d-flex justify-content-between' 
      style={{
        border: '1px solid #d8d8d8', 
        borderWidth: '1px 0px 0px',
      }}
      >
      <div className='d-flex' style={{width: '200px'}}>
        <p style={{marginLeft: '-18px', width: '20px', marginRight: '20px', marginBottom: '0px', color: '#757575'}}>{rank}</p>
        <p className='m-0' style={{color: '#5C5C5C', fontWeight: '600'}}>{name}</p>
      </div>
      {
        actionType === ChallengeActionType.UPLOAD_SINGLE_IMAGE &&
        <div className='m-0 d-flex justify-content-center' style={{width: '200px'}}>
          {
            imgUrl ? (
              <button
                className='remove-btn-styles'
                type='button'
                onClick={() => {
                  setSelectedViewImage(imgUrl);
                  setTimeout(handleShowViewImageModal, 200);
                }}
                >
                  <img
                    src={imgUrl}
                    alt={imgUrl}
                    style={{
                      height: '50px',
                      width: '80px',
                      borderRadius: '20px',
                      objectFit: 'cover'
                    }}
                    /> 
              </button>
            ) : (
              <div 
                className='m-0' 
                style={{
                  height: '50px',
                  width: '80px',
                  borderRadius: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#f2f2f2'
                }} 
                >
                <NoImageIcon width={18} height={18} color='#5C5C5C' />
              </div>
            )
          }
        </div>
      }
      <p className='m-0 px-10' style={{width: '200px', color: isAchieved ? 'green' : '#fc5f5f', textAlign: "center"}}>{isAchieved ? 'Yes' : 'No'}</p>
    </div>
  )
}

const RenderGroupRow = (props: any) => {
  const {name, rank, participantCount, percentage, attemptCount, actionType} = props;
  return (
    <div 
      className='px-10 py-3 fs-6 d-flex justify-content-between' 
      style={{
        border: '1px solid #d8d8d8', 
        borderWidth: '1px 0px 0px',
      }}
      >
      <div className='d-flex'>
        <p style={{marginLeft: '-18px', marginRight: '20px', marginBottom: '0px', color: '#757575'}}>{rank}</p>
        <p className='m-0' style={{color: '#5C5C5C', fontWeight: '600'}}>{name}</p>
      </div>
      <div className='d-flex justify-content-between' style={{width: '55%'}}>
        <p className='m-0' style={{color: '#5C5C5C', paddingLeft: '45px'}}>{participantCount}</p>
        <p className='m-0' style={{color: '#5C5C5C', paddingRight: '30px'}}>
          {
            actionType !== ChallengeActionType.USER_MARK_DAILY ? 
            `${attemptCount} (${percentage}%)` : 
            `${percentage}%`
          } 
        </p>
      </div>
    </div>
  )
}

const ChallengeDetailsPage: React.FC<PropsType> = () => {

  const navigate = useNavigate();
  const {state: routeState}: any  = useLocation();

  const [countCompletedUserIndChall, setCountCompletedUserIndChall] = useState<number>(0);
  const [partipantsArr, setParticipantsArr] = useState<any[]>([]);
  const [groupsArr, setGroupsArr] = useState<any[]>([]);
  const [showViewImageModal, setShowViewImageModal] = useState<boolean>(false);
  const [selectedViewImage, setSelectedViewImage] = useState<string>('');

  const [getChallengeById, {
    data: challengeData,
    isLoading: isLoadingGetChallengeById,
    isSuccess: isSuccessGetChallengeById,
    isError: isErrorGetChallengeById
  }] = useLazyGetChallengeByIdQuery();

  useEffect(() => {
    if (routeState.id)
      getChallengeById({id: routeState.id})
  }, [routeState.id])

  useEffect(() => {
    if (challengeData) {
      if (challengeData?.data?.type === CHALLENGE_PARTICIPANT_TYPE.INDIVIDUAL) {
        const uniqueUsersMap = new Map();

        let completedCount = 0;
        challengeData?.data?.groups?.forEach((group: any) => {
          group?.data?.users?.forEach((user: any) => {
            if (!uniqueUsersMap.has(user.userId)) {
              uniqueUsersMap.set(user.userId, {...user, isAchieved: user.actionTaken});
              if (user.actionTaken)
                completedCount += 1;
            }
          });
        });
        setCountCompletedUserIndChall(completedCount);

        setParticipantsArr(Array.from(uniqueUsersMap.values()));
      } else {
        // const achievedUserIds = challengeData?.data?.actionTakenUserIds;
        const totalDays = moment(challengeData?.data?.endDate).diff(moment(challengeData?.data?.startDate), "days") + 1;
        const tempArr = challengeData?.data?.groups?.map((group: any) => {
          let count = 0;
          group?.data?.users?.forEach((user: any) => {
            if (user?.actionTaken === 1) {
              count += routeState?.actionType === ChallengeActionType.USER_MARK_DAILY ? (user?.response?.length || 0) : 1;
            }
          })
          if (!group?.data?.users?.length)
            count = 0;
          let percentage = 0;
          if (count>0) {
            if (routeState?.actionType === ChallengeActionType.USER_MARK_DAILY) 
              percentage = (count/((group?.data?.users?.length||1)*totalDays)) * 100
            else 
              percentage = (count/(group?.data?.users?.length||1)) * 100
          }
          return {
            percentage: Math.round(percentage),
            name: group?.data?.name,
            participantCount: group?.data?.participantCount,
            attemptCount: count
          }
        }).sort((a:any, b:any) => b.percentage - a.percentage);
        setGroupsArr(tempArr)
      }
    }
  }, [challengeData])

  return (
    <div className='blank-page-view py-2 px-12 mb-10'>

      {/* --------------------------------------- HEADER ------------------------------------------ */}

      <div className='back-header-btn mt-8' style={{marginLeft: '0px'}}>
        <button 
          type='button' 
          className='p-2 back-left-arrow-btn-wrap'
          onClick={()=>{navigate(RoutesPath.CHALLENGES.ROOT)}}
          >
          <div className='back-left-arrow-btn' /> 
        </button>
        <h6 className='m-0 mx-4 fs-4 font-weight-normal'><u>Challenges</u></h6>
      </div>

      {/* ----------------------------------------- BODY ----------------------------------------------- */}

      <div className='mt-6 bordered-grey'>
        <div className='d-flex p-4'>
          <img
            alt={routeState.image}
            src={routeState.image}
            style={{
              height: '70px',
              borderRadius: '8px',
            }}
            />
          <div className='px-4 w-100'>
            <h2 className='m-0 mt-2 bold' style={{color: '#5C5C5C'}}>
              {routeState.name}
            </h2>
            <div className='mt-3 fs-6 d-flex align-items-center justify-content-between'>
              <IconCircleContainer
                content={`${moment(routeState.startDate).format('DD/MM/YYYY')} ${routeState?.endDate ? `- ${moment(routeState.endDate).format('DD/MM/YYYY')}` : ''}`}
                >
                <CalendarMonthIcon color="#4C707A" />
              </IconCircleContainer>
              <IconCircleContainer
                content={`${challengeData?.data?.uniqueUserCount || routeState.participantCount} participants`}
                >
                <IconGroupsChallenges color="#4C707A" height={18} width={18} />
              </IconCircleContainer>
              {
                (routeState.challengeType === CHALLENGE_PARTICIPANT_TYPE.INDIVIDUAL && [ChallengeActionType.USER_MARK, ChallengeActionType.UPLOAD_SINGLE_IMAGE].includes(routeState.actionType)) ? (
                  <IconCircleContainer
                    content={`${countCompletedUserIndChall} completed`}
                    >
                    <IconTickCompleteBlue color="#4C707A" height={16} width={16} />
                  </IconCircleContainer>
                ) : null
              }
              <IconCircleContainer
                content={`${routeState.challengeType === CHALLENGE_PARTICIPANT_TYPE.INDIVIDUAL ? "Individual" : "Group"} challenge`}
                >
                <IconTargetBoard color="#4C707A" height={18} width={18} />
              </IconCircleContainer>
            </div>
          </div>
        </div>
        <ul 
          className='my-2 py-3 d-flex justify-content-evenly align-items-center' 
          style={{backgroundColor: '#F0F0F0'}}
          >
          { routeState.actionText && <li className='m-0 fs-6' style={{color: '#4F4F4F'}}><span style={{fontWeight: '800'}}>User Action</span> - {routeState.actionText}</li> }
          { routeState.coinPerUser && <li className='m-0 fs-6' style={{color: '#4F4F4F'}}><span style={{fontWeight: '800'}}>Rewards</span> - {routeState.coinPerUser} Mynzo Coins</li> }
        </ul>
        <p className='m-0 fs-6 px-4 pt-3 pb-8' style={{color: '#666666'}}>
          {routeState.description}
        </p>
        
        <div>
        {
          routeState.challengeType === CHALLENGE_PARTICIPANT_TYPE.INDIVIDUAL ? (
            <>
              <RenderParticipantHeaderRow name="Member Name" isAchieve="Goal Achieved" actionType={routeState?.actionType} />
              {
                partipantsArr.map((item, index) => (
                  <RenderParticipantRow 
                    key={`${item.userId + index}`} 
                    rank={index+1} name={item.userName} 
                    isAchieved={item?.actionTaken} 
                    actionType={routeState?.actionType}
                    imageUrl={item?.response?.length && item?.response[0]?.images} 
                    setSelectedViewImage={setSelectedViewImage}
                    handleShowViewImageModal={() => setShowViewImageModal(true)}
                    />
                ))
              }
            </>
          ) : (
            <>
              <RenderGroupHeaderRow name="Group Name" />
              {
                groupsArr.map((item, index) => (
                  <RenderGroupRow 
                    key={`${item.userId + index}`} 
                    rank={index+1} name={item.name} 
                    participantCount={item.participantCount} 
                    percentage={item.percentage} 
                    attemptCount={item.attemptCount}
                    actionType={routeState?.actionType}
                    />
                ))
              }
            </>
          )
        }
        </div>

      </div>

      <ModalViewImage 
        show={showViewImageModal}
        onHide={() => setShowViewImageModal(false)}
        imageUrl={selectedViewImage}
        />

    </div>
  )
}

export default ChallengeDetailsPage;
/* eslint-disable no-useless-return */
/* eslint-disable prefer-destructuring */