import React, {useState, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { useIntl } from 'react-intl'
import Form from 'react-bootstrap/Form'
import moment from 'moment'
import { useSelector } from 'react-redux'
import {
  useLazyGetSuggestedPlansV2Query,
  useLazySubscribePlanQuery,
  useGeneratePlanLeadMutation,
} from './store/api'
import {useGetProfileDetailsQuery} from '../../routing/store/api'
import {updateProfile} from '../../store/slices/profile'
import {updateNotification} from '../../store/slices/notifier'
import {NotificationType} from '../../../_metronic/partials/components/types'
import {useAppDispatch} from '../../store/store'
import { RootState } from '../../store/store'
import { getLocalStorageItem } from '../../utils/storage'

// ₹

const SipField = ({amountVal, swapFields, setAmountVal, setIsSubmitActive, isSipValid, setIsSipValid}: any) => (
  <>
    <Form.Label htmlFor='inputAcres' style={{display: 'flex'}}>
      FIP amount (Rs. per month)
      {/* {
        !isSipValid && 
        <p className='m-0' style={{color: 'red', paddingLeft: '10px'}}>
          *minimum 5000
        </p>
      } */}
    </Form.Label>
    <Form.Control
      type='number'
      style={{color: 'black'}}
      value={amountVal}
      disabled={swapFields}
      onChange={(e) => {
        setIsSubmitActive(false)
        setAmountVal(e.currentTarget.value)
        if (e.currentTarget.value && Number(e.currentTarget.value) >= 4000) {
          setIsSipValid(true)
        } else {
          setIsSipValid(false)
        }
      }}
      pattern='[0-9]*'
      min={0}
      id='inputSIP'
      onWheel={(e) => e.currentTarget.blur()}
    />
    <p className='fs-7 m-0 mt-1' style={{color: 'grey'}}>*<span style={{fontWeight: '600'}}>FIP</span> - Forest Investment Plan is a monthly payment similar to <span style={{fontWeight: '600'}}>SIP</span> (Systematic Investment Plan) to build your forest every month.</p>
    <br />
    <br />
  </>
)

const DateField = ({zeroDateVal, swapFields, setZeroDateVal, setIsSubmitActive, isWorkshopOrg}: any) => {
  const today = moment(new Date())
  // workshop handling
  const years = isWorkshopOrg ? [
      {label: 'Dec 2030', value: '2030-12-31'},
    ] : [
      {label: `Now (Dec ${today.format('YYYY')})`, value: today.format('YYYY').concat('-12-31')}, 
      {label: 'Dec 2030', value: '2030-12-31'}, 
      {label: 'Dec 2035', value: '2035-12-31'}, 
      {label: 'Dec 2040', value: '2040-12-31'}, 
      {label: 'Dec 2050', value: '2050-12-31'}, 
    ]
  return (
  <>
    <Form.Label htmlFor='inputZeroDate'>Target net zero date</Form.Label>
    {
      swapFields ? 
      /*
        <Form.Control
          type='date'
          style={{color: 'black'}}
          value={zeroDateVal}
          disabled={!swapFields}
          onChange={(e) => {
            setIsSubmitActive(false)
            setZeroDateVal(e.currentTarget.value)
          }}
          pattern='[0-9]*'
          min={0}
          id='inputZeroDate'
        /> 
      */
      <select
        id='inputZeroDate'
        className='form-select form-select-lg'
        value={zeroDateVal}
        onChange={(e) => {
          setIsSubmitActive(false)
          setZeroDateVal(e.currentTarget.value)
        }}
        disabled={!swapFields}
        style={{color: 'black', paddingLeft: '15px', borderRadius: '5px'}}
      >
        {
          years.map((yr) => (
            <option value={yr.value} key={yr.label}>
              {yr.label}
            </option>
          ))
        }
      </select>
      :
      <Form.Control
        type='text'
        style={{color: 'black'}}
        value={moment(zeroDateVal).format('MMM YYYY')}
        disabled={!swapFields}
        id='inputZeroDate'
      />
    }
    <br />
    <br />
  </>
)}

export default function PurchaseForestModal(props: any) {
  const {onHide, show} = props
  const intl = useIntl()
  // const liveOnDate = new Date(getLocalStorageItem('live-on'))
  // API
  const [getSuggestedPlans, {data: suggestedPlanData}] = useLazyGetSuggestedPlansV2Query()
  // const [subscribePlan, {isLoading: isSubscribing}] = useLazySubscribePlanQuery()
  // States
  const {profile} = useSelector((state: RootState) => state.profile)
  const [isComputed, setIsComputed] = useState<Boolean>(false)
  const [isSipValid, setIsSipValid] = useState<Boolean>(true)
  const [zeroDateVal, setZeroDateVal] = useState<string | null>('2030-12-31')
  const [acresVal, setAcresVal] = useState<number | null>(null)
  const [swapFields, setSwapFields] = useState<Boolean>(true)
  const [isSubmitActive, setIsSubmitActive] = useState<Boolean>(true)
  const [amountPerMonthVal, setAmountPerMonthVal] = useState<number | null>(null)

  const {isForestAllocated} = useSelector((state: RootState) => state.flags)

  const [genereatePlanLead, {isSuccess, isError, isLoading}] = useGeneratePlanLeadMutation()
  const {
    data: profileDetails,
    isLoading: isProfileDataLoading,
    refetch: refetchUserProfileData,
  } = useGetProfileDetailsQuery()

  const dispatch:any = useAppDispatch()

  const getUserProfile = () => {
    if (!isProfileDataLoading) {
      dispatch(updateProfile(profileDetails))
      onHide()
    }
  }

  const handleSubmitClick = async () => {
    if (suggestedPlanData && suggestedPlanData?.length>0) {
      const body = {
        amount: suggestedPlanData[0]?.amount,
        net_zero_area: suggestedPlanData[0]?.net_zero_area,
        avg_emission: suggestedPlanData[0]?.avg_emission,
        net_zero_date: suggestedPlanData[0]?.net_zero_date,
        net_zero_date_percentage: suggestedPlanData[0]?.net_zero_date_percentage,
      }
      genereatePlanLead(body).then(async () => {
        await refetchUserProfileData()
        getUserProfile()
      })
    }
  }

  const handleComputeClick = (isSipCompute:Boolean) => {
    if (isSipCompute && (!amountPerMonthVal || amountPerMonthVal<1)) {
      setIsSipValid(false)
      return;
    }
    const payload = {
      net_zero_date: swapFields ? zeroDateVal : null,
      sip_amount_per_month: swapFields ? null : amountPerMonthVal,
      assigned_date: profile?.organization?.isWorkshopOrg ? // workshop handling
        moment(profile?.organization?.workshopDate).format('YYYY-MM-DD') 
        : null,
    }
    getSuggestedPlans(payload)
    .then((res) => setIsComputed(state => !state))
  }

  const checkValidSubmit = () => {
    if (acresVal && amountPerMonthVal && acresVal > 0 && amountPerMonthVal > 0) {
      return true;
    }
    return false;
  }

  useEffect(() => {
    if (show) {
      handleComputeClick(false)
    }
  }, [show])

  useEffect(() => {
    if (suggestedPlanData) {
      setAcresVal(Number(suggestedPlanData[0]?.net_zero_area?.toFixed(2)) || 0)
      setZeroDateVal(
        suggestedPlanData[0]?.net_zero_date ? 
        suggestedPlanData[0]?.net_zero_date : 
        moment(new Date()).format('YYYY').concat('-12-31')
      )
      setAmountPerMonthVal(Math.round(suggestedPlanData[0]?.amount || 0))
      if (Math.round(suggestedPlanData[0]?.amount || 0) && acresVal) {
        setIsSubmitActive(true)
      }
    }
  }, [suggestedPlanData, isComputed])

  const handleSubmit = (isSipCompute:Boolean) => {
    if (isSubmitActive) {
      // workshop handling -- able to purchase forest if (enterprise) or (workshop enterprise but forest is not allocated)
      if (!profile?.organization?.isWorkshopOrg) 
        handleSubmitClick()
      if (profile?.organization?.isWorkshopOrg && !isForestAllocated) 
        handleSubmitClick()
    } else {
      handleComputeClick(isSipCompute)
    }
  }

  const handleSwapClick = () => {
    setSwapFields((state) => !state)
    if (!swapFields && !checkValidSubmit()) {
      setIsSubmitActive(false)
    }
  }

  const  avgEmission = (suggestedPlanData && suggestedPlanData?.length>0) ? suggestedPlanData[0]?.avg_emission : 0;
  
  useEffect(() => {
    if (isSuccess) {
      dispatch(
        updateNotification({
          message: 'Request Submitted Successfully',
          type: NotificationType.SUCCESS,
        })
      )
      // workshop handling 
      if (profile?.organization?.isWorkshopOrg) {
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      }
      onHide()
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      dispatch(
        updateNotification({
          message: intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}),
          type: NotificationType.ERROR,
        })
      )
    }
    onHide()
  }, [isError])

  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <h3>Purchase Forest</h3>
          <h6 className='no-bold'>
            Kindly fill in the details below for your forests & someone from our team will be in
            touch with you shortly!
          </h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className='d-flex align-items-center justify-content-center flex-row position-relative'
        >
          <div style={{width: '90%'}}>
            <h5 className='no-bold'>Your Average Annual Emissions : <span className='bold'>{avgEmission}</span> kgCO2</h5>
            <h5 className='mb-8 no-bold'>Number of working days considered in a year : <span className='bold'>240</span> days</h5>
            {!swapFields ? (
              <>
                <SipField
                  amountVal={amountPerMonthVal}
                  swapFields={swapFields}
                  setAmountVal={setAmountPerMonthVal}
                  setIsSubmitActive={setIsSubmitActive}
                  isSipValid={isSipValid}
                  setIsSipValid={setIsSipValid}
                />
                <DateField
                  zeroDateVal={zeroDateVal}
                  setZeroDateVal={setZeroDateVal}
                  swapFields={swapFields}
                  setIsSubmitActive={setIsSubmitActive}
                  isWorkshopOrg={profile?.organization?.isWorkshopOrg}
                />
              </>
            ) : (
              <>
                <DateField
                  zeroDateVal={zeroDateVal}
                  setZeroDateVal={setZeroDateVal}
                  swapFields={swapFields}
                  setIsSubmitActive={setIsSubmitActive}
                  isWorkshopOrg={profile?.organization?.isWorkshopOrg}
                />
                <SipField
                  amountVal={amountPerMonthVal}
                  swapFields={swapFields}
                  setAmountVal={setAmountPerMonthVal}
                  setIsSubmitActive={setIsSubmitActive}
                  isSipValid={isSipValid}
                  setIsSipValid={setIsSipValid}
                />
              </>
            )}
            <Form.Label htmlFor='inputTimeline'>Total acres of forest to be planted</Form.Label>
            <Form.Control
              value={acresVal ? String(acresVal?.toFixed(2)) : '0'}
              style={{color: 'black'}}
              disabled
            />
          </div>
          {
            !profile?.organization?.isWorkshopOrg &&
            <button type='button' className='custom-position' onClick={handleSwapClick}>
              <i
                className='bi bi-arrow-down-up'
                style={{
                  fontSize: 14,
                  fontWeight: 400,
                  color: '#009ef7',
                  paddingRight: 0,
                }}
              />
            </button>
          }
        </div>
      </Modal.Body>
      <button
        type='button'
        className='custom-primary-delete-button align-self-center'
        data-toggle='modal'
        onClick={() => handleSubmit(!swapFields)}
        disabled={(isSubmitActive && !checkValidSubmit()) || isLoading}
      >
        {isSubmitActive ? 'Submit' : 'Re-Compute'}
      </button>
    </Modal>
  )
}