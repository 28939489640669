/* eslint-disable jsx-a11y/anchor-is-valid */
import {Navigate, Route, Routes} from 'react-router-dom'
import {useEffect} from 'react'
import {Login} from '../modules/auth/components/Login'
import {toAbsoluteUrl} from '../../_metronic/helpers'
import {RoutesPath} from './RoutesPath'

function PublicLayout() {
  useEffect(() => {
    document.body.style.backgroundImage = 'none'
    return () => undefined
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
      {/* begin::Content */}
      {/* <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'> */}
      {/* <div> */}
        {/* begin::Logo */}
        {/* end::Logo */}
        {/* begin::Wrapper */}
        {/* <div className='w-lg-500px bg-body rounded shadow-sm p-10 p-lg-15 mx-auto'> */}
        <div>
          <Routes>
            <Route path={RoutesPath.LOGIN} element={<Login />} />
            <Route path={RoutesPath.ALL} element={<Navigate to={RoutesPath.LOGIN} />} />

            <Route index element={<Login />} />
          </Routes>
        </div>

        {/* end::Wrapper */}
      {/* </div> */}
      {/* end::Content */}
      {/* begin::Footer */}
      {/* TODO: add foor with hyperlinks when links are available */}
      {/* <div className='d-flex flex-center flex-column-auto p-10'>
        <div className='d-flex align-items-center fw-semibold fs-6'>
          <a href='#' className='text-muted text-hover-primary px-2'>
            About
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact
          </a>

          <a href='#' className='text-muted text-hover-primary px-2'>
            Contact Us
          </a>
        </div>
      </div> */}
      {/* end::Footer */}
    </div>
  )
}

export default PublicLayout
