import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { tagsDarkColorShade } from '../../../utils/helpers'
import { RootState } from '../../../store/store'
import { updateReportsOrgTag, resetReportsOrgTag } from '../../../store/slices/filters'

type Props = {
  tagsList: any[]
}

const dropDownWidth = '180px';

export const OrgTagsDropdown: React.FC<Props> = (props) => {
  const {tagsList} = props;

  const dispatch = useDispatch();
  const {filterReportsOrgTag} = useSelector((state: RootState) => state.filters)

  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const handleHideDropdown = () => {
    setTimeout(() => {
      setShowDropdown(false);
    }, 300);
  }

  const handleTagSelect = (item: any) => {
    dispatch(updateReportsOrgTag({
      id: item.id,
      name: item.name,
      short_name: item.abbreviation_name,
      color: item.color_code,
    }));
  }

  return (
    <div className="dropdown m-0 p-0 bordered-grey" style={{backgroundColor: 'white'}} onBlur={handleHideDropdown}>
      <button 
        id="dropdownMenu2" 
        type="button" 
        className="btn btn-secondary dropdown-toggle" 
        onClick={() => {setShowDropdown(state => !state)}}
        style={{backgroundColor: 'white', borderRadius: '10px', width: dropDownWidth}} 
        data-toggle="dropdown" 
        aria-haspopup="true" 
        aria-expanded="false"
        >
        {filterReportsOrgTag.short_name}
      </button>
      { 
      showDropdown &&
      <div className="dropdown-menu bordered-grey" aria-labelledby="dropdownMenu2" style={{display: 'block', marginTop: '5px'}}>
        <button className="dropdown-item" style={{width: dropDownWidth, fontSize: '14px'}} type="button" 
          onClick={() => {
            dispatch(resetReportsOrgTag())
            handleHideDropdown();
          }}
          >Enterprise Report</button>
        <button className="dropdown-item justify-content-between d-flex" style={{width: dropDownWidth, fontSize: '14px'}} type="button">
          <p className='m-0'>Tag Report</p> 
          <p className='m-0'>❯</p>
        </button>
        <div className='bordered-grey' style={{width: 'max-content', position: 'absolute', backgroundColor: 'white', left: dropDownWidth, top: '40px', marginLeft: '5px'}}>
          {
            tagsList.map((item) => (
              !item.is_archive && (
                <button 
                  key={item.id} 
                  className="remove-btn-styles d-flex align-items-center dropdown-item" 
                  style={{padding: '12px 20px', justifyContent: 'space-between'}}
                  type="button" 
                  onClick={() => {
                    handleTagSelect(item);
                    handleHideDropdown();
                  }}
                  >
                  <div className='m-0' style={{fontSize: '14px'}}>{item.name} </div>
                  <div 
                    className='custom-group-display-color-card mx-2' 
                    style={{
                      backgroundColor: item.color_code, 
                      borderColor: tagsDarkColorShade[item.color_code],
                      color: tagsDarkColorShade[item.color_code],
                      padding: '1px 10px'
                    }}
                    >
                    {item.abbreviation_name}
                  </div>
                </button>
              )
            ))
          }
        </div>
      </div>
      }
    </div>
  )
}