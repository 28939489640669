const NoResultsMessage = ({message}: {message: string}) => (
  <tr>
    <td colSpan={5}>
      <div className='d-flex text-center w-100 align-content-center justify-content-center my-5'>
        {message}
      </div>
    </td>
  </tr>
)
export default NoResultsMessage
