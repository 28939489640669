import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import moment from 'moment'
import {useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {RoutesPath} from '../../routing/RoutesPath'
import ProjectCard from './ProjectCard'
import PurchaseProject from './PurchaseProject'
import {useGetProjectsQuery, useGetTotalForestQuery} from './store/api'
import { getLocalStorageItem } from '../../utils/storage'
import {Loader} from '../../../_metronic/partials'
import { PageTitle } from '../../routing/RoutesPageTitle'
import {RootState} from '../../store/store'
import ComingSoonCardAfter14Days from './ComingSoonCardAfter14Days'
import PurchaseForestModal from './PurchaseForestModal'
import { getYYYYDDMMFormat } from '../../utils/dateFormatter'
import ComingSoonCard from './ComingSoonCard'
import { setIsForestAllocated } from '../../store/slices/flags'

const PurchaseBanner = () =>(
  <div className='custom-banner-container'>
    <div className='custom-banner-header'>
      <h3>🛎️ Your forest purchase request is under process!</h3>
    </div>
    <p>   Your request for purchasing a forest has been accepted.  Someone from our team will get in touch with you soon!</p>
  </div>
)

const ProjectList: React.FC = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  const liveOnDate = new Date(getLocalStorageItem('live-on'));
  const currentDate = new Date()
  const userDetails = useSelector((state: RootState) => state.profile.profile)
  const timeDiff = currentDate.getTime() - liveOnDate.getTime()
  const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24))
  const isWithin14days = daysDiff<=14;

  const dispatch = useDispatch()

  const {data: forestsList, isFetching, isLoading} = useGetTotalForestQuery({
    fromDate: getYYYYDDMMFormat(liveOnDate), 
    toDate: userDetails?.organization?.isWorkshopOrg ? 
      // moment(userDetails?.organization?.workshopDate).add(90, 'days').format('YYYY-MM-DD') : 
      '2030-12-31' :
      moment(currentDate).format('YYYY-MM-DD'),
  });

  // update page title
  useEffect(() => {
    document.title = PageTitle.FOREST
  }, [])

  useEffect(() => {
    if (forestsList?.forests?.length) {
      dispatch(setIsForestAllocated(true))
    }
  }, [forestsList])

  const forests =  forestsList?.forests;
  const [modalShow, setModalShow] = useState(false)

  const isForestPurchaseInRequest: boolean = userDetails?.hasInProgressPlanLead || false

  if (isFetching || isLoading) {
    return <Loader />
  }

  if(!forests?.length){
    if(isWithin14days){
      return <ComingSoonCard />
    }else{
      return <ComingSoonCardAfter14Days />
    }
  } 
  return (
    <div className='mx-8'>
      <PurchaseForestModal show={modalShow} onHide={() => setModalShow(false)} />
      <div className='w-full d-flex justify-content-between align-items-center bg-white mt-5'>
        <h1 className='px-7'>Enterprise’s Forests</h1>
        <div>
          <button
            type='button'
            className='custom-outline-button'
            style={{marginRight: '10px'}}
            onClick={() => setModalShow(true)}
            >
            Purchase Forest
          </button>
          <button
            type='button'
            className='custom-primary-delete-button'
            style={{marginRight: '10px'}}
            onClick={()=> navigate(RoutesPath.OUR_FOREST.ALLOCATE_PROJECT)}
            >
            Allocate Recaptures
          </button>
        </div>
      </div>
      {isForestPurchaseInRequest && <PurchaseBanner/>}
      {forests?.length && (
        <div className='row g-6 g-xl-9 custom-content bg-white custom-pt-0'>
          {forests?.map((forest) => (
            <div key={forest.project_id} className='col-md-6 col-xl-4'>
              <ProjectCard forestsList={forestsList} forest={forest} recapture={forest.recapture} />
            </div>
          ))}
        </div>
      )}
      {/* <PurchaseProject
        show={showPurchaseProject}
        handleClose={() => setShowPurchaseProject(false)}
      /> */}
    </div>
  )
}

export default ProjectList
