import React, {useState, useEffect} from 'react'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import mixpanel from 'mixpanel-browser'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { PageTitle } from '../../routing/RoutesPageTitle'
// import { useSyncWorkshopDataMutation } from './store/api'
import {
  IProfileDetails,
  profileDetailsInitValues as initialValues,
} from '../../../_metronic/partials/components/types'
import {RootState} from '../../store/store'
import {RoutesPath} from '../../routing/RoutesPath'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import '../../routing/styles.css'
import { EVENT_TYPE, PAGES } from '../../analytics/tracker-types'

const profileDetailsSchema = Yup.object().shape({
  fName: Yup.string().required('First name is required'),
  lName: Yup.string().required('Last name is required'),
  company: Yup.string().required('Company name is required'),
  contactPhone: Yup.string().required('Contact phone is required'),
  companySite: Yup.string().required('Company site is required'),
  country: Yup.string().required('Country is required'),
  language: Yup.string().required('Language is required'),
  timeZone: Yup.string().required('Time zone is required'),
  currency: Yup.string().required('Currency is required'),
})

const Settings: React.FC = () => {
  const [data, setData] = useState<IProfileDetails>(initialValues)
  const updateData = (fieldsToUpdate: Partial<IProfileDetails>): void => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
  }
  // update page title
  useEffect(() => {
    document.title = PageTitle.SETTINGS
    // tracking
    mixpanel.track(EVENT_TYPE.PageView, {'name': PAGES.SettingsPage})
  }, [])

  const navigate = useNavigate()
  const userDetails = useSelector((state: RootState) => state.profile.profile)

  const [loading, setLoading] = useState(false)

  // const [syncWorkshopData, {
  //   data: workshopSyncData, 
  //   isLoading: isSyncingWorkshopData
  // }] = useSyncWorkshopDataMutation()

  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        values.communications.email = data.communications.email
        values.communications.phone = data.communications.phone
        values.allowMarketing = data.allowMarketing
        const updatedData = Object.assign(data, values)
        setData(updatedData)
        setLoading(false)
      }, 1000)
    },
  })

  // const handleSyncWorkshopData = (range:string) => {
  //   if (userDetails?.organization) {
  //     if (range === '180_days') {
  //       syncWorkshopData({
  //         start_date: moment(userDetails?.organization?.workshopDate).subtract(91, 'days').format('YYYY-MM-DD'), 
  //         end_date: moment(userDetails?.organization?.workshopDate).add(91, 'days').format('YYYY-MM-DD'), 
  //       })
  //     } else if (range === '2030') {
  //       syncWorkshopData({
  //         start_date: '2030-01-01', 
  //         end_date: '2031-01-01',
  //       })
  //     }
  //   }
  // }

  const role = 'Admin'
  return (
    <div style={{backgroundColor: 'white', flex: '1', minHeight: '100%'}}>
      <div className='card'>
        <div className='settings-btn px-8'>
          <div className='justify-content-end py-6 px-3'>
            <button
              type='button'
              // disabled={!loading}
              className='custom-outline-button '
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              onClick={() =>
                navigate({
                  pathname: RoutesPath.CHANGE_PASSWORD,
                })
              }
            >
              Change Password
            </button>
          </div>
          <div className='justify-content-end py-6 px-3'>
            <button
              type='button'
              // disabled={!loading}
              className='custom-primary-delete-button'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              onClick={() =>
                navigate(RoutesPath.PROFILE_EDIT, {
                  state: userDetails,
                })
              }
            >
              Edit Profile
            </button>
          </div>
        </div>

        {/* row 2: START */}
        <div className='px-18'>
          {/* row 2 */}
          <div className='me-7 mb-4 row-1 mb-xl-10'>
            <div className=' symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'>
              {/* <img src={organizationLogo} alt='Metronic' /> */}
              <img src={userDetails?.organization?.logo} alt='' className='logo-image'/>
            </div>
            <div className='flex-row align-items-end d-flex px-2'>
              <div className='px-6 pt-3'>
                <div className='text-gray-800 fs-3 fw-bolder me-1 text-title mb-3'>
                  {userDetails?.organization?.name}
                </div>
                <div className='text-gray-700 text-description fs-4'>{role}</div>
              </div>

              {/* <div className='d-flex align-items-center'>
                <div className='px-6 symbol symbol-70px symbol-lg-80px symbol-fixed position-relative'>
                  <img src={toAbsoluteUrl('/media/avatars/companyLogo.jpg')} alt='' />
                </div>
                <div className=''>
                  <div className='text-gray-800 fs-3 fw-bolder me-1 text-title'>
                    {userDetails?.organization?.name}
                  </div>
                </div>
              </div> */}
            </div>
          </div>
          <div className='me-7 mb-4 row-1 pt-10'>
            <div className='col-lg-2  d-flex mb-5 me-10'>
              <div className='symbol symbol-70px symbol-lg-80px symbol-fixed position-relative me-5'>
                <img src={toAbsoluteUrl('/media/avatars/division_1.svg')} alt='' />
              </div>
              <div className=''>
                <div className='text-gray-600 fs-3 me-1 text mb-3 pt-3'>Division</div>
                <div className='text-700 des fs-4'>{userDetails?.division?.name || 'NA'}</div>
              </div>
            </div>

            <div className='col-lg-2  d-flex mb-5'>
              <div className='symbol symbol-70px symbol-lg-80px symbol-fixed position-relative me-5'>
                <img src={toAbsoluteUrl('/media/avatars/department_1.svg')} alt='' />
              </div>
              <div className=''>
                <div className='text-gray-600 fs-3 me-1 text mb-3 pt-3'>Department</div>
                <div className='text-700 des fs-4'>{userDetails?.department?.name || 'NA'}</div>
              </div>
            </div>
          </div>

          <div className='me-7 mb-4 row-1 pt-10'>
            <div className='col-lg-2  d-flex mb-5 me-10'>
              <div className='symbol symbol-70px symbol-lg-80px symbol-fixed position-relative me-5'>
                <img src={toAbsoluteUrl('/media/avatars/location_1.svg')} alt='' />
              </div>
              <div className=''>
                <div className='text-gray-600 fs-3 me-1 text mb-3 pt-3'>Location</div>
                <div className='text-700 des fs-4'>{userDetails?.workLocation?.name || 'NA'}</div>
              </div>
            </div>

            <div className='col-lg-4  d-flex mb-5'>
              <div className='symbol symbol-60px symbol-lg-70px symbol-fixed position-relative me-5'>
                <img src={toAbsoluteUrl('/media/avatars/mail1.svg')} alt='' />
              </div>
              <div className=''>
                <div className='text-gray-600 fs-3 me-1 text mb-3 pt-3'>Email Id</div>
                <div className='text-700 des fs-4'>{userDetails?.email}</div>
              </div>
            </div>
          </div>

          {/* WORKSHOP HANDLING - SYNCINIG */}
          {/* {
            (userDetails?.organization?.isWorkshopOrg) ? (
              <div className='me-7 mb-4 row-1 pt-4'>
                <button
                  type='button'
                  disabled={isSyncingWorkshopData}
                  className='custom-primary-delete-button'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  onClick={() => handleSyncWorkshopData('180_days')}
                >
                  {isSyncingWorkshopData ? 'Syncing 180 Days..' : 'Sync 180 Days'}
                </button>
                <button
                  type='button'
                  disabled={isSyncingWorkshopData}
                  className='custom-primary-delete-button'
                  data-kt-menu-trigger='click'
                  data-kt-menu-placement='bottom-end'
                  data-kt-menu-flip='top-end'
                  onClick={() => handleSyncWorkshopData('2030')}
                >
                  {isSyncingWorkshopData ? 'Syncing 2030..' : 'Sync 2030'}
                </button>
              </div>
            ) : null
          } */}
        </div>
      </div>
      <div className='background-image user-profile-bg' />
    </div>
  )
}

export default Settings
