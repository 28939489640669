import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import mixpanel from 'mixpanel-browser'
import * as Yup from 'yup'
import {useFormik, useFormikContext} from 'formik'
import Form from 'react-bootstrap/Form'
import moment from 'moment'
import { useSelector } from 'react-redux'
import {
  utilityBillFormInitValues as initialValues,
} from '../../../_metronic/partials/components/types'
import { RoutesPath } from '../../routing/RoutesPath';
import {
  useLazyGetLocationsListQuery,
} from '../dashboard/store/api'
import { usePostAddUtilityBillMutation, useLazyGetLocationWiseEmployeeCountQuery } from './store/api';
import { UtilityBillForm, UtilityBillEnergyTypes } from './types';
import { EVENT_TYPE, PAGES } from '../../analytics/tracker-types'
import { RootState, useAppDispatch } from '../../store/store'
import { setActiveLocationsList } from '../../store/slices/masterList'

import './styles.css';

type Props = {

}

const UtilityBillEnergyType: Record<string, UtilityBillEnergyTypes> = {
  ELECTRICITY: {
    id: "electricity",
    name: "Electricity"
  },
  NATURAL_GAS: {
    id: "natural_gas",
    name: "Natural Gas"
  },
};

const UtilityBillElectricityUnitTypes: Record<string, UtilityBillEnergyTypes> = {
  KWH: {
    id: "kwh",
    name: "KWh"
  },
};

const UtilityBillGasUnitTypes: Record<string, UtilityBillEnergyTypes> = {
  GIGA_JOULES: {
    id: "giga_joules",
    name: "GJ"
  },
  THERM: {
    id: "therm",
    name: "Therm"
  },
  MMBTU: {
    id: "mmbtu",
    name: "MMBtu"
  },
  MCF: {
    id: "mcf",
    name: "Mcf"
  },
};


const utilityReqFormSchema = Yup.object().shape({
  work_location_id: Yup.string().required('Location is required'),
  energy_type: Yup.string().required('Energy type is required'),
  total_employee_count: Yup.number().test('isValueGreaterThanZero','Number of employees should be greater than zero',(value, context) => {
    if (context.parent.energy_type !== 'electricity') return true;
    if (value && value>0) return true;
    return false;
  }),
  bill_start_date: 
    Yup.string()
    .test('isValidStartDate', 'Start date is required', (value) => {
      if (value === undefined) {
        return false;
      }
      return true;
    })
    .required('Start date is required'),
  bill_end_date: 
    Yup.string()
    .test('isValidEndDate', 'End date is required', (value) => {
      if (!value) {
        return false;
      }
      return true;
    })
    .required('End date is required'),
  units: Yup.number().test('isUnitsGreaterThanZero','Units should be greater than zero',(value) => {
    if(value && value>0){
      return true;
    }
    return false;
  }),
  unit_type: Yup.string().required('Unit type is required'),
})

const AddUtilityBillPage: React.FC<Props> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const [postAddUtilityBill, {error, isSuccess, isError}] = usePostAddUtilityBillMutation()
  const [getLocationWiseEmployeeCount, {data: employeeCountData}] = useLazyGetLocationWiseEmployeeCountQuery()
  const [getLocationsList, {data: locationsListData}] = useLazyGetLocationsListQuery()
  const [isErrorAddUB, setIsErrorAddUB] = useState<boolean>(false)
  const [isSubmitInProgress, setIsSubmitInProgress] = useState<boolean>(false)
  const {profile} = useSelector((state:RootState) => state.profile)
  const {activeLocationsList} = useSelector((state: RootState) => state.masterList);
  
  useEffect(() => {
    // tracking
    mixpanel.track(EVENT_TYPE.PageView, {'name': PAGES.AddUtilityBillPage})
  }, [])

  const formik = useFormik<UtilityBillForm>({
    initialValues,
    validationSchema: utilityReqFormSchema,
    
    onSubmit: async (values) => {
      // workshop handling
      if (profile?.organization?.isWorkshopOrg) {
        return;
      }
      let { units } = values;
      if (values.energy_type === 'natural_gas') {
        switch (values.unit_type) {
          case 'therm': 
            units *= 0.105355;
            break;
          case 'mmbtu':
            units *= 1.053554;
            break;
          case 'mcf':
            units *= 1.082;
            break;
          default:
            // do nothing
        }
      }
      await postAddUtilityBill({
        work_location_id: Number(values.work_location_id),
        total_employee_count: values.energy_type !== 'natural_gas' ? values.total_employee_count : 0,
        energy_type: values.energy_type,
        bill_start_date: values.bill_start_date,
        bill_end_date: values.bill_end_date,
        units,
      })
    },
  })

  useEffect(() => {
    if (formik.values.energy_type === 'natural_gas' && formik.values.unit_type === 'KWh') {
      formik.setFieldValue('unit_type', 'giga_joules');
    } else if (formik.values.energy_type === 'electricity' && formik.values.unit_type !== 'KWh') {
      formik.setFieldValue('unit_type', 'KWh');
    }
  }, [formik.values.energy_type])

  useEffect(()=>{
    if (isError) {
      setIsErrorAddUB(true)
    }
    if (isSuccess) {
      setIsErrorAddUB(false)
      navigate(RoutesPath.CONFIGURATION.ROOT)
    }
  },[error, isSuccess, isError])

  useEffect(() => {
    if (formik.values.work_location_id !== '') {
      getLocationWiseEmployeeCount({work_location_id: Number(formik.values.work_location_id)})
    }
  }, [formik.values.work_location_id])

  useEffect(() => {
    if (employeeCountData?.data?.totalEmployeeCount) {
      formik.setFieldValue('total_employee_count', employeeCountData?.data?.totalEmployeeCount)
    }
  }, [employeeCountData])

  useEffect(() => {
    if (activeLocationsList.length === 0) {
      getLocationsList({isActive: true}).then((res:any)=>{
        const arr: any[] = [];
        res?.data.forEach((itm:any) => {
          arr.push(itm)
        })
        dispatch(setActiveLocationsList(arr));
      })
    }
  }, [])

  const handleSubmit = () => {
    setIsSubmitInProgress(true);
    formik.handleSubmit();
    setIsSubmitInProgress(false)
  }

  return (
    <div className='position-relative' style={{flex: '1', minHeight: '100%'}}>
      {/* CONTENT */}
      <div className='blank-page-view py-8 px-12'>
        <div className='back-header-btn'>
          <button 
            type='button' 
            className='p-2 back-left-arrow-btn-wrap'
            onClick={()=>{navigate(RoutesPath.CONFIGURATION.ROOT)}}
            >
            <div className='back-left-arrow-btn' /> 
          </button>
          <h6 className='m-0 mx-4 fs-4 font-weight-normal'><u>Add Utility Bill</u></h6>
        </div>
        
        <form noValidate className='form pt-6 pb-10 mb-10'>

            <div className='card-body px-12'>
              <div className='col-8'>

                <div className='row-6 mb-4'>
                  <label htmlFor='location' className='col-form-label fw-bold fs-6'>
                    Location
                  </label>
                      <div className='col-12'>
                        <select
                          className='form-select form-select-solid form-select-lg'
                          {...formik.getFieldProps('work_location_id')}
                        >
                          <option value='' key='empty'>
                            Select a location
                          </option>
                          {activeLocationsList &&
                            activeLocationsList.map((location) => (
                              <option value={location.id} key={location.id}>
                                {location.name}
                              </option>
                            ))}
                        </select>

                        {formik.touched.work_location_id && formik.errors.work_location_id && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.work_location_id}</div>
                          </div>
                        )}
                      </div>
                </div>

                <div className='row-6 mb-4'>
                  <label htmlFor='energy' className='col-form-label fw-bold fs-6'>
                    Type of energy
                  </label>
                  <div className='col-12'>
                    <select
                      className='form-select form-select-solid form-select-lg'
                      {...formik.getFieldProps('energy_type')}
                    >
                      {Object.keys(UtilityBillEnergyType).map(key => (
                        <option key={key} value={UtilityBillEnergyType[key].id}>
                          {UtilityBillEnergyType[key].name}
                        </option>
                      ))}
                    </select>

                    {formik.touched.energy_type && formik.errors.energy_type && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.energy_type}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className='row mb-4'>
                  <div className='col-6'>
                    <label htmlFor='units' className='col-lg-12 col-form-label fw-bold fs-6'>
                      Units
                    </label>
                    <input
                      type='number'
                      className='form-control form-control-lg form-control-solid'
                      placeholder='Units'
                      id='units'
                      pattern='[0-9]*'
                      min={0}
                      {...formik.getFieldProps('units')}
                    />
                    {formik.touched.units && formik.errors.units && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.units}</div>
                      </div>
                    )}
                  </div>

                  <div className='col-6'>
                    <label htmlFor='energy' className='col-form-label fw-bold fs-6'>
                      Type of energy unit
                    </label>
                    <div className='col-12'>
                      <select
                        className='form-select form-select-solid form-select-lg'
                        {...formik.getFieldProps('unit_type')}
                      >
                        {
                          formik.values.energy_type !== 'electricity' ? 
                            Object.keys(UtilityBillGasUnitTypes).map(key => (
                              <option key={key} value={UtilityBillGasUnitTypes[key].id}>
                                {UtilityBillGasUnitTypes[key].name}
                              </option>
                            )) : 
                            Object.keys(UtilityBillElectricityUnitTypes).map(key => (
                              <option key={key} value={UtilityBillElectricityUnitTypes[key].id}>
                                {UtilityBillElectricityUnitTypes[key].name}
                              </option>
                            ))
                        }
                      </select>
                      {formik.touched.unit_type && formik.errors.unit_type && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.unit_type}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className='row mb-4'>
                  <div className='col-6'>
                    <label htmlFor='startDate' className='col-form-label fw-bold fs-6'>
                      Start date of bill
                    </label>
                    <Form.Control
                      type='date'
                      style={{color: 'black', marginRight: '20px', cursor: 'pionter', width: '100%'}}
                      // value={billStartDate || ''}
                      // onChange={(e) => setBillStartDate(e.currentTarget.value)}
                      {...formik.getFieldProps('bill_start_date')}
                      // pattern='[0-9]*'
                      // min={0}
                      max={ 
                        formik.values.bill_end_date ?
                          moment(formik.values.bill_end_date).format('YYYY-MM-DD').toString() :
                          moment().format('YYYY-MM-DD').toString()
                      }
                      id='inputZeroDate'
                      />
                    {
                      formik.touched.bill_start_date && formik.errors.bill_start_date && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.bill_start_date}</div>
                        </div>
                      )
                    }
                  </div>
                  <div className='col-6'>
                    <label htmlFor='endDate' className='col-form-label fw-bold fs-6'>
                      End date of bill
                    </label>
                    <Form.Control
                      type='date'
                      style={{color: 'black'}}
                      // value={billEndDate || ''}
                      // onChange={(e) => setBillEndDate(e.currentTarget.value)}
                      {...formik.getFieldProps('bill_end_date')}
                      min={
                        formik.values.bill_start_date ? 
                          moment(formik.values.bill_start_date).format('YYYY-MM-DD').toString() : 
                          ''
                      }
                      max={moment().format('YYYY-MM-DD').toString()}
                      // pattern='[0-9]*'
                      // min={0}
                      id='inputZeroDate'
                      />
                    {
                      formik.touched.bill_end_date && formik.errors.bill_end_date && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.bill_end_date}</div>
                        </div>
                      )
                    }
                  </div>
                </div>
                
                {
                  formik.values.energy_type === 'electricity' &&
                  <div className='row-6 mb-4'>
                    <label htmlFor='units' className='col-lg-4 col-form-label fw-bold fs-6 w-100'>
                      Number of employees <span className='label-info-employees'>(Enter the total number of employees in selected location)</span>
                    </label>
                    
                        <div className='col-12'>
                          <input
                            type='number'
                            className='form-control form-control-lg form-control-solid'
                            placeholder='Number of employees'
                            id='total_employee_count'
                            pattern='[0-9]*'
                            min={0}
                            {...formik.getFieldProps('total_employee_count')}
                          />
                          {formik.touched.total_employee_count && formik.errors.total_employee_count && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>{formik.errors.total_employee_count}</div>
                            </div>
                          )}
                        </div>
                  </div>
                }

              </div>
            </div>

          </form>

        <div className='cloud-upload-bg bg-image-bottom-right' />
      </div>

      <div className='card-footer footer shadow mt-auto'>
        <div className='settings-btn-bottom px-8'>
          <div className='py-6 px-3'>
            <button
              onClick={() => {navigate(RoutesPath.CONFIGURATION.ROOT)}}
              id='kt_password_cancel'
              type='button'
              className='custom-outline-button '
            >
              Cancel
            </button>
          </div>
          {
            isErrorAddUB &&
            <p className='mb-0 p-0 fs-6' style={{color: 'red', alignSelf: 'center'}}>Something went wrong, please re-check fields!</p>
          }
          <div className='py-6 px-3'>
            <button 
              type='submit' 
              disabled={isSubmitInProgress}
              className='custom-primary-delete-button ' 
              onClick={handleSubmit}
              >
              Submit
            </button>
          </div>
        </div>
      </div>
   </div>
  )
}

export default AddUtilityBillPage