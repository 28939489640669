import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal'
import { Library } from '@googlemaps/js-api-loader';
import { GoogleMap, useJsApiLoader, useLoadScript, Marker } from '@react-google-maps/api'
import usePlacesAutocomplete, {
  getGeocode, 
  getLatLng
} from 'use-places-autocomplete'

import { usePostCreateLocationMutation, usePostUpdateLocationMutation } from '../../store/api'
import { getAddressByLatLng } from '../../../../utils/helpers'
import { RootState } from '../../../../store/store';

const defaultLatLng = {
  lat: 28.6186, 
  lng: 77.2037
}

const _places:Library = 'places';
const _libArray:Library[] = [_places];


const PlacesAutocomplete = (props:any) => {
  const {setLatitudeVal, setLongitudeVal} = props;
  const {
    ready, 
    value, 
    setValue, 
    suggestions: {status, data}, 
    clearSuggestions
  } = usePlacesAutocomplete();

  const handleSelect = ({ description }:any) => 
    () => {
      // When the user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();

      // Get latitude and longitude via utility functions
      getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0]);
        setLatitudeVal(lat);
        setLongitudeVal(lng);
      });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id: placeId,
        structured_formatting: { main_text: mainText, secondary_text: secondaryText },
      } = suggestion;

      return (
        <div
          className='address-dropdown-item'
          role='button' 
          tabIndex={0} 
          key={placeId} 
          onClick={handleSelect(suggestion)} 
          onKeyDown={handleSelect(suggestion)}
          >
          <strong>{mainText}</strong> <small>{secondaryText}</small>
        </div>
      );
    });

  return (
    <div className='position-relative'>
      <input
        disabled={!ready}
        placeholder='Search office location'
        className='modal-input-field'
        value={value}
        type='string'
        onChange={(v) => {
          setValue(v.currentTarget.value)
        }}
        />
      {
        status === "OK" && 
        <div className='address-dropdown-container'>
          {renderSuggestions()}
        </div>
      }
    </div>
  )
}


export default function ModalAddLocationData(props:any) {
  const {onHide, isEdit, name, onChange, numberOfEmployee, latitude, longitude, id} = props
  const [latitudeVal, setLatitudeVal] = useState<number>(Number(latitude));
  const [longitudeVal, setLongitudeVal] = useState<number>(Number(longitude));
  const [addressVal, setAddressVal] = useState<string>('');
  const [isDefault, setIsDefault] = useState<boolean>(true);

  useEffect(()=>{
    if (longitudeVal !== defaultLatLng.lng || latitudeVal !== defaultLatLng.lat) {
      setIsDefault(false)
    }
    async function getAddress() {
     const val = await getAddressByLatLng(latitudeVal, longitudeVal) ;
     setAddressVal(val)
    };
    getAddress();
  }, [latitudeVal, longitudeVal, isEdit])

  const containerStyle = {
    width: '100%',
    height: '300px'
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API_KEY || '', 
    libraries: _libArray
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback((tmap:any) => {
    const bounds = new window.google.maps.LatLngBounds({lat: latitudeVal, lng: longitudeVal});
    tmap.fitBounds(bounds);

    setMap(tmap)
  }, [latitudeVal, longitudeVal])

  const onUnmount = React.useCallback((tmap:any) => {
    setMap(null)
  }, [])

  const [createLocation, {
    data: createLocationResponse, 
    isSuccess: isSuccessCreateLoction, 
    isError: isErrorCreateLocation, 
    isLoading: isLoadingCreateLocation
  }] = usePostCreateLocationMutation();
  const [editLocation, {
    data: editLocationResponse, 
    isSuccess: isSuccessUpdateLoction, 
    isError: isErrorUpdateLocation, 
    isLoading: isLoadingUpdateLocation
  }] = usePostUpdateLocationMutation();

  const {profile} = useSelector((state:RootState) => state.profile)

  const [nameVal, setNameVal] = useState<string>('')
  const [employeeCountValue, setEmployeeCountValue] = useState<any>()
  // const [latValue, setLatValue] = useState<any>()
  // const [lngValue, setLngValue] = useState<any>()
  // validations
  const [isValidName, setIsValidName] = useState<boolean>(true)
  const [isValidEmployeeCount, setIsValidEmployeeCount] = useState<boolean>(true)
  const [isValidLat, setIsValidLat] = useState<boolean>(true)
  const [isValidLng, setIsValidLng] = useState<boolean>(true)

  const title = isEdit ? 'Edit Location' : 'Add New Location'

  useEffect(() => {
    if (!isEdit) {
      setNameVal('')
      setEmployeeCountValue(0)
      setLatitudeVal(defaultLatLng.lat)
      setLongitudeVal(defaultLatLng.lng)
      setIsDefault(true)
    } else {
      setNameVal(name || '')
      setEmployeeCountValue(numberOfEmployee || 0)
      setLatitudeVal(Number(latitude))
      setLongitudeVal(Number(longitude))
    }
    setIsValidName(true)
    setIsValidEmployeeCount(true)
    setIsValidLat(true)
    setIsValidLng(true)
  }, [name, numberOfEmployee, id, latitude, longitude, isEdit])

  const checkValidFields = () => {
    let a = true; let b = true; let c = true; let d = true 
    if (!nameVal || nameVal.trim().length===0) {
      setIsValidName(false)
      a = false
    }
    if (!employeeCountValue || employeeCountValue<=0) {
      setIsValidEmployeeCount(false)
      b = false
    }
    if (!latitudeVal || latitudeVal === defaultLatLng.lat) {
      setIsValidLat(false)
      c = false
    }
    if (!longitudeVal || longitudeVal === defaultLatLng.lng) {
      setIsValidLng(false)
      d = false
    }
    return a && b && c && d
  }

  const handleCreateEditLocation = () => {
    // workshop handling
    if (!checkValidFields() || profile?.organization?.isWorkshopOrg) {
      return; 
    }
    if (isEdit) {
      // code
      editLocation({
        "id": id, 
        "name": nameVal, 
        "total_employee_count": employeeCountValue, 
        "latitude": String(latitudeVal), 
        "longitude": String(longitudeVal)
      })
      .then(res => {
        onHide()
        onChange()
      })
    } else {
      // code
      createLocation({
        "name": nameVal, 
        "total_employee_count": employeeCountValue, 
        "latitude": String(latitudeVal), 
        "longitude": String(longitudeVal)
      })
      .then(res => {
        onHide()
        onChange()
      })
    } 
  }

  const RenderMap = React.useCallback(()=> (
    <div className='location-maps-wrapper'>
      {
        isDefault ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={{lat: latitudeVal, lng: longitudeVal}}
            zoom={3}
            options={{
              streetViewControl: false, 
              maxZoom: 3, 
              minZoom: 3, 
              zoom: 16,
              mapTypeControl: false,
            }}
            onLoad={onLoad}
            onUnmount={onUnmount}
            /> ) : (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={{lat: latitudeVal, lng: longitudeVal}}
            zoom={16}
            options={{
              streetViewControl: false, 
              maxZoom: 17, 
              minZoom: 3, 
              zoom: 16,
              mapTypeControl: false
            }}
            onLoad={onLoad}
            onUnmount={onUnmount}
            >
            { /* Child components, such as markers, info windows, etc. */ }
            <Marker
              draggable
              clickable
              position={{lat: latitudeVal, lng: longitudeVal}}
              onDragEnd={e => {
                setLatitudeVal(e?.latLng?.lat() || latitude)
                setLongitudeVal(e?.latLng?.lng() || longitude)
              }}
              />
          </GoogleMap> )
      }
      {
        !isDefault && 
        <div className='address-text-maps-wrapper'>
          <h6>{addressVal}</h6>
        </div>
      }
    </div>
    ), [latitudeVal, longitudeVal, addressVal, isDefault, isEdit])

  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header className='px-6' closeButton>
        <Modal.Title id='contained-modal-title-vcenter' style={{width: '80%'}}>
          <h4 className='m-0'>{title}</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body> 
        <h6 className='fs-5 mt-0'>Location Name</h6>
        <input
          placeholder='Enter location name'
          className='modal-input-field'
          value={nameVal}
          type='text'
          required
          onChange={(v) => {
            setNameVal(v.currentTarget.value)
          }}
        />
        {
          !isValidName &&
          <p className='mt-2 mb-0' style={{color: 'red', fontSize: '14px'}}>
            Location name is required
          </p>
        }
        <h6 className='fs-5 mt-6'>No. of Employees (Total)</h6>
        <input
          placeholder='Enter total employee count'
          className='modal-input-field'
          value={employeeCountValue}
          type='number'
          min="1"
          required
          onChange={(v) => {
            setEmployeeCountValue(v.currentTarget.value)
          }}
        />
        {
          !isValidEmployeeCount &&
          <p className='mt-2 mb-0' style={{color: 'red', fontSize: '14px'}}>
            Employee count should be greater than zero
          </p>
        }
        {/* <h6 className='fs-5 mt-6'>Latitude</h6>
        <input
          placeholder='Enter latitude of location'
          className='modal-input-field'
          value={latValue}
          type='number'
          step='any'
          required
          onChange={(v) => {
            setLatValue(v.currentTarget.value)
          }}
        />
        {
          !isValidLat &&
          <p className='mt-2 mb-0' style={{color: 'red', fontSize: '14px'}}>
            Latitude is required
          </p>
        }
        <h6 className='fs-5 mt-6'>Longitude</h6>
        <input
          placeholder='Enter longitude of location'
          className='modal-input-field'
          value={lngValue}
          type='number'
          step='any'
          required
          onChange={(v) => {
            setLngValue(v.currentTarget.value)
          }}
        />
        {
          !isValidLng &&
          <p className='mt-2 mb-0' style={{color: 'red', fontSize: '14px'}}>
            Longitude is required
          </p>
        } */}

        <h6 className='fs-5 mt-6'>Office Address  <span className='office-label-span'>(To {isEdit?'edit':'add'} type on search or drag the marker and save it)</span></h6>
        
        {
          isLoaded ? (
            <>
            <PlacesAutocomplete setLatitudeVal={setLatitudeVal} setLongitudeVal={setLongitudeVal} />
            {
              !isValidLat &&
              <p className='mt-2 mb-0' style={{color: 'red', fontSize: '14px'}}>
                Office location is required
              </p>
            }
            <RenderMap />
            </> 
            ) : (
              <h4>
                Fetching Map..
              </h4>
            ) 
        }

        

        <div className='mt-8 d-flex flex-end'>
          <button
            type='button'
            // disabled={!loading}
            className='custom-primary-delete-button m-0 py-2 px-3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={handleCreateEditLocation}
            >
            {
              isEdit ? 
              'Save' :
              'Add'
            }
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}