import React, {useState, useEffect} from 'react'
import DatePicker from 'react-datepicker';
import {useNavigate} from 'react-router-dom'
import mixpanel from 'mixpanel-browser'
import * as Yup from 'yup'
import {useFormik, useFormikContext} from 'formik'
import Form from 'react-bootstrap/Form'
import moment from 'moment'
import { useSelector } from 'react-redux'

import { 
  useLazyGetCustomGroupsListQuery, 
  usePostAttributesMutation 
} from '../../store/api';
import {
  createAttributesFormInitValues as initialValues,
} from '../../../../../_metronic/partials/components/types'
import { RoutesPath } from '../../../../routing/RoutesPath';
import { EVENT_TYPE, PAGES } from '../../../../analytics/tracker-types'
import { RootState, useAppDispatch } from '../../../../store/store'
import { setActiveLocationsList, setLiveCustomGroupsList, setLocationsList } from '../../../../store/slices/masterList';
import { AttributeWasteTypes, AttributeWasteUnitTypes, AttributesFormType } from '../types';

import "../styles.css"
import { getLocalStorageItem } from '../../../../utils/storage';
import { useLazyGetLocationsListQuery } from '../../../dashboard/store/api';

type Props = {

}

const attributeReqFormSchema = Yup.object().shape({
  waste_type: Yup.string().required('Waste type is required'),
  group_ids: Yup.array().test('isGroupIdsSelected', 'Group is required', (value, context) => {
    if (context.parent.group_ids.length > 0) return true;
    return false;
  }),
  group_type: Yup.string().required('Group type is required'),
  start_date: 
    Yup.date()
    .test('isValidStartDate', 'Start date is required', (value) => {
      if (!value) {
        return false;
      }
      return true;
    })
    .required('Start date is required'),
  end_date: 
    Yup.date()
    .test('isValidEndDate', 'End date is required', (value) => {
      if (!value) {
        return false;
      }
      return true;
    })
    .required('End date is required'),
  value: Yup.number().test('isUnitsGreaterThanZero','Value should be greater than zero',(value) => {
    if (value && value>0) {
      return true;
    }
    return false;
  }),
  unit_type: Yup.string().required('Unit type is required'),
})

const CreateAttributesPage: React.FC<Props> = () => {

  const navigate = useNavigate()
  const dispatch = useAppDispatch();
  const {profile} = useSelector((state:RootState) => state.profile)
  const {activeLocationsList} = useSelector((state: RootState) => state.masterList);
  
  const [isSubmitInProgress, setIsSubmitInProgress] = useState<boolean>(false)
  
  const [postAttributes, {error, isSuccess, isError}] = usePostAttributesMutation();
  const [getLocationsList, {data: locationsListData}] = useLazyGetLocationsListQuery()
  const [getCustomGroupList, {
    data: customGroupsListData, 
    isSuccess: isSuccessGetGroups, 
    isLoading: isLoadingGetGroups,
    isError: isErrorGetGroups,
  }] = useLazyGetCustomGroupsListQuery();
  
  useEffect(() => {
    // tracking
    mixpanel.track(EVENT_TYPE.PageView, {'name': PAGES.CreateAttributesPage})
  }, [])

  const formik = useFormik<AttributesFormType>({
    initialValues,
    validationSchema: attributeReqFormSchema,
    
    onSubmit: async (values) => {
      // workshop handling
      if (profile?.organization?.isWorkshopOrg) {
        return;
      }
      const defaultAttributeName = `${values.waste_type.slice(0,1).toUpperCase()}${values.waste_type.slice(1).toLowerCase()}`;
      await postAttributes({
        "name": values.name.length > 0 ? values.name : defaultAttributeName,
        "unit_type": values.unit_type,
        "value": values.value,
        "waste_type": values.waste_type,
        "group_type": values.group_type,
        "group_ids": values.group_ids,
        "start_date": moment(values.start_date).format("YYYY-MM-DD"),
        "end_date": moment(values.end_date).format("YYYY-MM-DD"),
      })
      .then(res => {
        setTimeout(() => navigate(RoutesPath.ATTRIBUTES.ROOT), 500)
      })
      .catch(err => {
        console.log(err)
      })
    },
  })

  const handleSubmit = () => {
    setIsSubmitInProgress(true);
    formik.handleSubmit();
    setIsSubmitInProgress(false)
  }

  useEffect(() => {
    if (activeLocationsList.length === 0) {
      getLocationsList({isActive: true}).then((res:any)=>{
        const arr: any[] = [];
        res?.data.forEach((itm:any) => {
          arr.push(itm)
        })
        dispatch(setActiveLocationsList(arr));
      })
    }
  }, [])

  return (
    <>
      {/* CONTENT */}
      <div className='blank-page-view pt-8 px-12'>
        <div className='back-header-btn'>
          <button 
            type='button' 
            className='p-2 back-left-arrow-btn-wrap'
            onClick={()=>{navigate(RoutesPath.ATTRIBUTES.ROOT)}}
            >
            <div className='back-left-arrow-btn' /> 
          </button>
          <h1 className='page-title-text m-0' style={{paddingLeft: '10px'}}>ALLOCATE WASTE TO EMPLOYEES</h1>
          {/* <h6 className='m-0 mx-4 fs-4 font-weight-normal'><u>Attributes</u></h6> */}
        </div>

        
        <form noValidate className='form pt-6'>

          <div className='card-body px-12'>
            
            <div className='col-12'>

              <div className='row mb-4'>
                <label htmlFor='location' className='col-form-label fw-bold fs-6'>
                  SELECT A LOCATION
                </label>
                <div className='col-6'>
                  <select
                    className='form-select'
                    style={{border: '1px solid #bababa', color: "#202020", borderRadius: '10px'}}
                    onChange={(event) => {
                      if (event.target.value !== '')
                        formik.setFieldValue('group_ids', [Number(event.target.value)])
                      else 
                        formik.setFieldValue('group_ids', [])
                    }}
                  >
                    <option value='' key='empty'>
                      Select a location
                    </option>
                    {activeLocationsList &&
                      activeLocationsList.map((group: any) => (
                        <option value={group.id} key={group.id}>
                          {group.name}
                        </option>
                      ))}
                  </select>

                  {formik.touched.group_ids && formik.errors.group_ids && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.group_ids}</div>
                    </div>
                  )}
                </div>
              </div>

              <div className='row mb-4'>
                <div className='col-6' style={{display: 'flex', flexDirection: 'column'}}>
                  <label htmlFor='startDate' className='col-form-label fw-bold fs-6'>
                    START DATE
                  </label>
                  <DatePicker
                    className='attributes-datepicker-styles'
                    placeholderText='DD/MM/YYYY'
                    dateFormat='dd/MM/YYYY'
                    selected={formik.values.start_date ? new Date(formik.values.start_date) : undefined} 
                    showMonthDropdown
                    showYearDropdown
                    minDate={new Date(getLocalStorageItem('live-on'))}
                    maxDate={formik.values.end_date ? new Date(formik.values.end_date) : new Date()}
                    onChange={(value) => formik.setFieldValue('start_date', value || '')}
                    // disabled={challengeData?.status && challengeData?.status === 'live'}
                    />
                  {
                    formik.touched.start_date && formik.errors.start_date && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.start_date}</div>
                      </div>
                    )
                  }
                </div>
                <div className='col-6' style={{display: 'flex', flexDirection: 'column'}}>
                  <label htmlFor='endDate' className='col-form-label fw-bold fs-6'>
                    END DATE
                  </label>
                  <DatePicker
                    className='attributes-datepicker-styles'
                    placeholderText='DD/MM/YYYY'
                    dateFormat='dd/MM/YYYY'
                    selected={formik.values.end_date ? new Date(formik.values.end_date) : undefined} 
                    showMonthDropdown
                    showYearDropdown
                    minDate={formik.values.start_date ? new Date(formik.values.start_date) : undefined}
                    maxDate={new Date()}
                    onChange={(value) => formik.setFieldValue('end_date', value || '')}
                    // disabled={challengeData?.status && challengeData?.status === 'live'}
                    />
                  {
                    formik.touched.end_date && formik.errors.end_date && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.end_date}</div>
                      </div>
                    )
                  }
                </div>
              </div>

              <div className='row mb-4'>
                <div className='col-6'>
                  <label htmlFor='energy' className='col-form-label fw-bold fs-6'>
                    SELECT
                  </label>
                  <select
                    className='form-select'
                    style={{border: '1px solid #bababa', color: "#202020", borderRadius: '10px'}}
                    {...formik.getFieldProps('waste_type')}
                  >
                    {Object.keys(AttributeWasteTypes).map((key:any) => (
                      <option key={key} value={key}>
                        {key.slice(0,1) + key.slice(1).toLowerCase()}
                      </option>
                    ))}
                  </select>

                  {formik.touched.waste_type && formik.errors.waste_type && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        {formik.errors.waste_type}
                      </div>
                    </div>
                  )}
                </div>

                <div className='col-6'>
                  <label htmlFor='att-name' className='col-lg-12 col-form-label fw-bold fs-6'>
                    WASTE NAME
                  </label>
                  <input
                    type='string'
                    className='form-control'
                    placeholder='For example: Waste'
                    id='att-name'
                    style={{border: '1px solid #bababa', color: "#202020", borderRadius: '10px'}}
                    {...formik.getFieldProps('name')}
                  />
                </div>
              </div>

              <div className='row mb-4'>
                <div className='col-6'>
                  <label htmlFor='value' className='col-lg-12 col-form-label fw-bold fs-6'>
                    VALUE
                  </label>
                  <input
                    type='number'
                    className='form-control'
                    placeholder='Value'
                    id='value'
                    pattern='[0-9]*'
                    min={0}
                    style={{border: '1px solid #bababa', color: "#202020", borderRadius: '10px'}}
                    {...formik.getFieldProps('value')}
                  />
                  {formik.touched.value && formik.errors.value && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.value}</div>
                    </div>
                  )}
                </div>

                <div className='col-6'>
                  <label htmlFor='energy' className='col-form-label fw-bold fs-6'>
                    UNITS
                  </label>
                  <div className='col-12'>
                    <select
                      className='form-select'
                      style={{border: '1px solid #bababa', color: "#202020", borderRadius: '10px'}}
                      {...formik.getFieldProps('unit_type')}
                    >
                      {
                        Object.keys(AttributeWasteUnitTypes).map(key => (
                          <option key={key} value={key}>
                            {key.slice(0,1) + key.slice(1).toLowerCase()}
                          </option>
                        )) 
                      }
                    </select>
                    {formik.touched.unit_type && formik.errors.unit_type && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.unit_type}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

            </div>
          </div>

        </form>

        <div className='px-8 py-8 w-100 d-flex justify-content-center'>
          <button 
            type='submit' 
            disabled={isSubmitInProgress}
            className='custom-primary-delete-button'
            style={{paddingLeft: '80px', paddingRight: '80px', alignSelf: 'center'}} 
            onClick={handleSubmit}
            >
            Submit
          </button>
        </div>

      </div>

   </>
  )
}

export default CreateAttributesPage;