const API_ENDPOINTS = {
  EMPLOYEES: '/employees',
  DISABLE_EMPLOYEE: '/disable-employee',
  LIMIT: 'limit',
  OFFSET: 'offset',
  SEARCH_NAME: 'search_name',
  SORT_BY: 'sort_by',
  SORT_ORDER: 'sort_order',
  DEPARTMENT_IDS: 'department_guids',
  POSITIONS: 'positions',
  DIVISIONS: 'divisions',
  DIVISION_IDS: 'division_guids',
  LOCATION_IDS: 'location_guids',
  DEPARTMENTS: '/departments',
  LOCATION: '/work-locations',
}

export default API_ENDPOINTS
