import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {DoneIcon, ErrorIcon} from '../../../assets/icons'
import {NotificationType} from '../types'
import {RootState, useAppDispatch} from '../../../../app/store/store'
import {updateNotification} from '../../../../app/store/slices/notifier'

const Notifier: React.FC = () => {
  const message = useSelector((state: RootState) => state.notifier.notification.message)
  const type = useSelector((state: RootState) => state.notifier.notification.type)

  const dispatch = useAppDispatch()
  useEffect(() => {
    const timer = setTimeout(() => {
      dispatch(
        updateNotification({
          message: null,
          type: null,
        })
      )
    }, 3000)
    return () => clearTimeout(timer)
  })

  if (!message) return null

  return (
    <div
      className='card p-0 position-fixed border border-1 shadow-sm border-gray-300 w-400px'
      style={{bottom: 36, left: 36, zIndex: 1050}}
    >
      <div className='card-body d-flex align-items-center gap-3 p-5'>
        {type === NotificationType.SUCCESS && (
          <DoneIcon className='svg-icon svg-icon-3x svg-icon-success' width='32px' height='32px' />
        )}
        {type === NotificationType.ERROR && (
          <ErrorIcon className='svg-icon svg-icon-3x svg-icon-danger' width='32px' height='32px' />
        )}
        <div className='fw-bold fs-4 text-gray-700'>{message}</div>
      </div>
    </div>
  )
}

export default Notifier
