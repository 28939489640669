import api from '../../../services/api'
import {APITags, MethodType} from '../../../services/constants'
import { queryStringBuilder } from '../../../utils/querStringBuilder'
import API_ENDPOINTS from './apiPaths'
import {UtilityBillList, AddUtilityBillRequestType} from '../types'

const customBaseUrl = `${process.env.REACT_APP_API_URL}`

const configurationsApis = api.injectEndpoints({
  endpoints: (builder) => ({
    getUtilityBillList: builder.query({
      query: (params) => {
        const queryParams = queryStringBuilder([])
        return {
          url: `${API_ENDPOINTS.GET_UTILITY_BILL_LIST}${queryParams ? `?${queryParams}` : ''}`,
          method: MethodType.get,
        }
      },
      transformResponse: (response: UtilityBillList) => response?.data,
    }),
    generateAuditReport: builder.query({
      query: (params) => {
        const queryList = [
          {name: 'start_date', value: params.start_date}, 
          {name: 'end_date', value: params.end_date}
        ]; 
        if (params?.org_tag_id !== 0) {
          queryList.push({name: 'org_tag_id', value: params.org_tag_id})
        }
        const queryParams = queryStringBuilder(queryList)
        return {
          url: `${API_ENDPOINTS.GENERATE_AUDIT_REPORT}${queryParams ? `?${queryParams}` : ''}`,
          method: MethodType.get,
        }
      },
    }),
    getAuditReports: builder.query({
      query: (params) => {
        const queryList = []; 
        if (typeof params.id !== "undefined") {
          queryList.push({name: 'id', value: params.id})
        }
        const queryParams = queryStringBuilder(queryList)
        return {
          url: `${API_ENDPOINTS.REPORT}${queryParams ? `?${queryParams}` : ''}`,
          method: MethodType.get,
        }
      },
    }),
    deleteAuditReport: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.REPORT}/${params.reportId}`, 
        method: MethodType.delete,
      }),
    }),
    getLocationWiseEmployeeCount: builder.query({
      query: (params) => {
        const queryParams = queryStringBuilder([])
        return {
          url: `${API_ENDPOINTS.LOCATION_WISE_EMPLOYEE_COUNT}/${params.work_location_id}`,
          method: MethodType.get,
        }
      },
    }),
    getCustomTagList: builder.query({
      query: () => ({
        url: `${API_ENDPOINTS.TAG}`,
        method: MethodType.get,
      }),
    }),
    postCustomTag: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.TAG}`,
        data: {...params},
        method: MethodType.post,
      }),
    }),
    updateCustomTag: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.TAG}/${params.id}`,
        data: {
          "name": params.name,
          "abbreviation_name": params.abbreviation_name,
          "color_hex_code": params.color_hex_code,
          "employee_ids": params.employee_ids,
          "is_archive": params?.is_archive === 1 ? 1 : 0,
        },
        method: MethodType.put,
      }),
    }),
    deleteCustomTag: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.TAG}/${params.tagId}`, 
        method: MethodType.delete,
      }),
    }),
    getCustomGroupsList: builder.query({
      query: () => ({
        url: `${API_ENDPOINTS.CUSTOM_GROUP}`,
        method: MethodType.get,
      }),
    }),
    postCustomGroup: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.CUSTOM_GROUP}`,
        data: {...params},
        method: MethodType.post,
      }),
    }),
    updateCustomGroup: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.CUSTOM_GROUP}/${params.id}`,
        data: {...params},
        method: MethodType.put,
      }),
    }),
    deleteCustomGroup: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.CUSTOM_GROUP}/${params.groupId}`, 
        method: MethodType.delete,
      }),
    }),
    getChallengesList: builder.query({
      query: () => ({
        url: `${API_ENDPOINTS.CHALLENGE}`,
        method: MethodType.get,
      })
    }),
    getChallengeById: builder.query({
      query: (params) => ({
        url: `${API_ENDPOINTS.CHALLENGE}/${params.id}`,
        method: MethodType.get,
      })
    }),
    getChallengesSummary: builder.query({
      query: (params) => ({
        url: `${API_ENDPOINTS.CHALLENGES_SUMMARY}`,
        method: MethodType.get
      })
    }),
    postChallenge: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.CHALLENGE}`,
        data: {...params},
        method: MethodType.post
      })
    }),
    updateChallenge: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.CHALLENGE}/${params.id}`,
        data: {...params.payload},
        method: MethodType.put
      })
    }),
    deleteChallenge: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.CHALLENGE}/${params.id}`,
        method: MethodType.delete
      })
    }),
    getQuizzesList: builder.query({
      query: (params) => ({
        url: `${API_ENDPOINTS.QUIZZES}/${params.skip}/${params.limit}`,
        method: MethodType.get,
      })
    }),
    getQuizById: builder.query({
      query: (params) => ({
        url: `${API_ENDPOINTS.QUIZZES}/${params.id}`,
        method: MethodType.get,
      })
    }),
    getQuizzesSummary: builder.query({
      query: (params) => ({
        url: `${API_ENDPOINTS.QUIZZES_SUMMARY}`,
        method: MethodType.get,
      })
    }),
    postAttributes: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.WASTE}`,
        data: {...params},
        method: MethodType.post
      })
    }),
    getAttributes: builder.query({
      query: () => ({
        url: `${API_ENDPOINTS.WASTE}`,
        method: MethodType.get
      })
    }),
    getAttributesById: builder.query({
      query: (params) => ({
        url: `${API_ENDPOINTS.WASTE}/${params.id}`,
        method: MethodType.get
      })
    }),
    postAddUtilityBill: builder.mutation<void, AddUtilityBillRequestType>({
      query: (params) => ({
        url: `${API_ENDPOINTS.ADD_UTILITY_BILL}`,
        data: {...params},
        method: MethodType.post,
      }),
    }),
    updateOrgEmployeeCount: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.UPDATE_ORG_EMPLOYEE_COUNT}`, 
        data: {...params}, 
        method: MethodType.put, 
      })
    }), 
    postUpdateDivision: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.UPDATE_DIVISION}`, 
        data: {...params}, 
        method: MethodType.put, 
      })
    }), 
    postUpdateDepartment: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.UPDATE_DEPARTMENT}`, 
        data: {...params}, 
        method: MethodType.put, 
      })
    }), 
    postUpdateLocation: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.UPDATE_WORK_LOCATION}`, 
        data: {...params}, 
        method: MethodType.put, 
      })
    }),
    postCreateLocation: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.CREATE_WORK_LOCATION}`, 
        data: {...params}, 
        method: MethodType.post, 
      })
    }), 
    postOrgUploadImage: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.UPLOAD_IMAGE}`, 
        data: params, 
        method: MethodType.post,
      })
    }),
  }),
})

export const {
  usePostOrgUploadImageMutation,
  useLazyGetQuizzesListQuery,
  useLazyGetQuizByIdQuery,
  useLazyGetQuizzesSummaryQuery,
  useLazyGetUtilityBillListQuery, 
  useLazyGenerateAuditReportQuery,
  useLazyGetAuditReportsQuery,
  useDeleteAuditReportMutation,
  usePostAddUtilityBillMutation, 
  useLazyGetLocationWiseEmployeeCountQuery, 
  usePostCustomTagMutation,
  useLazyGetCustomTagListQuery,
  useUpdateCustomTagMutation,
  useDeleteCustomTagMutation,
  usePostCustomGroupMutation,
  useLazyGetCustomGroupsListQuery,
  useUpdateCustomGroupMutation,
  useDeleteCustomGroupMutation,
  usePostChallengeMutation,
  useLazyGetChallengesListQuery,
  useLazyGetChallengeByIdQuery,
  useLazyGetChallengesSummaryQuery,
  useUpdateChallengeMutation,
  useDeleteChallengeMutation,
  useLazyGetAttributesQuery,
  useLazyGetAttributesByIdQuery,
  usePostAttributesMutation,
  useUpdateOrgEmployeeCountMutation, 
  usePostUpdateDivisionMutation, 
  usePostUpdateDepartmentMutation, 
  usePostUpdateLocationMutation, 
  usePostCreateLocationMutation,
} = configurationsApis