import {createSlice} from '@reduxjs/toolkit'

type mastertListType = {
  departmentsList: any[],
  divisionsList: any[],
  locationsList: any[],
  activeDepartmentsList: any[],
  activeDivisionsList: any[],
  activeLocationsList: any[],
  liveCustomGroupsList: any[],
}

const initialState: mastertListType = {
  departmentsList: [], 
  divisionsList: [],
  locationsList: [],
  activeDepartmentsList: [],
  activeDivisionsList: [],
  activeLocationsList: [],
  liveCustomGroupsList: []
}

export const masterListSlice = createSlice({
  name: 'materList',
  initialState,
  reducers: {
    setDepartmentsList: (state, action) => {
      state.departmentsList = action.payload;
    },
    setDivisionsList: (state, action) => {
      state.divisionsList = action.payload;
    },
    setLocationsList: (state, action) => {
      state.locationsList = action.payload;
    },
    setActiveDepartmentsList: (state, action) => {
      state.activeDepartmentsList = action.payload;
    },
    setActiveDivisionsList: (state, action) => {
      state.activeDivisionsList = action.payload;
    },
    setActiveLocationsList: (state, action) => {
      state.activeLocationsList = action.payload;
    },
    setLiveCustomGroupsList: (state, action) => {
      state.liveCustomGroupsList = action.payload;
    }
  },
})

export const {
  setDepartmentsList, 
  setDivisionsList, 
  setLocationsList,
  setActiveDepartmentsList,
  setActiveDivisionsList,
  setActiveLocationsList,
  setLiveCustomGroupsList
} = masterListSlice.actions

export default masterListSlice.reducer