import clsx from 'clsx'
import {Link, useLocation} from 'react-router-dom'
import {LeftCollapseIcon, MenuHamburgerIcon, RightExpandIcon} from '../../../assets/icons'
import {toAbsoluteUrl} from '../../../helpers'
import {ModifySVG} from '../../../helpers/components/ModifySVG'
import {useLayout} from '../../core'
import {HeaderToolbar} from './HeaderToolbar'
import {HeaderToolbarSettings} from './HeaderToolbarSettings'

type MonthSelectCallback = (month: string) => void

// interface HeaderWrapperProps {
//   handleMonthSelect: MonthSelectCallback
// }

export function HeaderWrapper() {
  const {config, classes, attributes} = useLayout()
  const {aside} = config

  const location = useLocation()
  // if (!location.pathname === '/dashboard') {

  // }
  return (
    <div
      id='kt_header'
      className={clsx('header border-btm-header', classes.header.join(' '))}
      {...attributes.headerMenu}
    >
      {/* begin::Brand */}
      <div className='header-brand' style={{backgroundColor: '#181c32'}}>
        {/* begin::Logo */}
        <Link to='/dashboard'>
          <img
            alt='Logo'
            src={toAbsoluteUrl('/media/logos/mynzo.svg')}
            className='h-25px h-lg-25px mt-2'
          />
        </Link>
        {/* end::Logo */}

        {aside.minimize && (
          <div
            id='kt_aside_toggle'
            className='btn btn-icon w-auto px-0 btn-active-color-primary aside-minimize'
            data-kt-toggle='true'
            data-kt-toggle-state='active'
            data-kt-toggle-target='body'
            data-kt-toggle-name='aside-minimize'
          >
            <ModifySVG className='svg-icon-2 me-n1 minimize-default'>
              <LeftCollapseIcon />
            </ModifySVG>
            <ModifySVG className='svg-icon-2 minimize-active'>
              <RightExpandIcon />
            </ModifySVG>
          </div>
        )}

        {/* begin::Aside toggle */}
        <div className='d-flex align-items-center d-lg-none ms-n3 me-1' title='Show aside menu'>
          <div
            className='btn btn-icon btn-active-color-primary w-30px h-30px'
            id='kt_aside_mobile_toggle'
          >
            <ModifySVG className='svg-icon-1'>
              <MenuHamburgerIcon />
            </ModifySVG>
          </div>
        </div>
        {/* end::Aside toggle */}
      </div>
      {/* end::Brand */}
      {(location.pathname.match('/dashboard/*') || location.pathname.match('/details/*') || location.pathname.match('/leaderboard/*') )? (
        <HeaderToolbar />
      ) : (
        <HeaderToolbarSettings />
      )}
    </div>
  )
}
