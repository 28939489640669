/* eslint-disable global-require */
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import mixpanel from 'mixpanel-browser'
import {useLazyLoginQuery} from '../store/api'
import {setLocalStorageItem} from '../../../utils/storage'
import {RoutesPath} from '../../../routing/RoutesPath'
import {updateToken} from '../../../store/slices/token'
import {TextCarousel} from './TextCarouselLogin'
import { PageTitle } from '../../../routing/RoutesPageTitle'
import { RootState } from '../../../store/store'
import { EVENTS, EVENT_TYPE, PAGES } from '../../../analytics/tracker-types'
import './login.css'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Minimum 8 characters')
    .max(50, 'Maximum 50 characters')
    .required('Password is required'),
})

const initialValues = {
  email: '',
  password: '',
}

export function Login() {
  const [login, {isLoading}] = useLazyLoginQuery()
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const intl = useIntl()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userEmail = useSelector((state: RootState) => state.profile.profile?.email)
  const orgName = useSelector((state: RootState) => state.profile.profile?.organization.name)

  // update page title
  useEffect(() => {
    document.title = PageTitle.LOGIN
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setErrorMessage(null)
      const response = await login({
        email: values.email.trim(),
        password: values.password,
      })
        .unwrap()
        .catch((err) => {
          // setErrorMessage(intl.formatMessage({id: 'LOGIN.ERROR_MESSAGE'}))
          setErrorMessage(err.data.message)
        })

      if (response?.access_token) {
        setLocalStorageItem('token', response.access_token)
        setLocalStorageItem('refresh-token', response.refresh_token)
        setLocalStorageItem('live-on', response.live_on)
        dispatch(updateToken(response.access_token))
        // tracking
        mixpanel.identify(values.email);
        mixpanel.people.set({
          "$email": values.email.toLowerCase().trim()
        })
        mixpanel.track(EVENTS.Logged_In)
        navigate(RoutesPath.DASHBOARD)
      }
    },
  })

  const carouselTexts = [
    <>
      2023 is set to be the hottest year ever recorded on Earth—and likely the hottest in about <span className='highlighted-blue'>120,000 years</span>
    </>, 
    <>
      As of August 2023, there are over <span className='highlighted-blue'>700 companies</span> that have committed to achieving net zero carbon emissions by <span className='highlighted-blue'>2050 or sooner.</span>
    </>, 
    <>
      Mynzo platform provides insights on employee emissions, guiding reductions via prompts, and offsetting the rest through <span className='highlighted-blue'>reforestation.</span>
    </>
  ]

  return (
    <div className="login-page">
      <div className="login-form-div">
        <img 
          className='logo-img-1'
          src={require('../../../../_metronic/assets/images/mynzoLoginLogo.png')} 
          alt='mynzo-logo-1' 
          />
        <form 
          onSubmit={formik.handleSubmit}
          noValidate
          id='kt_login_signin_form'
          className='login-form'
          >
          <h1>Welcome!</h1>
          {!!formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}
          <label htmlFor="username">Email</label>
          <input 
            type="email" 
            id="email" 
            autoComplete='off'
            placeholder='Enter your email'
            {...formik.getFieldProps('email')}
            className={clsx('', {
            'is-invalid': formik.touched.email && formik.errors.email,
            })} 
          />
          {formik.touched.email && formik.errors.email && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.email}</span>
              </div>
            </div>
          )}
          <label htmlFor="password" className='label-pwd'>Password</label>
          <input 
            type="password" 
            id="password" 
            placeholder='Enter your password'
            autoComplete='off'
            {...formik.getFieldProps('password')}
              className={clsx('', {
              'is-invalid': formik.touched.password && formik.errors.password,
            })}
            />
          {formik.touched.password && formik.errors.password && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{formik.errors.password}</span>
              </div>
            </div>
          )}
          {/* <Link
            to='/auth/forgot-password'
            className='link-primary fs-6 fw-bolder'
            style={{marginLeft: '5px'}}
          >
            Forgot Password?
          </Link> */}
          <button 
            type='submit'
            id='kt_sign_in_submit'
            // disabled={formik.isSubmitting || !formik.isValid}
            >
            {!isLoading && <span className='indicator-label'>Continue</span>}
            {isLoading && (
              <span className='indicator-progress' style={{display: 'block'}}>
                {/* Please wait... */}
                <span className='spinner-border spinner-border-sm align-middle ms-2' />
              </span>
            )}
          </button>
          {!!errorMessage && (
            <div className='fv-plugins-message-container'>
              <div className='fv-help-block'>
                <span role='alert'>{errorMessage}</span>
              </div>
            </div>
          )}
        </form>
      </div>
      <TextCarousel texts={carouselTexts} />
    </div>
  )
}

/* eslint-disable global-require */
