import {FC} from 'react'
import { EmissionTrendLineChartForAllMonths } from '../../../_metronic/partials/components/chart/EmissionTrendLineChartForAllMonths'
import { ActivityEmission } from './type'

type EmissionTrendLineForAllMonths = {
  locationType: string
  topValue: string
}

const EmissionTrendLineForAllMonths: FC<EmissionTrendLineForAllMonths> = ({locationType, topValue}) => (
  <EmissionTrendLineChartForAllMonths
    locationType={locationType}
    topValue={topValue}
  />
)

export {EmissionTrendLineForAllMonths}
