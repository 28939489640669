/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useCallback, useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import { useSelector } from 'react-redux'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import '../styles.css'
import {ChartValueTypes} from '../../../../app/modules/dashboard/type'
import {getLocalStorageItem} from '../../../../app/utils/storage'
import { RootState } from '../../../../app/store/store'
import { commaSeparatedFormat } from '../../../../app/utils/helpers'

function getChartOptions(_height: number, isOnlyMonth: boolean): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')

  return {
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 350,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
    },
    plotOptions: {},
    legend: {
      show: !isOnlyMonth,
      onItemClick: {
        toggleDataSeries: false,
      },
      // onItemHover: {
      //   highlightDataSeries: false,
      // },
      markers: {
        width: 20,
        height: 2,
        strokeColor: borderColor,
        strokeWidth: 0,
        fillColors: ['#F28881', "#fff"],
        offsetX: 0,
        offsetY: 0,
        radius: 0,
        customHTML: () =>
          `<div style="width: 20px;height: 2px;border-top: 2px dashed ${'#F28881'};"></div>`,
      },
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: ['gradient', ''],
      // opacity: 1,
      gradient: {
        opacityFrom: 0.7,
        opacityTo: 0.2,
      }
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: [3, 2],
      colors: ['#F28881', '#F29981'],
      dashArray: [0, 8],
    },
    xaxis: {
      tickAmount: 10,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        // formatter: (val) => `${val.slice(0, -3)}`
      },
      crosshairs: {
        show: false,
      },
      tooltip: {
        enabled: false,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      tickAmount: 4,
      title: {
        text: 'kgCO2',
        style: {
          color: labelColor,
          fontSize: '12px',
        },
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
        formatter: (val) => `${commaSeparatedFormat(parseFloat(val?.toFixed(1)))}`
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      custom: (itm) => {
        const curMonth = itm.w.globals.seriesNames[0].split(' ')[2]
        const compMonth = itm.w.globals.seriesNames[1].split(' ')[2]
        const curMonthIndex = new Date(Date.parse(`${curMonth} 1, 2023`)).getUTCMonth() + 1
        const compMonthIndex = new Date(Date.parse(`${compMonth} 1, 2023`)).getUTCMonth() + 1
        const data1 = commaSeparatedFormat(itm.series[0][itm.dataPointIndex])
        const currDate = new Date()
        // if (
        //   itm.dataPointIndex + 1 > currDate.getUTCDate() &&
        //   curMonthIndex >= currDate.getUTCMonth() + 1
        // ) {
        //   data1 = '&nbsp -'
        // } 
        // else {
        //   data1 += '&nbsp kgCO2'
        // }

        const data2 = commaSeparatedFormat(itm.series[1][itm.dataPointIndex])
        // data2 += '&nbsp kgCO2'
        // if (
        //   itm.series[1][itm.dataPointIndex] !== undefined &&
        //   !Number.isNaN(itm.series[1][itm.dataPointIndex]) &&
        //   itm.series[0][itm.dataPointIndex] !== undefined &&
        //   !Number.isNaN(itm.series[0][itm.dataPointIndex])
        // ) {
          return `<div>
                  <h5 style="background-color:  #d8d8d8; padding:5px">Emissions (kgCO2)</h5>
                  <div style="padding:5px 10px">
                  <h6 className="no-bold">${
                    itm.dataPointIndex + 1
                  } ${curMonth} : <span  className="no-bold">${data1}</span></h6>
                  ${
                    (!isOnlyMonth && !Number.isNaN(itm.series[1][itm.dataPointIndex]))?
                    `<h6>${
                      itm.dataPointIndex + 1
                    } ${compMonth} : <span className="no-bold">${data2}</span></h6>`
                    : ''
                  }
                   </div>
                </div>`
        // } else if (
        //   itm.series[1][itm.dataPointIndex] !== undefined &&
        //   !Number.isNaN(itm.series[1][itm.dataPointIndex])
        // ) {
        //   return ` <div>
        //           <h5 style="background-color:  #d8d8d8; padding:5px">Emissions (kgCO2)</h5>
        //           <div style="padding:5px 10px">
        //           <h6>${
        //             itm.dataPointIndex + 1
        //           } June : <span className="no-bold">${data2}</span></h6>       
        //           </div>
        //         </div>`
        // } else {
        //   return ` <div>
        //           <h5 style="background-color:  #d8d8d8; padding:5px">Emissions (kgCO2)</h5>
        //           <div style="padding:5px 10px">
        //           <h6 className="no-bold">${
        //             itm.dataPointIndex + 1
        //           } July : <span  className="no-bold">${data1}</span></h6>
        //         </div>
        //         </div>`
        // }
      },
      style: {
        fontSize: '12px',
      },
      marker: {
        show: false,
      },
    },
    colors: ['#F28881', 'rgba(255, 255, 255, 0)'],
    grid: { 
      borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: ['#F28881', '#F28881'],
      strokeWidth: 3,
    },
  }
}

const EmissionTrendsChart: FC<
  ChartValueTypes & {isOnlyMonth: boolean}
> = (props) => {
  const {series, categories, disableAnimation, isOnlyMonth} = props
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const labelColor = getCSSVariableValue('--bs-gray-500')

  const resizeArray = (array: any, newSize: any) => {
    const changeSize = newSize - array.length
    if (changeSize > 0) {
      return array.concat(Array(changeSize).fill(undefined))
    }
    return array.slice(0, newSize)
  }

  series[0].data = resizeArray(series[0].data, 31)
  series[1].data = resizeArray(series[1].data, 31)

  const refreshMode = useCallback(() => {
    if (!chartRef.current || !series) {
      return undefined
    }
    const height = parseInt(getCSS(chartRef.current, 'height'), 10)
    const values = getChartOptions(height, isOnlyMonth)
    const options = {
      ...values,
      series,
      xaxis: {...values.xaxis, categories, tickAmount: 5},
      ...(disableAnimation && {
        chart: {
          ...values.chart,
          animations: {
            enabled: false,
          },
        },
      }),
    }

    options.yaxis = {
      ...options.yaxis,
      labels: {
        formatter: (value) => `${value}`,
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    }

    const chart = new ApexCharts(chartRef.current, options)
    if (chart) {
      chart.render()
    }
    return chart
  }, [series, categories, disableAnimation])

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, refreshMode])

  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
  }, [])

  return (
    <div className='card-body pt-4'>
      {/* begin::Chart */}
      <div ref={chartRef} id='kt_charts_widget_3_chart' />
      {/* end::Chart */}
      {/* <div className='tips-container py-4 px-6 ml-2 mt-4 mb-2'>
        <div>
          <span className='tips-text'>{`Here's what you can do`}</span>
        </div>
        <img src={NextIcon} alt='' />
      </div> */}
    </div>
  )
}

export {EmissionTrendsChart}
