import React, {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import {useDropzone} from 'react-dropzone'

import { useUploadOrganizationLogoMutation } from './store/api'
import {UploadImageIcon} from '../../../_metronic/assets/icons'
import { FileIcon } from '../../../_metronic/assets/icons'
import { RootState } from '../../store/store'

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  alignSelf: 'center',
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
}

interface FileWithPreview extends File {
  preview: string
}

function ImageUploadModal(props: any) {
  const {show, onHide, onSave} = props

  const {profile} = useSelector((state: RootState) => state.profile)
  const [files, setFiles] = useState<FileWithPreview[]>([])
  const [isWrongImage, setIsWrongImage] = useState<boolean>(false)

  const [uploadOrganizationLogo, {
    data: uploadOrgLogoData, 
    isLoading: uploadOrgLogoLoading, 
    isSuccess: uploadOrgLogoSuccess, 
    isUninitialized: uploadOrgLogoUninitialized,
    isError: uploadOrgLogoError,
  }] = useUploadOrganizationLogoMutation();

  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
    },
  })

  const thumbs = files.map((file) => (
    <div className='thumb w-100 mt-10 mb-0 d-flex align-items-center justify-content-evenly' key={file.name}>
      {/* <div style={thumbInner}> */}
        <img
          src={file.preview}
          style={img}
          alt=''
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview)
          }}
        />
        <div className='d-flex align-items-center'>
          <FileIcon style={{color: '#666666', height: '24px', width: '24px'}} />
          <p className='mb-0 w-100 upload-csv-file-name'>{file.name}</p>
        </div>
      {/* </div> */}
    </div>
  ))

  const handleOrgLogoUpload = () => {
    // workshop handling
    if (profile?.organization?.isWorkshopOrg) {
      return;
    }
    if (files.length!==0) {
      const orgLogoData = new FormData()
      orgLogoData.append('image', files[0])
      setIsWrongImage(false)
      if (files[0].name.endsWith('.png') || files[0].name.endsWith('.jpg') || files[0].name.endsWith('.jpeg')) {
        uploadOrganizationLogo(orgLogoData)
      } else {
        setIsWrongImage(true)
      } 
    } 
  }

  useEffect(() => files.forEach((file) => URL.revokeObjectURL(file.preview)), [])

  useEffect(() => {
    if (uploadOrgLogoSuccess) {
      onHide();
      onSave();
    }
  }, [uploadOrgLogoSuccess])

  useEffect(() => {
    if (!show) {
      setFiles([])
      setIsWrongImage(false)
    }
  }, [show])
  
  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <h3>Edit Company Logo</h3>
          <h6 className='no-bold'>You can upload your company logo when you want</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* <div {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} />
          <UploadImageIcon />
          <p>{`Drag 'n' drop some files here, or click to select files`}</p>
          <Button className='custom-btn-modal' onClick={onHide}>
            Click here to uploadd file
          </Button>
        </div> */}
        <div {...getRootProps({className: 'dropzone'})}>
          <input {...getInputProps()} accept="image/png, image/jpg, image/jpeg" />
          <p>
            DRAG & DROP HERE <br />
            OR <br />
            CLICK TO UPLOAD
          </p>
        </div>
        <aside className='thumbsContainer'>{thumbs}</aside>
      </Modal.Body>
      {
        (!uploadOrgLogoUninitialized && uploadOrgLogoLoading) && 
        <p style={{color: 'green', alignSelf: 'center'}}>Uploading...</p>
      }
      {
        (uploadOrgLogoError) && 
        <p style={{color: 'red', alignSelf: 'center'}}>Something went wrong!</p>
      }
      {
        (isWrongImage) && 
        <p style={{color: 'red', alignSelf: 'center'}}>Please select an image of png, jpg or jpeg format!</p>
      }
      <Button className='custom-btn-modal' onClick={handleOrgLogoUpload} disabled={(files.length===0)}>
        Save Changes
      </Button>
    </Modal>
  )
}

export default ImageUploadModal
