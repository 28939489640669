/* eslint-disable global-require */
import React from 'react'
import {useNavigate} from 'react-router-dom'

import { toAbsoluteUrl } from '../../../_metronic/helpers';
import { RoutesPath } from '../../routing/RoutesPath';
import './styles.css';

type Props = {

}

const EmptyEmployeesPage: React.FC<Props> = () => {
  const navigate = useNavigate()
  return (
    <div className='blank-page-view py-8 px-12'>
      <div className='centered-div-wrap'>
        <img src={toAbsoluteUrl('/media/illustrations/empty/EmployeesEmpty.png')} alt="no_utility_bill" />
        <h1 className='fs-1 text-center mt-10 bold'>Oops! Looks like no one is here yet!</h1>
        <p className='fs-5 mt-5 mb-0' style={{color: '#666666'}}>Upload employee CSV so that we can track your data.</p>
        <p className='fs-5 mt-0 mb-5' style={{color: '#666666'}}>You can then view employee details on your organization dashboard.</p>
        <button
            type='button'
            // disabled={!loading}
            className='custom-primary-delete-button'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={()=>{navigate(RoutesPath.EMPLOYEES.UPLOAD_CSV)}}
          >
          Upload Employee CSV
        </button>
        <p className='fs-5 mt-5 mb-0' style={{color: '#666666'}}>Don’t have a CSV file?</p>
        <button 
          type='button' 
          className='fs-5 m-0 anchor-blue-p-link' 
          onClick={()=>{navigate(RoutesPath.EMPLOYEES.ADD_EMPLOYEE)}}
          >
          Add individual employee details
        </button>
      </div>
      <div className='cloud-upload-bg bg-image-bottom-right' />
    </div>
  )
}

export default EmptyEmployeesPage
/* eslint-disable global-require */