import {createSlice} from '@reduxjs/toolkit'
import {ProfileData} from '../../../_metronic/layout/types'

export type ProfileStoreType = {
  profile: ProfileData | null
}

const initialState: ProfileStoreType = {
  profile: null,
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateProfile: (state, action) => {
      state.profile = action.payload
    },
  },
})

export const {updateProfile} = profileSlice.actions

export default profileSlice.reducer
