import moment from "moment"
import { CustomGroupCategoryType } from "../../../../store/slices/filters"
import { CustomGroupIcon, EditPenIcon, TrashIcon2 } from "../../../../../_metronic/assets/icons"
import { tagsDarkColorShade } from "../../../../utils/helpers"

type CustomGroupsListUsersType = {
  id: number
  name: string
  email: string
}

type groupDataType = {
  id: number
  name: string
  description: string
  status: CustomGroupCategoryType
  colorCode: string
  startDate: string
  endDate: string | null
  users: CustomGroupsListUsersType[]
}

type propsType = {
  groupData: groupDataType
  showModal: Function
  showDeleteModal: Function
}

export const CardCustomGroups = (props: propsType) => {
  const {
    groupData,
    showModal,
    showDeleteModal
  } = props;

  return (
    <div key={groupData.id} className="bordered-grey card-custom-groups-div">
      <div className="card-custom-groups-header-div d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <CustomGroupIcon 
            color={tagsDarkColorShade[groupData.colorCode]}
            height={24}
            width={24}
            style={{marginRight: '10px'}}
            />
          <div style={{maxWidth: '190px'}}>
            <h2 className='d-flex fs-3 m-0 bold align-items-center position-relative' style={{color: "#5C5C5C", paddingRight: '10px'}}>
              {groupData.name}
              {
                groupData.status === "live" &&
                <span className="card-custom-group-live-indicator" />
              }
            </h2>
            <p className='fs-6 m-0' style={{color: "#636363"}}>{groupData.users.length} members</p>
          </div>
        </div>
        {
          groupData.status !== "completed" ?
          <div>
            <button
              type="button"
              className="remove-btn-styles"
              onClick={() => {
                setTimeout(showModal(groupData), 200);
              }}
              >
              <EditPenIcon 
                color="#84C0D2"
                height={22}
                width={22}
                style={{padding: '2px'}}
                />
            </button>
            {
              groupData.status === "upcoming" &&
              <button
                type="button"
                className="remove-btn-styles"
                style={{marginLeft: '5px'}}
                onClick={() => {
                  setTimeout(showDeleteModal(groupData.id), 200);
                }}
                >
                <TrashIcon2 
                  color="#84C0D2"
                  height={26}
                  width={26}
                  style={{padding: '2px'}}
                  />
              </button>
            }
          </div> : 
          <div />
        }
      </div>
      <div className="card-custom-groups-body-div">
        <p className='fs-6 m-0' style={{color: "#1A1A1A"}}>{groupData.description}</p>
        <div className="d-flex my-4">
          <p className='fs-6 m-0' style={{color: '#636363'}}>{moment(groupData.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</p>
          {
            groupData.endDate !== null &&
            <p className='fs-6 m-0 mx-1' style={{color: '#636363'}}>- {moment(groupData.endDate, 'YYYY-MM-DD').format('DD/MM/YYYY')}</p>
          }
        </div>
        <div className="d-flex flex-wrap">
          {
            groupData.users.slice(0, 3).map((user: CustomGroupsListUsersType) => (
              <div key={user.id} className="card-custom-groups-employee-badge">
                <p className="m-0">{user.name}</p>
              </div>
            ))
          }
          {
            groupData.users.length>3 &&
            <h2 className="m-0">...</h2>
          }
        </div>
      </div>
    </div>
  )
}