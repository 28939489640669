import moment from "moment"
import { string } from "yup"
// import 

export const getYYYYDDMMFormat = (date: Date) => {
  // const mom = 
  const year = date.toLocaleString('en-US', {year: 'numeric'})
  const month = date.toLocaleString('en-US', {month: '2-digit'})
  const day = date.toLocaleString('en-US', {day: '2-digit'})
  // Generate yyyy-mm-dd date string
  const formattedDate = `${year}-${month}-${day}`
  return formattedDate
}

export const getMMMYYFormat = (date: Date) => {
  const year = date.toLocaleString('en-US', {year: 'numeric'})
  const month = date.toLocaleString('en-US', {month: 'short'})

  // Generate mmm yy date string
  const formattedDate = `${month} '${year.substring(2, 4)}`
  return formattedDate
}

export const getMMFormat = (date: Date) => {
  const month = date.toLocaleString('en-US', {month: 'short'})

  // Generate mm date string
  const formattedDate = `${month}`
  return formattedDate
}

export const getDDMonthYYYYFormat = (date: Date) => {
  const year = date.toLocaleString('en-US', {year: 'numeric'})
  const month = date.toLocaleString('en-US', {month: 'short'})
  const day = date.toLocaleString('en-US', {day: '2-digit'})

  // Generate dd mmm yyyy date string
  const formattedDate = `${day} ${month} ${year}`
  return formattedDate
}

export const getNetZeroPeriod = (date: string) => {
  const endDate = new Date(date)
  const currentDate = new Date()
  const months =
    (endDate.getUTCFullYear() - currentDate.getUTCFullYear()) * 12 -
    currentDate.getUTCMonth() +
    endDate.getUTCMonth()
  if (months >= 12) return `${Math.ceil(months / 12)} years`
  if (months >= 1) return `${months} months`
  return 'Less than a month'
}

function getCurrentYear(): number {
  const currentDate = new Date()
  return currentDate.getUTCFullYear()
}

function getCurrentMonth(): number {
  const currentDate = new Date()
  return currentDate.getUTCMonth() + 1
}

function getMonthYearString(year: number, month: number): string {
  const monthName = new Date(year, month - 1).toLocaleString('en-us', {month: 'long'})
  return `${monthName} ${year}`
}

// GENERATES CATEGORIES(X-AXIS LABELS) FOR GRAPHS
function generateAllMonthsKeys2(liveOnDate:string, year:string, isWorkshop?: boolean | undefined): string[] {
  const monthsKeys: string[] = []
  const startYear = new Date(liveOnDate).getUTCFullYear(); 
  const currentYear = isWorkshop ? 2030 : new Date().getUTCFullYear();

  if (year === 'All') {
    let y = startYear; 
    while (y <= currentYear) {
      for (let month = 1; month <= 12; month += 1) {
        monthsKeys.push(getMonthYearString(y, month))
      }
      y += 1;
    }
  } else {
    for (let month = 1; month <= 12; month += 1) {
      monthsKeys.push(getMonthYearString(Number(year), month))
    }
  }
  // console.log('monthKeys...', monthsKeys)
  return monthsKeys
}

export function convertDailyToMonthlyWithActivities(data: any, liveOnDate: any, year: string, isWorkshop?: boolean | undefined) {
  const monthlyDataMap: Map<string, any> = new Map()
  data?.forEach((entry: any) => {
    const date = new Date(entry.dates)
    const yr = date.getUTCFullYear()
    const month = date.getUTCMonth() + 1

    const monthYear = getMonthYearString(yr, month)

    if (!monthlyDataMap.has(monthYear)) {
      monthlyDataMap.set(monthYear, {monthYear, emission: entry.emission, activities: [...entry.activities]})
    } else {
      const existingMonthlyData = monthlyDataMap.get(monthYear)
      if (existingMonthlyData) {
        existingMonthlyData.emission += entry.emission
        monthlyDataMap.set(monthYear, existingMonthlyData)
      }
    }
  })

  const allMonthsKeys = generateAllMonthsKeys2(liveOnDate, year, isWorkshop)
  
  const monthlyData = allMonthsKeys.map((key) => {
    const existingData = monthlyDataMap.get(key)
    return existingData || {monthYear: key, emission: 0, activities: []}
  })
  return monthlyData
}

export function convertDailyToMonthly(data: any, liveOnDate: any, year: string) {
  const monthlyDataMap: Map<string, any> = new Map();

  data?.forEach((entry: any) => {
    const date = new Date(entry.dates);
    const yr = date.getFullYear();
    const month = date.getMonth() + 1;

    const monthYear = getMonthYearString(yr, month);

    if (!monthlyDataMap.has(monthYear)) {
      monthlyDataMap.set(monthYear, { monthYear, emission: entry.emission });
    } else {
      const existingMonthlyData = monthlyDataMap.get(monthYear);
      if (existingMonthlyData) {
        existingMonthlyData.emission += entry.emission;
        monthlyDataMap.set(monthYear, existingMonthlyData);
      }
    }
  });

  const allMonthsKeys = generateAllMonthsKeys2(liveOnDate, year);
  const monthlyData = allMonthsKeys.map((key) => {
    const existingData = monthlyDataMap.get(key);
    return existingData || { monthYear: key, emission: 0 };
  });

  return monthlyData;
}


export function getAllDatesOfCurrentMonth(selectedDate:any) {
  const currentDate = new Date(selectedDate);
  const currentYear = currentDate.getUTCFullYear();
  const currentMonth = currentDate.getUTCMonth();
  const numDaysInCurrentMonth = moment(new Date(currentYear, currentMonth, 1)).daysInMonth();
  const datesArray = [];
  for (let day = 1; day <= numDaysInCurrentMonth; day += 1) {
    const date = new Date(currentYear, currentMonth, day).toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'short',
      year: '2-digit',
    });
    datesArray.push(date);
  }
  return datesArray;
}

export function getLongDateFormat(inputDate: string): string {
  const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];

  const [year, month, day] = inputDate.split('-').map(Number);
  const monthName = months[month - 1];
  const daySuffix = (() => {
    if (day >= 11 && day <= 13) {
      return "th";
    }
    switch (day % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  })();

  return `${day}${daySuffix} ${monthName} ${year}`;
}
type DateRange = {
  startDate: string;
  endDate: string;
};

type MonthRange = {
  month: string;
  startDate: string;
  endDate: string;
};

export function generateCompareMonthsRange(
  filterDate: DateRange,
  filterMonth: string,
  filterYear: string,
  liveOnDate: string, 
  isWorkshop?: boolean | undefined
): MonthRange[] {
  const currentDate = new Date();
  const currentYear = currentDate.getUTCFullYear();
  const currentMonth = currentDate.getUTCMonth() + 1; // Note: JavaScript months are 0-indexed

  const isValidMonth = (year: number, month: number): boolean => {
    if (isWorkshop) {
      return true;
    }
    if (year === currentYear && month > currentMonth) {
      return false;
    }
    if (year > currentYear) {
      return false;
    }
    if (year === currentYear && month === currentMonth) {
      const liveOnMonth = new Date(liveOnDate).getUTCMonth() + 1;
      if (month < liveOnMonth) {
        return false;
      }
    }
    return true;
  };

  const getMonthName = (month: number): string => {
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    return monthNames[month - 1];
  };

  const result: MonthRange[] = [];

  const liveOnMonth = new Date(liveOnDate).getUTCMonth() + 1;
  const liveOnYear = new Date(liveOnDate).getUTCFullYear()
  const startMonth = (parseInt(filterYear, 10) !== liveOnYear || filterMonth === 'All') ? 1 : liveOnMonth;

  for (let month = startMonth; month <= 12; month+=1) {
    const year = parseInt(filterYear, 10);
    if (isValidMonth(year, month) && getMonthName(month) !== filterMonth) {
      const monthString = month < 10 ? `0${month}` : `${month}`;
      const startDate = `${year}-${monthString}-01`;
      const lastDay = new Date(year, month, 0).getUTCDate();
      let endDay = lastDay;
      if (year === currentYear && month === currentMonth && !isWorkshop) {
        endDay = Math.min(lastDay, currentDate.getUTCDate());
      }
      const endDate = `${year}-${monthString}-${endDay < 10 ? `0${endDay}` : `${endDay}`}`;
      result.push({ month: getMonthName(month), startDate, endDate });
    }
  }

  return result;
}

type compareDefaultMonthType = { [key: string]: string; }


const monthAbbreviations: { [key: string]: string } = {
  January: "Jan",
  February: "Feb",
  March: "Mar",
  April: "Apr",
  May: "May",
  June: "Jun",
  July: "Jul",
  August: "Aug",
  September: "Sep",
  October: "Oct",
  November: "Nov",
  December: "Dec",
};

export function getMonthFormat(month: string){
  const [fullMonth, year] = month.split(" ");
  const abbreviatedMonth: string | undefined = monthAbbreviations[fullMonth];
  const resultString: string = abbreviatedMonth ? `${abbreviatedMonth} ${year}` : month;
  return resultString;

}

export const compareDefaultMonth:compareDefaultMonthType = {
  'Jan': 'Feb', 
  'Feb': 'Jan', 
  'Mar': 'Feb', 
  'Apr': 'Mar', 
  'May': 'Apr', 
  'Jun': 'May', 
  'Jul': 'Jun', 
  'Aug': 'Jul', 
  'Sep': 'Aug', 
  'Oct': 'Sep', 
  'Nov': 'Oct', 
  'Dec': 'Nov', 
}