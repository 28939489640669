/* eslint-disable global-require */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
import {useEffect, useMemo, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import moment from 'moment';

import { useLazyGetAttributesByIdQuery } from '../../store/api';
import { RoutesPath } from '../../../../routing/RoutesPath';
import { commaSeparatedFormat } from '../../../../utils/helpers';
import { CalendarMonthIcon, CubesIcon, CustomGroupTabIcon } from '../../../../../_metronic/assets/icons';

const IconCircleContainer = (props: any) => {
  const {children, content} = props;
  return (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
      <div style={{
        backgroundColor: '#E2F3FB', 
        height: '32px',
        width: '32px', 
        borderRadius: '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
        }}>
        {children}
      </div>
      <p className='m-0 px-2' style={{color: '#3A3A3A'}}>
        {content}
      </p>
    </div>
  )
}

const RenderParticipantHeaderRow = (props: any) => {
  const {name, valueType} = props;
  return (
    <div 
      className='px-10 py-3 fs-6 d-flex justify-content-between' 
      style={{
        border: '1px solid #d8d8d8', 
        borderWidth: '1px 0px 0px',
        fontWeight: '700',
      }}
      >
      <p className='m-0' style={{color: '#5C5C5C', paddingLeft: '10px'}}>{name}</p>
      <p className='m-0' style={{color: '#5C5C5C'}}>{valueType}(kg)</p>
    </div>
  )
}

const RenderParticipantRow = (props: any) => {
  const {name, rank, value=0} = props;
  return (
    <div 
      className='px-10 py-3 fs-6 d-flex justify-content-between' 
      style={{
        border: '1px solid #d8d8d8', 
        borderWidth: '1px 0px 0px',
      }}
      >
      <div className='d-flex'>
        <p style={{marginLeft: '-18px', marginRight: '20px', marginBottom: '0px', color: '#757575'}}>{rank}</p>
        <p className='m-0' style={{color: '#5C5C5C', fontWeight: '600'}}>{name}</p>
      </div>
      <p className='m-0' style={{color: '#5C5C5C', paddingRight: '60px'}}>{commaSeparatedFormat((value || 0)?.toFixed(1))}</p>
    </div>
  )
}

const AttributeDetailsPage = () => {
  const navigate = useNavigate()
  const {state: routeState}: any  = useLocation();

  const [ getAttributeById, {
    data: attributeData
  }] = useLazyGetAttributesByIdQuery();

  useEffect(() => {
    if (!attributeData) {
      getAttributeById({id: routeState.id})
    }
  }, [])

  return (
    <div className='blank-page-view py-8 px-12'>

      {/* ------------------------------------- HEADER ----------------------------------------- */}

      <div className='back-header-btn'>
        <button 
          type='button' 
          className='p-2 back-left-arrow-btn-wrap'
          onClick={()=>{navigate(RoutesPath.ATTRIBUTES.ROOT)}}
          >
          <div className='back-left-arrow-btn' /> 
        </button>
        <h6 className='m-0 mx-4 fs-4 font-weight-normal'><u>Waste Management</u></h6>
        {/* <h1 className='m-0 page-title-text'>ATTRIBUTE TO EMPLOYEES</h1> */}
      </div>

      {/* ------------------------------------- BODY ------------------------------------------- */}
      
      <div className='mt-6 bordered-grey'>
        <div className='d-flex p-4'>
          <div className='px-4 w-100'>
            <h2 className='m-0 mt-2 bold' style={{color: '#5C5C5C'}}>
              {routeState.attributeName}
            </h2>
            <div className='mt-4 d-flex align-items-center justify-content-between'>
              <IconCircleContainer
                content={`${moment(routeState.startDate).format('DD/MM/YYYY')} ${routeState?.endDate ? `- ${moment(routeState.endDate).format('DD/MM/YYYY')}` : ''}`}
                >
                <CalendarMonthIcon color="#4C707A" />
              </IconCircleContainer>
              <IconCircleContainer
                content={`${attributeData ? attributeData?.data?.users?.length : '--'} Employees`}
                >
                <CustomGroupTabIcon color="#4C707A" height={18} width={18} />
              </IconCircleContainer>
              {/* <IconCircleContainer
                content={`${routeState.users[0].groupName}`}
                >
                <CustomGroupTabIcon color="#4C707A" height={18} width={18} />
              </IconCircleContainer> */}
              <IconCircleContainer
                content={`Total Value: ${commaSeparatedFormat(Math.round(routeState.value))}`}
                >
                <CubesIcon color="#4C707A" height={18} width={18} />
              </IconCircleContainer>
            </div>
          </div>
        </div>
        <p className='m-0 p-4 pt-0' style={{color: '#666666'}}>
          {routeState.description}
        </p>
        
        <div>
          <RenderParticipantHeaderRow name="Employee name" valueType="Units per employee " />
          {
            attributeData?.data?.users?.map((item:any, index:number) => (
              <RenderParticipantRow key={`${item.userId + index}`} rank={index+1} name={item.name} value={item.value} />
            ))
          }
        </div>

      </div>

    </div>
  )
}

export default AttributeDetailsPage;
/* eslint-disable consistent-return */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
/* eslint-disable global-require */

