import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import mixpanel from 'mixpanel-browser'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import { useSelector } from 'react-redux'
import {
  IProfileDetails,
  NotificationType,
  employeeDetailsInitValues as initialValues,
} from '../../../_metronic/partials/components/types'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {RoutesPath} from '../../routing/RoutesPath'
import {
  useGetDepartmentsQuery,
  useGetDivisionsQuery,
  useGetLocationsQuery,
  useGetPositionsQuery,
  useUpdateEmployeeMutation,
} from './store/api'
import {useAddEmployeeMutation} from '../../routing/store/api'
import {Employee} from './types'
import {updateNotification} from '../../store/slices/notifier'
import {useAppDispatch} from '../../store/store'
import { EVENT_TYPE, PAGES } from '../../analytics/tracker-types'
import { RootState } from '../../store/store'

const profileDetailsSchema = Yup.object().shape({
  email: Yup.string().required('Email is required'),
  name: Yup.string().required('Name is required'),
  department: Yup.string().required('Department is required'),
  division: Yup.string().required('Division is required'),
  location: Yup.string().required('Location is required'),
})

const AddNewEmployee = () => {
  const navigate = useNavigate()
  const intl = useIntl()
  const [addEmployee, {isLoading, isSuccess, isError}] = useAddEmployeeMutation()
  const {data: departments} = useGetDepartmentsQuery()
  const {data: locations} = useGetLocationsQuery()
  const {data: divisions} = useGetDivisionsQuery()
  const {profile} = useSelector((state:RootState) => state.profile)

  useEffect(() => {
    // tracking
    mixpanel.track(EVENT_TYPE.PageView, {'name': PAGES.AddEmployeePage})
  }, [])

  const formik = useFormik<Employee>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      // workshop handling
      if (profile?.organization?.isWorkshopOrg) {
        return;
      }
      await addEmployee({
        name: values.name,
        email: values.email,
        employeeId: values.guid,
        department: values.department,
        location: values.location,
        division: values.division,
      })
    },
  })

  const dispatch = useAppDispatch()

  const updateData = () => {
    formik.handleSubmit()
  }

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        updateNotification({
          message: 'Employee Added Successfully',
          type: NotificationType.SUCCESS,
        })
      )
      navigate(RoutesPath.EMPLOYEES.EMPLOYEES_DETAILS)
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      dispatch(
        updateNotification({
          message: intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}),
          type: NotificationType.ERROR,
        })
      )
    }
  }, [isError])

  return (
    <div className='position-relative' style={{backgroundColor: 'white', flex: '1', minHeight: '100%', paddingBottom: '0px'}}>
      <div className='back-header-btn mt-8' style={{marginLeft: '40px'}}>
        <button 
          type='button' 
          className='p-2 back-left-arrow-btn-wrap'
          onClick={()=>{navigate(RoutesPath.EMPLOYEES.ROOT)}}
          >
          <div className='back-left-arrow-btn' /> 
        </button>
        <h6 className='m-0 mx-4 fs-4 font-weight-normal'><u>Add Employee</u></h6>
      </div>

      <div className='card'>
        <div className='me-7 mb-1 row-1 p-10'>
          <div className=' symbol symbol-100px symbol-lg-160px symbol-fixed position-relative me-18'>
            <img src={toAbsoluteUrl('/media/avatars/Avatar-empty-profile.svg')} alt='Metronic' />
            {/* <div className='position-absolute translate-middle top-0 start-100 mb-6 h-30px w-30px'>
              <img src={toAbsoluteUrl('/media/avatars/add-s.svg')} alt='Metronic' />
            </div> */}
          </div>
        </div>

        <form noValidate className='form'>
          <div className='card-body p-12'>
            <div className='row'>
              <div className='col mb-2'>
                <label htmlFor='fullName' className='col-lg-4 col-form-label fw-bold fs-6'>
                  Employee Name
                </label>

                <div className=''>
                  <div className='row'>
                    <div className='col-lg-10 fv-row'>
                      <input
                        type='text'
                        id='fullName'
                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                        placeholder='Full name'
                        {...formik.getFieldProps('name')}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.name}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card-body p-12'>
            <div className='row'>
              <div className='col mb-2'>
                <label htmlFor='division' className='col-lg-4 col-form-label fw-bold fs-6'>
                  Division
                </label>

                <div className=''>
                  <div className='row'>
                    <div className='col-lg-10 fv-row'>
                      <select
                        className='form-select form-select-solid form-select-lg'
                        {...formik.getFieldProps('division')}
                      >
                        <option value='' key='empty'>
                          Select a division
                        </option>
                        {divisions &&
                          divisions.map((division) => (
                            <option value={division.name} key={division.id}>
                              {division.name}
                            </option>
                          ))}
                      </select>

                      {formik.touched.division && formik.errors.division && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.division}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              {/** Department */}
              <div className='col mb-2'>
                <label htmlFor='department' className='col-lg-4 col-form-label fw-bold fs-6'>
                  Department
                </label>

                <div className=''>
                  <div className='row'>
                    <div className='col-lg-10 fv-row'>
                      <select
                        className='form-select form-select-solid form-select-lg'
                        {...formik.getFieldProps('department')}
                      >
                        <option value='' key='empty'>
                          Select a department
                        </option>
                        {departments &&
                          departments.map((department) => (
                            <option value={department.name} key={department.id}>
                              {department.name}
                            </option>
                          ))}
                      </select>

                      {formik.touched.department && formik.errors.department && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.department}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='card-body p-12'>
            <div className='row mb-20'>
              <div className='col mb-2'>
                <label htmlFor='location' className='col-lg-4 col-form-label fw-bold fs-6'>
                  Location
                </label>

                <div className=''>
                  <div className='row'>
                    <div className='col-lg-10 fv-row'>
                      <select
                        className='form-select form-select-solid form-select-lg'
                        {...formik.getFieldProps('location')}
                      >
                        <option value='' key='empty'>
                          Select a location
                        </option>
                        {locations &&
                          locations.map((location) => (
                            <option value={location.name} key={location.id}>
                              {location.name}
                            </option>
                          ))}
                      </select>

                      {formik.touched.location && formik.errors.location && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.location}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className='col mb-2'>
                <label htmlFor='email' className='col-lg-4 col-form-label fw-bold fs-6'>
                  Email Id
                </label>

                <div className=''>
                  <div className='row'>
                    <div className='col-lg-10 fv-row'>
                      <input
                        type='text'
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Email Id'
                        id='email'
                        {...formik.getFieldProps('email')}
                      />
                      {formik.touched.email && formik.errors.email && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.email}</div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <div className='background-image add-bg' />
      </div>

      {/* <div className='card pt-20 '> */}
      <div className='card-footer footer shadow mt-auto'>
        <div className='settings-btn-bottom px-8'>
          <div className='py-6 px-3'>
            <button
              type='button'
              // disabled={!loading}
              className='custom-outline-button'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              onClick={() =>
                navigate({
                  pathname: RoutesPath.EMPLOYEES.EMPLOYEES_DETAILS,
                })
              }
            >
              Cancel
            </button>
          </div>

          <div className='py-6 px-3'>
            <button
              type='button'
              onClick={() => formik.handleSubmit()}
              // disabled={!loading}
              className='custom-primary-delete-button'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
            >
              Save
            </button>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  )
}

export {AddNewEmployee}
