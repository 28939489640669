import { CHALLENGE_CATEGORY_TYPE } from "../challenges/types"

export enum AttributeWasteUnitTypes {
  KG = "KG",
}

export enum AttributeWasteTypes {
  PAPER = "PAPER",
  WASTE = "WASTE",
}

export type AttributesFormType = {
  name: string
  unit_type: AttributeWasteUnitTypes
  value: number
  waste_type: AttributeWasteTypes
  group_type: CHALLENGE_CATEGORY_TYPE
  group_ids: number[]
  start_date: string | undefined
  end_date: string | undefined
}