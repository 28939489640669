import {Suspense} from 'react'
import {BrowserRouter as Router} from 'react-router-dom'
import {Provider} from 'react-redux'
import mixpanel from 'mixpanel-browser';
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import {MasterInit} from '../_metronic/layout/MasterInit'
import {store} from './store/store'
import RouteLayout from './routing/RouteLayout'

mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY!, { persistence: 'localStorage' });

const App = () => (
  <Suspense fallback={<LayoutSplashScreen />}>
    <I18nProvider>
      <LayoutProvider>
        <Provider store={store}>
          <Router>
            <RouteLayout />
          </Router>
        </Provider>
        <MasterInit />
      </LayoutProvider>
    </I18nProvider>
  </Suspense>
)

export {App}
