import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {useIntl} from 'react-intl'
import {useState, useEffect} from 'react'
import { useSelector } from 'react-redux'

import {useDeleteEmployeeMutation} from './store/api'
import {NotificationType} from '../../../_metronic/partials/components/types'
import {updateNotification} from '../../store/slices/notifier'
import {useAppDispatch, RootState} from '../../store/store'

function DeleteModal(props: any) {
  const {selectedEmployee, onHide} = props

  const intl = useIntl()
  const [isDeleting, setIsDeleting] = useState<boolean>(false)
  const {profile} = useSelector((state:RootState) => state.profile)

  const [deleteEmployee, {isLoading, isSuccess, isError}] = useDeleteEmployeeMutation()
  const handleEmployeeDelete = async () => {
    // workshop handling
    if (profile?.organization?.isWorkshopOrg) {
      return;
    }
    setIsDeleting(true)
    const data = {
      empId: selectedEmployee.guid,
      department_id: selectedEmployee.departmentId,
      work_location_id: selectedEmployee.locationId,
    }
    await deleteEmployee(data)
  }

  const dispatch = useAppDispatch()

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        updateNotification({
          message: 'Employee Deleted Successfully',
          type: NotificationType.SUCCESS,
        })
      )
      onHide()
    }
    setIsDeleting(false)
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      dispatch(
        updateNotification({
          message: intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}),
          type: NotificationType.ERROR,
        })
      )
    }
    setIsDeleting(false)
    onHide()
  }, [isError])

  return (
    <Modal
      className='modal-md'
      {...props}
      size='md'
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header className='d-flex justify-content-center' closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <h2 className='ml-10'>Are you sure you want to remove employee?</h2>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='d-flex flex-column align-items-center'>
        <h5 className='no-bold'>
          Removing the employee will erase any data about them from the dashboard. This action
          cannot be undone.
        </h5>
        <button type='button' className='mt-10 custom-primary-delete-button' onClick={onHide}>
          No, Keep
        </button>
        <button type='button' className='custom-outline-button' onClick={handleEmployeeDelete} disabled={isDeleting}>
          {
            isDeleting ? 
            'Removing employee...' : 
            'Yes, Remove'
          } 
        </button>
      </Modal.Body>
    </Modal>
  )
}

export default DeleteModal
