export enum MethodType {
  get = 'GET',
  delete = 'DELETE',
  head = 'HEAD',
  options = 'OPTIONS',
  post = 'POST',
  put = 'PUT',
  patch = 'PATCH',
  purge = 'PURGE',
  link = 'LINK',
  unlink = 'UNLINK',
}

export enum APITags {
  GET_EMPLOYEES = 'GET_EMPLOYEES',
  GET_PROJECTS = 'GET_PROJECTS',
  GET_PROFILE = 'GET_PROFILE',
}

export const API_TAGS = [APITags.GET_EMPLOYEES, APITags.GET_PROJECTS, APITags.GET_PROFILE]

export const API_ENDPOINTS = {
  ORGANIZATIONS: '/organizations',
}
