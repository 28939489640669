type Parameter = {
  name: string
  value: string
  type?: string
}

export const ParameterTypes = {
  STRING: 'String',
  ARRAY: 'ARRAY',
}

export const queryStringBuilder = (paramerters: Parameter[]) => {
  const urlSearchParams = new URLSearchParams()
  paramerters.forEach((parameter) => {
    if (parameter.value) {
      if (parameter?.type === ParameterTypes.ARRAY) {
        urlSearchParams.set(parameter.name, `[${parameter.value}]`)
      } else {
        urlSearchParams.set(parameter.name, parameter.value)
      }
    }
  })
  const queryString = urlSearchParams.toString()
  return queryString
}
