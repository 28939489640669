const tryParse = (item: string) => {
  try {
    return JSON.parse(item || '')
  } catch {
    return item
  }
}

const getLocalStorageItem = (key: string) => {
  const item = localStorage.getItem(key)
  return item ? tryParse(item) : item
}

const setLocalStorageItem = (key: string, value: unknown) => {
  localStorage.setItem(key, typeof value === 'object' ? JSON.stringify(value) : `${value}`)
}

const deleteLocalStorageItem = (key: string) => localStorage.removeItem(key)

export {getLocalStorageItem, setLocalStorageItem, deleteLocalStorageItem}
