import {useEffect, useState} from 'react'
import { useSelector } from 'react-redux'
import {convertDailyToMonthlyWithActivities, getMonthFormat, getYYYYDDMMFormat} from '../../utils/dateFormatter'
import {DateRangeType} from '../../../_metronic/partials/components/types'
import {useLazyGetEmissionTrendsQuery} from './store/api'
import {ChartValueTypes} from './type'
import {getLocalStorageItem} from '../../utils/storage'
import {EmissionTrendsChartForAllMonths} from '../../../_metronic/partials/components/chart/EmissionTrendsChartForAllMonths'
import { RootState } from '../../store/store'
import { convertMonthYearToDate } from '../../utils/helpers'

const EmissionTrendForAllMonths = () => {
  const {filterDate, filterYear, filterExtrapolation} = useSelector((state: RootState) => state.filters)
  const {profile} = useSelector((state: RootState) => state.profile)

  const [getEmissionTrend, {data: emissionTrend}] = useLazyGetEmissionTrendsQuery()

  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeType>()
  const [chartValues, setChartValues] = useState<ChartValueTypes>()

  const setInitialSelectedDate = () => {
    const currentDate = new Date()
    const liveOnDate = new Date(getLocalStorageItem('live-on'))
    // currentDate.setMonth(currentDate.getUTCMonth() + 4)

    setSelectedDateRange({
      startDate: new Date(currentDate.getUTCFullYear(), liveOnDate.getUTCMonth(), 1),
      endDate: new Date(currentDate.getUTCFullYear(), currentDate.getUTCMonth() + 1, 0),
    })
  }

  // const getDaysArray = () => {
  //   const datesArray = []
  //   if (selectedDateRange)
  //     for (
  //       let dt = new Date(selectedDateRange.startDate);
  //       dt <= new Date(selectedDateRange.endDate);
  //       dt.setDate(dt.getUTCDate() + 1)
  //     ) {
  //       datesArray.push(new Date(dt))
  //     }
  //   return datesArray
  // }

  // const getDaysArray = () => {
  //   const datesArray:any[] = []
  //   if (selectedDateRange) {
  //     const currentDate = new Date(selectedDateRange.startDate);
  
  //     while (currentDate <= new Date(selectedDateRange.endDate)) {
  //       const monthYearString = `${currentDate.toLocaleString('default', { month: 'short' })} ${currentDate.getUTCFullYear()-2000}`;
  //       if (!datesArray.includes(monthYearString)) {
  //         datesArray.push(monthYearString);
  //       }
  
  //       // Move to the first day of the next month
  //       currentDate.setMonth(currentDate.getUTCMonth() + 1, 1);
  //     }
  //   }
  //   return datesArray
  // }

  useEffect(() => {
    setInitialSelectedDate()
  }, [])

  useEffect(() => {
    // const liveOnDate = new Date(getLocalStorageItem('live-on'))
    // const currentDate = new Date()
    // currentDate.setMonth(currentDate.getUTCMonth() + 4)

    if (filterDate.startDate !== '') {
      if (filterExtrapolation) {
        getEmissionTrend({
          // fromDate: getYYYYDDMMFormat(liveOnDate),
          // toDate: getYYYYDDMMFormat(currentDate),
          fromDate: filterDate.startDate, 
          toDate: filterDate.endDate, 
          is_scaled: true,
          is_all: true, 
          monthly: true,
        })
      } else {
        getEmissionTrend({
          // fromDate: getYYYYDDMMFormat(liveOnDate),
          // toDate: getYYYYDDMMFormat(currentDate),
          fromDate: filterDate.startDate, 
          toDate: filterDate.endDate, 
          is_all: true, 
          monthly: true,
        })
      }
    }
    
  }, [getEmissionTrend, filterExtrapolation, filterDate])

  // useEffect(() => {
  //   const dates = getDaysArray()
  //   const values:any[] = []
  //   // const values = new Array(dates.length).fill(0)
  //   if (emissionTrend?.length) {
  //     emissionTrend.forEach((trend:any) => {
  //       // const index = dates.findIndex((d) => {
  //       //   const date = new Date(trend.dates)
  //       //   date.setHours(0, 0, 0)
  //       //   return d.toISOString() === date.toISOString()
  //       // })
  //       // if (index >= 0) values[index] = trend.emission
  //       values.push(trend.emission)
  //     })
  //   }
  //   const series = [
  //     {
  //       name: 'Total Emissions',
  //       data: values,
  //     },
  //   ]
  //   // const categories = dates.map((date) =>
  //   //   date.toLocaleDateString('en-GB', {
  //   //     day: 'numeric',
  //   //     month: 'short',
  //   //     year: '2-digit',
  //   //   })
  //   // )
  //   const categories = dates;
  //   setChartValues({series, categories})
  // }, [emissionTrend])

  useEffect(() => {
    const liveOnDate = new Date(getLocalStorageItem('live-on'))

    const monthlyData = convertDailyToMonthlyWithActivities(emissionTrend || [], liveOnDate, filterYear, profile?.organization?.isWorkshopOrg)
    const seriesData: number[] = []
  
    const categories: string[] = []
      
    monthlyData?.forEach((itm) => {
      // eslint-disable-next-line no-sequences, no-unused-expressions
      const newDate = convertMonthYearToDate(itm.monthYear) // for safari error - invalid date
      // const currentYear = new Date('2023-01-01').getUTCFullYear()-2000;
      // const monthYearString = `${new Date('2023-01-01').toLocaleString('default', { month: 'short' })} ${currentYear}`;
      // const currentYear = new Date(newDate).getUTCFullYear()-2000;
      // const monthYearString = `${new Date(newDate).toLocaleString(undefined, { month: 'short' })} ${currentYear}`;
      const monthFormat = getMonthFormat(itm.monthYear);
      // Check if the month is valid before constructing the final string
      // const abbreviatedMonth = monthAbbreviations[fullMonth];
      // const resultString = `${abbreviatedMonth} ${year}`;
      seriesData.push(itm?.emission);
      categories.push(monthFormat)
    })

    const series = [
      {
        name: 'Total Emissions',
        data: seriesData,
      },
    ]

    setChartValues({series, categories})
  }, [emissionTrend])


  let chartComponent = null
  if (chartValues) {
    chartComponent = (
      <EmissionTrendsChartForAllMonths
        series={chartValues.series}
        categories={chartValues.categories}
        disableAnimation={chartValues.disableAnimation}
      />
    )
  }

  return <div>{chartComponent}</div>
}

export {EmissionTrendForAllMonths}
