import React, {useState, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux';

import { usePostUpdateDivisionMutation, usePostUpdateDepartmentMutation } from '../../store/api'
import { RootState } from '../../../../store/store';

export default function ModalAddDivDeptData(props:any) {
  const {onHide, category, isEdit, onChange, name, numberOfEmployee, id} = props

  const [editDivision, {
    data: editDivisionData, 
    isSuccess: isSuccessEditDivision, 
    isError: isErrorEditDivision, 
    isLoading: isLoadingEditDivision
  }] = usePostUpdateDivisionMutation(); 
  const [editDepartment, {
    data: editDepartmentData, 
    isSuccess: isSuccessEditDepartment, 
    isError: isErrorEditDepartment, 
    isLoading: isLoadingEditDepartment
  }] = usePostUpdateDepartmentMutation();  

  const {profile} = useSelector((state: RootState) => state.profile)

  const [nameVal, setNameVal] = useState<string>('')
  const [employeeCountValue, setEmployeeCountValue] = useState<any>()
  // validations
  const [isValidName, setIsValidName] = useState<boolean>(true)
  const [isValidEmployeeCount, setIsValidEmployeeCount] = useState<boolean>(true)

  useEffect(() => {
    setNameVal(name || ''); 
    setEmployeeCountValue(numberOfEmployee || 0)
    setIsValidName(true)
    setIsValidEmployeeCount(true)
  }, [name, numberOfEmployee, id])

  let title = ''
  if (category === 'division') {
    title = isEdit ? 'Edit Division' : 'Add New Division'
  } else {
    title = isEdit ? 'Edit Department' : 'Add New Department'
  }

  const checkValidFields = () => {
    let a = true; let b = true
    if (!nameVal || nameVal.trim().length===0) {
      setIsValidName(false)
      a = false
    }
    // if (!employeeCountValue || employeeCountValue<=0) {
    if (employeeCountValue<0) {
      setIsValidEmployeeCount(false)
      b = false
    }
    return a && b
  }

  const handleSubmit = () => {
    // workshop handling
    if (!checkValidFields() || profile?.organization?.isWorkshopOrg) {
      return;
    }
    if (category === 'division') {
      editDivision({
        "id": id, 
        "name": nameVal, 
        "total_employee_count": employeeCountValue, 
      })
      .then(res => {
        onHide()
        onChange()
      })
    } else {
      editDepartment({
        "id": id, 
        "name": nameVal, 
        "total_employee_count": employeeCountValue,
      })
      .then(res => {
        onHide()
        onChange()
      })
    }
  }

  return (
    <Modal {...props} size='md' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header className='px-6' closeButton>
        <Modal.Title id='contained-modal-title-vcenter' style={{width: '80%'}}>
          <h4 className='m-0'>{title}</h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body> 
        <h6 className='fs-5 mt-0'>{(category==='division') ? 'Division' : 'Department'} Name</h6>
        <input
          placeholder={(category==='division') ? 'Enter division name' : 'Enter department name'}
          className='modal-input-field'
          value={nameVal}
          type='text'
          required
          onChange={(v) => {
            setNameVal(v.currentTarget.value)
          }}
        />
        {
          !isValidName &&
          <p className='mt-2 mb-0' style={{color: 'red', fontSize: '14px'}}>
            {category === 'division' ? 'Division' : 'Department'} name is required
          </p>
        }
        {/* <h6 className='fs-5 mt-6'>No. of employees</h6>
        <input
          placeholder='Enter number of employees'
          className='modal-input-field'
          value={employeeCountValue}
          type='number'
          min='1'
          required
          onChange={(v) => {
            setEmployeeCountValue(v.currentTarget.value)
          }}
        />
        {
          !isValidEmployeeCount &&
          <p className='mt-2 mb-0' style={{color: 'red', fontSize: '14px'}}>
            Employee count should be greater than zero
          </p>
        } */}
        <div className='mt-6 d-flex flex-end'>
          <button
            type='button'
            // disabled={!loading}
            className='custom-primary-delete-button m-0 py-2 px-3'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={handleSubmit}
            >
            {
              isEdit ? 
              'Save' : 
              'Add'
            }
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}