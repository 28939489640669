/* eslint-disable global-require */
/* eslint no-restricted-syntax: ["error", "FunctionExpression", "WithStatement", "BinaryExpression[operator='in']"] */
import React, {useEffect, useState} from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux';

import { useLazyGetCustomGroupsListQuery, useDeleteCustomGroupMutation } from '../store/api';
import { 
  useLazyGetDivisionsListQuery,
  useLazyGetDepartmentsListQuery,
  useLazyGetLocationsListQuery
} from '../../dashboard/store/api';
import ModalCreateCustomModal from './components/modal-create-custom-groups';
import ModalDeleteCustomGroups from './components/modal-delete-custom-groups';
import { tagsColorPalette, tagsDarkColorShade } from '../../../utils/helpers';
import { updateNotification } from '../../../store/slices/notifier';
import { RootState, useAppDispatch } from '../../../store/store';
import { NotificationType } from '../../../../_metronic/partials/components/types';
import { FilterCustomGroups } from './components/filter-custom-groups';
import { CardCustomGroups } from './components/card-custom-groups';
import { EmptyBackgroudCustomGroups } from '../../../../_metronic/assets/images';
import { setActiveDepartmentsList, setActiveDivisionsList, setActiveLocationsList, setLiveCustomGroupsList } from '../../../store/slices/masterList';
import './styles.css';

type Props = {

}

export function transformGroupListData(inputData:any) {
  const result: any = [];

  Object.values(inputData || {}).forEach((item: any) => {
    const newItem = {
      id: item.id,
      name: item.name,
      colorCode: item.color_code,
      startDate: item.start_date,
      endDate: item.end_date,
      status: item.status,
      description: item.description,
      users: item.users,
    };
    result.push(newItem);
  })

  return result;
}

const RenderGroupColorBtn: React.FC<any> = (props) => {
  const {onClick, color} = props;
  return (
    <button
      type='button'
      onClick={onClick}
      className='remove-btn-styles tag-color-picker-btn m-1'
      style={{backgroundColor: color, border: '1.5px solid', borderColor: tagsDarkColorShade[color]}}
    />
  )
}

const CustomGroupsPage: React.FC<Props> = () => {

  /* ----------------------------- REACT STATES ------------------------------ */

  const [groupNameVal, setGroupNameVal] = useState('')
  const [modalShow, setModalShow] = useState(false)
  const [isEditCustomGroup, setIsEditCustomGroup] = useState(false)
  const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false)
  const [deleteGroupId, setDeleteGroupId] = useState<number>(0)
  const [isValidGroupName, setIsValidGroupName] = useState(true)
  const [transformedCustomGroupsListData, setTransformedCustomGroupsListData] = useState([])
  const [selectedGroupData, setSelectedGroupData] = useState(null)
  const [selectedGroupColor, setSelectedGroupColor] = useState(tagsColorPalette[0])
  const [isColorPickerActive, setIsColorPickerActive] = useState(false)

  /* ------------------------- REDUX DISPATCHER, SELECTOR --------------------------- */

  const intl = useIntl()
  const dispatch = useAppDispatch()
  const { filterCustomGroupCategory } = useSelector((state: RootState) => state.filters);

  /* --------------------------------- RTK QUERY ----------------------------------- */

  const [getCustomGroupList, {
    data: customGroupsListData, 
    isSuccess: isSuccessGetGroups, 
    isLoading: isLoadingGetGroups,
    isError: isErrorGetGroups,
  }] = useLazyGetCustomGroupsListQuery();

  const [deleteCustomGroup, {
    isSuccess: isSuccessDeleteGroup, 
    isLoading: isLoadingDeleteGroup, 
    isError: isErrorDeleteGroup
  }] = useDeleteCustomGroupMutation()

  const [getDivisionsList, {data: divisionsListData}] = useLazyGetDivisionsListQuery()
  const [getDepartmentsList, {data: departmentsListData}] = useLazyGetDepartmentsListQuery()
  const [getLocationsList, {data: locationsListData}] = useLazyGetLocationsListQuery()

  /* ---------------------------------- HANDLERS ----------------------------------- */

  const checkValidGroupName = (groupName:string) => {
    const str = groupName.trim()
    if (str.length<6) {
      setIsValidGroupName(false)
      return false;
    }
    setIsValidGroupName(true)
    return true;
  }

  const handleDeleteCustomGroup = () => {
    deleteCustomGroup({
      "groupId": deleteGroupId
    })
    .then(res => {
      getCustomGroupList({})
      .catch(err => console.log(err))
    })
    .catch(err => console.log(err))
  }

  const handleRefetchCustomGroup = () => {
    getCustomGroupList({})
    .then((res:any)=>{
      if (res?.data?.data?.groups) {
        let arr: any[] = Object.values(res?.data?.data?.groups || {});
        arr = arr.filter((item: any) => item.status === "live");
        dispatch(setLiveCustomGroupsList(arr));
      }
    })
    .catch(err => console.log(err))
    setGroupNameVal('')
  }

  /* --------------------------- COMPONENT MOUNT - API CALLS ----------------------------- */

  useEffect(() => {
    if (customGroupsListData) {
      const groupsRes:any = transformGroupListData(customGroupsListData?.data?.groups);
      setTransformedCustomGroupsListData(groupsRes);
    }
  }, [customGroupsListData])

  useEffect(() => {
    getCustomGroupList({})
    .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    getDivisionsList({isActive: true}).then((res:any)=>{
      const arr: any[] = []
      res?.data.forEach((itm:any) => {
        arr.push(itm)
      })
      dispatch(setActiveDivisionsList(arr))
    })
    getDepartmentsList({isActive: true}).then((res:any)=>{
      const arr: any[] = []
      res?.data.forEach((itm:any) => {
        arr.push(itm)
      })
      dispatch(setActiveDepartmentsList(arr))
    })
    getLocationsList({isActive: true}).then((res:any)=>{
      const arr: any[] = []
      res?.data.forEach((itm:any) => {
        arr.push(itm)
      })
      dispatch(setActiveLocationsList(arr))
    })
  }, [])


  /* ----------------------------- TOAST MESSAGES ------------------------------ */

  useEffect(() => {
    if (isSuccessDeleteGroup && !isLoadingDeleteGroup) {
      dispatch(
        updateNotification({
          message: 'Custom group deleted successfully', 
          type: NotificationType.SUCCESS
        })
      )
    }
  }, [isSuccessDeleteGroup])

  useEffect(() => {
    if (isErrorDeleteGroup || isErrorGetGroups) {
      dispatch(
        updateNotification({
          message: intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}),
          type: NotificationType.ERROR,
        })
      )
    }
  }, [isErrorDeleteGroup, isErrorGetGroups])

  return (
    <div className='blank-page-view py-8 px-12'>
      <h1 className='page-title-text'>CUSTOM GROUPS</h1>
      <div className='mt-5'>
        <h6 className='fs-5 grey'>
          Name your group 
          {
            !isValidGroupName &&
            <span style={{color: 'red', fontSize: '12px'}}> *Minimum 6 characters required</span>
          }
        </h6>
        <div className='d-flex w-100'>
          <div className='wrap-create-custom-group-2-search-input'>
            <input
              placeholder='Enter group name'
              className='employee-search-input'
              value={groupNameVal}
              onChange={(v) => {setGroupNameVal(v.currentTarget.value)}}
              onKeyDown={(e) => {
                if (e.key === 'Enter')  {
                  if (checkValidGroupName(groupNameVal)) {
                    setModalShow(true)
                  }
                }
              }}
            />
            <button
              type='button'
              className='remove-btn-styles tag-color-picker-btn' 
              style={{backgroundColor: selectedGroupColor, border: '1.5px solid', borderColor: tagsDarkColorShade[selectedGroupColor]}}
              onFocus={() => setIsColorPickerActive(true)}
              onBlur={() => setTimeout(() => setIsColorPickerActive(false), 300)}
              />
            {
              isColorPickerActive && 
              <div className='tag-color-picker-container'>
                {
                  tagsColorPalette.map((item, index) => (
                    <RenderGroupColorBtn
                      key={item}
                      color={item}
                      onClick={() => setSelectedGroupColor(item)}
                    />
                  ))
                }
              </div>
            }
          </div>
          <button
              type='button'
              // disabled={!loading}
              className='custom-primary-delete-button'
              style={{minWidth: '20%', margin: '0px 0px 0px 20px'}}
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              onClick={() =>{
                setIsEditCustomGroup(false)
                if (checkValidGroupName(groupNameVal)) {
                  setTimeout(() => {
                    setModalShow(true)
                  }, 200)
                }
              }}
            >
            Create New Group
          </button>
        </div>
      </div>
      <div className='mt-10'>
        <h1 className='page-title-text fs-2 mb-6 mt-6'>EXISTING GROUPS</h1>
        <FilterCustomGroups />
        <div className='container-custom-groups-card'>
          {
            transformedCustomGroupsListData?.length > 0 && 
            transformedCustomGroupsListData?.map((group: any) => {
              if (group.status !== filterCustomGroupCategory) return null;
              return (
                <CardCustomGroups 
                  key={group.id}
                  showModal={(data:any) => {
                    setIsEditCustomGroup(true);
                    setSelectedGroupData(data);
                    setTimeout(() => setModalShow(true), 200);
                  }} 
                  showDeleteModal={(id: number) => {
                    setDeleteGroupId(id);
                    setTimeout(() => setDeleteModalShow(true), 200); 
                  }}
                  groupData={group}
                  />
              )
            })
          }
          {
            transformedCustomGroupsListData?.filter((group: any) => group.status === filterCustomGroupCategory)?.length === 0 &&
            <div className='d-flex justify-content-center align-items-center my-10' style={{flex: 1, flexDirection: 'column'}}>
              <h3>There are no {filterCustomGroupCategory} groups!</h3>
              <EmptyBackgroudCustomGroups height={300} width={400} />
            </div>
          }
        </div>
      </div>
      {
        modalShow && 
        <ModalCreateCustomModal 
          show={modalShow} 
          onHide={() => {
            setIsEditCustomGroup(false)
            setSelectedGroupData(null)
            setModalShow(false)
          }} 
          isEdit={isEditCustomGroup} 
          groupTitle={groupNameVal}
          selectedGroupColor={selectedGroupColor}
          groupData={selectedGroupData}
          onSubmitSuccess={handleRefetchCustomGroup}
          />
      }
      <ModalDeleteCustomGroups 
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)} 
        onDelete={handleDeleteCustomGroup}
        />
    </div>
  )
}

export default CustomGroupsPage
/* eslint no-restricted-syntax: ["error", "FunctionExpression", "WithStatement", "BinaryExpression[operator='in']"] */
/* eslint-disable global-require */