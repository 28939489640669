import api from '../../../services/api'
import {APITags, MethodType} from '../../../services/constants'
import {ParameterTypes, queryStringBuilder} from '../../../utils/querStringBuilder'
import {EntityListResponse, PositionsListResponse, EmployeeListResponse, Entity} from '../types'
import API_ENDPOINTS from './apiPaths'

const employeeApis = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllEmployees: builder.query({
      query: (params) => {
        const departmentGuid = params.departmentId ? `${params.departmentId.map((item:string) => `"${item}"`).join(", ")}` : ''
        const locationGuid = params.locationId ? `${params.locationId.map((item:string) => `"${item}"`).join(", ")}` : ''
        const divisionGuid = params.divisionId ? `${params.divisionId.map((item:string) => `"${item}"`).join(", ")}` : ''
        const queryParams = queryStringBuilder([
          {name: API_ENDPOINTS.OFFSET, value: params.offset},
          {name: API_ENDPOINTS.LIMIT, value: params.limit},
          {name: API_ENDPOINTS.SEARCH_NAME, value: params.searchTerm},
          {name: API_ENDPOINTS.SORT_BY, value: params.sortBy},
          {name: API_ENDPOINTS.SORT_ORDER, value: params.sortOrder},
          {
            name: API_ENDPOINTS.DEPARTMENT_IDS,
            value: departmentGuid,
            type: ParameterTypes.ARRAY,
          },
          {name: API_ENDPOINTS.DIVISION_IDS, value: divisionGuid, type: ParameterTypes.ARRAY},
          {name: API_ENDPOINTS.LOCATION_IDS, value: locationGuid, type: ParameterTypes.ARRAY},
        ])
        return {
          url: `${API_ENDPOINTS.EMPLOYEES}${queryParams ? `?${queryParams}` : ''}`,
          method: MethodType.get,
        }
      },
      providesTags: [APITags.GET_EMPLOYEES],
      transformResponse: (response: EmployeeListResponse) => {
        const res = {
          ...response?.data,
          edges: response?.data?.edges
            ? response.data.edges.map((edge) => ({
                id: edge?.id,
                guid: edge?.guid,
                name: edge?.name,
                email: edge?.email,
                image: edge?.image,
                is_onboarding_completed: edge?.is_onboarding_completed,
                department: edge?.department?.name,
                departmentId: edge?.department?.guid,
                location: edge?.location?.name,
                locationId: edge?.location?.guid,
                division: edge?.division?.name,
                divisionId: edge?.division?.guid,
              }))
            : [],
        }
        return res
      },
    }),
    getDepartments: builder.query<Entity[], void>({
      query: () => ({
        url: `${API_ENDPOINTS.DEPARTMENTS}`,
        method: MethodType.get,
      }),
      transformResponse: (response: EntityListResponse) => response?.data,
    }),
    getLocations: builder.query<Entity[], void>({
      query: () => ({
        url: `${API_ENDPOINTS.LOCATION}`,
        method: MethodType.get,
      }),
      transformResponse: (response: EntityListResponse) => response?.data,
    }),
    getDivisions: builder.query<Entity[], void>({
      query: () => ({
        url: `/${API_ENDPOINTS.DIVISIONS}`,
        method: MethodType.get,
      }),
      transformResponse: (response: EntityListResponse) => response?.data,
    }),
    getPositions: builder.query<string[], void>({
      query: () => ({
        url: `/${API_ENDPOINTS.POSITIONS}`,
        method: MethodType.get,
      }),
      transformResponse: (response: PositionsListResponse) => response?.data,
    }),
    updateEmployee: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.EMPLOYEES}/${params.employeeId}`,
        data: {
          department_guid: params.departmentId,
          work_location_guid: params.locationId,
          division_guid: params.divisionId,
        },
        method: MethodType.put,
      }),
      invalidatesTags: (data, error) => {
        if (!error) return [APITags.GET_EMPLOYEES]
        return []
      },
      transformResponse: (response: PositionsListResponse) => response?.data,
    }),
    deleteEmployee: builder.mutation({
      query: (params) => ({
        url: `${API_ENDPOINTS.DISABLE_EMPLOYEE}/${params.empId}`,
        data: {
          department_id: params.departmentId,
          work_location_id: params.locationId,
        },
        method: MethodType.put,
      }),
      invalidatesTags: (data, error) => {
        if (!error) return [APITags.GET_EMPLOYEES]
        return []
      },
      transformResponse: (response: PositionsListResponse) => response?.data,
    }),
  }),
})

export const {
  useGetAllEmployeesQuery,
  useLazyGetAllEmployeesQuery,
  useGetDepartmentsQuery,
  useGetLocationsQuery,
  useGetPositionsQuery,
  useUpdateEmployeeMutation,
  useGetDivisionsQuery,
  useDeleteEmployeeMutation,
} = employeeApis
