/* eslint-disable dot-notation */
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { usePostChallengeMutation, useUpdateChallengeMutation } from '../../store/api';
import { useLazyGetAllEmployeesQuery } from '../../../employee/store/api';
import { ChallengesTitleImage } from '../../../../../_metronic/assets/images';
import { ChallengeGroupIcon, ChallengeIndividualIcon, DoneCircularIcon } from '../../../../../_metronic/assets/icons';
import { RoutesPath } from '../../../../routing/RoutesPath';
import { RootState } from '../../../../store/store';
import { setChallengesFormField } from '../../../../store/slices/forms/challengesForm';
import { 
  CHALLENGE_CATEGORY_TYPE, 
  CHALLENGE_PARTICIPANT_TYPE, 
  ChallengeCategoryType, 
  ChallengeParticipantType, 
  ENUM_PRE_DEFINED_CHALLENGE_TYPE, 
  PRE_DEFINED_CHALLENGES_CONFIG 
} from '../types';

import MultiSelectDropdown from '../components/multi-select-dropdown';

import "../styles.css";

type PropsType = {
  handleFormNavigation: (navigateTo: "part_one" | "part_two") => void
}

const RenderMemberCard: React.FC<any> = (props) => {
  const {name, email} = props;
  return (
    <div className='custom-tag-member-card' title={email || undefined}>
      <h6 className='m-0 mx-4'>{name}</h6>
    </div>
  )
}


const PartTwoCreateChallengePage: React.FC<PropsType> = (props) => {

  const {handleFormNavigation} = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /* ----------------------------------- STATES ----------------------------------- */

  const {challengesForm} = useSelector((state: RootState) => state);
  const {activeDepartmentsList, activeDivisionsList, activeLocationsList, liveCustomGroupsList} = useSelector((state: RootState) => state.masterList);

  const [selectedParticipantEmployees, setSelectedParticipantEmployees] = useState<any[]>([]);
  const [selectedParticipantGroups, setSelectedParticipantGroups] = useState<any[]>([]);
  const [isValidGroupIds, setIsValidGroupIds] = useState<boolean>(true);
  const [isValidParticipantsEmp, setIsValidParticipantsEmp] = useState<boolean>(true);
  const [isValidParticipantsGrp, setIsValidParticipantsGrp] = useState<boolean>(true);
  const [isCheckedSelectAll, setIsCheckedSelectAll] = useState<boolean>(false);

  const [isLoadingGetEmployeeByCategory, setIsLodingGetEmployeesByCategory] = useState<boolean>(false);

  const isLoadingGetSelecteGroupsList = false;

  /* --------------------------------- RTK QUERY ------------------------------------- */

  const [getEmployeesByCategory, {
    data: employeesListByCategory,
  }] = useLazyGetAllEmployeesQuery();

  const [postChallenge, {
    isLoading: isLoadingPostChallenge,
    isSuccess: isSuccessPostChallenge,
    isError: isErrorPostChallenge
  }] = usePostChallengeMutation();

  const [updateChallenge, {
    isLoading: isLoadingUpdateChallenge,
    isSuccess: isSuccessUpdateChallenge,
    isError: isErrorUpdateChallenge
  }] = useUpdateChallengeMutation();

  /* ---------------------------------- HANDLERS ---------------------------------- */

  const getEmployeesListByCategory = () => {
    setSelectedParticipantEmployees([]);
    if (selectedParticipantGroups.length === 0) return;
    setIsLodingGetEmployeesByCategory(true);

    if (challengesForm.challengeGroupType === CHALLENGE_CATEGORY_TYPE.GROUP) {
      const uniqueUsersMap = new Map();
      const selectCustomGroupIds = selectedParticipantGroups.map(grp => grp.id);

      liveCustomGroupsList.forEach((group: any) => {
        if (selectCustomGroupIds.includes(group.id)) {
          group?.users?.forEach((user: any) => {
            if (!uniqueUsersMap.has(user.id)) {
              uniqueUsersMap.set(user.id, user);
            }
          });
        }
      });

      setSelectedParticipantEmployees(Array.from(uniqueUsersMap.values()));
      setIsLodingGetEmployeesByCategory(false);
      return;
    }

    const payload: any = {
      sortBy: 'name',
      sortOrder: 'ASC',
      limit: 10000
    }
    if (challengesForm.challengeGroupType === CHALLENGE_CATEGORY_TYPE.DEPARTMENT) payload.departmentId = selectedParticipantGroups.map(grp => grp.guid);
    if (challengesForm.challengeGroupType === CHALLENGE_CATEGORY_TYPE.DIVISION) payload.divisionId = selectedParticipantGroups.map(grp => grp.guid);
    if (challengesForm.challengeGroupType === CHALLENGE_CATEGORY_TYPE.LOCATION) payload.locationId = selectedParticipantGroups.map(grp => grp.guid);

    const handleSelectedEmployeesIntersection = (res: any) => {
      const data = res.data.edges;
      setSelectedParticipantEmployees((state: any) => (
        data.map((emp: any) => ({
          id: emp.id,
          name: emp.name,
          email: emp.email
        }))
      ))
    }

    getEmployeesByCategory(payload)
    .then((res: any) => {
      if (typeof res !== 'undefined') 
        handleSelectedEmployeesIntersection(res)
    })
    .finally(() => setIsLodingGetEmployeesByCategory(false))
  }

  const handleSetSelectedChallengeParticipantType = (arg: ChallengeParticipantType) => {
    dispatch(setChallengesFormField({field: "challengeType", data: arg}));
  }

  const checkValidForm = () => {
    const validGroupIds = challengesForm.groupIds.length > 0;
    const validEmpParticipants = selectedParticipantEmployees.length > 1;
    const validGrpParticipants = selectedParticipantGroups.length > 1;

    if (!validGroupIds) setIsValidGroupIds(false);
    if (!validEmpParticipants) setIsValidParticipantsEmp(false);
    if (!validGrpParticipants) setIsValidParticipantsGrp(false);

    if (challengesForm.challengeType === CHALLENGE_PARTICIPANT_TYPE.INDIVIDUAL) {
      return validGroupIds && validEmpParticipants;
    } else {
      return validGroupIds && validGrpParticipants;
    } 
  }

  const handleSubmit = (action: "publish" | "save_as_draft") => {
    if (!checkValidForm())
      return;
      
    const payload: any = {
      "name": challengesForm.name,
      "image_url": challengesForm.image,
      "type": challengesForm.challengeType,
      "group_type": challengesForm.challengeGroupType,
      "group_ids": challengesForm.groupIds,
      "start_date": moment(challengesForm.startDate).format('YYYY-MM-DD'),
      "description": challengesForm.description,
      "is_publish_ready": action === "publish" ? 1 : 0,
      "is_mandatory": challengesForm.isMandatory,
      "coin_per_user": challengesForm.coinPerUser, // default 20 coins for custom challenge
      "action_type": challengesForm.actionType,
      "action_text": challengesForm.actionText
    }
    if (challengesForm.endDate)
      payload["end_date"] = moment(challengesForm.endDate).format('YYYY-MM-DD');

    if (challengesForm.isPreDefined) {
      const selectedPreDefinedChallenge = Object.values(PRE_DEFINED_CHALLENGES_CONFIG).find((item:any) => item.ID === challengesForm.preDefinedChallengeId);
      payload["name"] = selectedPreDefinedChallenge?.NAME;
      payload["description"] = selectedPreDefinedChallenge?.DESCRIPTION;
      payload["image_url"] = selectedPreDefinedChallenge?.IMAGE;
      payload["is_mandatory"] = selectedPreDefinedChallenge?.IS_MANDATORY;
      payload["coin_per_user"] = selectedPreDefinedChallenge?.COINS_PER_USER;
      payload["action_type"] = selectedPreDefinedChallenge?.ACTION_TYPE;
      payload["pre_defined_id"] = selectedPreDefinedChallenge?.ID;
      payload["action_text"] = selectedPreDefinedChallenge?.ACTION_TEXT;
    }

    if (challengesForm?.id) {
      updateChallenge({id: challengesForm.id, payload})
    } else {
      postChallenge(payload);
    }
    setTimeout(() => {
      navigate(RoutesPath.CHALLENGES.ROOT);
    }, 500);
  }

  const handleSelectAllGroupsCheckbox = () => {
    if (isCheckedSelectAll) {
      // clear fields
      setSelectedParticipantGroups([]);
    } else {
      // set all fields
      switch (challengesForm.challengeGroupType) {
        case CHALLENGE_CATEGORY_TYPE.LOCATION: 
          setSelectedParticipantGroups(activeLocationsList);
          break;
        case CHALLENGE_CATEGORY_TYPE.DEPARTMENT: 
          setSelectedParticipantGroups(activeDepartmentsList);
          break;
        case CHALLENGE_CATEGORY_TYPE.DIVISION: 
          setSelectedParticipantGroups(activeDivisionsList);
          break;
        case CHALLENGE_CATEGORY_TYPE.GROUP: 
          setSelectedParticipantGroups(liveCustomGroupsList);
          break;
        default: 
          break;
      }
    }
    setIsCheckedSelectAll(state => !state);
  }

  useEffect(() => {
    switch (challengesForm.challengeGroupType) {
      case CHALLENGE_CATEGORY_TYPE.LOCATION: 
        if (activeLocationsList.length !== selectedParticipantGroups.length) 
          setIsCheckedSelectAll(false);
        break;
      case CHALLENGE_CATEGORY_TYPE.DEPARTMENT: 
        if (activeDepartmentsList.length !== selectedParticipantGroups.length) 
          setIsCheckedSelectAll(false);
        break;
      case CHALLENGE_CATEGORY_TYPE.DIVISION: 
        if (activeDivisionsList.length !== selectedParticipantGroups.length) 
          setIsCheckedSelectAll(false);
        break;
      case CHALLENGE_CATEGORY_TYPE.GROUP: 
        if (liveCustomGroupsList.length !== selectedParticipantGroups.length) 
          setIsCheckedSelectAll(false);
        break;
      default: 
        break;
    }
  }, [selectedParticipantGroups])

  useEffect(() => {
    getEmployeesListByCategory();
    if (selectedParticipantGroups.length === 0) {
      dispatch(setChallengesFormField({field: 'groupIds', data: []}));
    } else {
      dispatch(setChallengesFormField({field: 'groupIds', data: selectedParticipantGroups.map((item: any) => item.id)}));
    }
  }, [selectedParticipantGroups]);

  useEffect(() => {
    if (challengesForm.groupIds.length > 0) {
      switch (challengesForm.challengeGroupType) {
        case CHALLENGE_CATEGORY_TYPE.DEPARTMENT:
          setSelectedParticipantGroups(
            activeDepartmentsList.filter((item: any) => challengesForm.groupIds.includes(item.id)).map((item) => {
              const deptId: number = item.id;
              const deptName: string = item.name;
              return {
                id: deptId, 
                name: deptName
              }
            })
          );
          break;
        case CHALLENGE_CATEGORY_TYPE.DIVISION:
          setSelectedParticipantGroups(
            activeDivisionsList.filter((item: any) => challengesForm.groupIds.includes(item.id)).map((item) => {
              const divId: number = item.id;
              const divName: string = item.name;
              return {
                id: divId, 
                name: divName
              }
            })
          );
          break;
        case CHALLENGE_CATEGORY_TYPE.LOCATION:
          setSelectedParticipantGroups(
            activeLocationsList.filter((item: any) => challengesForm.groupIds.includes(item.id)).map((item) => {
              const locId: number = item.id;
              const locName: string = item.name;
              return {
                id: locId, 
                name: locName
              }
            })
          );
          break;
        case CHALLENGE_CATEGORY_TYPE.GROUP:
          setSelectedParticipantGroups(
            liveCustomGroupsList.filter((item: any) => challengesForm.groupIds.includes(item.id)).map((item) => {
              const grpId: number = item.id;
              const grpName: string = item.name;
              return {
                id: grpId, 
                name: grpName
              }
            })
          );
          break;
        default: 
          break;
      }
    }
  }, [challengesForm.id])

  useEffect(() => {
    if (challengesForm.groupIds.length > 0) setIsValidGroupIds(true);
    if (selectedParticipantEmployees.length > 1) setIsValidParticipantsEmp(true);
    if (selectedParticipantGroups.length > 1) setIsValidParticipantsGrp(true);
  }, [challengesForm.groupIds, selectedParticipantEmployees, selectedParticipantGroups])

  return (
    <div className='mb-10'>

      {/* --------------------------------------- HEADER ------------------------------------------ */}

      <div className='d-flex align-items-center justify-content-center'>
        <ChallengesTitleImage height={80} width={100} className='mt-2' />
        <h2 className='m-0 px-6' style={{fontWeight: '800'}}>CREATE A CHALLENGE</h2>
      </div>
      <div className='my-6 d-flex align-items-center justify-content-center'>
        <button 
          type="button"
          className="position-relative remove-btn-styles"
          onClick={() => handleFormNavigation("part_one")}
          >
          <DoneCircularIcon height={24} width={24} color="#0491BD" />
          <p 
            className='position-absolute' 
            style={{
              fontWeight: '800',
              marginTop: '5px',
              color: '#666666',
              width: 'max-content',
              left: '-60px'
            }}
            >
              Challenges description
          </p>
        </button>
        <div className='line-bar' style={{marginLeft: '4px', backgroundColor: "#0491BD"}} />
        <div className='line-bar' style={{marginRight: '4px', backgroundColor: "#0491BD"}} />
        <div className='position-relative'>
          <DoneCircularIcon height={24} width={24} color="#0491BD" />
          <p 
            className='position-absolute' 
            style={{
              fontWeight: '800',
              marginTop: '5px',
              color: '#666666',
              width: 'max-content',
              left: '-60px'
            }}
            >
            Participant&apos;s information
          </p>
        </div>
      </div>

      {/* --------------------------------------- BODY ------------------------------------------ */} 

      <form className='mt-10 mx-0 col'>

        <h6 className='fs-5 pt-10 mb-6'>PARTICIPANT TYPE</h6>
        <div className='row'>
          <div className='col-6'>
            <label className='d-flex align-items-center' htmlFor='individual-participant' style={{cursor: 'pointer'}}>
              <h6 className='fs-5 m-0 d-flex align-items-center'>
                <ChallengeIndividualIcon height={18} width={18} color={challengesForm.challengeType === CHALLENGE_PARTICIPANT_TYPE.INDIVIDUAL ? "#0491BD" : "black"} style={{marginRight: '5px'}} />
                INDIVIDUAL BASED
              </h6>
              <input 
                type="radio"
                name="challenge_participant_type"
                className="mx-4 radio-button-styles"
                id="individual-participant"
                value="INDIVIDUAL CHALLENGE"
                defaultChecked={challengesForm.challengeType === CHALLENGE_PARTICIPANT_TYPE.INDIVIDUAL}
                onChange={() => handleSetSelectedChallengeParticipantType(CHALLENGE_PARTICIPANT_TYPE.INDIVIDUAL)}
                />
            </label>
          </div>

          <div className='col-6'>
            <label className='d-flex align-items-center' htmlFor='group-participant' style={{cursor: 'pointer'}}>
              <h6 className='fs-5 m-0 d-flex align-items-center'>
                <ChallengeGroupIcon height={20} width={20} color={challengesForm.challengeType === CHALLENGE_PARTICIPANT_TYPE.GROUP ? "#0491BD" : "black"} style={{marginRight: '5px'}} />
                GROUP BASED
              </h6>
              <input 
                type="radio"
                name="challenge_participant_type"
                className="mx-4 radio-button-styles"
                id="group-participant"
                value="GROUP CHALLENGE"
                defaultChecked={challengesForm.challengeType === CHALLENGE_PARTICIPANT_TYPE.GROUP}
                onChange={() => handleSetSelectedChallengeParticipantType(CHALLENGE_PARTICIPANT_TYPE.GROUP)}
                />
            </label>
          </div>
        </div>

        {/* SELECT FROM :::: CATEGORY DROPDOWNS */}
        <div className='row m-0 p-0'>
          <div className='col-6 p-0'>
            <div className='d-flex justify-content-between mt-10 mb-4'>
              <h6 className='fs-5 m-0 p-0'>SELECT FROM</h6>
              <label className='m-0 p-0 d-flex align-items-center' style={{cursor: "pointer"}}>
                <span className='m-0' style={{paddingRight: '10px'}}>Select all</span>
                <input 
                  type='checkbox'
                  className='m-0 p-0'
                  style={{accentColor: "#0491BD", cursor: "pointer", height: "15px", width: "15px"}}
                  checked={isCheckedSelectAll} 
                  onChange={handleSelectAllGroupsCheckbox} 
                  />
              </label>
            </div>
            {/* CATEGORY DROPDOWN */}
            <select
              className='form-select m-0'
              onChange={(event: any) => {
                setIsCheckedSelectAll(false);
                setSelectedParticipantGroups([]);
                setSelectedParticipantEmployees([]);
                dispatch(setChallengesFormField({field: "challengeGroupType", data: event.target.value}));
              }}
              style={{border: '1px solid #bababa', color: "#202020"}}
              >
              {
                [CHALLENGE_CATEGORY_TYPE.LOCATION, CHALLENGE_CATEGORY_TYPE.DEPARTMENT, CHALLENGE_CATEGORY_TYPE.DIVISION, CHALLENGE_CATEGORY_TYPE.GROUP].map((item: ChallengeCategoryType) => (
                  <option value={item} key={item} selected={challengesForm.challengeGroupType === item}>
                    { 
                      item === CHALLENGE_CATEGORY_TYPE.GROUP ?
                      'Group' : 
                      `${item.slice(0,1)}${item.slice(1).toLowerCase()}`
                    }
                  </option>
                ))
              }
            </select>
          </div>
        </div>
              
        {/* CATEGORY DROPDOWNS */}
        <div className='row m-0 p-0'>
          <div className='col-6 p-0'>
            <h6 className='fs-5 mt-10 mb-4 p-0'>
              {
                challengesForm.challengeType === CHALLENGE_PARTICIPANT_TYPE.INDIVIDUAL ? 
                "SELECT INDIVIDUALS FROM" : 
                "RUN CHALLENGES BETWEEN"
              }
            </h6>
            {/* DEPARTMENTS DROPDOWN */}
            {
              challengesForm.challengeGroupType === CHALLENGE_CATEGORY_TYPE.DEPARTMENT && (
                <MultiSelectDropdown 
                  placeholder="Select Departments"
                  setSelectedOptions={setSelectedParticipantGroups}
                  selectedOptions={selectedParticipantGroups}
                  options={activeDepartmentsList.map((item: any) => ({
                    id: item.id, 
                    name: item.name,
                    guid: item.guid
                  }))}
                  />
              )
            }
            {/* DIVISIONS DROPDOWN */}
            {
              challengesForm.challengeGroupType === CHALLENGE_CATEGORY_TYPE.DIVISION && (
                <MultiSelectDropdown
                  placeholder="Select Divisions"
                  setSelectedOptions={setSelectedParticipantGroups}
                  selectedOptions={selectedParticipantGroups}
                  options={activeDivisionsList.map((item: any) => ({
                    id: item.id, 
                    name: item.name,
                    guid: item.guid
                  }))}
                  />
              )
            }
            {/* LOCATIONS DROPDOWN */}
            {
              challengesForm.challengeGroupType === CHALLENGE_CATEGORY_TYPE.LOCATION && (
                <MultiSelectDropdown
                  placeholder="Select Locations"
                  setSelectedOptions={setSelectedParticipantGroups}
                  selectedOptions={selectedParticipantGroups}
                  options={activeLocationsList.map((item: any) => ({
                    id: item.id, 
                    name: item.name,
                    guid: item.guid
                  }))}
                  />
              )
            }
            {/* CUSTOM GROUPS DROPDOWN */}
            {
              challengesForm.challengeGroupType === CHALLENGE_CATEGORY_TYPE.GROUP && (
                <MultiSelectDropdown
                  placeholder="Select Groups"
                  setSelectedOptions={setSelectedParticipantGroups}
                  selectedOptions={selectedParticipantGroups}
                  options={liveCustomGroupsList.map((item: any) => ({
                    id: item.id, 
                    name: item.name,
                    guid: null
                  }))}
                  />
              )
            }
          </div>
        </div>

        {
          challengesForm.challengeType === CHALLENGE_PARTICIPANT_TYPE.INDIVIDUAL ? (
            <>
              {/* SELECTED PARTICIPANTS */}
              <div>
                <h6 className='fs-5 mt-10'>
                  SELECTED PARTICIPANTS
                  {
                    (!isValidGroupIds || !isValidParticipantsEmp) &&
                    <span style={{color: 'red', margin: '0px', marginLeft: '20px'}}>*Atleast 2 participants are required</span>
                  }
                </h6>
                <div className='mt-4 wrap-create-challenges-participant-input-container'>
                  {
                    isLoadingGetEmployeeByCategory &&
                    <p style={{color: 'green', marginLeft: '0px'}}>Fetching employees...</p>
                  }
                  {
                    selectedParticipantEmployees.map((item:any) => (
                      <RenderMemberCard 
                        key={item?.id}
                        name={item?.name} 
                        email={item?.email}
                        />
                    ))
                  }
                </div>
              </div>
            </>
          ) : (
            <>
              {/* SELECTED PARTICIPANTS */}
              <div>
                <h6 className='fs-5 mt-10'>
                  SELECTED GROUPS
                  {
                    (!isValidGroupIds || !isValidParticipantsGrp) &&
                    <span style={{color: 'red', margin: '0px', marginLeft: '20px'}}>*Atleast 2 participants are required</span>
                  }
                </h6>
                <div className='mt-4 wrap-create-challenges-participant-input-container'>
                  {
                    isLoadingGetSelecteGroupsList &&
                    <p style={{color: 'green', marginLeft: '0px'}}>Fetching groups...</p>
                  }
                  {
                    selectedParticipantGroups.map((item:any) => (
                      <RenderMemberCard 
                        key={item?.id}
                        name={item?.name} 
                        email={item?.email}
                        />
                    ))
                  }
                </div>
              </div>
            </>
          )
        }

        <div className='my-8 d-flex align-items-center justify-content-evenly'>
          {
            !challengesForm?.id &&
            <button
              type='button'
              // disabled={!loading}
              className='custom-group-category-filter-button m-0 mt-8 px-14'
              style={{
                borderRadius: '8px',
                fontWeight: '700'
              }}
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              onClick={() => handleSubmit("save_as_draft")}
              >
              SAVE AS DRAFT
            </button>
          }

          <button
            type='button'
            // disabled={!loading}
            className='custom-group-category-filter-button m-0 mt-8 px-20'
            style={{
              borderRadius: '8px',
              fontWeight: '700'
            }}
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={() => handleSubmit("publish")}
            >
            PUBLISH
          </button>
        </div>

      </form>

    </div>
  )
}

export default PartTwoCreateChallengePage;
/* eslint-disable dot-notation */