/* eslint-disable react/no-unstable-nested-components */
import React, { useState, useEffect, useMemo} from 'react'

import { useLazyGetDivisionsListQuery } from '../../dashboard/store/api'

import ReactTable from '../../../../_metronic/partials/components/tables/ReactTable'
import { ModifySVG } from '../../../../_metronic/helpers/components/ModifySVG'
import { EditIcon, TrashIcon } from '../../../../_metronic/assets/icons'
import ModalDeleteCurrentCell from './modals/ModalDeleteCurrentCell'
import ModalAddDivDeptData from './modals/ModalAddDivDeptData'

import { commaSeparatedFormat } from '../../../utils/helpers'

const RenderSectionName = ({value}: {value:string}) => (
  <div>{value}</div>
)

const RenderNoOfEmployee = ({value}: {value:number}) => (
  // <div>{commaSeparatedFormat(value)}</div>
  <div>-</div>
)

const RenderMenuOptions = ({item, handleShowAddEditModal, handleShowDeleteCurrent}: any) => (
  <div className='d-flex justify-content-start flex-shrink-0'>
    <button
      type='button'
      onClick={handleShowAddEditModal}
      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
    >
      <ModifySVG className='svg-icon-3'>
        <EditIcon className='svg-icon svg-icon-3' />
      </ModifySVG>
    </button>
    {/* <button
      type='button'
      onClick={() => {
        handleShowDeleteCurrent()
      }}
      className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
    >
      <ModifySVG className='svg-icon-3'>
        <TrashIcon className='svg-icon svg-icon-3' />
      </ModifySVG>
    </button> */}
  </div>
)

const DivisionMasterData: any = () => {
  const [onChange, setOnChange] = useState<boolean>(false)
  const [showDeleteCurrentModal, setShowDeleteCurrentModal] = useState<boolean>(false)
  const [showModalAddDivDeptData, setShowModalAddDivDeptData] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [numberOfDivision, setNumberOfDivision] = useState<number>(0)
  const [currentDivisionSelected, setCurrectDivisionSelected] = useState<any>()

  const [getDivisionsList, {data: divisionListData}] = useLazyGetDivisionsListQuery()

  useEffect(() => {
    getDivisionsList({})
  }, [onChange])

  useEffect(() => {
    if (divisionListData) {
      setNumberOfDivision(divisionListData?.length)
    }
  }, [divisionListData])

  const headers = useMemo(
    () => [
      {
        Header: `DIVISIONS (${numberOfDivision})`,
        accessor: 'name',
        Cell: ({cell}: {cell: any}) => (
          <RenderSectionName value={cell.row.original.name} />
        )
      },
      {
        Header: 'NO. OF EMPLOYEES', 
        accessor: 'total_employee_count', 
        Cell: ({cell}: {cell: any}) => (
          <RenderNoOfEmployee value={cell.row.original.total_employee_count || 0} />
        )
      },
      {
        Header: 'ACTION',
        accessor: 'edit',
        disableSortBy: true,
        // eslint-disable-next-line react/no-unstable-nested-components
        Cell: ({cell}: {cell: any}) => (
          <RenderMenuOptions
            item={cell.row.original}
            handleShowAddEditModal={() => {
              setIsEdit(true)
              setCurrectDivisionSelected(cell.row.original)
              setTimeout(() => {
                setShowModalAddDivDeptData(true)
              }, 500)
            }}
            handleShowDeleteCurrent={() => setShowDeleteCurrentModal(true)}
          />
        ),
      },
    ],
    [numberOfDivision]
  )

  return (
    <div className='my-5 position-relative'>
      <ReactTable
        headerData={headers}
        tableData={divisionListData || []}
        currentPageIndex={0}
        className='custom-border'
        manualSort
        disableSort
        showPagination={false}
        manualPagination
        message='No data found!'
      />
      {/* <button
        type='button'
        // disabled={!loading}
        className='custom-primary-delete-button m-0 master-data-add-new-btn'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        data-kt-menu-flip='top-end'
        onClick={() => {
          setIsEdit(false)
          setShowModalAddDivDeptData(true)
        }}
      >
        Add New Division
      </button> */}
      <ModalAddDivDeptData
        show={showModalAddDivDeptData}
        onHide={() => setShowModalAddDivDeptData(false)}
        onChange={() => setOnChange(state => !state)}
        category='division'
        isEdit={isEdit}
        name={currentDivisionSelected?.name || ''}
        numberOfEmployee={currentDivisionSelected?.total_employee_count || null}
        id={currentDivisionSelected?.id || null}
        />
      <ModalDeleteCurrentCell
        show={showDeleteCurrentModal}
        onHide={() => setShowDeleteCurrentModal(false)}
        onChange={() => setOnChange(state => !state)}
        />
    </div>
  )
}

export default DivisionMasterData
/* eslint-disable react/no-unstable-nested-components */