/* eslint-disable global-require */
import { useEffect } from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import {RoutesPath} from '../../routing/RoutesPath'
import {getDDMonthYYYYFormat} from '../../utils/dateFormatter'
import {ForestDetailFilteredResponse, ForestDetailIndividual} from './types'
import {injectParamsToUrl} from '../../utils/urlParamsInjector'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useLongPress} from '../../utils/useLongPress'
import {ReactComponent as ForestCardImage} from '../../../assets/image 77.svg'
import OpenIcon from '../../../assets/OpenIcon.svg'

type ProjectCartType  = {
  forestsList :  ForestDetailFilteredResponse | undefined
  forest: ForestDetailIndividual
  recapture: number
}

const ProjectCard = ({forest,forestsList,recapture}:ProjectCartType) => {
  const intl = useIntl()
  const navigate = useNavigate()

  const startYear = new Date(forest.plantation_start_date).getUTCFullYear()

  function handleNavigation() {
    navigate(RoutesPath.OUR_FOREST.PROJECT_UPDATE,{
      state:forest
    })
  }

  return (
    <div role='presentation' onClick={handleNavigation} className='card custom-border' style={{cursor:'pointer'}}>
      <div>
        <div className='d-flex align-items-end mt-3 mx-2 flex-row justify-content-between'>
          <div className='title mt-3 mx-4 text-black'>{forest?.name || `Mynzo-Forest ${forest?.project_id}`}</div>
          {/* <button
            type='button'
            className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
            
          >
            <img src={OpenIcon} alt='' />
          </button> */}
        </div>
        <div className='sub-heading mt-1 mx-6'>
          {forest.district} , {forest.villege}
        </div>
      </div>

      <div className='position-relative'>
        <img
          className='card-img-top mt-3'
          src='https://mynzo.s3.ap-south-1.amazonaws.com/images/forest.svg'
          alt='forest'
          style={{height: '250px'}}
        />
      </div>

      <div className='card-body' style={{padding: '20px'}}>
        {/** progress bar */}
        <div className='d-flex align-items-center  flex-column mt-1'>
          <div className='d-flex justify-content-between w-100 mb-2'>
            <div>
              <span className='fw-bold fs-5' style={{color: '#0491BD'}}>
                {forest.area_planted?.toFixed(2)}
              </span>
              <span className='text-muted-dark'> / {forest.area?.toFixed(2)} Acres planted</span>
            </div>
          </div>
          <div className='h-10px mx-3 mt-1 w-100 bg-light mb-3'>
            <div
              className='bg-progress-green h-10px'
              role='progressbar'
              style={{
                width: `${Math.round((forest.area_planted/ forest.area) * 100)}%`,
              }}
            />
          </div>
        </div>
        {/** --------- */}
        <div className=' d-flex mt-2 flex-row justify-content-between'>
          <p style={{color: '#0491BD'}}>{recapture?.toFixed(2)} kgCO2 recaptured</p>
          <p>
            <span className='text-muted-dark'>
              {Math.round((forest.area_planted / forest.area) * 100)}%
            </span>
            <span className='text-muted-dark'> Planted</span>
          </p>
        </div>

        {/* <div className='d-flex w-full justify-content-end'>
          <button
            type='button'
            className='btn btn-light-primary btn-sm fw-bolder'
            onClick={() =>
              navigate(
                injectParamsToUrl(RoutesPath.OUR_FOREST.ALLOCATE_PROJECT, {projectId: forest.guid})
              )
            }
          >
            {intl.formatMessage({id: 'OUR_FOREST.ALLOCATE_PROJECT'})}
          </button>
        </div> */}
      </div>
    </div>
  )
}

export default ProjectCard
