/* eslint-disable react/no-unstable-nested-components */
import {FC, useEffect, useState, useMemo} from 'react'
import { useSelector } from 'react-redux'
import Badge from 'react-bootstrap/Badge'
import Stack from 'react-bootstrap/Stack'
import {createPortal} from 'react-dom'
import {useIntl} from 'react-intl'
import ReactTable from '../../../../_metronic/partials/components/tables/ReactTable'
import {useLazyGetEmissionDetailedListQuery} from '../store/api'
import {getYYYYDDMMFormat} from '../../../utils/dateFormatter'
import {MenuItem} from '../../../../_metronic/partials/components/types'
import {getLocalStorageItem} from '../../../utils/storage'
import { commaSeparatedFormat } from '../../../utils/helpers'
import { RootState } from '../../../store/store'

const BadgeStack = ({value}: any) => {
  // internal temp state
  // const [isShow, setIsShow] = useState<Boolean>(false);
  const [isHover, setIsHover] = useState<Boolean>(false)
  const [mouseX, setMouseX] = useState(0)
  const [mouseY, setMouseY] = useState(0)
  // list of badges..
  const badges: any[] = []

  // const handleOnShowMoreBadges = () => {
  //   setIsShow(state => !state)
  // }

  const handleMouseOver = (evt: any) => {
    setIsHover(true)
    const x = evt.clientX + 10
    const y = evt.clientY + 20
    setMouseX(x)
    setMouseY(y)
  }

  value.forEach((itm: any) => {
    badges.push(
      <Badge key={itm} bg='light' text='dark' style={{fontSize: '13px'}}>
        {itm}
      </Badge>
    )
  })
  return (
    <Stack
      direction='horizontal'
      gap={2}
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        position: 'relative',
      }}
    >
      {badges.length > 2 ? (
        <>
          {badges.slice(0, 2)}
          <Badge
            bg='light'
            text='dark'
            style={{
              // textDecoration: 'underline',
              fontSize: '13px',
              cursor: 'pointer',
              fontWeight: 'bold',
            }}
            onMouseOver={handleMouseOver}
            onMouseLeave={() => {
              setIsHover(false)
            }}
          >
            {`+${badges.length - 2}`}
          </Badge>
        </>
      ) : (
        badges
      )}
      {createPortal(
        <Stack
          direction='vertical'
          gap={3}
          style={{
            position: 'absolute',
            display: isHover ? 'flex' : 'none',
            flexDirection: 'column',
            top: mouseY,
            left: mouseX,
            background: 'white',
            borderRadius: '5px',
            padding: '10px',
            marginBottom: '10px',
            zIndex: 1,
            boxShadow: '0 4px 10px #b8b8b8',
          }}
        >
          {badges.slice(2)}
        </Stack>,
        document.body
      )}
    </Stack>
  )
}

const RenderCellTitle = ({item, isMain, unit, alignTxtAt}: any) => (
  <div className={`text-dark fw-bold fs-5 ${isMain ? 'text-decoration-underline' : ''}`} style={{textAlign: alignTxtAt}}>
    {item}
    {unit ? ` ${unit}` : ''}
  </div>
)

type AllLocationsTableProps = {
  selectedMonth: MenuItem | undefined
}

const AllLocationsTable: FC<AllLocationsTableProps> = ({selectedMonth}) => {
  const intl = useIntl()

  const {filterDate, filterExtrapolation} = useSelector((state:RootState) => state.filters)
  const avgEployeesFlag = useSelector((state: RootState) => state.flags.avgEployeesFlag)

  const [getEmissionDetailsList, {data: emissionDetails, isLoading: isLoadingEmissionDetails}] =
    useLazyGetEmissionDetailedListQuery()

  useEffect(() => {
      const month = selectedMonth?.label
      // if (month!=='All') {
      //   const monthIndex:number = Number(selectedMonth?.value) || 0;
      //   const currentDate = new Date()
      //   const selectedYear = monthIndex > currentDate.getUTCMonth() ? currentDate.getUTCFullYear() - 1 : currentDate.getUTCFullYear()
      //   let endDay
      //   if (monthIndex === currentDate.getUTCMonth()) {
      //     endDay = new Date(selectedYear, monthIndex, currentDate.getUTCDate())
      //   } else {
      //     endDay = new Date(selectedYear, monthIndex + 1, 0)
      //   }
      //   const payload = {
      //     fromDate: getYYYYDDMMFormat(new Date(selectedYear, monthIndex, 1)),
      //     toDate: getYYYYDDMMFormat(endDay),
      //     category: 'location',
      //   }
      //   getEmissionDetailsList(payload)
      // } 
      // else {
      //   const liveOnDate = new Date(getLocalStorageItem('live-on'))
      //   const date = new Date()
      //   const payload = {
      //     fromDate: getYYYYDDMMFormat(liveOnDate),
      //     toDate: getYYYYDDMMFormat(date),
      //     category: 'location',
      //     is_scaled: filterExtrapolation,
      //   }
      //   getEmissionDetailsList(payload)
      // }
      if (filterDate.startDate !== '') {
        const payload = {
          fromDate: filterDate.startDate,
          toDate: filterDate.endDate,
          category: 'location',
          is_scaled: month!=='All' && filterExtrapolation,
          by_average: avgEployeesFlag
        }
        getEmissionDetailsList(payload)
      }
  }, [selectedMonth, filterExtrapolation, filterDate, avgEployeesFlag])

  const [sortBy, setSortBy] = useState('')
  const [sortOrder, setSortOrder] = useState('')

  // const handleEditEmployee = (employee: Employee) => {
  //   navigate(RoutesPath.EDIT_EMPLOYEE, { state: { selectedEmployee: employee } });
  // };

  const [currentPage, setCurrentPage] = useState(0)

  const handlePagechange = (pageIndex: number) => {
    setCurrentPage(pageIndex)
  }

  const onSortChange = (columnId: string, order: string) => {
    if (columnId !== sortBy) {
      setSortBy(columnId)
    }
    if (order !== sortOrder) {
      setSortOrder(order)
    }
  }

  const headers = useMemo(
    () => [
      {
        Header: 'LOCATIONS',
        accessor: 'location',
        Cell: ({cell}: {cell: any}) => <RenderCellTitle item={cell.value[0]} />,
        disableSortBy: true
      },
      {
        Header: 'DIVISIONS',
        accessor: 'division',
        Cell: ({cell}: {cell: any}) => <BadgeStack value={cell.value} />,
        disableSortBy: true
      },
      {
        Header: 'DEPARTMENTS',
        accessor: 'department',
        Cell: ({cell}: {cell: any}) => <BadgeStack value={cell.value} />,
        disableSortBy: true
      },
      {
        Header: () => <div style={{marginLeft: '17%', display: 'inline-block'}}>EMPLOYEES</div>,
        accessor: 'unique_employee_count',
        Cell: ({cell}: {cell: any}) => <RenderCellTitle alignTxtAt='center' item={commaSeparatedFormat(cell.value)} />,
        disableSortBy: true
      },
      {
        Header: () => <div style={{marginLeft: '17%', display: 'inline-block'}}>CO2 EMISSION (kg)</div>,
        accessor: 'emission',
        Cell: ({cell}: {cell: any}) => <RenderCellTitle alignTxtAt='center' item={commaSeparatedFormat(Math.round(cell.value))} />,
        disableSortBy: true
      },
      {
        Header: () => <div style={{marginLeft: '17%', display: 'inline-block'}}>CO2 RECAPTURE (kg)</div>,
        accessor: 'recapture',
        Cell: ({cell}: {cell: any}) => <RenderCellTitle alignTxtAt='center' item={commaSeparatedFormat(cell.value.toFixed(2))} />,
        disableSortBy: true
      },
      // {
      //   Header: '',
      //   accessor: 'edit',
      //   Cell: ({cell}: {cell: any}) => (
      //     <div
      //       className='d-flex align-items-center justify-content-center'
      //       style={{height: 22, width: 22, border: '1px solid #5CADC9', borderRadius: '4px', cursor: 'pointer'}}
      //     >
      //       <i className='bi bi-three-dots-vertical' style={{color: '#5CADC9'}} />
      //     </div>
      //   ),
      // },
    ],
    []
  )

  return (
    <div className='portal1'>
      <ReactTable
        headerData={headers}
        // tableData={employeeData?.edges ?? []}
        tableData={emissionDetails || []}
        currentPageIndex={currentPage}
        className='min-w-100px bordered-grey'
        manualSort
        onSort={onSortChange}
        showPagination={false}
        pagesCount={1}
        pageSize={100}
        onPageChange={handlePagechange}
        message={intl.formatMessage({id: 'EMPLOYEE.NO_RESULTS_FOUND'})}
        disableSort={false}
      />
    </div>
  )
}

export {AllLocationsTable}
