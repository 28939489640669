import React, {useState, useEffect} from 'react'
import Modal from 'react-bootstrap/Modal'
import { TrashIcon2 } from '../../../../../_metronic/assets/icons'

export default function ModalDeleteCurrentCell(props:any) {
  const {onHide, onChange} = props

  const handleDelete = () => {
    onHide()
    onChange()
  }

  return (
    <Modal {...props} size='md' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Body>
        <button type='button' className='remove-btn-styles modal-header-cross-btn' onClick={onHide}>
          <i className="bi bi-x-lg" style={{fontSize: '16px', color: 'black'}} />
        </button>
        <div className='d-flex align-items-center justify-content-center flex-column'>
          <div className='custom-tag-delete-modal-trash-wrap'>
            <TrashIcon2 style={{color: 'black', height: '60px', width: '60px'}} />
          </div>
          <h4 className='m-0 fs-2 mt-8'>Are you sure?</h4>
          <h6 className='m-0 mt-4'>Do you want to delete this?</h6>
        </div>
        <div className='d-flex align-items-center justify-content-between mb-5 mt-15 px-15'>
          <button
            type="button"
            className='remove-btn-styles btn-outline-round-style'
            style={{fontWeight: '600'}}
            onClick={onHide}
          >
            CANCEL
          </button>
          <button
            type="button"
            className='remove-btn-styles btn-outline-round-style'
            style={{color: '#C03E35', fontWeight: '600'}}
            onClick={() => handleDelete()}
          >
            DELETE
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}