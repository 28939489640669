import React, { useState, useEffect } from 'react';
import './login.css';

interface TextCarouselProps {
  texts: (string | JSX.Element)[];
}

export const TextCarousel: React.FC<TextCarouselProps> = ({ texts }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPause, setIsPause] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!isPause) {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % texts.length);
      }
      }, 5000);   
      return () => clearInterval(interval);
  }, [texts.length, isPause]);

  const handleIndicatorClick = (index: number) => {
    setCurrentIndex(index);
  };

  const handlePause = () => {
    setIsPause(true)
  }
  const handlePlay = () => {
    setIsPause(false)
  }

  return (
    <div className="carousel">
      <div className='carousel-in-wrapper'>
        <div className='shadow-div' />
        <div className="carousel-container" >
            <div className="carousel-text" onMouseOver={handlePause} onFocus={handlePause} onMouseLeave={handlePlay}>
              <h1>{texts[currentIndex]}</h1>
            </div>
        </div>
        <div className="carousel-indicators">
          {texts.map((_, index) => (
            <span
            key={_.toString().slice(0.5)}
            role="presentation"
            className={`indicator ${currentIndex === index ? 'active' : ''}`}
            onClick={() => handleIndicatorClick(index)}
            onKeyDown={() => handleIndicatorClick(index)}
            />
            ))}
        </div>
      </div>
    </div>
  );
};
