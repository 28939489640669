/* eslint-disable global-require */
/* eslint no-restricted-syntax: ["error", "FunctionExpression", "WithStatement", "BinaryExpression[operator='in']"] */
/* eslint-disable no-nested-ternary */
import React, {useEffect, useState} from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { 
  useLazyGetChallengesListQuery, 
  useDeleteChallengeMutation, 
  useLazyGetCustomGroupsListQuery,
  useLazyGetChallengesSummaryQuery
} from '../store/api';
import { 
  useLazyGetDivisionsListQuery,
  useLazyGetDepartmentsListQuery,
  useLazyGetLocationsListQuery
} from '../../dashboard/store/api';
import { RoutesPath } from '../../../routing/RoutesPath';
import { updateNotification } from '../../../store/slices/notifier';
import { RootState, useAppDispatch } from '../../../store/store';
import { NotificationType } from '../../../../_metronic/partials/components/types';

import ModalDeleteChallenge from './components/modal-delete-challenge';
import { CardChallenges } from './components/card-challenges';
import { CreateChallengesHeroImage, EmptyBackgroudCustomGroups } from '../../../../_metronic/assets/images';
import { setActiveDepartmentsList, setActiveDivisionsList, setLiveCustomGroupsList, setActiveLocationsList } from '../../../store/slices/masterList';
import { FilterExistingChallenges } from './components/filter-existing-challenges';

import './styles.css';
import { HeaderInfoCard } from '../quizzes/components/header-info-card';


type Props = {

}

export function transformChallengesListData(inputData:any) {
  const result: any = [];

  (inputData || []).forEach((item: any) => {
    const newItem: any = {
      id: item.id,
      name: item.name,
      description: item.description,
      image: item.imageUrl,
      startDate: item.startDate,
      endDate: item.endDate,
      challengeType: item.type,
      challengeGroupType: item.groupType,
      groupIds: item.groupIds,
      status: item.status,
      participantCount: item.participantCount,
      actionType: item.actionType,
      actionText: item.actionText,
      coinPerUser: item.coinPerUser
    };
    result.push(newItem);
  })

  return result;
}

const ChallengesPage: React.FC<Props> = () => {

   /* ------------------------- REDUX DISPATCHER, SELECTOR --------------------------- */

   const intl = useIntl();
   const dispatch = useAppDispatch();
   const navigate = useNavigate();
   const { filterChallengesType } = useSelector((state: RootState) => state.filters);
   const { activeDepartmentsList, activeDivisionsList, activeLocationsList, liveCustomGroupsList } = useSelector((state: RootState) => state.masterList)

  /* ----------------------------- REACT STATES ------------------------------ */

  const [deleteModalShow, setDeleteModalShow] = useState<boolean>(false);
  const [deleteChallengeId, setDeleteChallengeId] = useState<number>(0);
  const [transformedChallengesListData, setTransformedChallengesListData] = useState([]);

  /* --------------------------------- RTK QUERY ----------------------------------- */

  const [getChallengesList, {
    data: challengesListData, 
    isSuccess: isSuccessGetChallenges, 
    isLoading: isLoadingGetChallenges,
    isError: isErrorGetChallenges,
  }] = useLazyGetChallengesListQuery();

  const [deleteChallenge, {
    isSuccess: isSuccessDeleteChallenge, 
    isLoading: isLoadingDeleteChallenge, 
    isError: isErrorDeleteChallenge
  }] = useDeleteChallengeMutation();

  const [getDivisionsList, {data: divisionsListData}] = useLazyGetDivisionsListQuery()
  const [getDepartmentsList, {data: departmentsListData}] = useLazyGetDepartmentsListQuery()
  const [getLocationsList, {data: locationsListData}] = useLazyGetLocationsListQuery()

  const [getCustomGroupList, {
    data: customGroupsListData, 
    isSuccess: isSuccessGetGroups, 
    isLoading: isLoadingGetGroups,
    isError: isErrorGetGroups,
  }] = useLazyGetCustomGroupsListQuery();

  const [getChallengesSummary, {
    data: challengesSummary,
    isSuccess: isSuccessChallengeSummary,
    isLoading: isLoadingChallengesSummary,
    isError: isErrorChallengesSummary
  }] = useLazyGetChallengesSummaryQuery();

  /* ---------------------------------- HANDLERS ----------------------------------- */

  const handleDeleteChallenge = () => {
    // deleteChallenge({
    //   "id": deleteChallengeId
    // })
    // .then(res => {
    //   getChallengesList({})
    //   .catch(err => console.log(err))
    // })
    // .catch(err => console.log(err))
  }

  const handleRefetchChallengesList = () => {
    getChallengesList({})
      .catch(err => console.log(err))
  }

  const handleCreateChallengesNavigation = () => {
    navigate(RoutesPath.CHALLENGES.CREATE_CHALLENGES)
  }

  /* --------------------------- COMPONENT MOUNT - API CALLS ----------------------------- */

  useEffect(() => {
    if (challengesListData) {
      const groupsRes:any = transformChallengesListData(challengesListData?.data?.challenges);
      setTransformedChallengesListData(groupsRes);
    }
  }, [challengesListData])

  useEffect(() => {
    getChallengesList({})
    .catch(err => console.log(err))

    getChallengesSummary({});
  }, []);

  useEffect(() => {
    if (activeDivisionsList.length === 0) {
      getDivisionsList({isActive: true}).then((res:any)=>{
        const arr: any[] = []
        res?.data.forEach((itm:any) => {
          arr.push(itm)
        })
        dispatch(setActiveDivisionsList(arr))
      })
    }
    if (activeDepartmentsList.length === 0) {
      getDepartmentsList({isActive: true}).then((res:any)=>{
        const arr: any[] = []
        res?.data.forEach((itm:any) => {
          arr.push(itm)
        })
        dispatch(setActiveDepartmentsList(arr))
      })
    }
    if (activeLocationsList.length === 0) {
      getLocationsList({isActive: true}).then((res:any)=>{
        const arr: any[] = []
        res?.data.forEach((itm:any) => {
          arr.push(itm)
        })
        dispatch(setActiveLocationsList(arr))
      })
    }
    if (liveCustomGroupsList.length === 0) {
      getCustomGroupList({}).then((res:any)=>{
        let arr: any[] = Object.values(res?.data?.data?.groups || {});
        arr = arr.filter((item: any) => item.status === "live");
        dispatch(setLiveCustomGroupsList(arr));
      })
    }
  }, []);

  /* ----------------------------- TOAST MESSAGES ------------------------------ */

  useEffect(() => {
    if (isSuccessDeleteChallenge && !isLoadingDeleteChallenge) {
      dispatch(
        updateNotification({
          message: 'Challenge deleted successfully', 
          type: NotificationType.SUCCESS
        })
      )
    }
  }, [isSuccessDeleteChallenge])

  useEffect(() => {
    if (isErrorDeleteChallenge || isErrorGetChallenges) {
      dispatch(
        updateNotification({
          message: intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}),
          type: NotificationType.ERROR,
        })
      )
    }
  }, [isErrorDeleteChallenge, isErrorGetChallenges])

  return (
    <div className='blank-page-view py-8 px-12'>
      <div className='d-flex align-items-center justify-content-between mb-8'>
        <h1 className='m-0 page-title-text'>CHALLENGES</h1>
        <button
          type='button'
          // disabled={!loading}
          className='custom-group-category-filter-button m-0 mx-4 px-12 py-2'
          style={{
            borderRadius: '8px',
            fontWeight: '700'
          }}
          data-kt-menu-trigger='click'
          data-kt-menu-placement='bottom-end'
          data-kt-menu-flip='top-end'
          onClick={handleCreateChallengesNavigation}
        >
          CREATE A NEW CHALLENGE
        </button>
      </div>
      {/* <div className='mt-2 d-flex align-items-center justify-content-center'>
        <CreateChallengesHeroImage width='240px' height="100px" />
      </div> */}
      {/* HEADER CARDS */}
      <div className='d-flex justify-content-between'>
        <HeaderInfoCard   
          label="TOTAL CHALLENGES LAUNCHED"
          value={`${challengesSummary?.data?.totalChallengesCount ? challengesSummary?.data?.totalChallengesCount : '--'}`}
          containerStyle={{width: '25%'}}
          />
        <HeaderInfoCard 
          label="AVERAGE PARTICIPATION %"
          value={`${challengesSummary?.data?.avgParticipationPercentage ? Math.round(Number(challengesSummary?.data?.avgParticipationPercentage || 0)) : '--'}%`}
          containerStyle={{width: '25%'}}
          />
        <HeaderInfoCard 
          type="white"
          label="TOP EMPLOYEE"
          value={challengesSummary?.data?.topEmployee?.length ? challengesSummary?.data?.topEmployee[0].name.toUpperCase() : '--'}
          containerStyle={{width: '25%'}}
          />
        <HeaderInfoCard 
          type="white"
          label="TOP LOCATION"
          value={challengesSummary?.data?.topLocation?.length ? challengesSummary?.data?.topLocation[0].name.toUpperCase() : '--'}
          containerStyle={{width: '25%'}}
          />
      </div>
      <div className='mt-8'>
        <h1 className='page-title-text fs-2 mb-6 mt-6'>EXISTING CHALLENGES</h1>
        <FilterExistingChallenges />
        <div className='container-custom-groups-card'>
          {
            transformedChallengesListData?.length > 0 && 
            transformedChallengesListData?.map((group: any) => {
              if (group.status !== filterChallengesType) return null;
              return (
                <CardChallenges 
                  key={group.id}
                  showDeleteModal={(id: number) => {
                    setDeleteChallengeId(id);
                    setTimeout(() => setDeleteModalShow(true), 200); 
                  }}
                  groupData={group}
                  />
              )
            })
          }
          {
            isLoadingGetChallenges && !isSuccessGetChallenges ? (
              <div className='mt-12 d-flex align-items-center justify-content-center' style={{flex: 1}}>
                <h3>Loading...</h3>
              </div>
            ) : (
              transformedChallengesListData?.filter((group: any) => group.status === filterChallengesType)?.length === 0 ?
              <div className='d-flex justify-content-center align-items-center my-10' style={{flex: 1, flexDirection: 'column'}}>
                <h3>There are no {filterChallengesType}{filterChallengesType==="draft"&&"ed"} challenges!</h3>
                <EmptyBackgroudCustomGroups height={300} width={400} />
              </div> : null
            )
          }
        </div>
      </div>
      <ModalDeleteChallenge 
        show={deleteModalShow}
        onHide={() => setDeleteModalShow(false)} 
        onDelete={handleDeleteChallenge}
        />
    </div>
  )
}

export default ChallengesPage;
/* eslint-disable no-nested-ternary */
/* eslint no-restricted-syntax: ["error", "FunctionExpression", "WithStatement", "BinaryExpression[operator='in']"] */
/* eslint-disable global-require */