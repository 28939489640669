import React, {useState, useEffect, useRef} from 'react'
import { useIntl } from 'react-intl'
import Modal from 'react-bootstrap/Modal'
import { useSelector } from 'react-redux';
import moment from 'moment';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";
import { useLazyGetAllEmployeesQuery } from '../../../employee/store/api';
import { usePostCustomGroupMutation, useUpdateCustomGroupMutation } from '../../store/api';
import { tagsColorPalette, tagsDarkColorShade } from '../../../../utils/helpers';
import { updateNotification } from '../../../../store/slices/notifier';
import { RootState, useAppDispatch } from '../../../../store/store';
import { NotificationType } from '../../../../../_metronic/partials/components/types';
import { CustomGroupIcon } from '../../../../../_metronic/assets/icons';
import MultiSelectDropdown from '../../challenges/components/multi-select-dropdown';


type CreateCustomGroupPropsType = {
  show: boolean;
  groupTitle: string; 
  onHide: () => void; 
  isEdit: boolean; 
  selectedGroupColor: string; 
  groupData: any;
  onSubmitSuccess: () => void;
}

const RenderMemberCard: React.FC<any> = (props) => {
  const {name, email, removeMember} = props;
  return (
    <div className='custom-tag-member-card' title={email}>
      <h6 className='m-0 mx-4'>{name}</h6>
      <button type='button' className='remove-btn-styles' onClick={removeMember}>
        <i className="bi bi-x-circle-fill" style={{fontSize: '18px', color: '#666666'}} />
      </button>
    </div>
  )
}

const RenderSearchCard: React.FC<any> = (props) => {
  const {name, email, isLastCell, addMember} = props;
  return (
    <button 
      type='button'
      className='custom-tag-emp-search-cell remove-btn-styles'
      style={{borderWidth: isLastCell ? '0px': '0px 0px 1px 0px'}}
      onClick={addMember}
      >
      {name}
      <p className='custom-tag-emp-search-email-text'>• {email}</p>
    </button>
  )
}

const RenderTagColorBtn: React.FC<any> = (props) => {
  const {onClick, color} = props;
  return (
    <button
      type='button'
      onClick={onClick}
      className='remove-btn-styles tag-color-picker-btn m-1'
      style={{backgroundColor: color, border: '1.5px solid', borderColor: tagsDarkColorShade[color]}}
    />
  )
}

export default function ModalCreateCustomModal(props: CreateCustomGroupPropsType) {
  const {show, groupTitle, onHide, isEdit, selectedGroupColor, groupData, onSubmitSuccess} = props;

  /* -------------------------------- REACT STATES ----------------------------------- */

  const {profile} = useSelector((state: RootState) => state.profile)
  const {activeDepartmentsList, activeDivisionsList, activeLocationsList} = useSelector((state: RootState) => state.masterList)
  const [groupTitleVal, setGroupTitleVal] = useState('')
  const [groupDesciptionVal, setGroupDescriptionVal] = useState('')
  const [startDate, setStartDate] = useState<any>()
  const [endDate, setEndDate] = useState<any>()
  const [searchTerm, setSearchTerm] = useState('')
  const [isValidGroupName, setIsValidGroupName] = useState(true)
  const [isValidDates, setIsValidDates] = useState(true)
  const [isValidDescription, setIsValidDescription] = useState(true)
  const [isValidMemberList, setIsValidMemberList] = useState(true)
  const [lastDebounceSearch, setLastDebounceSearch] = useState('')
  const [isSearchTermActive, setIsSearchTermActive] = useState(false)
  const [selectedEmployees, setSelectedEmployees] = useState<any[]>([])
  const [isColorPickerActive, setIsColorPickerActive] = useState(false)
  const [groupColor, setGroupColor] = useState(selectedGroupColor || tagsColorPalette[0])
  const [isDisableSubmit, setIsDisableSubmit] = useState<boolean>(false)
  const [isLoadingGetEmployeeByCategory, setIsLodingGetEmployeesByCategory] = useState<boolean>(false)
  const [selectedDeptDropdown, setSelectedDeptDropdown] = useState<any[]>([])
  const [selectedDivDropdown, setSelectedDivDropdown] = useState<any[]>([])
  const [selectedLocDropdown, setSelectedLocDropdown] = useState<any[]>([])

  /* ------------------------- REDUX DISPATCHER, SELECTOR --------------------------- */

  const intl = useIntl()
  const dispatch = useAppDispatch()

  /* --------------------------------- RTK QUERY ------------------------------------- */

  const [getAllEmployees, {data: employeesData}] = useLazyGetAllEmployeesQuery();
  const [getEmployeesByCategory, {
    data: employeesListByCategory,
  }] = useLazyGetAllEmployeesQuery();
  const [postCustomGroup, {isSuccess, isError, isLoading}] = usePostCustomGroupMutation();
  const [updateCustomGroup, {
    isSuccess: isSuccessUpdateTag, 
    isError: isErrorUpdateTag, 
    isLoading: isLoadingUpdateTag
  }] = useUpdateCustomGroupMutation();

  const getEmployeeData = () => {
    getAllEmployees({
      offset: 0,
      limit: 5,
      searchTerm: searchTerm.trim(),
      sortBy: 'name',
      sortOrder: 'ASC',
    })
    .then((res) => {
      // preventing empty state while debouncing
      setLastDebounceSearch(searchTerm)
    })
  }

   /* ---------------------------------- VALIDATIONS ----------------------------------- */

   const checkValidGroupName = (nameVal:string) => {
    const str = nameVal.trim()
    if (str.length<3) {
      setIsValidGroupName(false)
      return false;
    }
    setIsValidGroupName(true)
    return true;
  }

  const checkValidDates = (start: any) => {
    if (!start) {
      setIsValidDates(false)
      return false;
    }
    setIsValidDates(true)
    return true;
  }

  const checkValidDescription= (nameVal:string) => {
    const str = nameVal.trim()
    if (str.length!==0 || str.length < 10) {
      setIsValidDescription(false)
      return false;
    }
    setIsValidDescription(true)
    return true;
  }

  const checkValidSelectedMembers = (numOfSelectedMembers:number) => {
    if (numOfSelectedMembers === 0) {
      setIsValidMemberList(false)
      return false;
    }
    setIsValidMemberList(true)
    return true;
  }

  const checkValidForm = () => {
    const checkA = checkValidGroupName(groupTitleVal)
    const checkB = checkValidDates(startDate)
    const checkC = checkValidSelectedMembers(selectedEmployees.length)
    return checkA && checkB && checkC
  }

  /* ---------------------------------- HANDLERS ----------------------------------- */

  // debouncing while searching for employees
  useEffect(() => {
    // resetting current page to first page when searched for employee
    const getData = setTimeout(() => {
      if (searchTerm.trim().length>0) {
        getEmployeeData()
      } 
    }, 500)
    return () => clearTimeout(getData)
  }, [searchTerm])

  const handleAddMember = (emp:any) => {
    let isPresent = false;
    selectedEmployees.forEach((item) => {
      if (item.id === emp.id) {
        isPresent = true;
      }
    })
    setSelectedEmployees((state:any) => {
      if (!isPresent) {
        return [...state, {id: emp.id, name: emp.name, email: emp.email}]
      }
      return state
    })
  }

  const getEmployeesListByCategory = () => {
    if(!show) return;
    if (selectedDeptDropdown.length === 0 && selectedDivDropdown.length === 0 && selectedLocDropdown.length === 0) return;
    else setSelectedEmployees([]);
    setIsLodingGetEmployeesByCategory(true);

    const payload: any = {
      sortBy: 'name',
      sortOrder: 'ASC',
      limit: 10000
    }
    if (selectedDeptDropdown.length>0) payload.departmentId = selectedDeptDropdown.map((item:any) => item.guid);
    if (selectedDivDropdown.length>0) payload.divisionId = selectedDivDropdown.map((item:any) => item.guid);
    if (selectedLocDropdown.length>0) payload.locationId = selectedLocDropdown.map((item:any) => item.guid);

    const handleSelectedEmployeesIntersection = (res: any) => {
      const data = res.data.edges;
      setSelectedEmployees((state: any) => {
        if (state.length > 0) {
          const emails = data.map((emp: any) => emp.email);
          return state.filter((emp: any) => emails.includes(emp.email));
        }
        else {
          return data?.filter((item: any) => item.is_onboarding_completed === 1)?.map((emp: any) => ({
            id: emp.id,
            name: emp.name,
            email: emp.email
          }));
        }
      })
    }

    getEmployeesByCategory(payload)
    .then((res: any) => {
      if (typeof res !== 'undefined') 
        handleSelectedEmployeesIntersection(res)
    })
    .finally(() => setIsLodingGetEmployeesByCategory(false))
  }

  useEffect(getEmployeesListByCategory, [show, selectedDeptDropdown, selectedDivDropdown, selectedLocDropdown])

  const handleRemoveMember = (empId:any) => {
    setSelectedEmployees((state:any) => state.filter((itm:any) => itm.id!==empId))
  }

  const handleClearState = () => {
    setGroupDescriptionVal('')
    setSearchTerm('')
    setIsValidDescription(true)
    setIsValidGroupName(true)
    setIsValidMemberList(true)
    setIsValidDates(true)
    setStartDate(null)
    setEndDate(null)
    setSelectedEmployees([])
  }

  const handlePostCustomTag = () => {
    const employeesIds = selectedEmployees.map((emp:any) => emp.id)
    const payload: any = {
      "name": groupTitleVal,
      "color_hex_code": groupColor, 
      "employee_ids": employeesIds,
      "start_date": moment(startDate).format('YYYY-MM-DD'),
      "is_group": 1,
      "abbreviation_name": "GRP",
    };

    if (endDate) payload.end_date = moment(endDate).format('YYYY-MM-DD');
    if (groupDesciptionVal) payload.description = groupDesciptionVal;

    postCustomGroup(payload)
      .then(res => {
        handleClearState()
        onSubmitSuccess()
        onHide()
      })
      .catch(err => {
        console.log('err', err)
      })
      .finally(() => setIsDisableSubmit(false))
  }

  const handleEditCustomTag = () => {
    const employeesIds = selectedEmployees.map((emp:any) => emp.id)

    const payload: any = {
      "id": groupData?.id,
      "name": groupTitleVal,
      "color_hex_code": groupColor, 
      "employee_ids": employeesIds,
      "start_date": moment(startDate).format('YYYY-MM-DD'),
      "end_date": null,
      "description": null,
    }

    if (endDate) payload.end_date = moment(endDate).format('YYYY-MM-DD');
    if (groupDesciptionVal) payload.description = groupDesciptionVal;

    updateCustomGroup(payload)
      .then(res => {
        handleClearState()
        onSubmitSuccess()
        onHide()
      })
      .catch(err => {
        console.log('err', err)
      })
      .finally(() => setIsDisableSubmit(false))
  }

  /* --------------------- COMPONENT MOUNT/UNMOUNT STATES ----------------------- */

  useEffect(() => {
    if (isEdit && groupData) {
      setGroupDescriptionVal(groupData?.description)
      setSelectedEmployees(groupData?.users)
      setGroupColor(groupData?.colorCode)
      setGroupTitleVal(groupData?.name)
      if (groupData?.startDate) setStartDate(new Date(groupData?.startDate))
      if (groupData?.endDate) setEndDate(new Date(groupData?.endDate))
    } else {
      setGroupDescriptionVal('')
      setGroupTitleVal(groupTitle)
      setSelectedEmployees([])
      setGroupColor(selectedGroupColor || tagsColorPalette[0])
    }
  }, [isEdit, groupData, onHide])

  useEffect(() => {
    setIsValidDescription(true)
    setIsValidGroupName(true)
    setIsValidMemberList(true)
    if (!show) handleClearState()
  }, [onHide])

  /* ----------------------------- TOAST MESSAGES ------------------------------ */

  useEffect(() => {
    if (isSuccess && !isLoading) {
      dispatch(
        updateNotification({
          message: 'Custom group created successfully', 
          type: NotificationType.SUCCESS
        })
      )
    }
  }, [isSuccess])

  useEffect(() => {
    if (isSuccessUpdateTag && !isLoadingUpdateTag) {
      dispatch(
        updateNotification({
          message: 'Custom group updated successfully', 
          type: NotificationType.SUCCESS
        })
      )
    }
  }, [isSuccessUpdateTag])

  useEffect(() => {
    if (isError || isErrorUpdateTag) {
      dispatch(
        updateNotification({
          message: intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}),
          type: NotificationType.ERROR,
        })
      )
    }
  }, [isError, isErrorUpdateTag])

  const getMinEndDate = () => {
    if (!startDate) return new Date();
    if (moment(startDate).diff(new Date(), 'days') < 0) return new Date();
    return startDate
  }

  return (
    <Modal {...props} size='lg' aria-labelledby='contained-modal-title-vcenter' centered>
      <Modal.Header className='px-20' closeButton>
        <Modal.Title id='contained-modal-title-vcenter' style={{width: '100%'}}>
          <div className='d-flex align-items-center justify-content-center position-relative'>
            <button
              type='button'
              className='remove-btn-styles mx-4 position-relative'
              onFocus={() => setIsColorPickerActive(true)}
              onBlur={() => setTimeout(() => setIsColorPickerActive(false), 300)}
              >
              <CustomGroupIcon 
                color={tagsDarkColorShade[groupColor]}
                height={28}
                width={28}
                style={{marginRight: '10px'}}
                />
              {
                isColorPickerActive && 
                <div className='tag-color-picker-container' style={{width: '240px', left: '-100px'}}>
                  {
                    tagsColorPalette.map((item, index) => (
                      <RenderTagColorBtn
                        key={item}
                        color={item}
                        onClick={()=>setGroupColor(item)}
                      />
                    ))
                  }
                </div>
              }
            </button>
            <input
              placeholder='Enter group name'
              className='create-group-model-title'
              value={groupTitleVal}
              onChange={(v) => {
                setGroupTitleVal(v.currentTarget.value)
              }}
            />
            
            
          </div>
          {
            !isValidGroupName &&
            <p 
              style={{
              color: 'red', marginTop: '5px', marginLeft: '10px', fontSize: '13px', fontWeight: '400'
              }}>
                *Minimum 3 characters required
            </p>
          }
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-20'>
        <h6 className='d-flex fs-5'>
          Description
          {
            !isValidDescription &&
            <p style={{color: 'red', fontSize: '12px', alignSelf: 'center', marginLeft: '10px'}}>*Maximum of 160 characters</p>
          }
        </h6>
        
        <div className='wrap-create-custom-group-modal-input'>
          <textarea
            placeholder='Add group description'
            className='employee-search-input'
            value={groupDesciptionVal}
            onChange={(v) => {
              if (v.currentTarget.value.length < 160) {
                setGroupDescriptionVal(v.currentTarget.value)
                setIsValidDescription(true)
              } else {
                setIsValidDescription(false)
              }
            }}
          />
        </div>
        <div className='d-flex align-items-center justify-content-between mt-8'>
          <div className='d-flex align-items-center'>
            <h6 className='fs-5 m-0'>Start Date</h6>
            <DatePicker
              className='mx-6 datepicker-styles'
              selected={startDate} 
              onChange={(date: any) => setStartDate(date)}
              minDate={new Date()}
              maxDate={endDate}
              placeholderText='DD/MM/YYYY'
              dateFormat='dd/MM/YYYY'
              showMonthDropdown
              showYearDropdown
              disabled={groupData?.status && groupData?.status === 'live'}
              />
          </div>
          <div className='d-flex align-items-center'>
            <h6 className='fs-5 my-0 mx-6'>End Date</h6>
            <DatePicker
              className='datepicker-styles'
              selected={endDate} 
              onChange={(date: any) => setEndDate(date)} 
              minDate={getMinEndDate()}
              placeholderText='DD/MM/YYYY'
              dateFormat='dd/MM/YYYY'
              showMonthDropdown
              showYearDropdown
              />
          </div>
        </div>
        {
          !isValidDates &&
          <p style={{color: 'red', marginTop: '20px', alignSelf: 'center'}}>*Invalid start date or end date</p>
        }
        <div className='position-relative'>
          <h6 className='fs-5 mt-8'>Participants</h6>
          {/* CATEGORY DROPDOWNS */}
          <div className='my-4 d-flex align-items-center justify-content-between'>
            {/* DEPARTMENTS DROPDOWN */}
            <MultiSelectDropdown
              placeholder="Select Departments"
              setSelectedOptions={setSelectedDeptDropdown}
              selectedOptions={selectedDeptDropdown}
              options={activeDepartmentsList.map((item: any) => ({
                id: item.id, 
                name: item.name,
                guid: item.guid
              }))}
              />
            {/* DIVISIONS DROPDOWN */}
            <div className='mx-2 w-100'>
              <MultiSelectDropdown
                placeholder="Select Divisions"
                setSelectedOptions={setSelectedDivDropdown}
                selectedOptions={selectedDivDropdown}
                options={activeDivisionsList.map((item: any) => ({
                  id: item.id, 
                  name: item.name,
                  guid: item.guid
                }))}
                />
            </div>
            {/* LOCATIONS DROPDOWN */}
            <MultiSelectDropdown
              placeholder="Select Locations"
              setSelectedOptions={setSelectedLocDropdown}
              selectedOptions={selectedLocDropdown}
              options={activeLocationsList.map((item: any) => ({
                id: item.id, 
                name: item.name,
                guid: item.guid
              }))}
              />
          </div>
          <div className='wrap-create-custom-group-modal-input'>
            <i className="bi bi-search mx-2" style={{fontSize: '15px', color: 'grey'}} />
            <input
              placeholder='Search individuals'
              onFocus={() => setIsSearchTermActive(true)}
              onBlur={() => {
                setTimeout(() => {
                  setIsSearchTermActive(false)
                }, 300)
              }}
              className='employee-search-input'
              value={searchTerm}
              onChange={(v) => {
                setSearchTerm(v.currentTarget.value)
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter')  {
                  getEmployeeData()
                }
              }}
              />
          </div>
          { (employeesData && isSearchTermActive && searchTerm.trim().length>0) &&
            <div className='custom-tag-search-results-wrap'>
            {
              employeesData?.edges?.filter((item) => item.is_onboarding_completed === 1)?.map((item, index) => (
                <RenderSearchCard 
                  key={item.name} 
                  name={item.name} 
                  email={item.email}
                  addMember={() => handleAddMember(item)} 
                  isLastCell={employeesData?.edges.length === index+1} 
                  />
              ))
            }
            </div>
          }
        </div>
        <div className='d-flex mt-2'>
          {/* <h6 className='fs-5'>Selected participants ({selectedEmployees.length})</h6> */}
          {
            !isValidMemberList && !isLoadingGetEmployeeByCategory &&
            <p style={{color: 'red', marginLeft: '0px'}}>*Atleast 1 member is required</p>
          }
        </div>
        <div className='wrap-create-custom-group-modal-input-emp'>
          {
            isLoadingGetEmployeeByCategory &&
            <p style={{color: 'green', marginLeft: '0px'}}>Fetching employees...</p>
          }
          {
            selectedEmployees.map((item:any) => (
              <RenderMemberCard 
                key={item?.id}
                name={item?.name} 
                email={item?.email}
                removeMember={() => handleRemoveMember(item.id)} 
                />
            ))
          }
        </div>
        <div className='d-flex flex-end'>
          <button
            type='button'
            // disabled={!loading}
            className='custom-primary-delete-button m-0 mt-5'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={() => {
              if (checkValidForm() && !profile?.organization?.isWorkshopOrg) { // workshop handling
                setIsDisableSubmit(true);
                if (isEdit) {
                  handleEditCustomTag()
                } else {
                  handlePostCustomTag()
                }
              }
            }}
            disabled={isDisableSubmit}
            >
            {isEdit ? 'Update' : 'Submit'}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}