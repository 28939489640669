/* eslint-disable global-require */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
import React, {useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {useNavigate} from 'react-router-dom'
import moment from 'moment';

import ReactTable from '../../../_metronic/partials/components/tables/ReactTable';
import { RoutesPath } from '../../routing/RoutesPath';
import { commaSeparatedFormat } from '../../utils/helpers';
import './styles.css';

const energyTypeLabels:any = {
  'electricity': 'Electricity',
  'natural_gas': 'Natural Gas'
}

const RenderDate = ({value}: {value:string}) => (
  <div>{moment(value.slice(0,10)).format('DD MMM YYYY')}</div>
)

const RenderLocation = ({value}: {value:string}) => (
  <div>{value}</div>
)

const RenderEnergy = ({value}: {value:string}) => (
  <div>{energyTypeLabels[value]}</div>
)

const RenderStartDate = ({value}: {value:string}) => (
  <div>{moment(value.slice(0,10)).format('DD MMM YYYY')}</div>
)

const RenderEndDate = ({value}: {value:string}) => (
  <div>{moment(value.slice(0,10)).format('DD MMM YYYY')}</div>
)

const RenderUnits = ({value}: {value:string}) => (
  <div>{commaSeparatedFormat(value)}</div>
)

const RenderStatus = ({value}: {value:number}) => {
  if (value === 1) {
    return (
      <span
        className='badge fw-semibold me-1 fs-6'
        style={{
          backgroundColor: '#F5FCF0',
          color: '#82C44B',
        }}
      >
        Completed
      </span>
    )
  }
  return (
    <span
      className='badge fw-semibold me-1 fs-6'
      style={{
        backgroundColor: '#FFFAE2',
        color: '#C8AD2F',
      }}
    >
      Pending
    </span>
  )
}

const UtilitiesPage = (props:any) => {
  const intl = useIntl()
  const navigate = useNavigate()
  const {listData} = props
  const tableData = []
  if (listData) {
    for (let i=listData.length-1; i>=0; i-=1) {
      tableData.push(listData[i])
    }
  }

  const headers = useMemo(
    () => [
      {
        Header: 'DATE', 
        accessor: 'created_at', 
        Cell: ({cell}: {cell: any}) => <RenderDate value={cell.row.original.created_at} />
      },
      {
        Header: 'LOCATION', 
        accessor: 'work_location_name',  
        Cell: ({cell}: {cell: any}) => <RenderLocation value={cell.row.original.work_location_name} />
      },
      {
        Header: 'TYPE OF ENERGY', 
        accessor: 'energy_type',  
        Cell: ({cell}: {cell: any}) => <RenderEnergy value={cell.row.original.energy_type} />
      },
      {
        Header: 'START DATE OF BILL',
        accessor: 'bill_start_date',
        Cell: ({cell}: {cell: any}) => <RenderStartDate value={cell.row.original.bill_start_date} />
      },
      {
        Header: 'END DATE OF BILL',
        accessor: 'bill_end_date',
        Cell: ({cell}: {cell: any}) => <RenderEndDate value={cell.row.original.bill_end_date} />
      },
      {
        Header: 'UNITS',
        accessor: 'units',
        Cell: ({cell}: {cell: any}) => <RenderUnits value={cell.row.original.units} />
      },
      {
        Header: 'STATUS',
        accessor: 'is_processed',
        Cell: ({cell}: {cell: any}) => <RenderStatus value={cell.row.original.is_processed} />
      },
    ],
    []
  )

  return (
    <div className='blank-page-view py-8 px-12'>
      <div className='d-flex align-items-center justify-content-between'>
        <h1 className='page-title-text'>UTILITIES</h1>
        <button
            type='button'
            // disabled={!loading}
            className='custom-primary-delete-button m-0'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='top-end'
            onClick={()=>{navigate(RoutesPath.CONFIGURATION.ADD_UTILITY)}}
          >
          Add Utility Bill
        </button>
      </div>
      <div className=''>
        <ReactTable
          headerData={headers}
          tableData={tableData}
          className='mt-6 custom-border'
          currentPageIndex={0}
          disableSort
          showPagination
          message={intl.formatMessage({id: 'CONFIGURATION.UTILITY_BILL_LIST.NO_RESULTS_FOUND'})}
          />
      </div>
      <div className='cloud-upload-bg bg-image-bottom-right' />
    </div>
  )
}

export default UtilitiesPage
/* eslint-disable consistent-return */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable camelcase */
/* eslint-disable global-require */