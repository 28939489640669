/* eslint-disable jsx-a11y/anchor-is-valid */
import '../styles.css'

type WidgetProps = {
  options?: any
}

type CardProps = {
  label?: String | undefined
  value?: String | undefined
  info?: String | undefined
}

const Card: React.FC<CardProps> = ({label, value, info}) => (
  <div className='custom-header-card'>
        <div className='chc-title'>
          <span 
            className='fs-6'
            style={{color: 'white', fontWeight: 400}}
            >
            {label}
          </span>
        </div>
        <div className='chc-content'>
          <span
            className='value'
            style={{display: 'inline-block', verticalAlign: 'baseline', color: 'white', fontSize: '2.2rem'}}
            >
            {value}
          </span>
          <span
            className='sub-label'
            style={{display: 'inline-block', verticalAlign: 'baseline', color: 'white'}}
            >
              <strong>kg</strong>CO2
          </span>
        </div>
        <div className='chc-info mb-2'>
          <span 
            className='fs-6'
            style={{color: 'white', fontWeight: 400}}
            >
            {info}
          </span>
        </div>
  </div>
)
    

const CardsWidget2: React.FC<WidgetProps> = ({
  options
}) => (
  <>
    <Card label={options?.label} value={options?.value} info={options?.info} />
    <Card label={options?.label2} value={options?.value2} info={options?.info2} />
  </>
)

export {CardsWidget2}
