/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import ApexCharts, {ApexOptions} from 'apexcharts'
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store/store'
import { RoutesPath } from '../../../../app/routing/RoutesPath' 
import OpenIcon from '../../../../assets/OpenIcon.svg'
import {ActivityEmission, DateRangeType, EmissionDataType} from '../types'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import { commaSeparatedFormat } from '../../../../app/utils/helpers'
import { useLazyGetDivisionsListQuery, useLazyGetDepartmentsListQuery, useLazyGetLocationsListQuery, useLazyGetTopActivitiesDataQuery } from '../../../../app/modules/dashboard/store/api';
import { getYYYYDDMMFormat } from '../../../../app/utils/dateFormatter';
import { getLocalStorageItem } from '../../../../app/utils/storage';



type Props = {
  _data: Array<ActivityEmission> | [];
  type: string;
}

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const TopActivites: React.FC<Props> = ({
  _data,
  type
}) => {
  const [categoryVal, setCategoryVal] = useState<string>('All')
  const [listData, setListData] = useState(['All'])
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  const [selectedDateRange, setSelectedDateRange] = useState<DateRangeType>()
  const avgEployeesFlag = useSelector((state: RootState) => state.flags.avgEployeesFlag)
  const [data, setData] = useState<any>([]);
  const selectedMonth = useSelector((state: RootState) => state.filters.filterMonth)


  const setInitialSelectedDate = (month: number) => {
    if(selectedMonth === "All")
    {    
      const currentDate = new Date()
      const liveOnDate = new Date(getLocalStorageItem('live-on'))
      // currentDate.setMonth(currentDate.getUTCMonth() + 4)

      setSelectedDateRange({
        startDate: new Date(currentDate.getUTCFullYear(), liveOnDate.getUTCMonth(), 1),
        endDate: currentDate,
        })
    } else {
      const currentDate = new Date()
      const selectedYear =
        month > currentDate.getUTCMonth() ? currentDate.getUTCFullYear() - 1 : currentDate.getUTCFullYear()
      let endDay
      if (month === currentDate.getUTCMonth()) {
        endDay = new Date(selectedYear, month, currentDate.getUTCDate())
  
        // let newComparedMonth = new Date(selectedYear,month+1,currentDate.getUTCDate())
        // setSelectedCompareToMonth(newComparedMonth)
      } else {
        endDay = new Date(selectedYear, month + 1, 0)
      }
      setSelectedDateRange({
        startDate: new Date(selectedYear, month, 1),
        endDate: endDay,
      })
    }
  }

  const { filterDate, filterDivSubCategory, filterExtrapolation} = useSelector((state: RootState) => state.filters)

  const [getDivisionsList] = useLazyGetDivisionsListQuery()
  const [getDepartmentsList] = useLazyGetDepartmentsListQuery()
  const [getLocationsList] = useLazyGetLocationsListQuery()

  useEffect(() => {
    const month = selectedMonth
    const monthIndex = months.findIndex((itm) => itm.toLowerCase() === month.toLowerCase())
    setInitialSelectedDate(monthIndex)
  }, [selectedMonth])

  const [getTopActivitiesList, {data: topActivitiesList, isLoading: isLoadingTopActivities}] =
  useLazyGetTopActivitiesDataQuery()

  const navigate = useNavigate();

  useEffect(()=>{
    if(topActivitiesList){
      setData(topActivitiesList.top_activities);
    }
  },[topActivitiesList])

  useEffect(() => {
    // if (selectedDateRange) {
    //   if (categoryVal === 'All') {
    //     getTopActivitiesList({
    //       fromDate: getYYYYDDMMFormat(selectedDateRange.startDate),
    //       toDate: getYYYYDDMMFormat(selectedDateRange.endDate),
    //       category: type, 
    //       // is_scaled: (month==='All') && filterExtrapolation,
    //       by_average: avgEployeesFlag
    //     })
    //   } else {
    //     getTopActivitiesList({
    //       fromDate: getYYYYDDMMFormat(selectedDateRange.startDate),
    //       toDate: getYYYYDDMMFormat(selectedDateRange.endDate),
    //       category: type, 
    //       sub_category: categoryVal,
    //       by_average: avgEployeesFlag
    //       // is_scaled: (month==='All') && filterExtrapolation,
    //     })
    //   }
    // }
    if (filterDate.startDate !== '') {
      if (categoryVal === 'All') {
        getTopActivitiesList({
          fromDate: filterDate.startDate,
          toDate: filterDate.endDate,
          category: type, 
          // is_scaled: (month==='All') && filterExtrapolation,
          by_average: avgEployeesFlag
        })
      } else {
        getTopActivitiesList({
          fromDate: filterDate.startDate,
          toDate: filterDate.endDate,
          category: type, 
          sub_category: categoryVal,
          by_average: avgEployeesFlag
          // is_scaled: (month==='All') && filterExtrapolation,
        })
      }
    }
  }, [selectedDateRange, categoryVal, avgEployeesFlag, filterDate])

  const sortedData = [...data].sort((a, b) => b.emission - a.emission)
  const topThreeItems = sortedData.slice(0, 3)

  const populateDataList = (getApi: any, _setData: any) => {
    getApi({})
      .then((res: any) => {
        const tempData = ['All']
        res.data?.forEach((itm: any) => {
          tempData.push(itm.name)
        })
        _setData(tempData)
      })
      .catch((err: any) => console.log(err))
  }

  const handleCategorySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCategoryVal(e.target.value)
  }



  useEffect(() => {
    document.body.setAttribute('data-kt-app-toolbar-fixed', 'true')
    if (type === 'department') {
      populateDataList(getDepartmentsList, setListData)
    } else if (type === 'location') {
      populateDataList(getLocationsList, setListData)
    } else if (type === 'division') {
      populateDataList(getDivisionsList, setListData)
    }
  }, [type])

  return (
    <div className='card mb-0 mb-xl-8 pt-2 bordered-grey'>
      <div className='card-header border-0 pt-6'>
        <h3 className='card-title align-items-center flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>TOP ACTIVITIES</span>
        </h3>

        {/* begin::Wrapper */}
        <div className='d-flex align-items-center'>
          <select
            className='form-select form-select-sm w-100 form-select-solid'
            data-control='select2'
            data-placeholder='Latest'
            data-hide-search='true'
            value={categoryVal}
            onChange={handleCategorySelect}
          >
            {listData.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className='card-body pt-2'>
      <h6>Below are the top 3 activities in the {type}</h6>
      <div className='mt-5 mb-2'>
      {topThreeItems.map((item,index)=>(
            <div key={item?.name} className='flex-1 mt-8'>
                <div className='d-flex top-activity-card flex-1'>
                  <div className='d-flex gap-4 align-items-center'>
                  <div className='symbol activity-symbol symbol-30px'>
                    <img src={item?.icon} alt='' />
                  </div>
                    <p className='category-location-value'>{item.name}</p>
                  </div>

                <p className='category-location-value'>{commaSeparatedFormat(Math.round(item.emission))}<span style={{color:"rgba(141, 141, 141, 1)"}}> kgCO2</span></p>
                </div>
                
            </div>
        ))}
      </div>

      </div>
      {/* end::Body */}
    </div>
  )
}

export {TopActivites}
