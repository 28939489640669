import {BaseQueryFn} from '@reduxjs/toolkit/dist/query'
import axios, {AxiosError, AxiosRequestConfig} from 'axios'
import {Mutex} from 'async-mutex'
import {getLocalStorageItem, setLocalStorageItem} from '../utils/storage'
import {logout} from '../modules/auth/core/logout'
import {RoutesPath} from '../routing/RoutesPath'
import {RootState} from '../store/store'
import {updateToken} from '../store/slices/token'

const mutex = new Mutex()

function authHeader() {
  // return auth header with jwt if user is logged in and request is to the api url
  const isLoggedIn = getLocalStorageItem('token')
  if (isLoggedIn) {
    return {Authorization: `Bearer ${getLocalStorageItem('token')}`}
  }
  return undefined
}

const axiosBaseQuery = async ({
  baseUrl,
  customBaseUrl,
  url,
  method,
  data,
  params,
}: {
  baseUrl: string
  customBaseUrl?: string
  url: string
  method: AxiosRequestConfig['method']
  data?: AxiosRequestConfig['data']
  params?: AxiosRequestConfig['params']
}) => {
  try {
    const result = await axios({
      url: customBaseUrl ? customBaseUrl + url : baseUrl + url,
      ...(authHeader() && {headers: authHeader()}),
      method,
      data,
      params,
    })

    return {data: result.data}
  } catch (axiosError) {
    const err = axiosError as AxiosError
    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message,
      },
    }
  }
}

export const axiosBaseQueryWithReauth =
  (
    {baseUrl}: {baseUrl: string} = {baseUrl: ''}
  ): BaseQueryFn<
    {
      url: string
      method: AxiosRequestConfig['method']
      data?: AxiosRequestConfig['data']
      params?: AxiosRequestConfig['params']
      customBaseUrl?: string
    },
    unknown,
    unknown
  > =>
  async ({customBaseUrl, url, method, data, params}, api) => {
    const {dispatch} = api
    await mutex.waitForUnlock()
    const apiState = api.getState() as RootState
    const newBaseUrl = baseUrl + (apiState?.profile?.profile?.organization.guid || '')


    // if(url === '/admins/me' && result.error){

    // }

    // apiState?.profile?.profile?.organization.guid ;

    let result = await axiosBaseQuery({
      baseUrl: newBaseUrl,
      customBaseUrl,
      url,
      method,
      data,
      params,
    })

    if(url === '/admins/me' && result.error && !apiState?.profile?.profile?.organization.guid){
      logout(dispatch)
      window.location.pathname = RoutesPath.LOGIN
    }

    if (result.error && result.error.status === 401 && getLocalStorageItem('refresh-token')) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire()
        try {
          const accessToken = getLocalStorageItem('token')
          const refreshToken = getLocalStorageItem('refresh-token')
          const refreshResult = await axiosBaseQuery({
            baseUrl: `${process.env.REACT_APP_API_URL}`,
            url: `/auth/token-refresh/?access_token=${accessToken}&refresh_token=${refreshToken}`,
            method: 'GET',
          })
          if (!refreshResult.error) {
            setLocalStorageItem('token', refreshResult?.data?.data?.access_token || '')
            dispatch(updateToken(refreshResult?.data?.data?.access_token))
            result = await axiosBaseQuery({baseUrl, customBaseUrl, url, method, data, params})
          } else {
            logout(dispatch)
            window.location.pathname = RoutesPath.LOGIN
          }
        } finally {
          release()
        }
      } else {
        await mutex.waitForUnlock()
        result = await axiosBaseQuery({baseUrl, customBaseUrl, url, method, data, params})
      }
    }
    return result
  }
