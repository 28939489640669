export const PageTitle = {
  LOGIN: 'Login - Mynzo',
  DASHBOARD: 'Dashboard - Mynzo', 
  DIVISION: 'Divisions - Mynzo', 
  DEPARTMENT: 'Departments - Mynzo', 
  LOCATION: 'Locations - Mynzo', 
  EMPLOYEE: 'Employees - Mynzo', 
  FOREST: 'Forests - Mynzo', 
  SETTINGS: 'Settings - Mynzo',
  PROFILE: 'Profile - Mynzo', 
  CONFIGURATION: 'Configuration - Mynzo', 
  EDIT_PROFILE: 'Edit Profile - Mynzo',
}