export enum EVENT_TYPE {
  Event = 'Event',
  PageView = 'PageView', 
  Click = 'Click',
}

export enum EVENTS {
  Logged_In = 'Logged_In',
  Month_Filter = 'Month_Filter',
  SubCategory_Filter = 'SubCategory_Filter'
}

export enum PAGES {
  DashboardPage = 'Dashboard Page',
  DivisionsPage = 'Divisions Page',
  DepartmentsPage = 'Departments Page',
  LocationsPage = 'Locations Page',
  OurForestPage = 'OurForest Page',
  EmployeesPage = 'Employees Page',
  ConfigurationPage = 'Configuration Page',
  SettingsPage = 'Settings Page',
  AddUtilityBillPage = 'Add Utility Bill Page',
  CreateAttributesPage = 'Create Attributes Page',
  ChangePasswordPage = 'Change Password Page',
  EditProfilePage = 'Edit Profile Page',
  UploadEmployeeCSVPage = 'Upload Employee CSV Page', 
  AddEmployeePage = 'Add Employee Page',
  ForestsProjectUpdatesPage = 'Forests Project Updates Page', 
  ForestsAllocatedProjectsPage = 'Forests Allocated Projects Page',
  ErrorPage404 = 'Error Page 404', 
  EditEmployeeDetailPage = 'Edit Employee Detail Page'
}
