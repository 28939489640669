import {useIntl} from 'react-intl'
import { OurForestBefore14} from '../../../_metronic/assets/icons'
import {PageTitle} from '../../../_metronic/layout/core'

const ComingSoonCard = () => {
  const intl = useIntl()

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.OUR_FOREST'})}</PageTitle>

      <div className='card d-flex flex-column flex-root flex-column-fluid flex-center align-items-center text-center h-100'>
        {/* begin::SVG Illustration */}
        <div className='mb-8'>
          <OurForestBefore14 />
          </div>
        {/* end::SVG Illustration */}
        {/* begin::Message */}
        <div className='fs-1 fw-bold mt-10  me-7 text-dark pt-10' style={{color: '#A3A3C7'}}>
          We’re still building your climate identity.
        </div>
        <div className='fs-3 mt-2 pt-6' style={{color: '#000000'}}>
          Discover the exact forest acreage your enterprise needs for net zero. <br/> Stay tuned for our Forest SIP plans!
        </div>
        <div className='background-image-f forest-bg'/>
        {/* end::Message */}
      </div>
    </>
  )
}

export default ComingSoonCard
