import React, {useState} from 'react'; 

const infoHelperStyles:any = {
  position: 'absolute',
  backgroundColor: 'white', 
  padding: '15px 20px',
  borderRadius: '5px',
  width: 'max-content',
  zIndex: 10000,
  webKitBoxShadow: '0px 2px 8px 0px rgba(0,0,0,0.25)',
  mozBoxShadow: '0px 2px 8px 0px rgba(0,0,0,0.25)',
  boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.25)',
}

const InfoHelperIcon = (props: any) => {
  const {content, style={}} = props;
  const [showHelper, setShowHelper] = useState<boolean>(false)
  return (
    <div className='px-2 position-relative'>
      <button
        type='button'
        className='remove-btn-styles'
        onClick={() => setShowHelper(state => !state)}
        onBlur={() => setShowHelper(false)}
        >
        <i className="bi bi-info-circle" style={{color: 'black'}} />
      </button>
      {
        showHelper &&
        <div style={{...infoHelperStyles, ...style}}>
          <p className='m-0 p-0' style={{color: 'black'}}>
            {content || ''}
          </p>
        </div>
      }
    </div>
  )
}

export default InfoHelperIcon