const API_ENDPOINTS = {
  ADMINS: '/admins',
  UPDATE: '/update',
  RESET_PASSWORD: '/reset-password',
  UPLOAD_ORGANIZATION_LOGO: '/upload-organization-logo',
  ORGANIZATIONS: '/organizations',
  ORG_EMISSION_SYNC: '/organization-emission-sync',
}

export default API_ENDPOINTS
