/* eslint-disable global-require */
/* eslint no-restricted-syntax: ["error", "FunctionExpression", "WithStatement", "BinaryExpression[operator='in']"] */
import React, {useEffect, useState} from 'react'
import { useIntl } from 'react-intl'
import {useNavigate} from 'react-router-dom'

import { RoutesPath } from '../../../routing/RoutesPath';
import { useLazyGetCustomTagListQuery, useDeleteCustomTagMutation } from '../store/api';
import ModalCreateCustomModal from './components/modal-create-custom-tags';
import ModalDeleteCustomTag from './components/modal-delete-custom-tags';
import { EditPenIcon, TrashIcon2 } from '../../../../_metronic/assets/icons';
import { tagsColorPalette, tagsDarkColorShade } from '../../../utils/helpers';
import { updateNotification } from '../../../store/slices/notifier';
import { useAppDispatch } from '../../../store/store';
import { NotificationType } from '../../../../_metronic/partials/components/types';
import './styles.css';

type Props = {

}

export function transformTagListData(inputData:any) {
  const result = [];

  for (const key in inputData) {
    if (inputData[key]) {
      const item = inputData[key];
      const users = item.users.map((user:any) => (
        { id: user.id, name: user.name, email: user.email }
      ));
      const newItem = {
        id: item.id,
        name: key,
        color_code: item.color_code,
        abbreviation_name: item.abbreviation_name,
        is_archive: item.is_archive,
        users,
      };
      result.push(newItem);
    }
  }

  return result;
}

function transformUserListData(inputData:any) {
  const result = [];

  for (const id in inputData) {
    if (inputData[id]) {
      const item = inputData[id];
      const newItem = {
        id,
        name: item.name,
        tags: item.tags,
      };
      result.push(newItem);
    }
  }

  return result;
}

const RenderColorGroupCard: React.FC<any> = (props) => {
  const {name, color} = props
  return (
    <div 
      className='custom-group-display-color-card'
      style={{
        backgroundColor: color, 
        borderColor: tagsDarkColorShade[color]
      }}
      >
      <h6 className='m-0' style={{marginRight: '10px', color: tagsDarkColorShade[color]}}>{name}</h6>
    </div>
  )
}

const RenderCellGroups: React.FC<any> = (props) => {
  const {
    name, 
    tagId, 
    color, 
    isLastCell, 
    showModal, 
    handleIsEditCustomTag,
    selectedTagId, 
    setSelectedTagName,
    setDeleteTagId, 
    setSelectedTagId, 
    showDeleteModal
  } = props;
  return (
    <>
      <div className='cell-wrap-custom-groups' style={{backgroundColor: selectedTagId===tagId ? '#E5EAEE' : '#FFFFFF'}}> 
        <button 
          type='button'
          className='remove-btn-styles select-custom-tag-wrap' 
          onClick={() => {
            setSelectedTagId(tagId)
            setSelectedTagName(name)
          }}
          >
          <RenderColorGroupCard name={name} color={color} />
        </button>
        <div className='d-flex'>
          <button
            type="button"
            className='remove-btn-styles mx-8'
            onClick={() => {
              handleIsEditCustomTag(tagId)
              setTimeout(() => {
                showModal()
              }, 200)
            }}
            >
            <EditPenIcon style={{color: '#327286', height: '20px', width: '20px'}} />
          </button>
          <button
            type="button"
            className='remove-btn-styles'
            onClick={() => {
              // setDeleteTagId(tagId)
              handleIsEditCustomTag(tagId)
              setTimeout(() => {
                showDeleteModal()
              }, 200)
            }}
            >
            <TrashIcon2 style={{color: '#C03E35', height: '24px', width: '24px'}} />
          </button>
        </div>
      </div>
      { 
        !isLastCell &&
        <div style={{height: '1px', margin: '0px 20px', backgroundColor: '#E5EAEE'}} /> 
      }
    </>
  )
}

const RenderCellMembers: React.FC<any> = (props) => {
  const {name, tags, isLastCell} = props;
  const activeTags = tags.filter((item: any) => item?.is_archive === 0); 
  if (activeTags.length === 0)
    return null;

  return (
    <div 
      className='cell-wrap-custom-members' 
      style={{
        borderWidth: isLastCell ? '0px' : '0px 0px 1px 0px'
      }}
      >
      <div style={{width: '45%'}}>
        <h6 className='m-0'>{name}</h6>
      </div>
      <div className='d-flex scrollbar-thumb-grey' style={{width: '55%', overflow: 'scroll'}}>
        {
          activeTags.map((item:any) => (
            !(item?.is_archive === 1) ?
            <RenderColorGroupCard key={item.abbreviation_name} name={item.abbreviation_name} color={item.color_code} /> : 
            null
          ))
        }
      </div>
    </div>
  )
}

const CustomTagsPage: React.FC<Props> = () => {
  const navigate = useNavigate()
  const [groupNameVal, setGroupNameVal] = useState('')
  const [modalShow, setModalShow] = useState(false)
  const [isEditCustomTag, setIsEditCustomTag] = useState(false)
  const [deleteModalShow, setDeleteModalShow] = useState(false)
  const [deleteTagId, setDeleteTagId] = useState(0)
  const [isValidGroupName, setIsValidGroupName] = useState(true)
  const [selectedTagId, setSelectedTagId] = useState(0)
  const [selectedTagName, setSelectedTagName] = useState('')
  const [transformedCustomTagsListData, setTransformedCustomTagsListData] = useState([])
  const [transformedUsersListData, setTransformedUsersListData] = useState([])
  const [displayUsersListData, setDisplayUsersListData] = useState([])
  const [tagData, setTagData] = useState(null)

  const intl = useIntl()
  const dispatch = useAppDispatch()

  const [getCustomTagList, {
    data: customTagListData, 
    isSuccess: isSuccessGetTags, 
    isLoading: isLoadingGetTags,
    isError: isErrorGetTags,
  }] = useLazyGetCustomTagListQuery();

  const [deleteCustomTag, {
    isSuccess: isSuccessDeleteTag, 
    isLoading: isLoadingDeleteTag, 
    isError: isErrorDeleteTag
  }] = useDeleteCustomTagMutation()

  const checkValidGroupName = (groupName:string) => {
    const str = groupName.trim()
    if (str.length<3) {
      setIsValidGroupName(false)
      return false;
    }
    setIsValidGroupName(true)
    return true;
  }

  const handleDeleteCustomTag = (tagId:any) => {
    deleteCustomTag(tagId)
    .then(res => {
      getCustomTagList({})
      .catch(err => console.log(err))
    })
    .catch(err => console.log(err))
  }

  const handleAddCustomTag = () => {
    getCustomTagList({})
      .catch(err => console.log(err))
    setGroupNameVal('')
  }

  const handleIsEditCustomTag = (tagId:any) => {
    setIsEditCustomTag(true)
    setTagData(transformedCustomTagsListData.filter((item:any) => item.id === tagId)[0])
  }

  useEffect(() => {
    if (customTagListData) {
      const tagsRes:any = transformTagListData(customTagListData?.data?.tags) || []
      const usersRes:any = transformUserListData(customTagListData?.data?.users) || []
      setTransformedCustomTagsListData(tagsRes.filter((item:any) => !item?.is_archive || item?.is_archive === 0))
      setTransformedUsersListData(usersRes)
      if (tagsRes.length>0) {
        setSelectedTagId(tagsRes[0]?.id)
        setSelectedTagName(tagsRes[0]?.abbreviation_name || '')
      }
    }
  }, [customTagListData])

  useEffect(() => {
    const tempRes:any = transformedCustomTagsListData.filter((item:any) => item.id === selectedTagId)[0]
    const selectedTagUsersList:any = [];
    if (tempRes) {
      tempRes?.users.forEach((item:any) => selectedTagUsersList.push(String(item.id)))
    }
    setDisplayUsersListData(state => transformedUsersListData.filter((item:any) => selectedTagUsersList.includes(item.id)))
  }, [transformedUsersListData, selectedTagId])

  useEffect(() => {
    getCustomTagList({})
    .catch(err => console.log(err))
  }, [])

  useEffect(() => {
    if (isSuccessDeleteTag && !isLoadingDeleteTag) {
      dispatch(
        updateNotification({
          message: 'Custom tag deleted successfully', 
          type: NotificationType.SUCCESS
        })
      )
    }
  }, [isSuccessDeleteTag])

  useEffect(() => {
    if (isErrorDeleteTag || isErrorGetTags) {
      dispatch(
        updateNotification({
          message: intl.formatMessage({id: 'SOMETHING_WENT_WRONG'}),
          type: NotificationType.ERROR,
        })
      )
    }
  }, [isErrorDeleteTag, isErrorGetTags])

  return (
    <div className='blank-page-view py-8 px-12'>
      <h1 className='page-title-text'>CREATE CUSTOM TAGS</h1>
      <div>
        <h6 className='fs-5 grey mt-10'>
          Name your tag 
          {
            !isValidGroupName &&
            <span style={{color: 'red', fontSize: '12px'}}> *Minimum 3 characters required</span>
          }
        </h6>
        <div className='d-flex w-100'>
          <div className='wrap-create-custom-group-search-input'>
            <input
              placeholder='Enter tag name (minimum 3 chars)'
              className='employee-search-input'
              value={groupNameVal}
              onChange={(v) => {setGroupNameVal(v.currentTarget.value)}}
              onKeyDown={(e) => {
                if (e.key === 'Enter')  {
                  if (checkValidGroupName(groupNameVal)) {
                    setModalShow(true)
                  }
                }
              }}
            />
          </div>
          <button
              type='button'
              // disabled={!loading}
              className='custom-primary-delete-button m-0 mx-10'
              data-kt-menu-trigger='click'
              data-kt-menu-placement='bottom-end'
              data-kt-menu-flip='top-end'
              onClick={() =>{
                setIsEditCustomTag(false)
                if (checkValidGroupName(groupNameVal)) {
                  setTimeout(() => {
                    setModalShow(true)
                  }, 200)
                }
              }}
            >
            Create New Tag
          </button>
        </div>
      </div>
      <div className='d-flex mt-10 mx-0'>
        <div className='custom-groups-container'>
          <div className='header-custom-group-contrainer'>
            <h6 className='m-0'>Tags ({transformedCustomTagsListData.length})</h6>
          </div>
          <div className='body-custom-group-contrainer'>
            {
              (customTagListData && transformedCustomTagsListData.length===0) && 
                <div className='cell-wrap-custom-groups empty-custom-cell-wrap'>
                  <h6 className='m-0' style={{color: 'rgb(111, 111, 111)'}}>Create a custom tag</h6>
                </div>
            }
            { 
              (customTagListData && transformedCustomTagsListData.length>0) &&
              transformedCustomTagsListData.map((item:any, index) => (
                <RenderCellGroups 
                  key={item.name} 
                  name={item.abbreviation_name} 
                  tagId={item.id}
                  color={item.color_code} 
                  handleIsEditCustomTag={handleIsEditCustomTag}
                  showModal={() => setModalShow(true)} 
                  showDeleteModal={() => setDeleteModalShow(true)}
                  setDeleteTagId={setDeleteTagId}
                  selectedTagId={selectedTagId}
                  setSelectedTagId={setSelectedTagId}
                  setSelectedTagName={setSelectedTagName}
                  isLastCell={index===transformedCustomTagsListData.length-1} 
                  />
              ))
            }
          </div>
        </div>
        <div className='group-memebers-container'>
          <div className='header-group-memebers-container'>
            <h6 className='m-0 header1'>Members of {selectedTagName} ({displayUsersListData.length})</h6>
            <h6 className='m-0 header2'>Tags shared by member</h6>
          </div>
          <div className='body-group-memebers-container'>
            {
              (customTagListData && displayUsersListData.length===0) &&
              <div className='cell-wrap-custom-members empty-custom-cell-wrap'>
                <h6 className='m-0' style={{color: 'rgb(111, 111, 111)'}}>Tag members will be shown here</h6>
              </div>
            }
            {
              (customTagListData && displayUsersListData.length>0) &&
              displayUsersListData.map((item:any, index) => (
                <RenderCellMembers 
                  key={item.id} 
                  name={item.name} 
                  tags={item.tags} 
                  isLastCell={index===transformedUsersListData.length-1} 
                  />
              ))
            }
          </div>
        </div>
      </div>
      <ModalCreateCustomModal 
        show={modalShow} 
        onHide={() => {
          setIsEditCustomTag(false)
          setModalShow(false)
        }} 
        groupTitle={groupNameVal} 
        isEdit={isEditCustomTag} 
        tagData={tagData}
        onSubmitSuccess={handleAddCustomTag}
        />
      <ModalDeleteCustomTag 
        show={deleteModalShow} 
        onHide={() => setDeleteModalShow(false)} 
        tagData={tagData}
        onSubmitSuccess={handleAddCustomTag}
        />
    </div>
  )
}

export default CustomTagsPage
/* eslint no-restricted-syntax: ["error", "FunctionExpression", "WithStatement", "BinaryExpression[operator='in']"] */
/* eslint-disable global-require */