/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react'
import {Route, Routes} from 'react-router-dom'
import mixpanel from 'mixpanel-browser'
import {Error500} from './components/Error500'
import {Error404} from './components/Error404'
import { EVENT_TYPE, PAGES } from '../../analytics/tracker-types'

const ErrorsPage = () => {
  useEffect(() => {
    // tracking 
    mixpanel.track(EVENT_TYPE.PageView, {'name': PAGES.ErrorPage404})
  },[])
  return (
  <Routes>
    <Route path='404' element={<Error404 />} />
    <Route path='500' element={<Error500 />} />
    <Route index element={<Error404 />} />
  </Routes>
)}

export {ErrorsPage}
