/* eslint-disable global-require */
/* eslint-disable no-nested-ternary */
import React, {useState, useRef, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import { useDropzone } from 'react-dropzone';
import mixpanel from 'mixpanel-browser';
import { useSelector } from 'react-redux'

import { useUploadEmployeesMutation } from '../../routing/store/api';
import { RoutesPath } from '../../routing/RoutesPath';
import { formatBytes } from '../../utils/helpers';
import './styles.css';
import { FileIcon, TrashIcon2 } from '../../../_metronic/assets/icons';
import { EVENT_TYPE, PAGES } from '../../analytics/tracker-types';
import { RootState } from '../../store/store'

type Props = {

}

const UploadEmployeeCSV: React.FC<Props> = () => {
  const navigate = useNavigate()
  const [postUploadEmployees, {data: uploadEmployeesResponse, isError: uploadCSVerror}] = useUploadEmployeesMutation();
  const {profile} = useSelector((state:RootState) => state.profile)
  const [isVerified, setIsVerified] = useState<boolean>(false)
  const [incorrectCSV, setIncorrectCSV] = useState<boolean>(false)
  const [selectedCsvFile, setSelectedCsvFile] = useState<any | undefined>()
  const [selectedFileName, setSelectedFileName] = useState<string | null>(null);
  const [selectedFileSize, setSelectedFileSize] = useState<string | null>(null);
  const [isCSVlimit, setIsCSVlimit] = useState<boolean>(false);
  const [isUploadingCSV, setIsUploadingCSV] = useState<boolean>(false);

  useEffect(() => {
    // tracking
    mixpanel.track(EVENT_TYPE.PageView, {'name': PAGES.UploadEmployeeCSVPage})
  }, [])

  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'text/csv': ['.csv'],
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length===0) {
        alert('Please select a .csv format file');
      }
      setSelectedCsvFile(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        )
      )
    },
  })

  const handleRemoveCsvFile = () => {
    setSelectedFileName(null);
    setSelectedFileSize(null);
    setSelectedCsvFile(null);
    setIsVerified(false); 
    setIncorrectCSV(false); 
    setIsCSVlimit(false);
  }

  const handleVerifyCSV = () => {
    const formData = new FormData();
    if (selectedCsvFile && selectedCsvFile.length>0) {
      formData.append('is_validate', 'true')
      formData.append('employees', selectedCsvFile[0])
      postUploadEmployees(formData)
        .then((res:any) => {
          if (res?.data?.errors?.length>0) {
            setIsVerified(false)
            setIncorrectCSV(true)
          } else {
            setIsVerified(true)
            setIncorrectCSV(false)
          }
        })
        .catch(err => setIncorrectCSV(true))
        .finally(() => setIsUploadingCSV(false))
    } else {
      setIsUploadingCSV(false)
    }
  }

  const handleFileUploadCSV = () => {
    // workshop handling
    if (profile?.organization?.isWorkshopOrg) {
      return;
    }
    const formData = new FormData();
    if (selectedCsvFile && selectedCsvFile.length>0) {
      formData.append("is_validate", "false");
      formData.append("employees", selectedCsvFile[0]);
      postUploadEmployees(formData)
        .then(() => navigate(RoutesPath.EMPLOYEES.EMPLOYEES_DETAILS))
        .catch(err => console.log(err))
        .finally(() => setIsUploadingCSV(false))
    } else {
      setIsUploadingCSV(false)
    }
  }

  useEffect(() => {
    if (selectedCsvFile && selectedCsvFile.length>0) {
      if (selectedCsvFile[0]?.name.endsWith('.csv')) {
          setSelectedFileName(selectedCsvFile[0]?.name)
          setSelectedFileSize(formatBytes(selectedCsvFile[0]?.size))
          if (selectedCsvFile[0]?.size >= 100*1024*1024) {
            setIsCSVlimit(true)
          } else {
            setIsCSVlimit(false)
          }
      } else {
        alert('Please select a valid .csv file.');
      }
    } else {
      setIsCSVlimit(false)
    }
  }, [selectedCsvFile])

  return (
    <div className='blank-page-view py-8 px-12'>
      <div className='back-header-btn mb-10'>
        <button 
          type='button' 
          className='p-2 back-left-arrow-btn-wrap'
          onClick={()=>{navigate(RoutesPath.EMPLOYEES.ROOT)}}
          >
          <div className='back-left-arrow-btn' /> 
        </button>
        <h6 className='m-0 mx-4 fs-4 font-weight-normal'>Back</h6>
      </div>
      <div className='centered-div-wrap'>
        {/* <input {...getInputProps()} accept="text/csv" /> */}
        <h1 className='bold'>UPLOAD EMPLOYEE CSV</h1>
        {selectedCsvFile?.length>0 ? (
            <div className='csv-file-drop-div position-relative py-20 mt-10'>
              <div className='uploaded-csv-file-name-wrap' style={{zIndex: 1000}}>
                <div className='d-flex align-items-center'>
                  <FileIcon style={{color: '#666666'}} />
                  <p className='upload-csv-file-name'>{selectedFileName}</p>
                </div>
                <button
                  type="button"
                  className='remove-btn-styles'
                  onClick={handleRemoveCsvFile}
                  >
                  <TrashIcon2 style={{color: 'black', height: '26px', width: '26px'}} />
                </button>
              </div>
              <p className='m-0 text-end fs-8 fw-700'>{selectedFileSize}</p>
              {
                uploadCSVerror &&
                <p className='fs-6 mt-6 mb-0' style={{color: 'red'}}>Something went wrong, please try again later!</p>
              }
              {
                incorrectCSV && 
                <p className='fs-6 mt-6 mb-0' style={{color: 'red'}}>Incorrect CSV format, try downloading sample.csv for reference!</p>
              }
              {
                isVerified &&
                <p className='fs-6 mt-6 mb-0' style={{color: 'green'}}>CSV verified, you can submit the file!</p>
              }
              {
                isCSVlimit &&
                <p className='fs-6 mt-6 mb-0' style={{color: 'red'}}>CSV file size limit exceeded!</p>
              }
              <button
                type="button"
                className='mt-4 mb-0 custom-primary-delete-button remove-btn-styles'
                disabled={isCSVlimit || incorrectCSV || isUploadingCSV}
                onClick={() => {
                  if (isUploadingCSV)
                    return;
                  
                  if (isVerified) {
                    setIsUploadingCSV(true)
                    handleFileUploadCSV()
                  } else {
                    setIsUploadingCSV(true)
                    handleVerifyCSV()
                  }}
                }
                >
                {
                  isUploadingCSV ? 
                  "Loading.." : 
                  (isVerified ? 'Submit' : 'Verify')
                }
              </button> 
            </div>
          )
          :
          (
            <div {...getRootProps({className: 'dropzone csv-file-drop-div position-relative py-20 mt-10'})}>
              <input {...getInputProps()} accept=".csv" />
              <h2 className='my-0 no-bold'>DRAG & DROP HERE</h2>
              <h6 className='my-4'>OR</h6>
              <h2 className='mt-0 mb-4 no-bold'>CLICK TO UPLOAD</h2>
              <p className='fs-12' style={{color: '#666666'}}>Maximum limit 100 MB</p>
              
              <div className='cloud-upload-bg bg-image-bottom-right' />
            </div>
          )
        }
        <div className='mt-5'>
          <p className='fs-5 mb-0 d-inline' style={{color: '#666666'}}>Download sample CSV file </p>
          <a 
            type='button' 
            className='fs-5 m-0 anchor-blue-p-link' 
            href='https://mynzo.s3.ap-south-1.amazonaws.com/sample-employees-template.csv'
            >
            sample.csv
          </a>
        </div>
        <p className='fs-5 mt-5 mb-0' style={{color: '#666666'}}>Don&apos;t have a CSV file?</p>
        <button 
          type='button' 
          className='fs-5 m-0 anchor-blue-p-link' 
          onClick={()=>{navigate(RoutesPath.EMPLOYEES.ADD_EMPLOYEE)}}
          >
          Add individual employee details
        </button>
      </div>
    </div>
  )
}

export default UploadEmployeeCSV
/* eslint-disable no-nested-ternary */
/* eslint-disable global-require */